import React, { Component } from 'react';
import { Button, Drawer, Spin } from 'antd';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './index.css'
import ShowMenu from '../menu';
import API from '../../../../api'
import { withRouter, Link } from 'react-router-dom'
import Footer from '../../home/footer/index';

class ShowHotel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      dataList: [],
      totalPrice: '',
      dta: '',
      selectedRoom: '',
      loading: true,
      credits: 0,
      credit_disabled: false,
      visible: false,
      user: [],
      service_fee: 0,
      final_price: 0,
      payment_type: '',
    };

    // this.handleTotalPrice = this.handleTotalPrice.bind(this);
    this.handleSelectedRoom = this.handleSelectedRoom.bind(this);
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };


  componentDidMount() {
    this.userFetch();
    window.scrollTo(0, 0)
    // this.banklistFetch();
    const params = this.props.location.search;
    let url = './api/v1' + this.props.location.pathname;
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        //console.log(response,'------------res');
        that.setState({
          dataList: response.data,
          loading: false
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  getCredits = (value) => {
      this.setState({
        credits: value
      }, () => { this.handleUserCredits(); })
  }

  // banklistFetch() {
  //   let url = './api/v1/payments/payment_method';
  //   var that = this;

  //   API.get(url)
  //     .then(function (response) {
  //       // console.log(response, '------------res banklist');
  //       that.setState({
  //         credits: response.data.credits,
  //       })
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     })
  //     .then(function () {
  //       // always executed
  //     });
  // }

  // handleTotalPrice(value) {
  //   this.setState({
  //     totalPrice: value,
  //     loading: false
  //   })
  // }

  handleSelectedRoom(value) {
    // var total_price = this.state.user.collect_dta ? value.price + value.dta : value.price
    var total_price = value.price + value.dta
    var final_price = this.state.user.collect_dta ? total_price : total_price - value.dta
    var service_fee = this.state.dataList.cc_rate * total_price;

    this.setState({
      totalPrice: final_price,
      final_price: final_price,
      dta: value.dta,
      selectedRoom: value,
      service_fee: service_fee,
    }, () => { this.handleUserCredits(); })
  }

  handleUserCredits() {
    if (this.state.credits >= this.state.totalPrice) {
      this.setState({
        credit_disabled: false
      })
    }
    else {
      this.setState({
        credit_disabled: true
      })
    }
  }

  handlePaymentType = (value) => {
      this.setState({
        payment_type: value
      })
    }

  userFetch() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          user: response.data,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    const { photoIndex, isOpen, dataList, totalPrice, dta, selectedRoom, loading, credit_disabled, credits, visible, service_fee, payment_type, final_price } = this.state;

    if (dataList.hasOwnProperty('images') && dataList.images.length > 0) {
      const images = dataList.images;

      return (
        <div>
          <ShowMenu />
          <div id="ShowHotel">

            {
              images.length > 1 ? (
                <div>
                  <div>
                    <img className="bannerImage" src={images[0]['image_url']} />
                  </div>
                  <div id="overlay"></div>

                  <div className="logo_top">
                    <Link to="/">
                      <div className="logo_warp">
                        <img src={homepage_new.app_logo} alt="ice" />
                        <span>TRAVELB2B</span>
                      </div>
                    </Link>
                  </div>

                  <div className="img_lightbox">
                    <div className="img_cantainer">
                      <div className="img_warp"><img src={images[1]['image_url']} /></div>
                      <div className="img_warp"><img src={images[2]['image_url']} /></div>
                      <div className="clear"></div>
                    </div>
                    <Button type="primary" onClick={() => this.setState({ isOpen: true })}>VIEW MORE</Button>
                    {isOpen && (
                      <Lightbox
                        mainSrc={images[photoIndex]['image_url']}
                        nextSrc={images[(photoIndex + 1) % images.length]['image_url']}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]['image_url']}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + images.length - 1) % images.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex: (photoIndex + 1) % images.length,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
              ) : (
                  <div>
                    <div className="">
                      <img className="bannerImage" src={images[0]['image_url']} />
                    </div>
                    <div id="overlay"></div>
                  </div>
                )
            }
            <Spin size="large" spinning={loading}>
              <div className="warppage">
                <LeftPanel dataList={dataList} /* handleTotalPrice={this.handleTotalPrice} */ handleSelectedRoom={this.handleSelectedRoom} getCredits={this.getCredits} credit_disabled={credit_disabled} credits={credits} service_fee={service_fee} final_price={final_price} handlePaymentType={this.handlePaymentType} />
                <RightPanel dataList={dataList} totalPrice={totalPrice} dta={dta} selectedRoom={selectedRoom} collectDta={this.state.user.collect_dta} service_fee={service_fee} payment_type={payment_type} />
                <div className="clear"></div>
              </div>
              <div className="res_panel">
                <Button type="primary" onClick={this.showDrawer} className="res_panel_btn">
                  Summary
                    </Button>
                <Drawer
                  title=""
                  placement="right"
                  closable={true}
                  onClose={this.onClose}
                  visible={visible}
                >
                  <RightPanel dataList={dataList} totalPrice={totalPrice} dta={dta} selectedRoom={selectedRoom} collectDta={this.state.user.collect_dta} service_fee={service_fee} />
                </Drawer>
              </div>
            </Spin>
          </div>
        </div>
      );
    }
    else {
      return (
        <div>
          <ShowMenu />
          <div id="ShowHotel">
            <div className="banner"></div>
            <div id="overlay"></div>
            <Spin size="large" spinning={loading}>
              <div className="warppage">
                <LeftPanel dataList={dataList} /* handleTotalPrice={this.handleTotalPrice} */ handleSelectedRoom={this.handleSelectedRoom} getCredits={this.getCredits} loading={loading} credit_disabled={credit_disabled} credits={credits} />
                <RightPanel dataList={dataList} totalPrice={totalPrice} dta={dta} loading={loading} />
                <div className="clear"></div>
              </div>
            </Spin>
          </div>
          <Footer />
        </div>
      )
    }
  }
}

export default withRouter(ShowHotel);
