import React, { Component } from 'react';
import { Card, Icon, Radio, Collapse, Slider, InputNumber, Checkbox, Rate } from 'antd';
import './index.css';
import withRouter from "react-router-dom/es/withRouter";

const { Meta } = Card;
const { Panel } = Collapse;

function callback(key) {
    console.log(key);
}

function onChange(value) {
    console.log('onChange: ', value);
}

function onAfterChange(value) {
    console.log('onAfterChange: ', value);
}

function onChangeBox(checkedValues) {
    console.log('checked = ', checkedValues);
}
function onChangeStar(e) {
    console.log(`checked = ${e.target.checked}`);
}
const facilities = ['Wifi', 'Baggage', 'Meal', 'Power / USB port', 'In-flight entertainment'];
const rate = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

class LeftPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 1,
            inputValue: 1,
            cutValue: 100,
            addValue: 5000,
            onBeforeValue: '0',
            onAfterValue: '0'
        }
    }

    onChangeValue = value => {
        this.setState({
            onBeforeValue: value,
        });
    };

    onAfterChange = value => {
        console.log('onAfterChange: ', value);
        this.setState({
            onAfterValue: value
        })
    }

    // onAfterChange = value => {
    //     this.setState({
    //         addValue: value
    //     });
    // };



    onChange = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };

    sortByOnChange = e => {
      console.log("radio checked", e.target.value);
      this.props.sortByCallback(e.target.value);
    };

    render() {
        const { inputValue, addValue, cutValue, onAfterValue, onBeforeValue } = this.state;
        const addNum = onAfterValue[1];
        const beforeNum = onBeforeValue[0];
        // console.log(onBeforeValue, '-------onBeforeValue')
        // console.log(addValue, '-------addValue')

        return (
            <div id="LeftPanel">
                <div className="sort_result">
                    <Card
                        actions={[
                            <Radio.Group onChange={this.sortByOnChange} value={this.props.sortBy}>
                                <Radio value='lowest_price'>Lowest Price</Radio>
                                <Radio value='highest_price'>Highest Price</Radio>
                                {/* <Radio value={3}>Review Score</Radio>
                                <Radio value={4}>Popularity</Radio> */}
                            </Radio.Group>
                        ]}
                    >
                        <Meta
                            title="Sort Results"
                            description="Sort your search results by"
                        />
                    </Card>
                </div>
                {/* <div className="filter_result">
                    <Card
                        actions={[
                            <Collapse
                                defaultActiveKey={['1']}
                                onChange={callback}
                                expandIconPosition="right"
                            >
                                <Panel header="Price Range Per Night" key="1">
                                    <div className="slider_value">
                                        <p>RM {onBeforeValue === '0' ? cutValue : beforeNum}</p>
                                        <p>-</p>
                                        <p>RM {onAfterValue === '0' ? addValue : addNum}</p>
                                        <div className="clear"></div>
                                    </div>

                                    <Slider
                                        range
                                        step={10}
                                        min={100}
                                        max={10000}
                                        defaultValue={[this.state.cutValue, this.state.addValue]}
                                        onChange={this.onChangeValue}
                                        onAfterChange={this.onAfterChange}
                                    />
                                </Panel>
                                <Panel header="Star Rating" key="2">
                                    <div className="checkbox_warp star_check">
                                        <Checkbox onChange={onChangeStar}>
                                            <Rate disabled defaultValue={1} />
                                        </Checkbox>
                                        <Checkbox onChange={onChangeStar}>
                                            <Rate disabled defaultValue={2} />
                                        </Checkbox>
                                        <Checkbox onChange={onChangeStar}>
                                            <Rate disabled defaultValue={3} />
                                        </Checkbox>
                                        <Checkbox onChange={onChangeStar}>
                                            <Rate disabled defaultValue={4} />
                                        </Checkbox>
                                        <Checkbox onChange={onChangeStar}>
                                            <Rate disabled defaultValue={5} />
                                        </Checkbox>
                                    </div>
                                </Panel>
                                <Panel header="Facilities" key="3">
                                    <div className="checkbox_warp">
                                        <Checkbox.Group
                                            options={facilities}
                                            defaultValue={['Apple']}
                                            onChange={onChangeBox}
                                        />

                                    </div>
                                </Panel>

                            </Collapse>
                        ]}
                    >
                        <Meta
                            title={[
                                <div className="title">
                                    <p>Filter Results</p>
                                    <p><small>Reset Filter</small></p>
                                </div>
                            ]}
                            description="Showing results based on categories"
                            extra={<a href="#">More</a>}
                        />
                    </Card>
                </div> */}
            </div>
        );
    }
}

export default withRouter(LeftPanel);
