import React, { Component } from 'react';
import { Form, Card, Row, Col, Slider, Divider, Button } from 'antd';
import instant from '../../../../../images/payment/instant.png';
import PaymentMethod from '../../show/payment';
import { withRouter } from 'react-router-dom';
import API from '../../../../api';

const gridStyle = {
  width: '100%',
  // textAlign: 'center',
};

class LeftPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selected: '',
      selected_package: '',
      banklist: [],
      payment_type: '',
      payment_method: null,
      check_out_disabled: false,
      banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      banklist_select: [],
      method_visible: false,
      validationErrors: [],
      agree: '',
      fixed_package: { amount: 0, credits: 0, extra_credits: 0 },
      custom_package: { amount: 0, credits: 0, extra_credits: 0 }
    }
  }

  priceFormat(value) {
    // return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  componentDidMount() {
    this.banklistFetch();
  }

  handlePurchaseInfo = (value) => {
    this.props.handlePurchaseInfo(value)
  }

  onChange = value => {
    this.setState({
      inputValue: value,
    });

    this.state.custom_package['amount'] = value
    this.state.custom_package['credits'] = value
    this.state.custom_package['extra_credits'] = 0
    let type_value = 0

    if (value >= 20000) {
      type_value = 20000
    } else if (value >= 10000) {
      type_value = 10000;
    } else if (value >= 4000) {
      type_value = 4000;
    } else if (value >= 2000) {
      type_value = 2000;
    }

    this.state.custom_package['extra_credits'] = type_value > 0 ? Math.floor(value * this.props.credit_packages.find(cp => cp.amount == type_value).extra_credits_percentage / 100) : 0;
    
    this.handlePurchaseInfo(this.state.custom_package)
  };

  onClickCredit(event) {
    this.setState({
      selected: event.currentTarget.dataset.id,
      selected_package: event.currentTarget.dataset.type
    })

    if (event.currentTarget.dataset.type == 'fixed') {
      this.state.fixed_package['amount'] = this.props.credit_packages[event.currentTarget.dataset.id].amount
      this.state.fixed_package['credits'] = this.props.credit_packages[event.currentTarget.dataset.id].credits
      this.state.fixed_package['extra_credits'] = this.props.credit_packages[event.currentTarget.dataset.id].amount * this.props.credit_packages[event.currentTarget.dataset.id].extra_credits_percentage / 100

      this.handlePurchaseInfo(this.state.fixed_package)
    }
    
    console.log('event', event.currentTarget.dataset.id)
  }

  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          // credits: response.data.credits
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handlePaymentChange(event) {
    console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'fpx_b2c') {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else /** if (event.target.value === 'credit_transaction') **/ {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }

    this.setState({
      payment_type: event.target.value
    })
  }

  handlePaymentMethodChange(value) {
    this.setState({
      payment_method: value
    })
  }

  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  };

  submitForm = event => {
    event.preventDefault();

    const { fixed_package, custom_package, selected_package, payment_type, payment_method, agree } = this.state;

    let outputJson = {
      purchase_info: {...selected_package == 'fixed' ? fixed_package : custom_package,...{package_type: selected_package}},
      payment: { gateway: payment_type, method: payment_method },
      agree: agree
    };
    console.log("This is the outputJson", outputJson);

    let url = './api/v1' + this.props.location.pathname + '/purchase_credits/';
    let that = this;

    API.post(url, outputJson)
      .then(function (response) {
        console.log(response, '------------res');
        console.log(response.data.redirect_to, '------------redirect');
        if (response.data.redirect_to) {
          window.location = response.data.redirect_to
        // } else if (response.data.credits_error) {
        //   that.openNotificationWithIcon('error','credits_error');
        //   that.setState({ payment_type: '' })
        }
        else {
          that.setState({
            // check_out_disabled: false,
            // bookingErrorModal: true,
            // bookingErrorMessage: response.data.error
          })
        }
        that.setState({
          loading: false
        })
      })
      .catch(function (error) {
        console.log(error);
        that.setState({
          visible: true,
        })
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    const { inputValue, selected, credits, payment_type, payment_method, method_visible, validationErrors, banklist_select, offline_banklist, fixed_package, custom_package } = this.state;
    
    return (
      <div className="LeftPanel">
        {/************************************************ Form ************************************************/}
        <Form onSubmit={this.submitForm} className="form_sty_select" >
          <div className="credit_purchase">
            <div className="header_title">
              <h2>Your Payment Method</h2>
            </div>
            <Card className="things instant_sty creditpad">
              <img src={instant} alt="instant" />
              <span>Your Current Credits:</span> <span className="price">{this.props.credits}</span>
            </Card>
          </div>

          <div className="credit_purchase">
            <div className="header_title">
              <h2>Select Packages</h2>
            </div>
            <Card className="things instant_sty instant_sty2">
            {
              this.props.credit_packages.map((credit_package, credit_package_index) => {
                return(
                  <Card.Grid style={gridStyle} onClick={this.onClickCredit.bind(this)} data-type="fixed" data-id={credit_package_index} className={selected == credit_package_index && selected !== '' ? 'addBorderActive' : null}>
                    <Row>
                      <Col span={8}><span className="packagesCredit" >{credit_package.credits} Credits</span></Col>
                      <Col span={8}><span className="credit">+ {credit_package.amount * credit_package.extra_credits_percentage / 100} Credits</span></Col>
                      <Col span={8}><span className="creditmoney">RM {this.priceFormat(credit_package.amount)}</span></Col>
                    </Row>
                  </Card.Grid>
                )
              })
            }
              <Card.Grid style={gridStyle} onClick={this.onClickCredit.bind(this)} data-type="custom" data-id={(this.props.credit_packages.length - 1) + 1} className={selected == (this.props.credit_packages.length - 1) + 1 ? 'addBorderActive' : null}>
                <Col span={24} className="range_sty">
                  <Col span={8}>Min</Col>
                  <Col span={8} className="center">RM {this.priceFormat(inputValue)}</Col>
                  <Col span={8} className="right">Max</Col>
                </Col>
                <Col span={24}>
                  <Slider
                    min={0}
                    max={30000}
                    onChange={this.onChange}
                    value={typeof inputValue === 'number' ? inputValue : 0}
                  />
                </Col>
                <Col span={24} className="price_range_sty">
                  <Col span={12}>
                    <div>Credits</div>
                    <div>Extra Credits</div>
                    <div>Total Credits</div>
                  </Col>
                  <Col span={12} className="price_range">
                    <div>{custom_package.credits} Credits</div>
                    <div>+ {custom_package.extra_credits} Credits</div>
                    <div>{custom_package.credits + custom_package.extra_credits} Credits</div>
                  </Col>
                </Col>
                <Divider />
                <Col span={24}>
                  <Col span={12}>
                    <div>Current Credits</div>
                    <div><strong>{this.props.credits} Credits</strong></div>
                  </Col>
                  <Col span={12} className="price_range">
                    <div>Grand Total</div>
                    <div><strong>RM {this.priceFormat(custom_package.amount)}</strong></div>
                  </Col>
                </Col>
              </Card.Grid>

            </Card>
          </div>

          {/************************************************ Payment Method ************************************************/}
          <div className="header_title">
            <h2>Your Payment Method</h2>
          </div>

          <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={true} credit_disabled={this.props.credit_disabled} fpx_disabled={false}
            payment_type={payment_type} payment_method={payment_method} credits={this.props.credits} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select}
            offline_banklist={offline_banklist} handlePaymentMethodChange={(value) => this.handlePaymentMethodChange(value)}
            onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)}
          /> 

          <div className="after_select">
            <Button type="primary" htmlType="submit">CHECK OUT</Button>
          </div>

        </Form>
      </div>
    );
  }
}

const PurchaseCreditShowPage = Form.create({ name: 'purchase_credit' })(LeftPanel);
export default withRouter(PurchaseCreditShowPage);