import React, { Component } from 'react';
import { Collapse, Card, Button, Skeleton, Icon } from 'antd';
import './index.css'



class RightPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  priceFormat(value) {
    // return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const { loading } = this.state;
    
    return (
      <div className="RightPanel res_panel_warp">
        {
          loading ? (
            <Skeleton active />
          ) : (
              <Card title={[
                <div className="title">
                  <h2>Summary</h2>
                </div>]}>
                <div className="dates">
                  <p className="date_title">Amount</p>
                  <p className="date_select">RM {this.props.getPurchaseInfo.amount !== undefined ? this.priceFormat(this.props.getPurchaseInfo.amount) : 0}</p>
                </div>
                <div className="dates">
                  <p className="date_title">Credits</p>
                  <p className="date_select">{this.props.getPurchaseInfo.credits !== undefined ? this.props.getPurchaseInfo.credits : 0} Credits</p>
                </div>
                <div className="dates">
                  <p className="date_title">Extra Credits</p>
                  <p className="date_select">+ {this.props.getPurchaseInfo.extra_credits !== undefined ? this.props.getPurchaseInfo.extra_credits : 0} Credits</p>
                </div>
                <div className="dates">
                  <p className="date_title">Total Credits</p>
                  <p className="date_select">{(this.props.getPurchaseInfo.credits !== undefined ? this.props.getPurchaseInfo.credits : 0) + (this.props.getPurchaseInfo.extra_credits !== undefined ? this.props.getPurchaseInfo.extra_credits : 0)} Credits</p>
                </div>
                {/* <div className="total2">
                  <span>Promotion</span><span className="total_summary">-</span>
                </div> */}
                <div className="select_list">
                  <div className="list_warp">
                    <div className="total">
                      <span>Grand Total</span><span className="total_summary">RM {this.props.getPurchaseInfo.amount !== undefined ? this.priceFormat(this.props.getPurchaseInfo.amount) : 0}</span>
                      <p className="creditnote">Please note that upon purchase of credits, it takes around 20 minutes for your credits to be available in your account.</p>
                    </div>
                  </div>
                </div>  
              </Card>
            )}
      </div>
    );
  }
}

export default RightPanel;
