import React, { Component } from 'react';
import { Drawer, Button, Spin } from 'antd';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import './index.css';
import ShowMenu from '../menu';
import API from '../../../../api';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import Footer from '../../home/footer/index';


class ShowWifi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      banklist: [],
      totalDay: 0,
      totalQty: 1,
      departDate: moment().format("DD MMM YYYY"),
      returnDate: '',
      payment_type: '',
      credits: 0,
      service_fee: 0,
      final_price: 0,
      credit_disabled: false,
      visible: false,
      loading: true,
      user: []
    };

    this.handleDepartDate = this.handleDepartDate.bind(this);
    this.handleReturnDate = this.handleReturnDate.bind(this);
    this.handleTotalDay = this.handleTotalDay.bind(this);
    this.handleTotalQty = this.handleTotalQty.bind(this);
    this.handlePickUpLocation = this.handlePickUpLocation.bind(this);
    this.handleUserCredits = this.handleUserCredits.bind(this);
    this.handlePaymentType = this.handlePaymentType.bind(this);
  }

  componentDidMount() {
    this.userFetch();
    window.scrollTo(0, 0)
    // this.banklistFetch();

    const params = this.props.location.search;
    let url = './api/v1' + this.props.location.pathname;
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        // console.log(response,'------------res');
        that.setState({
          dataList: response.data,
          loading: false
        })
      })
      .catch(function (error) {
          console.log(error);
      })
      .then(function () {
          // always executed
      });
  }

  getCredits = (value) => {
    this.setState({
      credits: value
    }, () => { this.handleUserCredits(); })
  }

  // banklistFetch() {
  //     let url = './api/v1/payments/payment_method';
  //     var that = this;

  //     API.get(url)
  //         .then(function (response) {
  //             // console.log(response, '------------res banklist');
  //             that.setState({
  //                 banklist: response.data,
  //                 loading: false,
  //                 credits: response.data.credits,
  //             })
  //         })
  //         .catch(function (error) {
  //             console.log(error);
  //         })
  //         .then(function () {
  //             // always executed
  //         });
  // }

  handlePickUpLocation(value) {
    this.setState({
      pickupLocation: value
    })
  }

  handleDepartDate(value) {
    this.setState({
      departDate: moment(value).format("DD MMM YYYY")
    }, () => { this.handleUserCredits(); })
  }

  handleReturnDate(value) {
    this.setState({
      returnDate: moment(value).format("DD MMM YYYY")
    }, () => { this.handleUserCredits(); })
  }

  handleTotalDay(value) {
    this.setState({
      totalDay: value > 0 ? value : 0
    }, () => { this.handleUserCredits(); })
  }

  handleTotalQty(value) {
    this.setState({
      totalQty: value
    }, () => { this.handleUserCredits(); })
  }

  handleUserCredits() {
    var price_day = this.state.dataList.price * this.state.totalDay;
    var price_qty = price_day * this.state.totalQty;
    var dta_price = this.state.user.collect_dta ? 0 : ((this.state.dataList.dta * this.state.totalDay) * this.state.totalQty); 
    var final_price =  price_qty - dta_price;
    var service_fee = this.state.dataList.cc_rate * final_price;

    if (this.state.credits >= final_price) {
      this.setState({
        credit_disabled: false,
        service_fee: service_fee,
        final_price: final_price
      })
    }
    else {
      this.setState({
        credit_disabled: true,
        service_fee: service_fee,
        final_price: final_price
      })
    }
  }

  handlePaymentType(value) {
    this.setState({
      payment_type: value
    })
  }

  showDrawer = () => {
    this.setState({
        visible: true,
    });
  };

  onClose = () => {
    this.setState({
        visible: false,
    });
  };

  userFetch() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          user: response.data,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    const {loading} = this.state;
    // console.log(this.state.dataList, '------wifi data')
    // console.log(this.state.credits, '------user credits')
    // console.log(this.state.credit_disabled, '------user credit_disabled')

    return (
      <div>
        <ShowMenu />
        <div id="ShowWifi">
          <div className="banner">
            <img className="bannerImage" src={this.state.dataList.image_url !== '' ? this.state.dataList.image_url : homepage_new.default_show_header} />
          </div>
          <div className="logo_top">
            <Link to="/">
              <div className="logo_warp">
                <img src={homepage_new.app_logo} alt="ice" />
                <span>TRAVELB2B</span>
              </div>
            </Link>
          </div>
          <Spin size="large" spinning={loading}>
            <div className="warppage">
              <LeftPanel dataList={this.state.dataList} handleDepartDate={this.handleDepartDate} handleReturnDate={this.handleReturnDate} handleTotalDay={this.handleTotalDay} handleTotalQty={this.handleTotalQty} handlePickUpLocation={this.handlePickUpLocation} getCredits={this.getCredits} credit_disabled={this.state.credit_disabled} credits={this.state.credits} loading={this.state.loading} username={this.state.user.username} service_fee={this.state.service_fee} final_price={this.state.final_price} handlePaymentType={this.handlePaymentType} />
              <RightPanel dataList={this.state.dataList} departDate={this.state.departDate} returnDate={this.state.returnDate} totalDay={this.state.totalDay} totalQty={this.state.totalQty} pickupLocation={this.state.pickupLocation} loading={this.state.loading} collectDta={this.state.user.collect_dta} username={this.state.user.username} service_fee={this.state.service_fee} payment_type={this.state.payment_type} />
              <div className="clear"></div>
            </div>
            <div className="res_panel">
              <Button type="primary" onClick={this.showDrawer} className="res_panel_btn">
                Summary
              </Button>
              <Drawer
                  title=""
                  placement="right"
                  closable={true}
                  onClose={this.onClose}
                  visible={this.state.visible}
              >
                <RightPanel dataList={this.state.dataList} departDate={this.state.departDate} returnDate={this.state.returnDate} totalDay={this.state.totalDay} totalQty={this.state.totalQty} pickupLocation={this.state.pickupLocation} loading={this.state.loading} collectDta={this.state.user.collect_dta} username={this.state.user.username} service_fee={this.state.service_fee} final_price={this.state.final_price} />
              </Drawer>
            </div>
          </Spin>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ShowWifi);
