import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from 'antd';

class CopyToClipboardTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      itineraryCopyUrl: '', 
      tooltipTitle: 'copy to clipboard'
    }
  }

  onCopy = (url) => {
    this.setState({
      copied: true,
      tooltipTitle: 'Copied!',
      itineraryCopyUrl: url,
    });
  };

  resetCopyState = () => {
    this.setState({
      copied: false,
      tooltipTitle: 'copy to clipboard',
    })
  }

  render() {
    return (
      <CopyToClipboard
        onMouseEnter={this.resetCopyState}
        onCopy={() => this.onCopy(this.props.text)}
        options={{message: 'Link Copied!'}}
        text={this.props.text}
      >
        <Tooltip title={this.state.tooltipTitle}>
          { this.props.children }
        </Tooltip>
      </CopyToClipboard>
    )
  }
}

CopyToClipboardTooltip.propTypes = {
  text: PropTypes.string.isRequired,
}

export default CopyToClipboardTooltip;
