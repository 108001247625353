import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

const SuccessIcon = props => {
  return (
    <Icon type="check-circle" style={{ color: '#389e0d' }} />
  );
};

SuccessIcon.propTypes = {};

export default SuccessIcon;
