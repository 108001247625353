import React, { Component } from 'react';
import { Form, Input, Button, Radio, Icon, DatePicker, Menu, Dropdown, Avatar, AutoComplete, Select, Spin, Calendar, Modal } from 'antd';
import Buttons from './button'
import moment from 'moment';
import Fuse from 'fuse.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import withRouter from "react-router-dom/es/withRouter";
//import { withRouter, Redirect } from "react-router-dom";
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs';
import API from '../../../api';

const { RangePicker } = DatePicker;

const { Option, OptGroup } = AutoComplete;

const dateFormat = 'YYYY/MM/DD';

function onChangedate(date, dateString) {
  console.log(date, dateString);
}


function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

function onPanelChange(value, mode) {
  console.log(value, mode);
}

function renderOption(item) {
  return (
    <Option key={item.iata} value={item.iata + ', ' + item.name + ', ' + item.city + ', ' + item.country}>
      {item.iata + ', ' + item.name + ', ' + item.city + ', ' + item.country}
    </Option>
  );
}


class Flight extends Component {

  constructor(props) {
    super(props);
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    console.log(params)
    const countAdults = params['adults'] != undefined ? Number(params['adults']) : 1
    const countChildren = params['children'] != undefined ? Number(params['children']) : 0
    const countInfants = params['infants'] != undefined ? Number(params['infants']) : 0

    this.state = {
      typeRadio: params['type'] != undefined ? params['type'] : 'round_trip',
      visible: false,
      tripData: params['trips'] != undefined ? params['trips'] : [{ from: '', to: '', depart: '', return: '' }],
      countAdults: countAdults,
      //returnValue: moment().format('YYYY/MM/DD'),
      returnSelectValue: moment().format('YYYY/MM/DD'),
      countChildren: countChildren,
      countInfants: countInfants,
      // countAdultsLength: 9,
      // countChildrenLength: 8,
      isLoadingBtn: false,
      totalCount: countAdults + countChildren + countInfants,
      adult_plus_disabled: false,
      child_plus_disabled: false,
      infant_plus_disabled: false,
      adult_minus_disabled: false,
      child_minus_disabled: true,
      infant_minus_disabled: true,
      cabinClass: params['cabin_class'] != undefined ? params['cabin_class'] : 'Economy',
      airports: [],
      fuse: null
    };
    this.submitForm = this.submitForm.bind(this);

    moment.updateLocale('en', {
      week: {
        dow: 1,
      },
    })

  }


  componentDidMount() {
    this.fetchAirports()
  }

  fetchAirports() {
    let url = homepage_new.airports_url;
    var that = this;
    // console.log(params);

    API.get(url)
        .then(function (response) {
            console.log(response, '------------res airports');
            that.setState({
                fuse: new Fuse(response.data, {
                shouldSort: true,
                threshold: 0.3,
                location: 0,
                distance: 10,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: [
                  {
                    name: "iata",
                    weight: 0.4
                  },
                  {
                    name: "name",
                    weight: 0.3
                  },
                  {
                    name: "city",
                    weight: 0.2
                  },
                  {
                    name: "country",
                    weight: 0.1
                  }
                ]
              })
            })
        })
        .catch(function (error) {
            console.log(error);
        })
        .then(function () {
            // always executed
        });
  }


  /****************************** add Form *******************************/
  handletripDataChange(index, type, e, mode) {
    console.log(e, '-----------date');
    if (type == 'depart_return') {
      this.state.tripData[index]['depart'] = moment(e[0]).format(dateFormat);
      this.state.tripData[index]['return'] = moment(e[1]).format(dateFormat);
    } else {
      this.state.tripData[index][type] = e;
    }
    this.setState({
      tripData: this.state.tripData,
    })
  }

  /****************************** add Form *******************************/
  addTrip() {
    this.setState({
      tripData: [...this.state.tripData, { from: '', to: '', depart: '', return: '' }]
    })
  }

  /****************************** remove add Form *******************************/
  removeTrip(index) {
    this.state.tripData.splice(index, 1)
    console.log(this.state.formDat, '--------splice');
    this.setState({
      tripData: this.state.tripData
    })
  }

  handleClick = e => {
    for (let i = 1; i <= 100; i++) {
      setTimeout(() => (this.node.scrollTop = i), i * 2);
    }
  };

  /****************************** submitForm *******************************/
  submitForm(event) {
    const { countAdults, countChildren, countInfants, cabinClass, tripData, typeRadio, isLoadingBtn } = this.state;
    event.preventDefault();

    let outputJson = {
      type: typeRadio,
      adults: countAdults,
      children: countChildren,
      infants: countInfants,
      cabin_class: cabinClass,
      trips: tripData.slice(0, typeRadio === "multi_city" ? tripData.length : 1),
    };
    this.setState({ isLoadingBtn: false });
    console.log("This is the outputJson", outputJson);
    console.log(qs.stringify(outputJson))

    // let url = './api/v1/flights';
    var that = this;

    that.props.history.push({
      pathname: '/flights',
      search: "?" + qs.stringify(outputJson),
    });

  }

  /****************************** onChange Radio Button *******************************/
  onChange = e => {
    console.log('radio checked', e.target.value);
    this.setState({ typeRadio: e.target.value, });
  };

  handleCabinChange = value => {
    console.log('cabin selected', value);
    this.setState({ cabinClass: value, });
  };

  /****************************** Travellers HandleMenuClick *******************************/
  handleMenuClick = e => { if (e.key === '4') { this.setState({ visible: false }); } };

  /****************************** Dropdown Function *******************************/
  handleVisibleChange = flag => { this.setState({ visible: flag }); };

  /****************************** Traveller Count Function *******************************/
  handleCounterTravellers(count_type, traveller_type, e) {
    if (count_type === "increment") {
      if (traveller_type === "adult") {
        this.setState({
          countAdults: this.state.countAdults + 1,
        }, () => { this.handleMinTravellers(); })
      }
      else if (traveller_type === "child") {
        this.setState({
          countChildren: this.state.countChildren + 1,
        }, () => { this.handleMinTravellers(); })
      }
      else {
        this.setState({
          countInfants: this.state.countInfants + 1,
        }, () => { this.handleMinTravellers(); })
      }
      this.setState({
        totalCount: this.state.totalCount + 1,
      }, () => { this.handleTotalTravellers(); })
    }
    else {
      if (traveller_type === "adult") {
        this.setState({
          countAdults: this.state.countAdults - 1,
        }, () => { this.handleMinTravellers(); })
      }
      else if (traveller_type === "child") {
        this.setState({
          countChildren: this.state.countChildren - 1,
        }, () => { this.handleMinTravellers(); })
      }
      else {
        this.setState({
          countInfants: this.state.countInfants - 1,
        }, () => { this.handleMinTravellers(); })
      }
      this.setState({
        totalCount: this.state.totalCount - 1,
      }, () => { this.handleTotalTravellers(); })
    }
  }

  handleMinTravellers = () => {
    if (this.state.countAdults <= 0) {
      this.setState({ countAdults: 0, adult_minus_disabled: true });
    }
    else {
      this.setState({ adult_minus_disabled: false });
    }
    if (this.state.countChildren <= 0) {
      this.setState({ countChildren: 0, child_minus_disabled: true });
    }
    else {
      this.setState({ child_minus_disabled: false });
    }
    if (this.state.countInfants <= 0) {
      this.setState({ countInfants: 0, infant_minus_disabled: true });
    }
    else {
      this.setState({ infant_minus_disabled: false });
    }

  }

  /****************************** Total Travellers *******************************/
  handleTotalTravellers = () => {
    if (this.state.totalCount >= 9) {
      this.setState({ adult_plus_disabled: true, child_plus_disabled: true, infant_plus_disabled: true });
    }
    else {
      if (this.state.countInfants >= this.state.countAdults) {
        this.setState({ infant_plus_disabled: true, infant_minus_disabled: false });
      }
      else {
        this.setState({ infant_plus_disabled: false });
      }
      this.setState({ adult_plus_disabled: false, child_plus_disabled: false });
    }
  }

  /****************************** handle Search autocomplate *******************************/
  handleSearch = value => {
    this.setState({
      airports: this.state.fuse.search(value).slice(0, 20)
    });
  };




  render() {
    const { countAdults, countChildren, countInfants, cabinClass, typeRadio, visibleReturn, confirmLoading, ModalText,
      startValue, endValue, endOpen
    } = this.state;
    const datas = this.state.tripData.slice(0, this.state.typeRadio === "multi_city" ? this.state.tripData.length : 1);
    console.log(datas)
    console.log(this.state.tripData[0].return, 'return date')
    console.log(this.state.returnValue, 'returnValue')
    console.log(this.state.returnSelectValue, 'returnSelectValue')

    /****************************** Travellers Item *******************************/
    const menu = (
      <Menu onClick={this.handleMenuClick}>

        {/************************ Adults ************************/}
        <Menu.Item key="1" className="dropdown_warp">
          <div className="selection_warp">
            <div><FontAwesomeIcon icon='male' fixedWidth className="fa_icon adults" /></div>
            <div>Adults</div>
            <div>
              <Buttons className="travellers_btn adult_plus" title={"+"}
                task={(e) => this.handleCounterTravellers('increment', 'adult', e)} disabled={this.state.adult_plus_disabled}
              />
              <span>{countAdults}</span>
              <Buttons className="travellers_btn minus_btn" title={"-"} task={(e) => this.handleCounterTravellers('decrement', 'adult', e)} disabled={this.state.adult_minus_disabled} />
            </div>
            <div className="clear"></div>
          </div>
        </Menu.Item>

        {/************************ Children ************************/}
        <Menu.Item key="2" className="dropdown_warp">
          <div className="selection_warp">
            <div><FontAwesomeIcon icon='child' fixedWidth className="fa_icon children" /> </div>
            <div>Children <small>(2  -  12)</small></div>
            <div>
              <Buttons className="travellers_btn child_plus" title={"+"}
                task={(e) => this.handleCounterTravellers('increment', 'child', e)} disabled={this.state.child_plus_disabled}
              />
              <span>{countChildren}</span>
              <Buttons className="travellers_btn minus_btn" title={"-"} task={(e) => this.handleCounterTravellers('decrement', 'child', e)} disabled={this.state.child_minus_disabled} />

            </div>
            <div className="clear"></div>
          </div>
        </Menu.Item>

        {/************************ Infants ************************/}
        <Menu.Item key="3" className="dropdown_warp">
          <div className="selection_warp">
            <div><FontAwesomeIcon icon='baby' fixedWidth className="fa_icon infants" /> </div>
            <div>Infants</div>
            <div>
              <Buttons className="travellers_btn infant_plus" title={"+"}
                task={(e) => this.handleCounterTravellers('increment', 'infants', e)} disabled={this.state.infant_plus_disabled}
              />
              <span>{countInfants}</span>
              <Buttons className="travellers_btn minus_btn" title={"-"} task={(e) => this.handleCounterTravellers('decrement', 'infants', e)} disabled={this.state.infant_minus_disabled} />

            </div>
            <div className="clear"></div>
          </div>
        </Menu.Item>

        <Menu.Item key="4" className="dropdown_warp">
          <div className="close_btn">
            <span>CLOSE</span>
          </div>
        </Menu.Item>
      </Menu >
    );


    return (

      <div className="Flight" >
        {/************************ Radio Button Select ************************/}
        <div className="radio_select">
          <div className="top_warp">
            <Radio.Group onChange={this.onChange} value={typeRadio}>
              <Radio value="one_way">One Way</Radio>
              <Radio value="round_trip">Round Trip</Radio>
              <Radio value="multi_city">Multi City</Radio>

            </Radio.Group>
          </div>
          <div className="clear"></div>
        </div>

        {/************************ form submit ************************/}

        <form onSubmit={this.submitForm} className="form_warp " id="contact_form" onKeyDown={this.onKeyDown}>
          {datas.map((data, index) => {
            console.log(data.depart, 'depart-------');
            return (
              <div className="form_1 show_style" key={index}>
                <div className="form_layout">
                  <label>From</label>
                  <AutoComplete
                    style={{ width: 200 }}
                    dataSource={this.state.airports.map(renderOption)}
                    onSearch={this.handleSearch}
                    allowClear={true}
                    placeholder="Select Locations"
                    value={data.from}
                    onChange={(e) => this.handletripDataChange(index, 'from', e)}
                  />
                </div>
                <div className="form_layout">
                  <label>To</label>
                  <AutoComplete
                    style={{ width: 200 }}
                    dataSource={this.state.airports.map(renderOption)}
                    onSearch={this.handleSearch}
                    allowClear={true}
                    placeholder="Select Locations"
                    value={data.to}
                    onChange={(e) => this.handletripDataChange(index, 'to', e)}
                  />
                </div>

                <div className="form_layout">
                {
                  typeRadio === "round_trip" ? (
                    <React.Fragment>
                      <label>Depart - Return</label>
                      <RangePicker
                        id="select_id_return"
                        format={dateFormat}
                        placeholder={['Depart date', 'Return date']}
                        defaultValue={[data.depart != '' ? moment(data.depart) : '', data.return != '' ? moment(data.return) : '']}
                        defaultPickerValue={[index > 0 && datas[index - 1].depart ? moment(datas[index - 1].depart) : moment().add(4, "day"), index > 0 && datas[index - 1].return ? moment(datas[index - 1].return) : moment().add(4, "day")]}
                        // disabledDate={current => {
                        //   return (current && current < moment().add(3, "day")) || (current && current > moment().add(350, "day")) || (current && data.depart && current < moment(data.depart));
                        // }}
                        disabledDate={current => {
                          return (current && current < moment().add(3, "day")) || (current && current > moment().add(350, "day"));
                        }}
                        onChange={(date, dateString) => this.handletripDataChange(index, 'depart_return', dateString)}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <label>Depart</label>
                      <DatePicker
                        format={dateFormat}
                        placeholder={'Depart date'}
                        defaultValue={data.depart != '' ? moment(data.depart) : ''}
                        defaultPickerValue={index > 0 && datas[index - 1].depart ? moment(datas[index - 1].depart) : moment().add(4, "day")}
                        disabledDate={current => {
                          return (current && current < moment().add(3, "day")) || (current && current > moment().add(350, "day")) || (index > 0 && current && datas[index - 1].depart && current < moment(datas[index - 1].depart));
                        }}
                        onChange={(date, dateString) => this.handletripDataChange(index, 'depart', dateString)}
                      />
                    </React.Fragment>
                  )
                }
                </div>

                {/* 
                  typeRadio === "round_trip" ? (
                    <div className="form_layout">
                      <label>Return</label>
                      <DatePicker
                        format={dateFormat}
                        defaultValue={data.return != '' ? moment(data.return) : ''}
                        defaultPickerValue={data.depart != '' ? moment(data.depart) : moment().add(4, "day")}
                        disabledDate={current => {
                          return (current && current < moment().add(3, "day")) || (current && current > moment().add(350, "day")) || (current && data.depart && current < moment(data.depart));
                        }}
                        onChange={(date, dateString) => this.handletripDataChange(index, 'return', dateString)}
                        id="select_id_return"
                      />
                    </div>

                  ) : (null)
                */}

                {
                  index > 0 ? (
                    <div className="close_btn">
                      <Button type="primary" shape="circle"
                        onClick={(e) => this.removeTrip(e)} icon="close"
                      />
                    </div>
                  ) : (null)
                }

                <div className="clear"></div>
              </div>

            )
          })}


          {
            typeRadio === "multi_city" ? (
              <div className="add_btn">
                <Button type="primary" icon="search" size="default" className="search_btn"
                  onClick={(e) => this.addTrip(e)}>
                  Add Another Flight
                  </Button>
              </div>
            ) : (null)
          }


          <div className="form_2">
            <div className="form_layout Travellers_style">
              <label>Travellers</label>
              <Dropdown overlay={menu} onVisibleChange={this.handleVisibleChange} visible={this.state.visible} id="select_id_travelles">
                <div className="a_link_warp">
                  <div className="ant-dropdown-link">
                    <FontAwesomeIcon icon='male' fixedWidth className="fa_icon adults" />  {countAdults}   &nbsp;
                      <FontAwesomeIcon icon='child' fixedWidth className="fa_icon children" />  {countChildren}    &nbsp;
                      <FontAwesomeIcon icon='baby' fixedWidth className="fa_icon infants" />  {countInfants}
                    <Icon type="down" />
                  </div>
                </div>
              </Dropdown>
              <div className="clear"></div>
            </div>

            <div className="form_layout">
              <label>Cabin class</label>
              <Select
                showSearch
                style={{ width: 200 }}
                defaultValue={cabinClass}
                optionFilterProp="children"
                onChange={this.handleCabinChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                id="select_id_class"
              >
                <Option value="Economy">Economy</Option>
                <Option value="Business">Business</Option>
                <Option value="First">First</Option>
              </Select>
            </div>
          </div>
          <div className="btn_submit">
            <Button type="primary" onClick={this.submitForm} ref={node => this.node = node}
              loading={this.state.isLoadingBtn} icon="search" size="default" className="search_btn">
              Search
              </Button>
          </div>
        </form>








      </div>
    );
  }
}

export default withRouter(Flight);
