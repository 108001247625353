import React, { Component } from 'react';
import Search from '../../search/index';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import { withRouter } from 'react-router-dom';
import API from '../../../../api'
import { Spin } from 'antd';
import Footer from '../../home/footer/index';

class ListingVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: [],
      params: this.props.location.search,
      isLoading: true,
      sortBy: 'lowest_price',
      user_status: false
    }
    this.myRef = React.createRef();
    this.sortByCallback = this.sortByCallback.bind(this);
  }

  componentDidMount() {
    this.checkUser();
    this.apiFetch();
    this.myRef.current.scrollIntoView({
      behavior: "smooth",
    })
    // console.log(this.myRef, '---------ref')
  }
    
  componentDidUpdate() {
    if (this.props.location.search != this.state.params) {
      this.setState({
        params: this.props.location.search,
        isLoading: true
      })
      console.log(this.props.location.search)
      // console.log(this.state.params)
      this.checkUser();
      this.apiFetch();
    }

    this.myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
    })
  }

  sortByCallback(value){
    console.log(value, 'SortBy')
    this.setState({
      sortBy: value
    })
  }

  processDetail(data){
    const { sortBy } = this.state

    if(data && data.length){
      if(sortBy == 'lowest_price') {
        return data.sort((a, b) => a.price - b.price)
      } else if(sortBy == 'highest_price') {
        return data.sort((a, b) => b.price - a.price)
      }
    }
    return data
  }

  checkUser() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        console.log(response, '------------check user res');
        that.setState({
          user_status: response.data.status,
        })

        if (response.data.status) {
          this.checkUser();
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
    // this.userFetch();
  }

  apiFetch() {
    // const { params } = this.state;
    const params = this.props.location.search;
    let url = './api/v1' + this.props.location.pathname;
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          detail: response.data,
          isLoading: false
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  render() {
    const { isLoading, detail, sortBy } = this.state;
    let params = this.props.location.search;

    const data = this.processDetail(detail);
    console.log(this.state.detail, '--------voucher detail');
    return (
      <div>
        <Search tagline={homepage_new.tagline} covers={homepage_new.search_covers} />
        <Spin size="large" spinning={isLoading}>
          <div id="ListingVoucher" ref={this.myRef}>
            <div className="warp">
              <LeftPanel sortByCallback={this.sortByCallback} sortBy={sortBy} />
              <RightPanel detail={data} dataLength={data.length} loading={isLoading} params={params} />
              <div className="clear"></div>
            </div>
          </div>
        </Spin>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ListingVoucher);
