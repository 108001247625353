import React, { Component } from "react";
import { Modal, Empty } from 'antd';
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";
import "./index.css";
import ShowMenu from "../../../show/menu";
import API from "../../../../../api";
import { withRouter, Link } from "react-router-dom";
import { Drawer, Button, Spin } from "antd";
import Footer from '../../../home/footer/index';
import moment from 'moment'
import warning from '../../../../../../images/warning.png';

import dummyFlightData from './dummy_flights_data.json'
import dummyReturnFlightData from './dummy_return_flights_data.json'
import dummyPassengerData from './dummy_passengers_data.json'
import dummy from './dummy.json'

class FlightBookingAddon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      user: [],
      addonData: [],
      loading: true,
      visible: false,
      brbCount: 0,
      visibleNodata: false,
      dataLenght: [],
      errorResponseVisible: false,
      payment_type: '',
      final_price: 0,
      service_fee: 0,
    };
    this.handleBrbCount = this.handleBrbCount.bind(this);

  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  /***************************************************** addOnsFetch function *******************************************************/
  addOnsFetch() {
    let id = this.props.match.params.id;
    let url = './api/v1/flights/'+id+'/checkout';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {        
        console.log(response.data, '------------res addOnsList');

        if (response.data.error) {
          that.setState({ errorResponseVisible: true })
        }
        else {
          that.setState({
            data: response.data
          }, () => {
            if (that.state.data.booking_group && that.state.data.booking_group.payment_status.includes('Paid')) {
              that.props.history.push("/bookings/flights");
            }
            that.handleTotalPrice();
            console.log("from addOnsFetch", that.state.data)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  };

  checkExceedTimeLimit = () => {
    if (this.state.data.flight !== undefined) {
      console.log("Flight is loaded")
      if (moment(this.state.data.flight.ttl).isAfter(moment())) {
        ;
      } else {
        this.setState({ errorResponseVisible: true })
      }
    } else {
      console.log("Flight is loading...")
    }
  };

  componentDidMount() {
    this.addOnsFetch();
    this.userFetch();

    if (!this.state.errorResponseVisible) {
      setInterval(() => {
        this.checkExceedTimeLimit()
      }, 1000)
    }
  }

  userFetch() {
    const that = this;

    API.get('./api/v1/users/details')
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          user: response.data,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handlePaymentType = (value) => {
    this.setState({
      payment_type: value
    })
  }

  handleBrbCount(value) {
    this.setState({
      brbCount: value,
    });
  }

  componentDidUpdate() {
  }

  handleDataChangeFromLeftpanel = (type, data) => {
    console.log(type, data)

    // if (this.state.addonData.length === 0) {
    //   this.state.data.flight.journeys.map((journey, journey_index) => {
    //     this.state.addonData.push({ name: `${journey.departure_airport.iata}-${journey.arrival_airport.iata}`, passengers: this.state.data.flight.passengers })
    //   })

      const results = data.reduce(function (r, a) {
          r[a.board_off_point] = r[a.board_off_point] || [];
          r[a.board_off_point].push(a);
          return r;
      }, Object.create(null));



      // console.log("check result ssahaja ==> ", results)

    //   this.state.addonData.map((addon) => {
    //     if (results[addon.name] !== undefined) {
    //       if (addon.name === results[addon.name][0].board_off_point) {
    //         addon.passengers.map((passenger) => {
    //           if (passenger.id === results[addon.name].find(x => x.pax_number === passenger.id).pax_number) {
    //             passenger[type] = results[addon.name].find(x => x.pax_number === passenger.id).code
    //           }
    //         })
    //       }
    //     }
    //   })      
    // }

    //   data.map((item) => {
    //     this.state.addonData.map((addon) => {
    //       console.log("chekc bossksu ===> ", item.board_off_point === addon.name)
    //       if (addon.name === item.board_off_point) {
    //         addon.passengers.find(x => x.id === item.pax_number)[type] = item.code
    //       }
    //     })
    //     // this.state.addonData.find(x => x.name === item.board_off_point).passengers.find(x => x.id === item.pax_number)[type] = item.
    //   })
    // } else {
    //   data.map((item) => {
    //     this.state.addonData.map((addon) => {
    //       if (addon.name === item.board_off_point) {
    //         addon.passengers.find(x => x.id === item.pax_number)[type] = item.code
    //       }
    //     })
    //     // this.state.addonData.find(x => x.name === item.board_off_point).passengers.find(x => x.id === item.pax_number)[type] = item.
    //   })
    // }


    // this.state.data.flight.passengers.map((passenger, passenger_index) => {
    //   this.state.addonData.push(
    //     { 
    //       passengers: `${passenger.departure_airport.iata}-${passenger.arrival_airport.iata}`,
    //     }
    //   )
    // })
    this.state.addonData[type] = data
    this.setState({
      addonData: this.state.addonData
    }, () => {
      console.log("data handleDataChangeFromLeftpanel index ===> ", this.state.addonData)
    })
    this.handleTotalPrice();
  };

  handleTotalPrice() {
    let pax_total_pricing = 0
    let meal_total_pricing = 0
    let baggage_total_pricing = 0
    let seat_total_pricing = 0
    let total = 0

    if (this.state.data.booking_group !== undefined) {
      total = parseFloat(this.state.data.booking_group.detail.price)
    } else {
      this.state.data.flight.pricings.map((price) => ( pax_total_pricing += parseFloat(price.total_price) ))
      if (this.state.addonData.meals !== undefined) {
        this.state.addonData.meals.map((meal) => ( meal_total_pricing += parseFloat(meal.amount) ))
      }
      if (this.state.addonData.baggages !== undefined) {
        this.state.addonData.baggages.map((baggage) => ( baggage_total_pricing += parseFloat(baggage.amount) ))
      }
      if (this.state.addonData.seats !== undefined) {
        this.state.addonData.seats.map((seat) => ( seat_total_pricing += parseFloat(seat.amount) ))
      }
      total = pax_total_pricing + meal_total_pricing + baggage_total_pricing + seat_total_pricing
    }

    var service_fee = this.state.data.flight.cc_rate * total;

    this.setState({
      final_price: total,
      service_fee: service_fee,
    })
  };

  render() {
    const { loading, visibleNodata, data, addonData, user, payment_type, final_price, service_fee } = this.state;

    const dataList =  dummyReturnFlightData || [] // dummyFlightData || []
    const dataPassenger = dummyPassengerData || []

    return (
      <div>
        <ShowMenu />

        <div id="FlightBookingAddon">
          <div className="banner"></div>
          <div className="logo_top">
            <Link to="/">
              <div className="logo_warp">
                <img src={homepage_new.app_logo} alt="ice" />
                <span>TRAVELB2B</span>
              </div>
            </Link>
          </div>
          <Spin size="large" spinning={false}>
            <div className="warppage">
              <LeftPanel data={data} handleDataChangeFromLeftpanel={this.handleDataChangeFromLeftpanel} user={user} handlePaymentType={this.handlePaymentType} final_price={final_price} service_fee={service_fee} instant_access_flight={user.instant_access_flight} />
              <RightPanel data={data} addonData={addonData} dataList={dataList} user={user} payment_type={payment_type} final_price={final_price} service_fee={service_fee} />
              <div className="clear"></div>
            </div>
            <div className="res_panel">
              <Button
                type="primary"
                onClick={this.showDrawer}
                className="res_panel_btn"
              >
                Summary
            </Button>
              <Drawer
                title=""
                placement="right"
                closable={true}
                onClose={this.onClose}
                visible={this.state.visible}
              >
                <RightPanel dataList={dataList} loading={loading} brbCount={this.brbCount} payment_type={payment_type} final_price={final_price} service_fee={service_fee} />
              </Drawer>
            </div>
          </Spin>
        </div>
        <Footer />
        <Modal
          visible={this.state.errorResponseVisible}
          title={null}
          footer={null}
          closable={null}
          destroyOnClose={true}
        >
          <Empty
            image={warning}
            imageStyle={{ height: 100, }}
            description={<p className="popup_text">You have exceeded the ticketing time limit.</p>}
            className="popup_footer"
          >
            <Button type="primary" onClick={() => { this.setState({ errorResponseVisible: false }, () => { this.props.history.push("/bookings/flights"); }) }}>Close</Button>
          </Empty>
        </Modal>
      </div>
    );
  }
}

export default withRouter(FlightBookingAddon);
