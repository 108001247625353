import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Tag, Popconfirm, Form, Input, InputNumber, Select } from 'antd';
import "./subagent_table.css"
import Api from '../../../api'
import ResetPassword from './reset_password_form_modal';

const EditableContext = React.createContext();
const { Option } = Select;

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    if (this.props.inputType === 'select_tags') {
      return <Select mode="tags"></Select>
    }
    if (this.props.inputType === 'select_active') {
      return (
        <Select placeholder="Please select active status">
          <Option value="true">Yes</Option>
          <Option value="false">No</Option>
        </Select>
      )
    }
    return <Input />;
  };

  setFieldInputType = (dataIndex) => {
    if (['phones', 'emails'].includes(dataIndex)) {
      return 'array'
    }
    if (['is_active'].includes(dataIndex)) {
      return 'boolean'
    }
    return 'any'
  }

  setInitialValue = (record, dataIndex) => {
    if (['emails', 'phones'].includes(dataIndex)) {
      return record[dataIndex]?.split(',')
    }
    return record[dataIndex]
  }

  validatePhonesFormat = (rule, value, callback) => {
    if (value) {
      let result = value.every(phone => /^(?:\+[1-9]\d{0,2})?([0-9\-]{8,14})$/.test(phone))
      if (!result) {
        callback('Invalid phone number. Must be in format +[country_code][number] (e.g., +601234567890)')
      }
    }
    callback();
  }

  customValidationRules = (dataIndex, title) => {
    let rules = [
      {
        required: !['other_name', 'alias_name'].includes(dataIndex),
        message: `Please Input ${title}!`,
      },
    ]

    if (dataIndex === 'phones') {
      rules.push(
        {
          validator: this.validatePhonesFormat
        }
      )
    }
    return rules
  }

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              type: this.setFieldInputType(dataIndex),
              rules: this.customValidationRules(dataIndex, title),
              initialValue: this.setInitialValue(record, dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class SubagentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editingKey: '',
    }
    this.columns = [
      {
        title: 'Full Name', dataIndex: 'full_name', key: 'full_name', fixed: 'left', width: 200,
        render: full_name_data => (
          <span>
            { full_name_data[0] && <Tag color="blue">New</Tag> }
            <span>{ full_name_data[1] }</span>
          </span>
        )
      },
      { title: 'Username', dataIndex: 'username', key: 'username', editable: true, width: 120 },
      { title: 'Other Name', dataIndex: 'other_name', key: 'other_name', editable: true, width: 120 },
      { title: 'Alias Name', dataIndex: 'alias_name', key: 'alias_name', editable: true, width: 120 },
      { title: 'Address', dataIndex: 'address', key: 'address', editable: true, width: 200 },
      { title: 'City', dataIndex: 'city', key: 'city', editable: true, width: 100 },
      { title: 'State', dataIndex: 'state', key: 'state', editable: true, width: 100 },
      { title: 'Postal', dataIndex: 'postal', key: 'postal', editable: true, width: 100 },
      { title: 'Country', dataIndex: 'country', key: 'country', editable: true, width: 100 },
      { title: 'Phones', dataIndex: 'phones', key: 'phones', editable: true, width: 200 },
      { title: 'Emails', dataIndex: 'emails', key: 'emails', editable: true, width: 200 },
      { title: 'Active', dataIndex: 'is_active', key: 'is_active', editable: true, width: 100 },
      {
        title: 'Actions', dataIndex: 'actions', key: 'actions', fixed: 'right', width: 200,
        render: (_text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <a
                    onClick={() => this.save(form, record.key)}
                    style={{ marginRight: 8 }}
                  >
                    Save
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <div className="subagent-actions">
              <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                Edit
              </a>
              |
              <ResetPassword record={record} apiPatchSubagent={this.ApiPatchSubagent} openNotification={this.props.openNotification}/>
            </div>
          )
        }
      }
    ]
  }

  ApiPatchSubagent = async (id, data) => {
    let success = false
    await Api
      .patch(`api/v1/subagents/${id}`, { user: data })
      .then(() => {
        success = true
        this.props.openNotification(success, 'Success', 'Subagent was successfully updated!')
      })
      .catch(error => {
        success = false
        if (error.response.status == 422) {
          this.props.openNotification(success, 'Error' + ': ' + error.response.statusText, error.response.data.errors)
        } else {
          this.props.openNotification(success, 'Error' + ': ' + error.response.statusText, error.message)
        }
      })
    return success
  }

  filteredSubagentData = (subagents) => {
    let data = subagents.map(row_data => (
      {
        key: row_data.id,
        full_name: [row_data.is_new, row_data.attributes.full_name],
        username: row_data.attributes.username,
        other_name: row_data.attributes.other_name,
        alias_name: row_data.attributes.alias_name,
        address: row_data.attributes.address,
        city: row_data.attributes.city,
        state: row_data.attributes.state,
        postal: row_data.attributes.postal,
        country: row_data.attributes.country,
        phones: row_data.attributes.phones?.toString(),
        emails: row_data.attributes.emails?.toString(),
        is_active: row_data.attributes.is_active ? 'Yes' : 'No',
      }
    ))
    return data
  }

  isEditing = record => record.key === this.state.editingKey;

  edit(key) {
    this.setState({ editingKey: key });
  }

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) { return; }

      this.ApiPatchSubagent(key, row)
      .then((success) => {
        if (success) {
          this.updateTableRowData(key, row);
        }
      })
    });
  }

  setColumnInputType = (dataIndex) => {
    if (['phones', 'emails'].includes(dataIndex)) {
      return 'select_tags'
    }
    if (dataIndex === 'is_active') {
      return 'select_active'
    }
    return 'text'
  }

  updateTableRowData(key, row) {
    const newData = [...this.state.data];
    const index = newData.findIndex(item => key === item.key);
    if (typeof Array.isArray(row.phones)) {
      row.phones = row.phones.toString();
    }
    if (typeof Array.isArray(row.emails)) {
      row.emails = row.emails.toString();
    }

    if (typeof row.is_active === 'string' && row.is_active === 'true') {
      row.is_active = 'Yes';
    }
    if (typeof row.is_active === 'string' && row.is_active === 'false') {
      row.is_active = 'No';
    }
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      this.setState({ data: newData, editingKey: '' });
    } else {
      newData.push(row);
      this.setState({ data: newData, editingKey: '' });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.subagents !== prevProps.subagents) {
      this.setState({
        data: this.filteredSubagentData(this.props.subagents)
      })
    }
  }
  
  render() {
    const { data } = this.state
    const { isLoading, isMasterAgent } = this.props
    const renderColumns = isMasterAgent ? this.columns : this.columns.slice(0, -1)
    const columns = renderColumns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: this.setColumnInputType(col.dataIndex),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          scroll={{ x: 1300 }}
          components={components}
          pagination={{ onChange: this.cancel }}
        />
      </EditableContext.Provider>
    );
  }
}

SubagentTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  subagents: PropTypes.array.isRequired,
  isMasterAgent: PropTypes.bool,
  openNotification: PropTypes.func.isRequired,
};

const EditableSubagentTable = Form.create()(SubagentTable);

export default EditableSubagentTable;
