import React, { Component } from 'react';
import { Card, Button, Spin, Skeleton, Collapse, Icon } from 'antd';
import './index.css'

const { Panel } = Collapse;

class RightPanel extends Component {

  componentDidMount() {

  }

  /***************************************************** priceFormat function *******************************************************/
  priceFormat(value) {
    return value === undefined || value === '' ? (0).toFixed(2) : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const data = this.props.dataList;
    const total = this.props.totalPrice;
    const selectedRoom = this.props.selectedRoom;
    const dta = this.props.collectDta ? 0 : this.props.dta;
    const loading = this.props.loading;
    const payment_type = this.props.payment_type;
    const service_fee = this.props.service_fee;

    console.log(selectedRoom, '-----selectedRoom');
    // console.log(total, '-------totalPrice right')

    if (payment_type == "public_bank" || payment_type == "public_bank_2") {
      var final_total = total + service_fee
    }
    else {
      var final_total = total
    }

    if (data.hasOwnProperty('guest_count') && data.guest_count.length > 0) {
      var guest = data.guest_count.length;
    }
    else {
      var guest = 0;
    }

    if (data.hasOwnProperty('adult_count')) {
      var adult_count = data.adult_count;
    }
    else {
      var adult_count = 0;
    }

    if (data.hasOwnProperty('child_count')) {
      var child_count = data.child_count;
    }
    else {
      var child_count = 0;
    }

   // console.log(data, '--------data-right')
    return (
      <div className="RightPanel res_panel_warp">
        {
          loading ? (
            <Skeleton active />
          ) : (
              <Card title={[
                <div className="title">
                  <h2>Summary</h2>
                </div>]}>
                <div className="dates">
                  <p className="date_title">Hotel</p>
                  <p className="date_select">{data.name}</p>
                </div>
                <div className="dates">
                  <p className="date_title">Check-in/Check-out Date</p>
                  <p className="date_select">{data.check_in} <span>—</span> {data.check_out}</p>
                </div>
                <div className="dates">
                  <p className="date_title">Guests</p>
                  <p className="date_select">{adult_count} Adult(s), {child_count} Child(s)</p>
                </div>
                <div className="dates">
                  <p className="date_title">Room</p>
                  <p className="date_select">{ selectedRoom != undefined && selectedRoom.room_type ? selectedRoom.room_type : 'No room selected'}</p>
                </div>
                {/* <div className="type_rent">
                  <div className="rent">
                    <p className="rent_title">Guests</p>
                    <p className="rent_select">{guest} guest(s)</p>
                  </div>
                  <div className="rent">
                    <p className="rent_title">Room</p>
                    <p className="rent_select">{data.rooms && data.rooms.find(x => x.index === data.result_index).room_index} room(s)</p>
                  </div>
                  <div className="clear"></div>
                </div> */}
                <div className="select_list">
                  <p className="note_label"><strong>Note:</strong> Full payment required for this booking.</p>
                  <p className="date_title">Fare Breakdown</p>
                  {
                    dta > 0 ?
                    <div className="list_warp">
                      <div className="subtraction_collapse">
                        <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                          <Panel  showArrow={true} header={
                            <div className="list_warp fare_info">
                              <span>Subtraction</span>
                              <span>- RM {this.priceFormat(dta)}</span>
                              </div>
                            }>
                            {
                              dta > 0 ?
                              <div>
                                <span>DTA</span>
                                <span>- RM {this.priceFormat(dta)}</span>
                              </div> : ''
                            }
                          </Panel>
                        </Collapse>
                      </div>
                    </div> : ''
                  }
                  {
                    (payment_type == "public_bank" || payment_type == "public_bank_2") ?
                      <div className="list_warp">
                        <span>Service Fees</span>
                        <span>RM {service_fee > 0 ? this.priceFormat(service_fee) : this.priceFormat(service_fee)}</span>
                      </div>
                    : ''
                  }                    
                  <div className="clear"></div>
                </div>

                <div className="select_list">
                  <div className="list_warp">
                    <div className="total">
                      <span>Grand Total</span><span>RM {this.priceFormat(final_total)}</span>
                    </div>
                  </div>
                </div>

              </Card>
            )
        }

      </div>
    );
  }
}

export default RightPanel;
