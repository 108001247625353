import React, { Component } from 'react';
import { Modal, Spin, Button, Card, Icon, Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Meta } = Card;

class AgentContact extends Component {

  action_list(item){
    var list = [];
    if(item.whatsapp_no != ""){
      list.push(<a href={'https://api.whatsapp.com/send?phone='+item.whatsapp_no+'text='}><FontAwesomeIcon fixedWidth icon={['fab', 'whatsapp']} /></a>)
    }
    if(item.phone != ""){
      list.push(<a href={'tel:'+item.phone}><FontAwesomeIcon fixedWidth icon='phone' /></a>)
    }
    if(item.email != ""){
      list.push(<a href={'mailto:'+item.email}><FontAwesomeIcon fixedWidth icon='envelope' /></a>)
    }
    return list
  }

  render() {
    console.log(this.props, "===props")
    return (
      <Modal
        title={<React.Fragment><FontAwesomeIcon fixedWidth icon="info-circle" />&nbsp; Agent Details</React.Fragment>}
        maskClosable={this.props.infoModalVisible}
        closable={this.props.infoModalVisible}
        visible={this.props.infoModalVisible}
        width={450}
        onCancel={this.props.handleCancel}
        footer={[
          <Button key="back" type="primary" onClick={this.props.handleCancel}>
            Close
          </Button>
        ]}
      >
        <Card
          style={{ width: 400 }}
          cover={
            <img
              alt="agent_img"
              src={this.props.modalData.image}
            />
          }
          actions={this.action_list(this.props.modalData)}
        >
          <Meta
            // avatar={<Avatar src={this.props.modalData.image} />}
            title={this.props.modalData.full_name}
            description={
              <React.Fragment>
                <p>
                  <FontAwesomeIcon icon='map-marker-alt' className="address" />&nbsp;&nbsp;{this.props.modalData.address}&nbsp;{this.props.modalData.city}&nbsp;{this.props.modalData.state}
                </p>
                {
                  this.props.modalData.phone != "" && <p><FontAwesomeIcon icon='phone' className="phone" />&nbsp;&nbsp;{this.props.modalData.phone}</p>
                }
                {
                  this.props.modalData.whatsapp_no != "" && <p><FontAwesomeIcon icon={['fab', 'whatsapp']} />&nbsp;&nbsp;{this.props.modalData.whatsapp_no}</p>
                }
                {
                  this.props.modalData.email != "" && <p><FontAwesomeIcon icon='envelope' className="email" />&nbsp;&nbsp;{this.props.modalData.email}</p>
                }
             </React.Fragment>
            }
          />
        </Card>
      </Modal>
    );
  }
}

export default AgentContact;