const debounce = (cb, wait) => {
  let timeout;

  return function() {
    let callback = () => cb.apply(this, arguments);

    
    clearTimeout(timeout);
    timeout = setTimeout(callback, wait);
  }
}

export {
  debounce,
}
