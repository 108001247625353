import React, { Component } from 'react';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import MenuBooking from '../menu';
import './index.css'
import API from '../../../../api';

class PurchaseCredit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: { username: '', role: '', credits: 0 },
      credit_packages: [],
      purchase_info: []
    }
  }

  componentDidMount() {
    this.userFetch();
    this.creditPackagesFetch();
  }

  userFetch() {
    let url = './api/v1/users/details';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        that.setState({
          user: { role: response.data.role, username: response.data.username, credits: response.data.credits },
          currentTab: that.state.prevTab,
          isLoading: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  }

  creditPackagesFetch() {
    let url = './api/v1/users/credit_packages';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        that.setState({
          credit_packages: response.data
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  }

  handlePurchaseInfo = (data) => {
    this.setState({
      purchase_info: data !== undefined ? data : []
    })
  }

  render() {
    return (
      <div id="PurchaseCredit">
        <MenuBooking selected="purchase_credit" />
        <div className="content">
          <div className="warppage">
            <LeftPanel credit_packages={this.state.credit_packages} credits={this.state.user.credits} handlePurchaseInfo={this.handlePurchaseInfo} />
            <RightPanel getPurchaseInfo={this.state.purchase_info} />
          </div>
        </div>
      </div>

    );
  }
}

export default PurchaseCredit;