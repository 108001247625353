import React, { Component } from 'react';
import { Card, Divider, Select, Input, DatePicker, Checkbox, Button, List, Modal, Empty, Spin, Skeleton, notification, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import './index.css';
import flight from '../../../../../../images/flight.png';
import brb from '../../../../../../images/brb.png';
import land from '../../../../../../images/land.png';
import warning from '../../../../../../images/warning.png';
import { Link, withRouter } from 'react-router-dom';
import countryList from 'react-select-country-list'
import qs from 'qs'
import API from '../../../../../api';
import PaymentMethod from '../../../show/payment';
import SeatPicker from 'react-seat-picker';

import dummySeatsData from './dummy_seats_data.json';

function formattedTime(s) {
  let duration = s;
  let hours = duration/3600;
  duration = duration % (3600);

  let min = parseInt(duration/60);
  duration = duration % (60);

  if (min < 10) {
    min = `0${min}`;
  }
  if (parseInt(hours, 10) > 0) {
    return (`${parseInt(hours, 10)}H ${min}M`)
  }
  return (`${parseInt(hours, 10)}H ${min}M`)
};

const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const locaData = [
  { kgEmpty: "-" }
]
const passengerCategoryMapping = { 'ADT': 'Adult', 'CNN': 'Child', 'INF': 'Infant' }

class LeftPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      meals: [],
      baggages: [],
      seats: [],
      selectSeatModalVisible: false,
      selectSeatModalData: {},
      showSeatMap: '',
      payment_type: '',
      payment_method: null,
      // selectedValue: '',
      // detail: [],
      // passengers: [{designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: '', brb: 'No' ,remark: ''}]
      // passengers: [{designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: 'ADT', brb: 'No' ,remark: ''}, {designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: 'ADT', brb: 'No' ,remark: ''}, {designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: 'CNN', brb: 'No' ,remark: ''}, {designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: 'INF', brb: 'No' ,remark: ''}]
      passengers: [],
      validationErrors: [],
      // visible: false,
      confirmationVisible: false,
      disabledConfirmationButtion: false,
      check_out_disabled: false,
      loading: true,
      banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      fpxb2b_banklist: [],
      b2b_offline_banklist: '',
      banklist_select: [],
      method_visible: false,
      credits: 0,
      credit_disabled: false,
      agree: "",
      errorResponse: [],
      errorResponseVisible: false,
      passenger_select: 0,
      prev_segment: '',
      bank_type: null,
      redirect_url: null,
      dobw_banklist: [],
    }
    // this.submitForm = this.submitForm.bind(this);
    // this.formValidate = this.formValidate.bind(this);
    // this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
  }

  /***************************************************** componentDidMount *******************************************************/
  componentDidMount() {
    this.banklistFetch();
  }

  /***************************************************** componentDidUpdate *******************************************************/
  componentDidUpdate(prevProps, prevState) {
    // if (this.props.dataList != this.state.detail) {
    //   var passengers = [];
    //   var validationErrors = [];
    //   if (this.props.dataList.hasOwnProperty('pricings') && this.props.dataList.pricings.length > 0) {
    //     this.props.dataList.pricings.forEach((pricing) => {
    //       for (var i = 0; i < pricing.count; i++) {
    //         passengers.push({ designation: '', first_name: '', last_name: '', country: 'MY', mobile: '',  email:'', passport_number: '', passport_issue_date: '', passport_expiry_date: '', passport_issue_place: 'MY', date_of_birth: '', category: pricing.code, brb: 'No', remark: '' })
    //         validationErrors.push({ agree: '', payment_type: '', payment_method: null })
    //       }
    //     })
    //     if (this.state.credits >= (this.props.dataList.pricings[0].total_price + 26 * this.props.brbCount)) {
    //       this.setState({
    //         credit_disabled: false
    //       })
    //     }
    //     else {
    //       this.setState({
    //         credit_disabled: true
    //       })
    //     }
    //   }
    //   this.setState({
    //     detail: this.props.dataList,
    //     passengers: passengers,
    //     validationErrors: validationErrors,
    //     loading: false
    //   })
    // }
  }

  /***************************************************** banklistFetch function *******************************************************/
  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        console.log(response, '------------res banklist--------------------');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          credits: response.data.credits
        })

        // if (response.data.credits >= (that.state.detail.total_price + 26 * that.props.brbCount)) {
        //   that.setState({
        //     credit_disabled: false
        //   })
        // }
        // else {
        //   that.setState({
        //     credit_disabled: true
        //   })
        // }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  
  /******************************************** handlePaymentChange function *******************************************************/
  handlePaymentChange(event) {
    console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null
      })
    }
    else {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }
    this.setState({
      payment_type: event.target.value
    })
    this.props.handlePaymentType(event.target.value);
  }

  /*************************************** handlePaymentMethodChange function *******************************************************/
  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  // onChangeLuggageCheckbox(event) {
  //   console.log(event.target.value);
  //   this.setState({
  //     type: event.target.value
  //   })
  // }

  /***************************************************** handleCancelWrong function *******************************************************/
  handleCancelWrong = () => {
    this.setState({
      visible: false,
      disabledConfirmationButtion: false,
    });
  };

  /***************************************************** handleCancel function *******************************************************/
  handleCancel = () => {
    this.setState({
      visible: false,
      visibleNodata: false,
      errorResponseVisible: false
    });
    this.props.history.go(-1);
  };

  /***************************************************** handleCloseConfirmation function *******************************************************/
  handleCloseConfirmation = () => {
    this.setState({
      confirmationVisible: false,
    });
  };

  /***************************************************** handleOpenConfirmation function *******************************************************/
  handleOpenConfirmation = () => {
    this.setState({
      confirmationVisible: true,
    });
  };

  /***************************************************** openNotificationWithIcon function *******************************************************/
  openNotificationWithIcon = (type, error_name) => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
    }
    if (type === "error") {
      if (error_name === "credits_error") {
        notification[type]({
          message: 'Error!',
          description:
            'Insufficient credits! please purchase more credits.',
        });
      }
    }
  };

  /***************************************************** onChangePaymentCheckBox function *******************************************************/
  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
    console.log(`checked = ${e.target.checked}`);
  }

  onToggleShowSeatMap = (segment) => {
    if (this.state.showSeatMap.includes(segment.segment_number)) {
      this.setState({
        showSeatMap: ''
      })
    } else {
      this.setState({
        showSeatMap: segment.segment_number
      })
    }
  };

  onOpenSelectSeatModal = (journey_index, segment, segment_index, seatQuota) => {
    this.setState({
      selectSeatModalData: {
        journey_index: journey_index,
        segment: segment,
        segment_index: segment_index,
        seatQuota: seatQuota
      },
      selectSeatModalVisible: !this.state.selectSeatModalVisible
    })
  };

  handleOk = () => {
    this.setState({
      selectSeatModalVisible: !this.state.selectSeatModalVisible
    })
  };

  handleCancel = () => {
    this.setState({
      selectSeatModalVisible: !this.state.selectSeatModalVisible
    })
  };

  /***************************************************** submitForm function *******************************************************/
  submitForm = event => {
    event.preventDefault();
    this.banklistFetch();
    const params = {
     addons: {
       meals: this.state.meals,
       baggages: this.state.baggages,
       seats: this.state.seats,
     },
     payment: { gateway: this.state.payment_type, method: this.state.payment_method, bank_type: this.state.bank_type, redirect_url: this.state.redirect_url },
     agree: this.state.agree
    }
    console.log("check params ===> ", params)

    this.setState({
      check_out_disabled: true,
    }, () => {
      this.props.form.validateFieldsAndScroll((err, values) => {
        if(this.state.payment_type === "" || (this.state.payment_type === 'credit_transaction' || this.state.payment_type === 'pay_later' ? "" : this.state.payment_method === null) || this.state.agree === "" || !err === false) {
          if (!err) {
            console.log('Received values of form: ', values);
          }
          if (this.state.payment_type === "") {
            this.openNotificationWithIcon('warning','payment_method_error');
          }
          if (this.state.payment_method === null) {
            this.state.validationErrors.payment_method = "*Please select a payment method";
            this.setState({ validationErrors: this.state.validationErrors })
          } else {
            this.state.validationErrors.payment_method = null;
            this.setState({ validationErrors: this.state.validationErrors })
          }
          if (this.state.agree === "") {
            this.state.validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
            this.setState({ validationErrors: this.state.validationErrors })
          } else {
            this.state.validationErrors.agree = "";
            this.setState({ validationErrors: this.state.validationErrors })
          }
          this.setState({
            check_out_disabled: false,
          })
        } else {
          console.log("submitting", params)
          let url = './api/v1/flights/' + this.props.data.flight.booking_id + '/checkout/';
          let that = this; 

          console.log("check url ====> ", url)

          API.post(url, params)
          .then((response) => {
            if (response.data.redirect_to) {
              window.location = response.data.redirect_to
            } else if (response.data.credits_error) {
              that.openNotificationWithIcon('error','credits_error');
              that.setState({ payment_type: '', check_out_disabled: false })
            } else {
              console.log(response.data.error, 'error--------------------------------console')
              that.setState({
                check_out_disabled: false,
                errorResponseVisible: true,
                errorResponse: response.data.error
              })
            }
          })
          .catch((err) => {
            that.setState({
              visible: true,
            })
            console.log("err formSubmit ===> ", err)
          })
        }
      });    
    })
  }

  handleMealChange = (passenger_id, segment, meal_id, meal_list) => {
    const isBlank = meal_id === ""
    const board_off_point = `${segment.departure_airport.iata}-${segment.arrival_airport.iata}` // meal_list.find(x => x.code === meal_id).board_off_point
    const mealInfo = meal_list.find(x => x.code === meal_id)
    const itemExisted = this.state.meals.find(x => x.pax_number === passenger_id && x.board_off_point === board_off_point && x.segment_no === segment.segment_number)

    if (isBlank) {
      this.setState({
        meals: this.state.meals.filter(x => x.pax_number !== passenger_id || x.board_off_point !== board_off_point && x.segment_no !== segment.segment_number)
      }, () => {
        console.log("value handleMealChange ===> ", this.state.meals)
        this.props.handleDataChangeFromLeftpanel("meals", this.state.meals)
      }) 
    } 
    else if (itemExisted) {
      itemExisted.code = meal_id
      itemExisted.amount = mealInfo.amount
      itemExisted.name = mealInfo.name
      this.setState({
        meals: this.state.meals
      }, () => {
        console.log("value handleMealChange ===> ", this.state.meals)
        this.props.handleDataChangeFromLeftpanel("meals", this.state.meals)
      }) 
    } 
    else {
      this.state.meals.push({ pax_number: passenger_id, segment_no: segment.segment_number, code: meal_id, board_off_point: board_off_point, amount: mealInfo.amount, name: mealInfo.name })
      this.setState({
        meals: this.state.meals
      }, () => {
        console.log("value handleMealChange ===> ", this.state.meals)
        this.props.handleDataChangeFromLeftpanel("meals", this.state.meals)
      })         
    }
  };

  handleBaggageChange = (passenger_id, segments, baggage_id, baggage_list) => {
    const isBlank = baggage_id === ""
    const baggageInfo = baggage_list.find(x => x.code.includes(baggage_id))
    const segmentIds = segments.map((segment) => ( segment.id ))
    const segmentBoardOffPoints = segments.map((segment) => ( `${segment.departure_airport.iata}-${segment.arrival_airport.iata}` )).join('/')
    const itemExisted = this.state.baggages.find(x => x.pax_number === passenger_id && x.board_off_point.includes(segmentBoardOffPoints))

    if (isBlank) {
      this.setState({
        baggages: this.state.baggages.filter(x => x.pax_number !== passenger_id || !x.board_off_point.includes(segmentBoardOffPoints))
      }, () => {
        console.log("value handleBaggageChange ===> ", this.state.baggages)
        this.props.handleDataChangeFromLeftpanel("baggages", this.state.baggages)
      })
    } 
    else if (itemExisted) {
      itemExisted.code = baggage_id
      itemExisted.amount = baggageInfo.amount
      itemExisted.name = baggageInfo.quantity
      this.setState({
        baggages: this.state.baggages
      }, () => {
        console.log("value handleBaggageChange ===> ", this.state.baggages)
        this.props.handleDataChangeFromLeftpanel("baggages", this.state.baggages)
      })
    } 
    else {
      this.state.baggages.push({ pax_number: passenger_id, segment_no: segmentIds, code: baggage_id, board_off_point: segmentBoardOffPoints, amount: baggageInfo.amount, name: baggageInfo.quantity })
      this.setState({
        baggages: this.state.baggages
      }, () => {
        console.log("value handleBaggageChange ===> ", this.state.baggages)
        this.props.handleDataChangeFromLeftpanel("baggages", this.state.baggages)
      })
    }
  };

  addSeatCallback = (passenger_index, segment, seat, addCb) => {
    addCb(seat.row, seat.number, seat.id)
    const passengers_count = this.props.data.flight.passengers.length
    if ((passenger_index == passengers_count) || (segment.segment_number != this.state.prev_segment)) {
      passenger_index = 0
    }
    
    const board_off_point = `${segment.departure_airport.iata}-${segment.arrival_airport.iata}`
    const segment_ref = this.props.data.addons.seat_maps.find(x => x.board_off_point === board_off_point).segment_ref
    const seatInfo = this.props.data.addons.seat_maps.find(x => x.board_off_point === board_off_point).seats[seat.row - 1].filter(v => v !== null).find(x => x.id === seat.id)
    const foundSeat = this.state.seats.find(x => x.pax_number === this.props.data.flight.passengers[passenger_index].id && x.segment_no === segment.segment_number) // this.state.seats.find(x => x.pax_number === this.props.data.flight.passengers[passenger_index].id && x.journey_index === journey_index)
    const formattedPriceDisplay = (seatInfo.amount).replace(/^MYR +/i, '');
    let final_amount = 0;
    let tax = 0;

    // if (foundSeat) {
    //   if (seatInfo.prices) {
    //     let seat_price = seatInfo.prices.find(x => x.passenger_number == passenger_index + 1)
    //     final_amount = seat_price.amount
    //   }
    //   else {
    //     final_amount = formattedPriceDisplay
    //   }
    
    //   this.state.seats.push({ pax_number: this.props.data.flight.passengers[passenger_index + 1].id, segment_no: segment.segment_number, code: seat.id, board_off_point: board_off_point, amount: final_amount, seatRow: seat.row })
    // } 
    // else {

      if (seatInfo.prices) {
        let seat_price = seatInfo.prices.find(x => x.passenger_number == passenger_index)
        if (seat_price) {
          final_amount = seat_price.amount
          tax = seat_price.tax
        }
      }
      else {
        final_amount = formattedPriceDisplay
      }

      this.state.seats.push({ pax_number: this.props.data.flight.passengers[passenger_index].id, segment_no: segment.segment_number, code: seat.id, board_off_point: board_off_point, amount: final_amount, tax: tax, seatRow: seat.row, segment_ref: segment_ref })
    // }
    this.setState({
      passenger_select: passenger_index + 1,
      prev_segment: segment.segment_number,
      seats: this.state.seats
    }, () => {
      console.log("value addSeatCallback ===> ", this.state.seats)
      this.props.handleDataChangeFromLeftpanel("seats", this.state.seats)
    })
  };

  removeSeatCallback = (passenger_index, seat, removeCb,) => {
    removeCb(seat.row, seat.number, seat.id)
    this.setState({
      passenger_select: passenger_index - 1,
      seats: this.state.seats.filter(x => x.code !== seat.id)
    }, () => {
      console.log("value removeSeatCallback ===> ", this.state.seats)
      this.props.handleDataChangeFromLeftpanel("seats", this.state.seats)
    })
  };

  // showTitle = (journeys) => {
  //   if (journeys.length === 2) {
  //     return `Round Trip Flight: ${journeys[0].departure_airport.name} - ${journeys[0].arrival_airport.name}`
  //   } else if (journeys.length > 2) {
  //     return `Multi City Flight: ${journeys.map((journey) => ( `${journey.departure_airport.name} - ${journey.arrival_airport.name}` )).join(', ')}`
  //   } else {
  //     return `One Way Flight: ${journeys[0].departure_airport.name} - ${journeys[0].arrival_airport.name}`
  //   }
  // };

  // showDate = (journeys) => {
  //   return `${journeys[0].departure_date} - ${journeys[journeys.length - 1].departure_date}` 
  // };

  showPassengerCount = (flight) => {
    let array = []
    flight.passengers.map((passenger) => {
      const foundType = array.find(x => x.type === passenger.type)
      if (foundType) {
        foundType.qty += 1
      } else {
        array.push({ type: passenger.type, qty: 1 })
      }
    })

    return array.map((item)=>( `${item.qty} x ${item.type}` )).join(', ')
  };

  seatMaps = (seats, selectedSeats, journey_index, segment, segment_index) => {
    console.log("check raw selectedSeats ===> ", selectedSeats)
    const selectedSeatsArray = selectedSeats.filter(x => x.segment_no === segment.segment_number).map((ss) => ({ code: ss.code, row: ss.seatRow })) || []
    console.log("check selectedSeatsArray ===> ", selectedSeatsArray)
    if (selectedSeatsArray.length > 0) {
      selectedSeatsArray.map((selected) => {
        seats[selected.row - 1].filter(v => v !== null).find(x => x.id === selected.code).isSelected = true
      })
      return seats
    } else {
      return seats
    }
  };

  render() {
    /******************************************* state ****************************************/
    const { visible, banklist_select, payment_type, payment_method, method_visible, offline_banklist, credit_disabled, credits, validationErrors, terms_coditions, termsError, errorResponse, bank_type } = this.state;
    const { getFieldDecorator } = this.props.form;

    /************************************** formItemLayout ****************************************/
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    const adt_chd_passengers = this.props.data.flight && this.props.data.flight.passengers.filter(x => x.type != "INF")
    console.log(this.props.data.addons && this.props.data.addons.baggages, "=========this.props.data.addons.baggages")

    return (
      <div className="LeftPanel">
        <div className="flight_details">
          {/********************************** header title **********************************/}
          {
            this.props.data.flight !== undefined ? (
              <div className="header_title">
                <h1>{this.props.data.flight.title}</h1>
                <p className="subtitle">{this.props.data.flight.dates} | {this.props.data.flight.passenger}</p>
              </div>
            )
            :
            (
              null
            )
          }

          {/********************************** list for flight details **********************************/}
          {
            this.props.data.flight === undefined ? (
              <Skeleton active />
            ) : (
              <List
                bordered={false}
                dataSource={this.props.data.flight.journeys}
                renderItem={journey =>
                  <div>
                    <Card title={[
                      <div className="title_content">
                        <img src={flight} /><span>{journey.departure_airport.city || "N/A"} ({journey.departure_airport.iata || "N/A"})</span>
                        <FontAwesomeIcon icon='long-arrow-alt-right' />
                        <span>{journey.arrival_airport.city || "N/A"} ({journey.arrival_airport.iata || "N/A"})</span>
                        <span className="total_travel_time">{journey.travel_time} {journey.stop === '' || journey.stop === null || journey.stop === 0 ? (null) : (`| ${journey.stop} Stop`)}</span>
                      </div>
                    ]} bordered={false} >
                    {
                      journey.segments.map((segment, segment_index) => {
                        return (
                          <div className="airline_content">
                            <div className="img_warp"><img src={segment.airline_image} /></div>
                            <div className="content">
                              <div className="content_title">
                                <span><b className="title_air">{segment.aircraft}</b><span className="number">{segment.carrier || "N/A"} {segment.flight_number}</span></span>
                                <span className="kg"><FontAwesomeIcon size='lg' icon='suitcase' /> &nbsp;{segment.baggage_max_weight === "" || segment.baggage_max_weight === null ? "-" : segment.baggage_max_weight}</span>
                              </div>
                              <p className="class_type">{segment.cabin_class || "N/A"}</p>
                              <div className="time_warp">
                                <div className="from">
                                  <div className="time">
                                    <p className="time_sty">{segment.departure_time || "N/A"}</p><p><small>{segment.departure_airport.city || "N/A"} ({segment.departure_airport.iata || "N/A"})</small></p>
                                  </div>
                                  <div className="icon"><FontAwesomeIcon size='lg' icon='long-arrow-alt-right' /></div>
                                  <div className="clear"></div>
                                </div>
                                <div className="to">
                                  <p className="time_sty">{segment.arrival_time || "N/A"}</p><p><small>{segment.arrival_airport.city || "N/A"} ({segment.arrival_airport.iata || "N/A"})</small></p>
                                </div>
                                {/* <div className="stop_time">
                                  <p className="time_sty">{segment.flight_time || "N/A"}</p><p><small>{segment.stop || "N/A"} stop</small></p>
                                </div>*/}
                                <div className="clear"></div>
                              </div>
                            </div>
                            <div className="clear"></div>
                            {
                              segment.transit_time.includes("0M") || segment.transit_time === "" || segment.transit_time === null ? 
                              (
                                null
                              ) 
                              : 
                              (
                                <div className="plane">
                                  <Divider>TRANSFER DURATION {segment.transit_time}</Divider>
                                </div>
                              )
                            }
                            { segment_index === journey.segments.length - 1 ? (null) : (<Divider />) }
                            {/*<div className="air_time">
                              <p className="type">{segment.airline_name || "N/A"} - {segment.carrier || "N/A"} {segment.flight_number || "N/A"} <span className="flight_time">{segment.flight_time || "N/A"}</span></p>
                              <div className="depart">
                                <img src={flight} /><span className="space"><b>{segment.departure_time || "N/A"}</b></span>
                                <span className="space">{segment.departure_date || "N/A"}</span>
                                <span>
                                  <b>{segment.departure_airport.city || "N/A"} ({segment.departure_airport.iata || "N/A"})</b>
                                </span>
                              </div>
                              <div className="depart">
                                <img src={land} /><span className="space"><b>{segment.arrival_time || "N/A"}</b></span>
                                <span className="space">{segment.arrival_date || "N/A"}</span>
                                <span>
                                  <b>{segment.arrival_airport.city || "N/A"} ({segment.arrival_airport.iata || "N/A"})</b>
                                </span>
                              </div> 
                            </div>*/}
                          </div>
                        )
                      })
                    }
                    </Card>
                  </div>
                }
              />
            )
          }
          <Form {...formItemLayout} className="form_sty_select" >
            <div className="header_title">
              <h2>Add Ons</h2>
            </div>
            {
              this.props.data.booking_group !== undefined ?
              (
                <React.Fragment>
                  <Card className="passenger">
                    <React.Fragment>
                      {
                         this.props.data.booking_group.booking_addons.map((booking_addon, booking_addon_index) => {
                          return (
                            <div className="addons_form" style={{ marginBottom: 0 }}>
                              <div className="header_title">
                                <h3><b>{booking_addon.journey}</b></h3>
                              </div>
                              {
                                booking_addon.passengers.map((passenger, passenger_index) => {
                                  return (
                                    <div>
                                      <div className="passenger_content">
                                        <p>Passenger {passenger_index + 1} : {passengerCategoryMapping[passenger.type]}</p>
                                      </div>
                                      {
                                        passenger.segments.map((segment, segment_index) => {
                                          return (
                                            <div className="segment_list" style={{ marginLeft: 30, marginTop: 5 }}>
                                              <div className="passenger_content">
                                                <p style={{ color: 'orange' }}>{segment.segment_name}</p>
                                              </div>
                                              <div style={{ marginTop: 5 }}>
                                              {
                                                segment.addons.map((addon) => {
                                                  const addonKind = addon.kind.includes('meal') ? "Meal" : addon.kind.includes('baggage') ? "Baggage" : addon.kind.includes('seat') ? "Seat" : addon.kind

                                                  return (
                                                    <p style={{ marginBottom: '5px' }}><b>{addonKind}:</b> {addon.name}</p>
                                                  )
                                                })
                                              }
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  )
                                })
                              }
                              {
                                booking_addon_index === this.props.data.booking_group.booking_addons.length - 1 ?
                                (
                                  null
                                )
                                :
                                (
                                  <Divider style={{ top: 5, height: 10 }} />
                                )
                              }
                            </div>
                          )
                        })
                      }
                    </React.Fragment>
                  </Card>
                </React.Fragment>
              )
              :
              (
                <React.Fragment>
                  <Card className="passenger">
                    {
                      this.props.data.flight !== undefined ?
                      (
                        <React.Fragment>
                        {
                           this.props.data.flight.journeys.map((journey, journey_index) => {
                            return (
                              <div className="addons_form" style={{ marginBottom: 0 }} key={journey_index}>
                                <div className="header_title">
                                  <h3><b>{journey.departure_airport.iata} - {journey.arrival_airport.iata}</b></h3>
                                </div>
                                {
                                  adt_chd_passengers.map((passenger, passenger_index) => {
                                    return (
                                      <div key={passenger_index}>
                                        <div className="passenger_content">
                                          <p>Passenger {passenger_index + 1} : {passengerCategoryMapping[passenger.type]}</p>
                                        </div>

                                        <div className="form_passenger">
                                          <div className="input_warp">
                                            {/************************************** Designation **************************************/}
                                            <div className="form_control pandding_right" >
                                              <Form.Item label="Checked Baggage" >
                                                <Select placeholder="Please select" onChange={(value) => { this.handleBaggageChange(passenger.id, journey.segments, value, this.props.data.addons.baggages[journey_index] !== undefined ? this.props.data.addons.baggages[journey_index].baggage_list : []) }}>
                                                  <Option value={""}>Select Baggage</Option>
                                                  {
                                                    this.props.data.addons.baggages[journey_index] !== undefined ?
                                                    (
                                                      this.props.data.addons.baggages[journey_index].baggage_list.map((baggage) => {
                                                        return (
                                                          <Option key={baggage.code} value={baggage.code}>({baggage.currency} {parseFloat(baggage.amount || 0).toFixed(2)}) +{baggage.quantity}</Option>
                                                        )
                                                      })
                                                    )
                                                    :
                                                    (
                                                      null
                                                    )
                                                  }
                                                </Select>
                                              </Form.Item>
                                            </div>
                                          </div>
                                        </div>
                                        {
                                          journey.segments.map((segment, segment_index) => {
                                            return (
                                              <div className="segment_list" style={{ marginLeft: 30 }} key={segment_index}>
                                                {
                                                  this.props.data.addons.meals && this.props.data.addons.meals.length > 0 ? (
                                                    <div>
                                                      <div className="passenger_content">
                                                        <p style={{ color: 'orange' }}>Meal : {`${segment.departure_airport.iata}-${segment.arrival_airport.iata}`}</p>
                                                      </div>
                                                      <div className="form_passenger">
                                                        <div className="input_warp">
                                                          <div className="form_control pandding_right" >
                                                            <Form.Item label="">
                                                              <Select placeholder="Please select" onChange={(value) => { this.handleMealChange(passenger.id, segment, value, this.props.data.addons.meals[journey_index] !== undefined ? this.props.data.addons.meals[journey_index].meal_list : []) }}>
                                                                <Option value={""}>Select Meal</Option>
                                                                {
                                                                  this.props.data.addons.meals[journey_index] !== undefined ? 
                                                                  (
                                                                    this.props.data.addons.meals[journey_index].meal_list.map((meal) => {
                                                                      return (
                                                                        <Option key={meal.code} value={meal.code}>({meal.currency} {meal.amount}) {meal.name}</Option>
                                                                      )
                                                                    })
                                                                  )
                                                                  :
                                                                  (
                                                                    null
                                                                  )
                                                                }
                                                              </Select>
                                                            </Form.Item>
                                                          </div>
                                                          {/* <div className="form_control pandding_right" >
                                                            <Form.Item label="Seats" >
                                                              <Select placeholder="Please select" onChange={(value) => { this.handleMealChange(passenger.id, segment.segment_number, value, this.props.data.addons.meals[journey_index].meal_list) }}>
                                                                {
                                                                  this.props.data.addons.meals[journey_index].meal_list.map((meal) => {
                                                                    return (
                                                                      <Option key={meal.code} value={meal.code}>({meal.currency} {meal.amount}) {meal.name}</Option>
                                                                    )
                                                                  })
                                                                }
                                                              </Select>
                                                            </Form.Item>
                                                          </div> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (null)
                                                }
                                              </div>
                                            )
                                          })
                                        }
                                      </div>
                                    )
                                  })
                                }
                                {
                                  journey_index === this.props.data.flight.journeys.length - 1 ?
                                  (
                                    null
                                  )
                                  :
                                  (
                                    <Divider style={{ top: 5, height: 10 }} />
                                  )
                                }
                              </div>
                            )
                          })
                        }
                        </React.Fragment>
                      )
                      :
                      (
                        <Skeleton active />
                      )
                    }
                  </Card>
                  {
                    this.props.data.flight !== undefined ?
                    (
                      <React.Fragment>
                        <div className="header_title">
                        <h2>Pick Seat</h2>
                      </div>
                      <Card className="passenger">
                        {
                          this.props.data.flight !== undefined ?
                          (
                            <React.Fragment>
                            {
                              this.props.data.flight.journeys.map((journey, journey_index) => {

                                const seatQuota = this.props.data.flight.passengers.filter(x => x.type != "INF").length || 0

                                {/*let newSeatArray = this.props.data.addons.seat_maps[journey_index].seats.slice()
                                newSeatArray.map((seat) => ( seat.splice(3,0,null) ))
                                console.log("check newSeatArray ===> ", newSeatArray)*/}

                                return (
                                  <div key={journey_index} style={{ marginBottom: journey_index === this.props.data.flight.journeys.length - 1 ? 0 : 15 }}>
                                    <div className="header_title">
                                      <h3><b>{journey.departure_airport.iata} - {journey.arrival_airport.iata}</b></h3>
                                    </div>
                                    {
                                      journey.segments.map((segment, segment_index) => {
                                        return (
                                          <div key={segment_index} style={{ marginLeft: 30, marginBottom: segment_index === journey.segments.length - 1 ? 0 : 15 }}>
                                            <div className="passenger_content">
                                              <p style={{ color: 'orange' }}>Segment {segment_index + 1} : {`${segment.departure_airport.iata}-${segment.arrival_airport.iata}`}</p>
                                            </div>
                                            {
                                              adt_chd_passengers.map((passenger, passenger_index) => {
                                                const seatCode = this.state.seats.find(x => x.pax_number === passenger.id && x.segment_no === segment.segment_number) !== undefined ? this.state.seats.find(x => x.pax_number === passenger.id && x.segment_no === segment.segment_number).code : "None"

                                                return (
                                                  <p key={passenger_index} style={{ marginBottom: 0 }}>
                                                    Passenger {passenger_index + 1} : {passengerCategoryMapping[passenger.type]} - {seatCode}
                                                  </p>
                                                )
                                              })
                                            }
                                            <div className="passenger_content">
                                              <a onClick={() => { this.onToggleShowSeatMap(segment) /* this.onOpenSelectSeatModal(journey_index, segment, segment_index, seatQuota) */ }}>
                                                <p>{this.state.showSeatMap.includes(segment.segment_number) ? "Hide" : "Show"} Seat Selection</p>
                                              </a>
                                            </div>
                                            {
                                              this.props.data.addons.seat_maps.length > 0 ?
                                                (
                                                  <div style={{ display: this.state.showSeatMap.includes(segment.segment_number) ? 'block' : 'none' }}>
                                                    {/************************************** Designation **************************************/}
                                                    <div className="form_control pandding_right" style={{ width: '100%' }} >
                                                      <Form.Item style={{ overflow: 'scroll' }}>
                                                        {
                                                          this.props.data.addons.seat_alphabets.length > 0 ? (
                                                            <div className="seat_picker_header_wrapper">
                                                              <div className="seat_picker_header_label_row">&nbsp;</div>
                                                              {
                                                                this.props.data.addons.seat_alphabets.map((alphabet, j) => {
                                                                  return (
                                                                    <div key={j} className={alphabet == "" ? "seat_picker_header_label_row" : "seat_picker_header_label"}><b>{alphabet}</b></div>
                                                                  )
                                                                })
                                                              }
                                                            </div>
                                                          ) : (
                                                            <div className="seat_picker_header_wrapper">
                                                              <div className="seat_picker_header_label_row">&nbsp;</div>
                                                              <div className="seat_picker_header_label"><b>A</b></div>
                                                              <div className="seat_picker_header_label"><b>B</b></div>
                                                              <div className="seat_picker_header_label"><b>C</b></div>
                                                              <div className="seat_picker_header_label_row">&nbsp;</div>
                                                              <div className="seat_picker_header_label"><b>D</b></div>
                                                              <div className="seat_picker_header_label"><b>E</b></div>
                                                              <div className="seat_picker_header_label" style={{ marginRight: 0 }}><b>F</b></div>
                                                            </div>
                                                          )
                                                        }
                                                        
                                                        <SeatPicker
                                                          addSeatCallback={(value, cb) => { this.addSeatCallback(this.state.passenger_select, segment, value, cb) }}
                                                          removeSeatCallback={(value, cb) => { this.removeSeatCallback(this.state.passenger_select, value, cb) }}
                                                          rows={this.props.data.addons.seat_maps[journey_index] != undefined ? this.props.data.addons.seat_maps[journey_index].seats : []}
                                                          maxReservableSeats={seatQuota}
                                                          // alpha
                                                          visible
                                                          selectedByDefault
                                                          // loading={loading}
                                                          tooltipProps={{multiline: true}}
                                                        />
                                                      </Form.Item>
                                                    </div>
                                                  </div>
                                                ) : (null)
                                            }
                                            
                                            {/* 
                                              this.state.showSeatMap.includes(segment.segment_number) ? 
                                              (
                                                
                                              )
                                              :
                                              (
                                                null
                                              )
                                             */}
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              })
                            }
                            </React.Fragment>
                          )
                          :
                          (
                            <Skeleton active />
                          )
                        }
                      </Card>
                      </React.Fragment>
                    )
                    :
                    (
                      null
                    )
                  }
                </React.Fragment>
              )
            }
            {/************************************************ Payment Method ************************************************/}
            <div className="header_title">
              <h2>Payment Method</h2>
            </div>

            <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={false} credit_disabled={credit_disabled} fpx_disabled={false} final_price={this.props.final_price} service_fee={this.props.service_fee}
              credits={credits} payment_type={payment_type} payment_method={payment_method} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select} paymentTypeAvailable={4}
              offline_banklist={offline_banklist} instant_access={!this.props.instant_access_flight} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)} fpxb2b_disabled={this.props.data.booking_group && !this.props.data.booking_group.fpxb2b_available} onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)}allow_fpxb2c_overlimit={false} dobw_disabled={this.props.data.booking_group && !this.props.data.booking_group.dobw_available} fpxb2c_disabled={this.props.data.booking_group && !this.props.data.booking_group.fpxb2c_available} bank_type={bank_type}
            />

            <div className="after_select">
              <Button type="primary" disabled={this.state.check_out_disabled} onClick={this.submitForm}>CHECKOUT</Button>
            </div>

          </Form>

        </div>

        {/************************************************ modal errorResponseVisible ************************************************/}
        <Modal
          visible={this.state.errorResponseVisible}
          title={null}
          footer={null}
          closable={null}
          destroyOnClose={true}
        >
          <Empty
            image={warning}
            imageStyle={{ height: 100, }}
            description={<p className="popup_text">{errorResponse}</p>}
            className="popup_footer"
          >
            <Button type="primary" onClick={this.handleCancel}>Close</Button>
          </Empty>
        </Modal>

        {/************************************************ modal submit problems ************************************************/}
        <Modal
          visible={this.state.visible}
          title={null}
          footer={null}
          closable={null}
          destroyOnClose={true}
        >
          <Empty
            image={warning}
            imageStyle={{ height: 100, }}
            description={<p className="popup_text">There are some problems with your submit.</p>}
            className="popup_footer"
          >
            <Button type="primary" onClick={this.handleCancelWrong}>Close</Button>
          </Empty>
        </Modal>

        <Modal
          visible={this.state.selectSeatModalVisible}
          title={"Select Seat"}
          onOk={() => { this.handleOk() }}
          onCancel={() => { this.handleCancel() }}
          footer={null}
        >
        {
          this.state.selectSeatModalVisible ?
          (
            <div>
              {/************************************** Designation **************************************/}
              <div className="form_control pandding_right" style={{ width: '100%' }} >
                <Form.Item style={{ overflow: 'scroll' }}>
                  <div className="seat_picker_header_wrapper">
                    <div className="seat_picker_header_label_row">&nbsp;</div>
                    <div className="seat_picker_header_label"><b>A</b></div>
                    <div className="seat_picker_header_label"><b>B</b></div>
                    <div className="seat_picker_header_label"><b>C</b></div>
                    <div className="seat_picker_header_label_row">&nbsp;</div>
                    <div className="seat_picker_header_label"><b>D</b></div>
                    <div className="seat_picker_header_label"><b>E</b></div>
                    <div className="seat_picker_header_label" style={{ marginRight: 0 }}><b>F</b></div>
                  </div>
                  <SeatPicker
                    addSeatCallback={(value, cb) => { this.addSeatCallback(0, this.state.selectSeatModalData.segment, value, cb) }}
                    removeSeatCallback={(value, cb) => { this.removeSeatCallback(value, cb) }}
                    rows={this.props.data.addons.seat_maps[this.state.selectSeatModalData.journey_index].seats}
                    maxReservableSeats={this.state.selectSeatModalData.seatQuota}
                    // alpha
                    visible
                    selectedByDefault
                    // loading={loading}
                    tooltipProps={{multiline: true}}
                  />
                </Form.Item>
              </div>
            </div>
          )
          :
          (
            <Skeleton active />
          )
        }
        </Modal>

      </div >
    );
  }
}

const FlightBookingAddonForm = Form.create({ name: 'FlightBookingAddon' })(LeftPanel);
export default withRouter(FlightBookingAddonForm);
