import React, { Component } from 'react';
import './index.css';
import { List, Button, Icon, Rate, Skeleton, Empty, Typography, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter, Link } from 'react-router-dom'
import { element } from 'prop-types';
import moment from 'moment';

const { Paragraph } = Typography;

const dateFormat = 'Do MMM YYYY';

const localData = [
   { kgEmpty: "-" }
]

class RightPanel extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isVisible: false,
         modalData: { title: '', content: '' }
      }
      this.handleClick = this.handleClick.bind(this);
   }

   handleClick(e, data) {
      var btn = e.target
      btn.classList.toggle("active");

      var content = btn.nextElementSibling;
      if (content.style.maxHeight) {
         content.style.maxHeight = null;
      } else {
         content.style.maxHeight = content.scrollHeight + "px";
      }
   }

   showModal(id, type) {
      const modalData = this.props.detail.find(element => {
         return element.taggable_id === id && element.taggable_type === type
      })
      this.setState({
         isVisible: true,
         modalData: { title: modalData.title, content: modalData.description }
      });
   };
   handleCancel = e => {
      console.log(e);
      this.setState({
         isVisible: false,
      });
   };

   availableInfo(item) {
      if (item.taggable_type == "GT") {
         return ("Available Today")
      } else if (item.taggable_type == "ACT") {
         var date_today = moment().format(dateFormat)
         var date_tomorrow = moment(new Date()).add(1, 'days').format(dateFormat)
         var travellingDateStart = moment(item.travelling_date_start, 'YYYY-MM-DD[T]HH:mm:ss').format(dateFormat)
         if (travellingDateStart == date_today) {
            return ("Available Today")
         } else if (travellingDateStart == date_tomorrow) {
            return ("Available Tomorrow")
         } else {
            return ("Available from " + travellingDateStart)
         }
      } else {
         return ('')
      }
   }

   render() {
      const dataDetail = this.props.detail;
      const lengthDetail = this.props.dataLength;
      console.log(dataDetail, 'dataDetail')
      return (
         <div id="RightPanel">

            {
               lengthDetail === 0 ? (
                  <div className="empty">
                     {
                        this.props.loading ? (
                           <Skeleton active avatar paragraph={{ rows: 4 }} />
                        ) : (
                              <Empty description="Data no Found" />
                           )
                     }
                  </div>
               ) : (
                     dataDetail.map((item, i) => {
                        return (
                           <List
                              itemLayout="vertical"
                              size="large">
                              <List.Item
                                 key={item.taggable_id}
                                 extra={[
                                    <div className="right_price">
                                       <p className="from_style"><small>From</small></p>
                                       <p className="price_tag">RM {item.price}</p>
                                       <div className="select_btn">
                                          <Link to={{
                                             pathname: "/activities/" + item.taggable_id,
                                             search: "?taggable_type=" + item.taggable_type
                                          }}
                                          >
                                             <Button type="primary" ghost>SELECT</Button>
                                          </Link>
                                       </div>
                                       <div className="clear"></div>
                                    </div>
                                 ]}
                              >
                                 <List.Item.Meta
                                    avatar={<img alt="logo" src={item.taggable_type == "GT" ? item.images : item.images.length > 0 ? item.images[0].url : this.props.defaultImage} className="img_sty" />}
                                    title={<p className="title_panel">
                                       <Paragraph ellipsis={{ rows: 2, }}
                                          className="ellipsis_style ellipsis_style_view">{item.title}</Paragraph>
                                       <span className="view_sty">
                                          <Icon type="question-circle" className="view_icon"
                                             onClick={(e) => this.showModal(item.taggable_id, item.taggable_type)} />
                                       </span>
                                       <div className="clear"></div>
                                    </p>}
                                    description={[
                                       <div className="content_rate">
                                          <p ><Paragraph ellipsis={{ rows: 2, }}
                                             className="ellipsis_style">{item.description}</Paragraph></p>
                                          {/* <p>{items.description.substring(0, 50)} {items.description.length > 50 ? '...' : ''}</p> */}
                                          <p>
                                             <FontAwesomeIcon icon='map-marker-alt' className="location_icon" />{item.country}
                                             <small className="available_info" style={{ float: 'right' }}>{this.availableInfo(item)}</small>
                                          </p>
                                       </div>
                                    ]}
                                 />
                              </List.Item>
                           </List>
                        )
                     })

                  )

            }
            <Modal
               title={this.state.modalData.title}
               visible={this.state.isVisible}
               footer={null}
               onCancel={this.handleCancel}
            >
               <p>{this.state.modalData.content}</p>
            </Modal>

         </div>
      );
   }
}

export default withRouter(RightPanel);
