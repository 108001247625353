import React, { Component } from 'react';
import './index.css';
import { List, Button, Icon, Rate, Skeleton, Empty, Typography, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter, Link } from 'react-router-dom';


const { Paragraph } = Typography;


class RightPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            modalData: { title: '', content: '' }

        }
        this.handleClick = this.handleClick.bind(this);
        // this.showModal = this.showModal.bind(this);
        // this.state = {
        //     dataDetail: this.props.detail,
        // }

    }

    showModal(value) {
        console.log(this);
        console.log(value, 'value')
        const modalData = this.props.detail.find(element => {
            return element.id === value
        })
        console.log(modalData, 'modalData')
        this.setState({
            isVisible: true,
            modalData: { title: modalData.name, content: modalData.country }
        });

    };

    handleCancel = (e) => {
        console.log(e);
        this.setState({
            isVisible: false,
        });
    };


    handleClick(e, data) {
        var btn = e.target
        btn.classList.toggle("active");

        var content = btn.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }


    render() {
        const { isVisible } = this.state;
        const dataDetail = this.props.detail;
        const lengthDetail = this.props.dataLenght;
        //console.log(this.props.loading, '---------------loading')

        return (
            <div id="RightPanel">

                {
                    lengthDetail === 0 ? (
                        <div className="empty">
                            {
                                this.props.loading ? (
                                    <Skeleton active avatar paragraph={{ rows: 4 }} />
                                ) : (
                                        <Empty description="Data no Found" />
                                    )
                            }
                        </div>
                    ) : (
                            <div>
                                <List
                                    itemLayout="vertical"
                                    size="large"
                                    dataSource={dataDetail}
                                    renderItem={(item, index) => (
                                        <List.Item
                                            key={item.id}
                                            extra={[
                                                <div className="right_price">
                                                    <p className="price_tag">RM {item.price} </p>
                                                    <p className="tax_tag"><small>Per Day</small></p>
                                                    <div className="select_btn">

                                                        <Link to={{
                                                            pathname: "/wifis/" + encodeURIComponent(item.id),
                                                            search: this.props.location.search
                                                        }} >
                                                            <Button type="primary" ghost>SELECT</Button>
                                                        </Link>
                                                    </div>
                                                    <div className="clear"></div>
                                                </div>
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <img
                                                        className="img_sty"
                                                        alt="logo"
                                                        src={item.image_url !== '' ? item.image_url : homepage_new.default_image}
                                                    />}
                                                title={<p className="title_panel">
                                                    <Paragraph ellipsis={{ rows: 2, }}
                                                        className="ellipsis_style ellipsis_style_view">{item.name}
                                                    </Paragraph>
                                                    <span className="view_sty">
                                                        <Icon type="question-circle" className="view_icon" onClick={(e) => this.showModal(item.id)} />
                                                    </span>
                                                    <div className="clear"></div>
                                                </p>
                                                }
                                                description={[
                                                    <div className="content_rate">
                                                        <p>
                                                            <Paragraph ellipsis={{ rows: 1, }} className="ellipsis_style">
                                                                <FontAwesomeIcon icon='map-marker-alt' className="location_icon" />{item.country}
                                                            </Paragraph>
                                                        </p>
                                                        <p className="type">{item.category} ({item.data_rules})<br/><span class="type-title">Provided by: Roaming Man</span></p>
                                                    </div>
                                                ]}

                                            />

                                        </List.Item>
                                    )}
                                />
                            </div>
                        )
                }
                <Modal
                    title={this.state.modalData.title}
                    visible={isVisible}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalData.content}</p>
                </Modal>

            </div>
        );
    }
}

export default withRouter(RightPanel);
