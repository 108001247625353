import React, { Component } from 'react';
import Search from '../../search/index';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import { withRouter } from 'react-router-dom';
import API from '../../../../api'
import { Spin } from 'antd';
import Footer from '../../home/footer/index';


class ListingHotel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            detail: [],
            params: this.props.location.search,
            isLoading: true,
            sortBy: 'lowest_price',
            filterByRating: [],
            filterByName: ''
        }
        this.myRef = React.createRef();

        this.sortByCallback = this.sortByCallback.bind(this);
    }

    componentDidMount() {
        this.apiFetch();
        this.myRef.current.scrollIntoView({
            behavior: "smooth",
        })
        console.log(this.myRef, '---------ref')
    }

    componentDidUpdate() {
        if (this.props.location.search != this.state.params) {
            this.setState({
                params: this.props.location.search,
                isLoading: true
            })
            console.log(this.props.location.search)
            // console.log(this.state.params)
            this.apiFetch();
        }
        this.myRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
        })

    }

    sortByCallback(value){
      console.log(value, 'SortBy')
      this.setState({
        sortBy: value
      })
    }

    handleRatingChange = (value) => {
      this.setState({
        filterByRating: value
      });
    };

    handleNameFilter = (e) => {
      this.setState({
        filterByName: e.target.value
      });
    };

    processDetail(data){
      const { sortBy, filterByRating, filterByName } = this.state

      if(data && data.length){
        if (filterByRating.length > 0) {
          data = data.filter(i => filterByRating.includes(i.star_rating));
        }

        if (filterByName !== "") {
          data = data.filter(i => i.name.toLowerCase().includes(filterByName.toLowerCase()));
        }

        if(sortBy == 'lowest_price') {
          return data.sort((a, b) => a.price - b.price)
        } else if(sortBy == 'highest_price') {
          return data.sort((a, b) => b.price - a.price)
        }
      }
      return data
    }

    apiFetch() {
        // const { params } = this.state;
        const params = this.props.location.search;
        let url = './api/v1' + this.props.location.pathname;
        var that = this;
        console.log(params);

        API.get(url + params)
            .then(function (response) {
                console.log(response, '------------res');
                that.setState({
                    detail: response.data,
                    isLoading: false,
                    filterByRating: [],
                    filterByName: ''
                })
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });

    }


    render() {
        const { isLoading, detail, sortBy, filterByRating, filterByName } = this.state;
        let params = this.props.location.search;
        const data = detail.error ? detail : this.processDetail(detail);

        return (
            <div>
                <Search tagline={homepage_new.tagline} covers={homepage_new.search_covers} />

                <Spin size="large" spinning={isLoading}>
                    <div id="ListingHotel" ref={this.myRef}>
                        <div className="warp">
                            <LeftPanel sortByCallback={this.sortByCallback} sortBy={sortBy} filterByRating={filterByRating} handleRatingChange={this.handleRatingChange} filterByName={filterByName} handleNameFilter={this.handleNameFilter} />
                            <RightPanel detail={data} dataLength={data.length} loading={isLoading} />
                            <div className="clear"></div>
                        </div>
                    </div>
                </Spin>
                <Footer />
            </div>
        );
    }
}

export default withRouter(ListingHotel);
