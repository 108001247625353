import React, { Component } from 'react';
import { Form, Input, Card, Skeleton, } from 'antd';

class ContactInfor extends Component {

  /***************************************************** handleContactDataChange function *******************************************************/
  handleContactDataChange(index, type, value, e = '') {
    if (type === 'name') {
      this.props.contacts[index]['customerName'] = value; // for globaltix ticket
    }
    this.props.contacts[index][type] = value;
  }

  render() {

    const getFieldDecorator = this.props.getFieldDecorator;

    return (
      <div>
        <div className="header_title">
          <h2>Contact Info</h2>
        </div>
        {
          this.props.loading ? (
            <Skeleton active />
          ) : (
              <Card title={[
                <div className="passenger_content" key={0}>
                  <p>Contact Details</p>
                </div>
              ]} className="passenger contact_infor">

                <div className="form_passenger">

                  {/************************************ Contact name ************************************/}
                  <div className="form_control pandding_right" >
                    <Form.Item label="Guest name" >
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: '*Please fill in the name', }],
                      })(
                        <Input placeholder="Name" onChange={(event) => this.handleContactDataChange(0, 'name', event.target.value)} />
                      )}
                    </Form.Item>
                  </div>

                  {/************************************ Mobile phone ************************************/}
                  <div className="form_control pandding_left" >
                    <Form.Item label="Guest Mobile" className="mobile_form" >
                      {getFieldDecorator('phone', {
                        rules: [{ required: true, message: '*Please fill in the phone number', }],
                      })(
                        <Input placeholder=" 01x - xxxxxxxxxx" onChange={(event) => this.handleContactDataChange(0, this.props.pageName === "VoucherShowPage" ? 'mobile_no' : 'mobile', event.target.value)} />
                      )}
                    </Form.Item>
                    <span className="phone_notice">
                      <p>Please provide a number so we can contact you to resolve any booking issues.</p>
                    </span>
                  </div>

                  {/************************************ Email ************************************/}
                  <div className="form_control pandding_right">
                    <Form.Item label="Guest E-mail">
                      {getFieldDecorator('email', {
                        rules: [
                          { type: 'email', message: 'The input is not valid E-mail', },
                          { required: true, message: '*Please fill in the E-mail', },
                        ],
                      })(
                        <Input placeholder="E-mail" onChange={(event) => this.handleContactDataChange(0, 'email', event.target.value)} />
                      )}
                    </Form.Item>
                  </div>

                  {/************************************ Remark ************************************/}
                  <div className="form_control pandding_left">
                    <Form.Item label="Remark">
                      {getFieldDecorator('remark')(
                        <Input placeholder="Remark" onChange={(event) => this.handleContactDataChange(0, 'remark', event.target.value)} />
                      )}
                    </Form.Item>
                    {
                      this.props.pageName && 
                        this.props.pageName == "ActivityShowPage" ? (
                          <span className="phone_notice">
                            <p>Please provide arrival/departure flight detail if you book our airport transfer.</p>
                          </span>
                        ) : (null)
                    }
                  </div>

                  {/************************************ notice ************************************/}
                  <div className="notice">
                    <p><small>* We will send your booking confirmation and check-in info to this email address.</small></p>
                    { this.props.pageName === "VoucherShowPage" ? (null) : (<p><small>* Please ensure you are able to receive emails from iceb2b when traveling before submitting your booking.</small></p>) }
                  </div>

                </div>
              </Card>
            )
        }

      </div>
    )

  }
}

export default ContactInfor;
