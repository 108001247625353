import React, { Component } from "react";
import LeftPanel from "./leftPanel";
import RightPanel from "./rightPanel";
import "./index.css";
import ShowMenu from "../menu";
import API from "../../../../api";
import { withRouter, Link } from "react-router-dom";
import { Drawer, Button, Spin } from "antd";
import Footer from '../../home/footer/index';

class ShowFlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      selectedData: '',
      selectedKey: 0,
      loading: true,
      visible: false,
      brbCount: 0,
      visibleNodata: false,
      dataLenght: []
    };
    this.handleBrbCount = this.handleBrbCount.bind(this);

  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0)
    //console.log(getUrl, '-----------getUrl');
    const params = this.props.location.search;
    let url = "./api/v1" + this.props.location.pathname;
    var that = this;
    //console.log(params);

    API.get(url + params)
      .then(function (response) {
        //console.log(response,'------------res');

        const count = Object.keys(response.data.solutions).length;

        that.setState({
          dataList: response.data,
          selectedData: count === 0 ? '' : response.data.solutions[0],
          loading: false,
          visibleNodata: count === 0 ? true : false
        });
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }

  handleTabCallback = (key) => {
    this.setState({
      selectedKey: key,
      selectedData: this.state.dataList.solutions[key]
    });
  };

  handleBrbCount(value) {
    this.setState({
      brbCount: value,
    });
  }

  render() {
    const { dataList, loading, visibleNodata, selectedKey, selectedData } = this.state;

    return (
      <div>
        <ShowMenu />

        <div id="ShowFlight">
          <div className="banner"></div>
          <div className="logo_top">
            <Link to="/">
              <div className="logo_warp">
                <img src={homepage_new.app_logo} alt="ice" />
                <span>TRAVELB2B</span>
              </div>
            </Link>
          </div>
          <Spin size="large" spinning={loading}>
            <div className="warppage">
              <LeftPanel dataList={dataList} selectedKey={selectedKey} selectedData={selectedData} handleTabCallback={this.handleTabCallback} handleBrbCount={this.handleBrbCount} brbCount={this.state.brbCount} jsonLength={visibleNodata} />
              <RightPanel selectedData={selectedData} loading={loading} brbCount={this.state.brbCount} />
              <div className="clear"></div>
            </div>
            <div className="res_panel">
              <Button
                type="primary"
                onClick={this.showDrawer}
                className="res_panel_btn"
              >
                Summary
            </Button>
              <Drawer
                title=""
                placement="right"
                closable={true}
                onClose={this.onClose}
                visible={this.state.visible}
              >
                <RightPanel selectedData={selectedData} loading={loading} brbCount={this.brbCount} />
              </Drawer>
            </div>
          </Spin>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ShowFlight);
