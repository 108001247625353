import React, { forwardRef } from 'react';
import { Table } from 'antd';
import './index.css';

export const OrderTable = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="print_summary">
      {
        props.data ?
        ( 
          <Table
            columns={props.columns}
            dataSource={props.data}
            pagination={props.pagination}
            loading={props.loading}
            onChange={props.handleTableChange}
            // expandedRowRender={expandedRowRender}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        ) : (null)
      }
    </div>
  );
});