import React, { Component } from 'react';
import { Icon, Collapse, Input, Card, Button, Skeleton } from 'antd';
import './index.css'
import qs from 'qs'

const { Panel } = Collapse;

class RightPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activePackages: []
    }
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  } 

  componentDidMount() {
  }
  
  render() {
    const data = this.props.dataList;
    const ticketDetails = this.props.ticketDetails && this.props.ticketDetails.filter((t) => t.visible === true);

    let dta = 0;
    let total_quantity = 0;
    let total_price = 0;
    const payment_type = this.props.payment_type
    const service_fee = this.props.service_fee;
    const final_price = this.props.final_price;

    if (payment_type == "public_bank" || payment_type == "public_bank_2") {
      var final_total = total_price + service_fee
    }
    else {
      var final_total = total_price
    }
    
    return (
      <div className="RightPanel res_panel_warp">
      {
          this.props.loading ? (
            <Skeleton active />
          ) : (
        <Card title={[
          <div className="title">
            <h2>Summary</h2>
          </div>]}>
          <div className="dates">
            <p className="date_title">Activity</p>
            <p className="date_select">{ data.title }</p>
          </div>
          {
            data.taggable_type == 'GT' ?
            (
              ''
            ) :
            data.taggable_type == 'ACT' ?
            (
              <div className="dates">
                <p className="date_title">Departure Date</p>
                <p className="date_select">{ this.props.visitDate !== "" ? this.props.visitDate : 'No date selected' }</p>
              </div>
            ) : 
            (
              ''
            )
          }
          <div className="select_list">
            <p className="note_label"><strong>Note:</strong> Full payment required for this booking.</p>
            <p className="date_title">Fare Breakdown</p>
            {
              ticketDetails && ticketDetails.map((ticket,index) => {
                if (this.props.collectDta) {
                  dta += 0;
                }
                else {
                  dta += ticket.dta * ticket.quantity;
                }
                total_quantity += ticket.quantity;
                total_price += ticket.quantity * ticket.price;
                return (
                  ticket.quantity > 0 ?
                    <div className="list_warp" key={index}>
                    {
                      ticket.quantity > 1 ?
                      <React.Fragment>
                        <div className="subtraction_collapse">
                          <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                            <Panel header={
                              <div className="list_warp fare_info">
                                <span>
                                  <span>{ticket.name}</span>
                                  <div className="break-line"></div>
                                  <span>x <strong>{ticket.quantity}</strong></span>
                                </span>  
                                  <span>RM {this.priceFormat(ticket.price * ticket.quantity)}</span>
                              </div>
                            }>
                              <div>
                                <span>Price per pax</span>
                                <span>RM {this.priceFormat(ticket.price)}</span>
                              </div>
                            </Panel>
                          </Collapse>
                        </div>
                      </React.Fragment>
                      :
                      <React.Fragment>
                        <div className="list_warp fare_info">
                          <span>
                            <span>{ticket.name}</span>
                            <div className="break-line"></div>
                            <span>x <strong>{ticket.quantity}</strong></span>
                          </span> 
                          <span>RM {this.priceFormat(ticket.price * ticket.quantity)}</span>
                        </div>
                      </React.Fragment>
                    }
                      {/* <span>{ticket.name} x <strong>{ticket.quantity}</strong></span>
                      <div className="break-line"></div>
                      <span>RM {this.priceFormat(ticket.price * ticket.quantity)}</span>
                      */}
                    </div> 
                  : ''
                )
              })
            }
            {
              dta > 0 ?
              <div className="list_warp">
                <div className="subtraction_collapse">
                  <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                    <Panel header={
                      <div className="list_warp fare_info">
                        <span>Subtraction</span>
                        <span>- RM {this.priceFormat(dta)}</span>
                      </div>
                    }>
                      {
                        dta > 0 ?
                        <React.Fragment>
                        {  
                          ticketDetails && ticketDetails.map((ticket,index) => {
                          return(
                            ticket.quantity > 0 ?
                              <div>
                                <span>DTA : {ticket.name}</span>
                                <div className="break-line"></div>
                                <span>x <strong>{ticket.quantity}</strong></span>
                                <span>- RM {this.priceFormat(ticket.dta * ticket.quantity)}</span>
                              </div>
                              : ''
                            )
                          })
                        }
                        </React.Fragment> : ''
                      }
                    </Panel>
                  </Collapse>
                </div>
                {/*<Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                  <a className="ant-dropdown-link" href="#">
                    Subtraction &nbsp;<Icon type="info-circle" />
                  </a>
                </Dropdown>*/}
              </div> : ''
            }

            {
              (payment_type == "public_bank" || payment_type == "public_bank_2") ?
                <div className="list_warp">
                  <span>Service Fees</span>
                  <span>RM {service_fee > 0 ? this.priceFormat(service_fee) : this.priceFormat(service_fee)}</span>
                </div>
              : ''
            }
          </div>

          <div className="select_list">
            <div className="list_warp">
              <div className="total">
                 <span>Grand Total</span>
                {
                  (payment_type == "public_bank" || payment_type == "public_bank_2") ?
                    <span className="total_summary">RM {total_price > 0 ? this.priceFormat(total_price + service_fee - dta) : this.priceFormat(total_price + service_fee - dta)}</span>
                  : <span className="total_summary">RM {this.priceFormat(total_price - dta)}</span>
                }
              </div>
            </div>
          </div>

        </Card>
        )
      }
      </div>
    );
  }
}

export default RightPanel;
