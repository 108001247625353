import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

const ErrorIcon = props => {
  return (
    <Icon type="close-circle" style={{ color: '#cf1322' }} />
  );
};

ErrorIcon.propTypes = {};

export default ErrorIcon;
