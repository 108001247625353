import React, { Component } from 'react';
import { Card, Rate, Button } from 'antd';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css'

const { Meta } = Card;

class More extends Component {

    render() {

        const tab = this.props.tabs[0];
        const settings = {
          infinite: false,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
          responsive: [
            {
              breakpoint: 770,
              settings: {
                  arrows: true,
                  infinite: true,
                  slidesToShow: 3,
                  slidesToScroll: 2,
                  swipeToSlide: true,
              }
            },
            {
              breakpoint: 625,
              settings: {
                arrows: true,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 2,
                swipeToSlide: true,
              }
            },
          ]
        };

        return (
            <div id="More">
                <div className="content_title">
                    <h1>{this.props.title}</h1>
                    {/* <p>In publishing and graphic design, Lorem ipsum</p> */}
                </div>
                <div className="slider">
                    <Slider {...settings} slidesToShow={parseInt(tab.slides_to_show)}>
                    {
                      tab.cards.map((card) => {
                        return (
                          <div className="card_warp" key={card}>
                              <Card
                                hoverable
                                cover={
                                  card.link ?
                                  (<a href={`${card.link}`} target="_blank"><img alt={card.cover} src={card.cover} /></a>)
                                  :
                                  (<img alt={card.cover} src={card.cover} />)
                                }
                                >
                              </Card>
                          </div>
                        )
                      })
                    }
                    </Slider>
                </div>
                <div className="clear"></div>
            </div>
        );
    }
}

export default More;
