import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Descriptions, List, Steps, Spin, Empty, Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.css';
import flight from '../../../../../images/flight.png';
import land from '../../../../../images/land.png';
import { withRouter } from 'react-router-dom'

const locaData = [
  { kgEmpty: "-" }
]

class RightPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true
    };
    this.handleClick = this.handleClick.bind(this);
    // this.state = {
    //     dataDetail: this.props.detail,
    // }
  }

  handleClick(e, data) {
    var btn = e.target;
    btn.classList.toggle("active");

    var content = btn.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  }

  // componentDidUpdate(prevProps) {

  ///////////////////// props data update //////////////////
  //console.log(this.props.detail)
  //console.log(prevProps.detail)
  // if (this.props.detail !== prevProps.detail) {
  //     this.setState({
  //         dataDetail: this.props.detail,
  //     })
  //     console.log(this.state.dataDetail, '--------state');
  // }

  ///////////////////collapsible function //////////////////
  // var coll = document.getElementsByClassName("collapsible");
  // var i;

  // for (i = 0; i < coll.length; i++) {
  //     coll[i].addEventListener("click", function () {
  //         this.classList.toggle("active");
  //         var content = this.nextElementSibling;
  //         if (content.style.maxHeight) {
  //             content.style.maxHeight = null;
  //         } else {
  //             content.style.maxHeight = content.scrollHeight + "px";
  //         }
  //     });
  // }
  // } 

  render() {
    // const { dataDetail } = this.state;
    const dataDetail = this.props.detail;
    const lengthDetail = this.props.dataLenght;
    console.log(this.props.location.search, "---------------search");
    console.log(dataDetail, '---------------dataDetail')
    console.log('this.props.loading', this.props.loading)

    return (
      <div id="RightPanel">
        <div className="flight_content">
          {
            this.props.loading ? (
              <Skeleton active avatar paragraph={{ rows: 4 }} />
            ) : (
                lengthDetail === 0 ? (
                  <div className="empty">
                    <Empty description="Data no Found" />
                  </div>
                ) : (
                    dataDetail.map((item, i) => {
                      return (
                        <div className="collaps_pannel" key={i}>
                          <div className="header_content">
                            <div className="time_panel">
                              {item.journeys.map((journey, j) => {
                                return (
                                  <div className="top_content" key={j}>
                                    <div className="img_panel">
                                      <img src={journey.airline_image} />
                                    </div>

                                    <div className="left_content">
                                      <div className="left">
                                        <div className="left_warp">
                                          <div className="ap_icon">
                                            <img src={flight} />
                                          </div>
                                          <p className="time_depature">
                                            {journey.departure_time}
                                          </p>
                                          <p className="location_depature">
                                            {journey.departure_airport.iata}
                                          </p>
                                          <div className="clear"></div>
                                        </div>
                                      </div>
                                      <div className="center">
                                        <div className="stop">
                                          <div className="stop_warp">
                                            <p>
                                              {journey.stop == 0
                                                ? "Non-Stop"
                                                : journey.stop + "-Stop"}
                                            </p>
                                            <p className="stop_time">
                                              <small>{journey.travel_time}</small>
                                            </p>
                                          </div>
                                          <FontAwesomeIcon
                                            icon="long-arrow-alt-right"
                                            className="left_arrow"
                                          />
                                        </div>
                                        <div className="clear"></div>
                                      </div>
                                      <div className="right">
                                        <div className="right_warp">
                                          <div className="ap_icon">
                                            <img src={land} />
                                          </div>
                                          <p className="time_depature">
                                            {journey.arrival_time}
                                          </p>
                                          <p className="location_depature">
                                            {journey.arrival_airport.iata}
                                          </p>
                                          <div className="clear"></div>
                                        </div>
                                      </div>
                                      <div className="clear"></div>
                                    </div>
                                  </div>
                                );
                              })}

                              <div className="clear"></div>
                            </div>

                            <div className="price_panel">
                              <p className="price_tag">RM{item.total_price}</p>
                              <p className="tax_tag">
                                <small>Include Tax</small>
                              </p>
                              <div className="select_btn">
                                <Link
                                  to={{
                                    pathname: "/flights/" + encodeURIComponent(item.id),
                                    search: this.props.location.search + '&source=' + item.source
                                  }}
                                >
                                  <Button type="primary" ghost>
                                    SELECT
                            </Button>
                                </Link>
                              </div>
                            </div>

                            <div className="clear"></div>
                          </div>

                          <button
                            className="collapsible"
                            onClick={e => this.handleClick(e)}
                          ></button>
                          <div className="content">
                            <div className="left">
                              {item.journeys.map((journey, j) => {
                                return (
                                  <div key={j}>
                                    <div className="depart">
                                      <Divider orientation="right" className="hour">
                                        {journey.travel_time}
                                      </Divider>
                                      <div className="clear"></div>
                                    </div>
                                    <div className="depart_details">
                                      {journey.segments.map((segment, s) => {
                                        return (
                                          <div className="depart_1" key={s}>
                                            <Descriptions
                                              title={[
                                                <div className="title">
                                                  <p className="flight_title">
                                                    <div className="logo_img">
                                                      <img
                                                        src={segment.airline_image}
                                                      />
                                                    </div>
                                                    - {segment.carrier}{" "}
                                                    {segment.flight_number}
                                                    <span className="flight_time_style">
                                                      {segment.flight_time}
                                                    </span>
                                                  </p>
                                                  <p className="flight_kg">
                                                    <span className="cabin_class">
                                                      {segment.cabin_class}
                                                    </span>
                                                    <FontAwesomeIcon
                                                      icon="suitcase"
                                                      className="suitcase"
                                                    />
                                                    <span>
                                                      {segment.baggage_max_weight === ""
                                                        ? locaData[0].kgEmpty
                                                        : segment.baggage_max_weight}
                                                    </span>
                                                  </p>
                                                  <div className="clear"></div>
                                                </div>
                                              ]}
                                            >
                                              <Descriptions.Item
                                                label={[
                                                  <div className="from">
                                                    <img src={flight} />
                                                    <p>{segment.departure_time}</p>
                                                  </div>
                                                ]}
                                              >
                                                <p className="from_detail">
                                                  {segment.departure_date}
                                                </p>
                                                <p className="from_detail">
                                                  {segment.departure_airport.city}(
                                            {segment.departure_airport.iata})
                                          </p>
                                                <div className="clear"></div>
                                              </Descriptions.Item>
                                            </Descriptions>
                                            <Descriptions>
                                              <Descriptions.Item
                                                label={[
                                                  <div className="from">
                                                    <img src={land} />
                                                    <p>{segment.arrival_time}</p>
                                                  </div>
                                                ]}
                                              >
                                                <p className="from_detail">
                                                  {segment.arrival_date}
                                                </p>
                                                <p className="from_detail">
                                                  {segment.arrival_airport.city}(
                                            {segment.arrival_airport.iata})
                                          </p>
                                                <div className="clear"></div>
                                              </Descriptions.Item>
                                            </Descriptions>
                                            {segment.transit_time === "" ? null : (
                                              <div className="plane">
                                                <Divider>Transfer Duration</Divider>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            <div className="right">
                              <List
                                header={<div>Your Order</div>}
                                footer={[
                                  <div className="total">
                                    <p>Total :</p>
                                    <p>RM {item.total_price}</p>
                                    <div className="clear"></div>
                                  </div>
                                ]}
                                bordered
                              >
                                <List.Item>
                                  <ul className="price_style">
                                    {item.pricings[0] ? (
                                      <li>
                                        <p>
                                          {item.pricings[0].code === 'ADT' ? 'Adult' : 'Adult'} x{" "}
                                          {item.pricings[0].count}
                                        </p>
                                        <p>RM {item.pricings[0].base}</p>
                                        <p className="tax_style">Tax</p>
                                        <p className="tax_style">
                                          RM {item.pricings[0].taxes}
                                        </p>
                                        <div className="clear"></div>
                                      </li>
                                    ) : null}

                                    {item.pricings[1] ? (
                                      <li>
                                        <p>
                                          {item.pricings[1].code === 'CNN' ? 'Child' : 'Child'} x{" "}
                                          {item.pricings[1].count}
                                        </p>
                                        <p>RM {item.pricings[1].base}</p>
                                        <p className="tax_style">Tax</p>
                                        <p className="tax_style">
                                          RM {item.pricings[1].taxes}
                                        </p>
                                        <div className="clear"></div>
                                      </li>
                                    ) : null}
                                    {item.pricings[2] ? (
                                      <li>
                                        <p>
                                          {item.pricings[2].code === 'INF' ? 'Infant' : 'Infant'} x{" "}
                                          {item.pricings[2].count}
                                        </p>
                                        <p>RM {item.pricings[2].base}</p>
                                        <p className="tax_style">Tax</p>
                                        <p className="tax_style">
                                          RM {item.pricings[2].taxes}
                                        </p>
                                        <div className="clear"></div>
                                      </li>
                                    ) : null}
                                  </ul>
                                </List.Item>
                              </List>
                            </div>

                            <div className="clear"></div>
                          </div>
                        </div>
                      );
                    })
                  )
              )
          }
        </div>
      </div>
    );
  }
}

export default withRouter(RightPanel);
