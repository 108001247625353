import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, Form, Row, Col, Select, Input } from 'antd';
import axios from '../../../../api';
import moment from 'moment';

const { Option } = Select
const maxMonth = 18

class filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fairOptions: [],
      defaultStartDate: moment().startOf('month'),
      defaultEndDate: moment().endOf('month'),
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        let filters = {
          ...values,
          booking_date_start: values.booking_date_start?.format('YYYY-MM-DD'),
          booking_date_end: values.booking_date_end?.format('YYYY-MM-DD'),
          departure_date_start: values.departure_date_start?.format('YYYY-MM-DD'),
          departure_date_end: values.departure_date_end?.format('YYYY-MM-DD'),
        }
        // console.log('Received values of form: ', filters);
        this.props.fetchStatisticData(filters);
      }
    });
  };

  fetchFairsData = () => {
    axios
      .get('/api/v1/fairs',
        {
          params: {
            app_type: this.props.appType,
          }
        })
      .then((response) => {
        console.log(response)
        this.setState({
          fairOptions: response.data.data.map((fair) => ({ value: fair.id , name: fair.attributes.code }))
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  validateDate = (_rule, value, cb) => {
    let today = moment()

    if(Math.abs(today.diff(value, 'months')) > maxMonth) {
      return cb(true)
    }
    return cb()
  }

  componentDidMount() { 
    this.fetchFairsData();
  }

  render() {
    const { fairOptions } = this.state
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {}
    const rowLayout = {
      gutter: [8,8]
    }
    const colLayout = {
      md: { span: 6 },
      sm: { span: 12 },
      xs: { span: 24 },
    }

    return (
      <div>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Row {...rowLayout}>
            <Col {...colLayout}>
              <Form.Item
                colon={false}
                label="Book date from"
              >
                {getFieldDecorator('booking_date_start', {
                  rules: [
                    {
                      message: `cannot exceed ${maxMonth} months`,
                      validator: this.validateDate,
                    }
                  ],
                  initialValue: this.state.defaultStartDate,
                })(<DatePicker allowClear={true} style={{width: '100%'}}/>)}
              </Form.Item>
            </Col>
            <Col {...colLayout}>
              <Form.Item
                colon={false}
                label="Book date to"
              >
                {getFieldDecorator('booking_date_end', {
                  rules: [
                    {
                      message: `cannot exceed ${maxMonth} months`,
                      validator: this.validateDate,
                    }
                  ],
                  initialValue: this.state.defaultEndDate,
                })(<DatePicker allowClear={true} style={{width: '100%'}} />)}
              </Form.Item>
            </Col>
            <Col {...colLayout}>
              <Form.Item
                colon={false}
                label="Departure date from"
              >
                {getFieldDecorator('departure_date_start', {
                  rules: [
                    {
                      message: `cannot exceed ${maxMonth} months`,
                      validator: this.validateDate,
                    }
                  ],
                })(<DatePicker allowClear={true} style={{width: '100%'}}/>)}
              </Form.Item>
            </Col>
            <Col {...colLayout}>
              <Form.Item
                colon={false}
                label="Departure date to"
              >
                {getFieldDecorator('departure_date_end', {
                  rules: [
                    {
                      message: `cannot exceed ${maxMonth} months`,
                      validator: this.validateDate,
                    }
                  ],
                })(<DatePicker allowClear={true} style={{width: '100%'}} />)}
              </Form.Item>
            </Col>
            <Col {...colLayout}>
              <Form.Item
                colon={false}
                label="Active fair"
              >
                {
                  getFieldDecorator('fair_id', {
                    rules: [],
                  })(
                      <Select
                        showSearch
                        style={{width: '100%'}}
                        placeholder="Select active fair"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear={true}
                      >
                        {
                          fairOptions.map((option) => (
                            <Option key={option.value} value={option.value}>{option.name}</Option>
                          ))
                        }   
                      </Select>
                    )
                }
              </Form.Item>
            </Col>
            <Col {...colLayout}>
              <Form.Item
                colon={false}
                label="Destination"
              >
                {
                  getFieldDecorator('destination', {
                    rules: [],
                  })(<Input style={{width: '100%'}} placeholder="Select destination" allowClear={true}/>)
                }
              </Form.Item>
            </Col>
          </Row>

          <Row {...rowLayout}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Filter
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    );
  }
}

filter.propTypes = {
  appType: PropTypes.string.isRequired,
  fetchStatisticData: PropTypes.func.isRequired,
};

const StatisticFilterForm = Form.create({ name: 'statistic_filter' })(filter);
export default StatisticFilterForm;
