import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { Form, Input, Select, Button, AutoComplete, Radio, Icon, DatePicker, Menu, Dropdown, Avatar } from 'antd';
import Buttons from './button'
import moment from 'moment';
import Fuse from 'fuse.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import countryList from 'react-select-country-list'
import qs from 'qs'
import API from '../../../api';

const { Option, OptGroup } = AutoComplete;
const { MonthPicker } = DatePicker;
const monthFormat = 'YYYY/MM';
const dateFormat = 'YYYY-MM-DD';

class LandTour extends Component {
  constructor(props) {
    super(props);
    const land_tour_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.state = {
      keyword: land_tour_params['keyword'] != undefined ? land_tour_params['keyword'] : '',
      month: land_tour_params['month'] != undefined ? land_tour_params['month'] : '',
      destinations: [],
      land_tours: ''
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.countryFetch();
    this.landtourFetch();
    moment.updateLocale(moment.locale(), { invalidDate: '' });
  }

  /****************************** Travellers HandleMenuClick *******************************/
  handleMenuClick = e => { if (e.key === '4') { this.setState({ visible: false }); } };

  /****************************** Dropdown Function *******************************/
  handleVisibleChange = flag => { this.setState({ visible: flag }); };

  dateOnChange(dateString) {
    this.setState({ 
      month: moment(dateString).format(monthFormat)
    });
  }

  handleFormChange(type, value){
    this.setState({
      [type]: value
    })
  }

  countryFetch() {
    let url = './api/v1/land_tours/country_list';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
          // console.log(response, '------------res country');
          that.setState({
              destinations: response.data
          })
      })
      .catch(function (error) {
          console.log(error);
      })
      .then(function () {
          // always executed
      });
  }

  landtourFetch() {
      let url = './api/v1/land_tours/land_tours_list';
      var that = this;
      // console.log(params);

      API.get(url)
          .then(function (response) {
              // console.log(response, '------------res country');
              that.setState({
                  land_tours: response.data
              })
          })
          .catch(function (error) {
              console.log(error);
          })
          .then(function () {
              // always executed
          });
  }

  submitForm(event) {
    event.preventDefault();

    let outputJson = {
      keyword: this.state.keyword,
      month: this.state.month
    };

    console.log("This is the outputJson", outputJson);
    console.log(qs.stringify(outputJson))
    console.log(this.props)

    this.props.history.push({
        pathname: '/land_tours',
        search: "?" + qs.stringify(outputJson)
    });      
  }

  render() {
    if (this.state.destinations.countries == undefined || this.state.destinations.countries === '') {
      var countries = [];
    }
    else {
      var countries = this.state.destinations.countries
      var dataCountries = [];
      countries.map((country,index) => 
        dataCountries.push({"name": country})
      )
    }

    if (this.state.destinations.cities == undefined || this.state.destinations.cities === '') {
      var cities = [];
    }
    else {
      var cities = this.state.destinations.cities
      var dataCities = [];
      cities.map((city,index) => 
        dataCities.push({"name": city})
      )
    }

    if (this.state.land_tours.tours == undefined || this.state.land_tours.tours === '') {
      var tours = [];
    }
    else {
      var tours = this.state.land_tours.tours
      var dataTours = [];
      tours.map((tour, index) => 
        dataTours.push({"name": tour[1] === "" ? "Untitled "+(index+1) : tour[1] })
      )
    }

    var dataSource = [
      // { "title": "Cities", "children": dataCities },
      // { "title": "Ground Tours", "children": dataTours },
      { "title": "Destinations", "children": dataCountries }  
    ]

    const options = dataSource
      .map(item => (
        <OptGroup key={item.title} label={<strong>{item.title}</strong>}>
            {item.children && item.children.map(opt => (
              <Option key={opt.name} value={opt.name}>
                {opt.name}
              </Option>
            ))}
        </OptGroup>
    ))

    return (
      <div className="LandTour" >
        <form onSubmit={this.submitForm} className="form_warp">
          <div className="form_1">
            <div className="form_layout">
              <label>Keyword</label>
              <AutoComplete
                className="certain-category-search"
                dropdownClassName="certain-category-search-dropdown"
                allowClear={true}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 300 }}
                style={{ width: '100%' }}
                dataSource={options}
                placeholder="Search city, country, title..."
                optionLabelProp="value"
                filterOption={true}
                optionFilterProp={"children"}
                // onChange={this.handleKeywordChange}
                onChange={(value) => this.handleFormChange('keyword', value)}
                defaultValue={this.state.keyword}
              >
                <Input onKeyPress={this.handleKeyPress} suffix={<Icon type="search" className="certain-category-icon" />} />
              </AutoComplete>
            </div>
            <div className="form_layout">
              <label>Travelling Month</label>
              <MonthPicker
                placeholder="YY/MM"
                onChange={(date, dateString) => this.dateOnChange(dateString)}
                defaultValue={this.state.month !== '' ? moment(this.state.month, monthFormat) : ''}
                disabledDate={current => { return moment().add(-1, 'month') >= current || moment().add(12, 'month') <= current }}
              />
            </div>
            <div className="btn_submit">
              <Link to="/land_tours">
                <Button type="primary" onClick={this.submitForm} icon="search" size="default" className="search_btn">
                  Search 
                </Button>
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(LandTour);
