import React, { Component } from 'react';
import { Button, Divider, Descriptions, List, Steps, Icon, Rate , Skeleton, Empty} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.css';
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs';
import moment from 'moment';

const locaData = [
    { kgEmpty: "-" }
]

class RightPanel extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        // this.state = {
        //     dataDetail: this.props.detail,
        // }

    }

    handleClick(e, data) {
        var btn = e.target
        btn.classList.toggle("active");

        var content = btn.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    }


    render() {
        const dataDetail = this.props.detail;

        const lengthDetail = dataDetail.error ? 0 : this.props.dataLength;
        const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        console.log(params, '---------------params')
        // console.log(dataDetail, '---------------detail')
        // console.log(lengthDetail, '---------------lengthDetail')
        // console.log(this.props.loading, '---------------loading')
        var check_in = moment(params['search_criteria']['check_in_date']);
        var check_out = moment(params['search_criteria']['check_out_date']);
        var date_diff = check_out.diff(check_in, 'days');

        return (
            <div id="RightPanel">

                {
                    lengthDetail === 0 ? (
                        <div className="empty">
                            {
                                this.props.loading ? (
                                    <Skeleton active avatar paragraph={{ rows: 4 }} />
                                ) : (
                                        <Empty description={dataDetail.error} />
                                    )
                            }
                        </div>
                    ) : (
                            <List
                                itemLayout="vertical"
                                size="large"
                                dataSource={dataDetail}
                                renderItem={item => (
                                    <List.Item
                                        key={item.id}
                                        extra={[
                                            <div key={item.id} className="right_price">
                                                <p className="tax_tag"><small>From</small></p>
                                                <p className="price_tag">RM {item.price} </p>
                                                <div className="select_btn">
                                                    <Link to={{
                                                        pathname: "/hotels/" + item.id,
                                                        search: "?" + qs.stringify({ session_id: item.session_id, result_index: item.id, hotel_code: item.code, search_criteria: params['search_criteria'], room_datas: params['room_datas'], guest_in_room: item.guest_in_room, rating: item.star_rating })
                                                    }} >
                                                        <Button type="primary" ghost>SELECT</Button>
                                                    </Link>
                                                </div>
                                                <p className="tax_tag"><small>For {date_diff} night(s)</small></p>
                                                <div className="clear"></div>
                                            </div>
                                        ]}
                                    >
                                        <List.Item.Meta 
                                            avatar={
                                                <img
                                                    alt="logo"
                                                    src={item.image_url !== '' ? item.image_url : homepage_new.default_image}
                                                    className="img_sty" 
                                                />}
                                            title={<p>{item.name}</p>}
                                            description={[
                                                <div key={item.id} className="content_rate">
                                                    <Rate allowHalf disabled defaultValue={Number(item.star_rating)} />
                                                    <div className="locate">
                                                      <span className="locate_warp">
                                                        <FontAwesomeIcon size="lg" icon='map-marker-alt' className="location_icon" />{item.destination}
                                                      </span>
                                                    </div>
                                                </div>
                                            ]}
                                        />
                                    </List.Item>
                                )}


                            />
                        )
                }


            </div>
        );
    }
}

export default withRouter(RightPanel);
