import React, { Component } from 'react';
import {
  Form, Input, Card, Collapse, Tooltip, Icon, Cascader, DatePicker, InputNumber, Typography, Select, notification, Skeleton, Row,
  Col, Checkbox, Button, AutoComplete, Modal, Empty, Divider, List, Carousel, Tag
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css';
import moment from 'moment';
import ReactMarkdown from 'react-markdown/with-html';
import { Link, withRouter } from 'react-router-dom';
import countryList from 'react-select-country-list';
import qs from 'qs';
import API from '../../../../api';
import PaymentMethod from '../payment';
import warning from '../../../../../images/warning.png';
import ContactInfor from '../contactInfor';
import Slider from "react-slick";

// const dummyData = new Array(20).fill({
//   description: 'Voucher',
//   price: '12',
//   image_url: 'http://iceholidays2.test/uploads/cover/image/1014/gift-voucher-design-template-1d2e142142c075845e225bb740e61276_screen.jpg'
// });

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;
const { OptGroup } = AutoComplete;
const { Paragraph } = Typography;
const dateFormat = 'YYYY-MM-DD';

class LeftPanel extends Component {

  constructor(props) {
    super(props);
    const voucher = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.state = {
      visible: false,
      type: '',
      selectValue: '',
      payment_type: '',
      payment_method: null,
      // depart_date: moment().format(dateFormat),
      // return_date: '',
      endOpen: false,
      dateDisable: moment().startOf('day'),
      defDate: '',
      package_id: '',
      detail: {},
      contacts: [],
      loading: true,
      check_out_disabled: false,
      banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      fpxb2b_banklist: [],
      b2b_offline_banklist: '',
      banklist_select: [],
      // credits: 0,
      method_visible: false,
      validationErrors: [],
      agree: '',
      confirmDirty: false,
      // autoCompleteResult: [],
      bookingErrorModal: false,
      bookingErrorMessage: '',
      voucherTourInfoModal: false,
      voucherTourInfoModalContent: '',
      agents: [],
      quantity: 1,
      agent_disabled: voucher['agent'] != undefined ? true : false,
      agentId: '',
      sale_rep: '',
      bank_type: null,
      redirect_url: null,
      dobw_banklist: [],
    }
    this.submitForm = this.submitForm.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
  }

  /***************************************************** componentDidMount *******************************************************/
  componentDidMount() {
    this.agentListFetch();
    this.banklistFetch();
  }

  /***************************************************** componentDidUpdate *******************************************************/
  componentDidUpdate() {
    if (this.props.dataList != this.state.detail) {
      var contacts = [];
      var validationErrors = [];
      contacts.push({
        name: '', mobile_no: '', email: '', remark: '',
      })
      validationErrors.push({
        name: '', mobile_no: '', email: '', agent: '', agree: '',
      })
      this.setState({
        detail: this.props.dataList,
        agentId: this.props.dataList.agent_id,
        contacts: contacts,
        validationErrors: validationErrors,
      }, () => { this.props.handleTotalQty(this.state.quantity) });
    }
  }

  agentListFetch = () => {
    API.get('./api/v1/' + this.props.location.pathname + '/agent_list')
      .then((response) => {
        console.log(response, '------------res');
        this.setState({
          agents: response.data
        })
      })
      .catch((error) => {
        console.log(error);
      })
  };

  /***************************************************** banklistFetch api *******************************************************/
  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);
    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          // credits: response.data.credits
        });
        that.props.getCredits(response.data.credits)
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  /******************************************* handleContactDataChange function *****************************************************/
  handleContactDataChange(index, type, value, e = '') {
    if (type === "quantity") {
      // console.log(value)
      this.setState({
        quantity: value
      }, () => {
        this.props.handleTotalQty(this.state.quantity);
      })
    } 
    else if (type === "agent") {
      const agentId = parseInt(value)
      // console.log(agentId)
      this.setState({
        agentId: agentId
      })
    }
    else if (type == "sale_rep") {
      this.setState({
        sale_rep: value
      });
    } 
    else {
      this.state.contacts[index][type] = value;
    }
    this.setState({
      contacts: this.state.contacts
    })
  }

  /****************************************** openNotificationWithIcon function *******************************************************/
  openNotificationWithIcon = (type, error_name) => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
    }
  };

  /******************************************* onChangePaymentCheckBox function *******************************************************/
  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  }

  /***************************************************** onCloseModal function *******************************************************/
  onCloseModal = (modal) => {
    if (modal === 'bookingErrorModal') {
      this.props.history.go(-1);
    }
  };

  /*********************************************** handlePaymentChange function *******************************************************/
  handlePaymentChange(event) {
    // console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null
      })
    }
    else {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }
    this.setState({
      payment_type: event.target.value
    })
    this.props.handlePaymentType(event.target.value);
  }

  /***************************************** handlePaymentMethodChange function *******************************************************/
  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  /***************************************************** validateQuantity *******************************************************/
  validateQuantity = (rule, value, callback) => {
    const quantity = value || 0;
    // console.log(quantity, '---------------------checkQuantity')
    if (quantity === 0) {
      callback('*Quantity must greater than 0');
    } else if (quantity > this.props.dataList.max_booking) {
      callback('*Only ' + this.props.dataList.max_booking + ' voucher(s) left');
    } else {
      callback();
    }
  };

  /***************************************************** submitForm function *******************************************************/
  submitForm = e => {
    e.preventDefault();
    this.banklistFetch();

    const { contacts, payment_type, payment_method, validationErrors, agree, sale_rep, redirect_url, bank_type } = this.state;
    // const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    // console.log(params, '---params')

    let outputJson = {
      agent_id: this.state.agentId,
      quantity: this.state.quantity,
      sale_rep: sale_rep,
      bookings: contacts,
      payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url },
      agree: this.state.agree
    }

    console.log("This is the outputJson", outputJson);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (payment_type === "" || agree === "" || !err === false) {
          if (!err) {
            console.log('Received values of form: ', values);
          }
          if (payment_type === "") {
            this.openNotificationWithIcon('warning','payment_method_error');
          }
          if (payment_method === null) {
            validationErrors.payment_method = "*Please select your payment method";
            this.setState({ validationErrors: validationErrors })
          } else {
            validationErrors.payment_method = null;
            this.setState({ validationErrors: validationErrors })
          }
          if (agree === "") {
            validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
            this.setState({ validationErrors: validationErrors })
          } else {
            validationErrors.agree = "";
            this.setState({ validationErrors: validationErrors })
          }
      }
      else {
        this.setState({
          check_out_disabled: true,
          // loading: true
        })

        let url = './api/v1' + this.props.location.pathname + '/create_booking/';
        // let that = this;

        API.post(url, outputJson)
          .then((response) => {
            console.log("Create Booking Response ====> ", response)
            if (response.data.redirect_to) {
              window.location = response.data.redirect_to
            } else if (response.data.credits_error) {
              that.openNotificationWithIcon('error','credits_error');
              that.setState({ payment_type: '', check_out_disabled: false })
            } else {
              this.setState({
                check_out_disabled: false,
                bookingErrorModal: true,
                bookingErrorMessage: response.data.error
              })
              console.log(response.data.error)
            }
            this.setState({
              loading: false
            })
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              visible: true
            })
          })
      }
    });
  }

  onVoucherTourInfo = (status, selectedVoucher) => {
    if (status === true) {
      this.setState({
        voucherTourInfoModal: status,
        voucherTourInfoModalContent: selectedVoucher
      })
    } else {
      this.setState({
        voucherTourInfoModal: status,
      }, () => {
        setTimeout(() => {
          this.setState({
            voucherTourInfoModalContent: selectedVoucher
          })
        }, 500)
      })
    }
  };

  render() {
    /********************************* state ******************************/
    const { type, banklist, offline_banklist, method_visible, banklist_select, /* credits, */ validationErrors, /* depart_date, defDate
      /* return_date, endOpen, autoCompleteResult,*/  bookingErrorModal, bookingErrorMessage, agent_disabled, bank_type } = this.state;
    const { getFieldDecorator } = this.props.form;
    const data = this.props.dataList;
    // const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const loading = this.props.loading;
    // const contactsData = this.state.contacts[0] || 0;

    /********************************* formItemLayout ******************************/
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    const settings = {
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 770,
          settings: {
              arrows: true,
              infinite: true,
              slidesToShow: 3,
              slidesToScroll: 2,
              swipeToSlide: true,
          }
        },
        {
          breakpoint: 625,
          settings: {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 2,
            swipeToSlide: true,
          }
        },
      ]
    };

    let agentListGroupByState = []
    this.state.agents.map((agent) => {
      agentListGroupByState.push(
        {
          id: agent.id,
          title: agent.state,
          children: agent.agents
        }
      )
    })
    // console.log("check agent ==> ", agentListGroupByState)

    const renderTitle = (title) => {
      return (
        <span>
          {title}
          {/* <a
            style={{ float: 'right' }}
            href="https://www.google.com/search?q=antd"
            target="_blank"
            rel="noopener noreferrer"
          >
            more
          </a>*/}
        </span>
      );
    }

    const options = agentListGroupByState
      .map(group => (
        <OptGroup key={group.id} label={renderTitle(group.title)}>
          {group.children.map(opt => (
            <Option key={opt.id} value={`${opt.id}`} label={opt.name} state={opt.state}>
              {opt.name}
            </Option>
          ))}
        </OptGroup>
      ))

    const new_images = []
    if (data.images && data.images.length > 1) {
      new_images.push(data.images.slice(1));
    }
    
    return (
      <div className="LeftPanel">
        <div className="hotel_details">
          {/************************************* header title *************************************/}
          <div className="header_title">
            <h1>{data.title} {data.other_title}</h1><br />
          </div>

          {
            data.voucher_entitlements && data.voucher_entitlements.length > 0 ? (
              /* <Card className="things">
                <h3 className="title">Voucher Entitlements</h3><br />
              </Card> */
              /*<Row gutter={[16, 16]} wrap={false} style={{ display: 'flex', overflow: 'scroll' }}>
              {
                dummyData.map((item, index) => {
                  return(
                    <Col xs={18} md={6}>
                      <Card
                        data-id={index}
                        onClick={(event) => { this.onSelectVoucher(event) }}
                        cover={<img src={item.image_url} />}
                      >
                        <Card.Meta 
                          title={item.description}
                          description={"RM " + item.price}
                        />
                      </Card>
                    </Col>
                  )
                })
              }
              </Row> */
              <div>
                <h2>You can select one of the following tours upon redemption</h2>
                <div className="slider">
                  <Slider {...settings}>
                    {
                      data.voucher_entitlements.map((item, i) => {
                        const price_before_discount = item.price
                        const price_after_discount = item.discounted_price

                        return(
                          <Card key={i}
                            actions={[<span><h4 style={{ display: 'inline', color: 'red' }}><b><strike>RM {price_before_discount}</strike></b></h4>&nbsp;&nbsp;<h3 style={{ display: 'inline', color: 'green' }}><b>RM {price_after_discount}</b></h3></span>]}
                            cover={<img style={{ width: '100%', height: '155px', objectFit: 'cover' }} src={item.taggable.images[0]} />}
                          >
                            <Card.Meta 
                              title={
                                <div className="title_panel">
                                  <Paragraph ellipsis={{ rows: 1, }}
                                     className="ellipsis_style ellipsis_style_view">{item.taggable.title}</Paragraph>
                                  <div className="clear"></div>
                               </div>
                              }
                              description={<div key={item.id} className="content_rate">
                                {/* <h3>RM {item.price}</h3> */}
                                <div className="locate">
                                  <span className="locate_warp">
                                    <FontAwesomeIcon size="lg" icon='map-marker-alt' className="location_icon" />&nbsp;&nbsp;{item.taggable.country}
                                  </span>
                                </div>
                                <div className="locate">
                                  <span className="locate_warp">
                                    <Tag color="blue">{item.taggable.code}</Tag>
                                  </span>
                                </div>
                                <div className="date">
                                    <p><strong>Departure Description</strong></p>
                                    {
                                      item.departure_date !== '' ?
                                        (<p>Departure Date : {item.departure_date}</p>)
                                      : 
                                      item.departure_day !== '' ?
                                        (<p>Departure Day : {item.departure_day}</p>)
                                      : 
                                        (null)
                                    }
                                    <p>Travelling Period : {item.travelling_period}</p>
                                    {/* <div className="depart_style">
                                      <span className="locate_warp">
                                        <Tag color="orange"><b>{item.taggable.min_booking}</b> To Go</Tag>
                                      </span>
                                      <span className="locate_warp">
                                        <Tag color="orange">{item.taggable.category}</Tag>
                                      </span>
                                    </div> */}
                                </div>
                                <span>
                                   Details / T&C &nbsp;<Icon type="question-circle" className="view_icon"
                                      onClick={() => this.onVoucherTourInfo(true, item)}
                                   />
                                </span>
                             </div>}
                            />
                          </Card>
                        )
                      })
                    }
                  </Slider>
                </div>
              </div>
           ) : (
              <div>
                <div className="slider-images">
                  <Slider {...settings}>
                    {
                      data.images === undefined || data.images === "" ? (
                        null
                      ) : (
                        new_images.flat().map((item) => {
                          return(
                            <Card
                              cover={<img style={{ width: '300'}} src={item} />}
                            >
                              <Card.Meta 
                                style={{padding: '0px'}}
                              />
                            </Card>
                          )
                        })
                      )
                    }
                  </Slider>
                </div>
              </div>
           )
          }

          {
            data.description === undefined || data.description === "" ? (
              null
            ) : (
              <Card className="things">
                <p className="title">Voucher Description</p>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: data.description.replace(/\n/g, '<br />') }} />
              </Card>
            )
          }

          {
            data.tnc === undefined || data.tnc === "" ? (
              null
            ) : (
              <Card className="things">
                <p className="title">Voucher Terms & Conditions</p>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: data.tnc.replace(/\n/g, '<br />') }} />
              </Card>
            )
          }

          {
            data.expiry === undefined || data.expiry === "" ? (
              null
            ) : (
              <Card className="things">
                <p className="title">Voucher Expiry Date</p>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: data.expiry }} />
              </Card>
            )
          }

          {/***************************************************** Form *****************************************************/}
          {
            data.balance === undefined || data.balance === 0 ? (
              null
            ) : (
              <Form {...formItemLayout} onSubmit={this.submitForm} className="form_sty_select" >

                {/********************************** Device Info **********************************/}
                <div className="header_title">
                  <h2>Voucher Info</h2>
                </div>
                {
                  loading ? (
                    <Skeleton active />
                  ) : (
                      <Card title="Voucher Details" className="passenger contact_infor">
                        <div className="form_passenger">
                          <div className="input_warp">
                            {/************************************** Quantity **************************************/}
                            <div className="form_control pandding_left Quantity_sty" >
                              <Form.Item label="Quantity" >
                                {getFieldDecorator('quantity', {
                                  initialValue: this.state.quantity,
                                  rules: [{ required: true, validator: this.validateQuantity }],
                                })(
                                  <InputNumber min={1} max={data.max_booking} onChange={(value) => this.handleContactDataChange(0, 'quantity', value)} />
                                )}
                              </Form.Item>
                            </div>
                            {/************************************** Agent **************************************/}
                            <div className="form_control pandding_left Quantity_sty" >
                              <Form.Item label="Agent" >
                                {getFieldDecorator('agent', {
                                  initialValue: this.state.agentId !== undefined ? this.state.agentId.toString() : '',
                                  rules: [{ required: true }],
                                })(
                                  <AutoComplete
                                    className="certain-category-search"
                                    dropdownClassName="certain-category-search-dropdown"
                                    dropdownMatchSelectWidth={false}
                                    dropdownStyle={{ width: 300 }}
                                    size="default"
                                    style={{ marginTop: 4.4, width: '100%' }}
                                    dataSource={options}
                                    placeholder="Select agent"
                                    optionLabelProp="label"
                                    defaultValue={this.state.agentId !== undefined ? this.state.agentId.toString() : ''}
                                    disabled={agent_disabled}
                                    onSelect={(value) => this.handleContactDataChange(0, 'agent', value)}
                                    filterOption={(input, option) => {
                                      if (option.props.state !== undefined) {
                                        if (option.props.state.toLowerCase().includes(input.toLowerCase())) {
                                          return true
                                        }
                                        if (option.props.label.toLowerCase().includes(input.toLowerCase())) {
                                          return true
                                        }
                                      }
                                    }}
                                  >
                                    <Input suffix={<Icon type="search" className="certain-category-icon" />} />
                                  </AutoComplete>
                                )}
                              </Form.Item>
                            </div>

                            <div className="form_control">
                              <Form.Item label="Sales person">
                                {getFieldDecorator('sale_rep', {
                                  rules: [{ required: true, pattern: new RegExp("^[A-Za-z]{1}[A-Za-z0-9\\s\\-\\.]{2,}$"), message: '*Please fill in the sales person', }],
                                })(                            
                                  <Input onChange={(event) => this.handleContactDataChange(0, 'sale_rep', event.target.value)} placeholder="Sales person" />
                                )}
                              </Form.Item>
                            </div>
                          </div> 
                        </div>
                      </Card>
                    )
                }

                {/************************************************* Contact Info Component ******************************************/}
                <ContactInfor loading={loading} getFieldDecorator={getFieldDecorator} contacts={this.state.contacts} pageName={"VoucherShowPage"} />

                {/********************************************** Payment Method Component **********************************************/}
                <div className="header_title">
                  <h2>Your Payment Method</h2>
                </div>
                <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={true} credit_disabled={this.props.credit_disabled} fpx_disabled={false} payment_type={this.state.payment_type} payment_method={this.state.payment_method} credits={this.props.credits} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select} offline_banklist={offline_banklist} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)} onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)} terms={homepage_new.wifi_terms} no_credit_payment={false} pageName={"VoucherShowPage"} userStatus={this.props.userStatus} final_price={this.props.final_price} service_fee={this.props.service_fee} fpxb2b_disabled={!data.fpxb2b_available} allow_fpxb2c_overlimit={false} dobw_disabled={!data.dobw_available} fpxb2c_disabled={!data.fpxb2c_available} bank_type={bank_type} />
                <div className="after_select">
                  <Button type="primary" htmlType="submit" disabled={this.state.check_out_disabled}>CHECK OUT</Button>
                </div>

              </Form>
            )
          }
        </div>

        <Modal
          visible={bookingErrorModal}
          title={null}
          footer={null}
          closable={false}
          onCancel={() => this.onCloseModal('bookingErrorModal')}
        >
          <Empty
            image={warning}
            imageStyle={{
              height: 100,
            }}
            description={
              <p className="popup_text">{bookingErrorMessage}</p>
            }
            className="popup_footer"
          >
            <Button type="primary" onClick={() => this.onCloseModal('bookingErrorModal')}>Close</Button>
          </Empty>
        </Modal>

        <Modal
          title={data.title}
          visible={this.props.detailError}
          footer={null}
          onCancel={() => this.onCloseModal('bookingErrorModal')}
        >
          <Empty
            image={warning}
            imageStyle={{
              height: 100,
            }}
            description={
              <p className="popup_text">{this.props.detailErrorMessage}</p>
            }
            className="popup_footer"
          >
            <Button type="primary" onClick={() => this.onCloseModal('bookingErrorModal')}>Close</Button>
          </Empty> 
        </Modal>

        <Modal
          visible={this.state.voucherTourInfoModal}
          title={"Tour Info"}
          footer={null}
          width={800}
          onCancel={() => this.onVoucherTourInfo(false)}
        >
          {/**************************** Things to know ****************************/}
          { this.state.voucherTourInfoModalContent ? (
            <React.Fragment>
              {/* <div className="quick-info">
                <h1>{this.state.voucherTourInfoModalContent.taggable.title}</h1>
                <p className="title">Things to know</p>
                <br />
                  <Row gutter={0}>
                  {
                    this.state.voucherTourInfoModalContent.taggable.cut_off_day === "" ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={8} lg={24}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="clock" />&nbsp; <span className="cutoff">Balance Payment Deadline :&nbsp;</span>{this.state.voucherTourInfoModalContent.taggable.cut_off_day} day(s)</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.guide_languages === "" ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="walking" />&nbsp; Tour Guide : {this.state.voucherTourInfoModalContent.taggable.guide_languages}</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.free_of_charge === 0 ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="user-tag" />&nbsp; {this.state.voucherTourInfoModalContent.taggable.free_of_charge > 1 ? this.state.voucherTourInfoModalContent.taggable.free_of_charge - 1 : this.state.voucherTourInfoModalContent.taggable.free_of_charge} + 1 Half Twin <strong>FOC</strong></p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.deposit === "" || this.state.voucherTourInfoModalContent.taggable.deposit === 0 ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="money-bill-alt" />&nbsp; <strong>Deposit</strong>: {this.state.voucherTourInfoModalContent.taggable.deposit_text}</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.inc_hotel === false ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="bed" />&nbsp; Hotel(s) Included</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.inc_flight === false ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="plane-departure" />&nbsp; Flight(s) Included</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.inc_half_board_meals === false ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="utensils" />&nbsp; Half board Meal(s) Included</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.inc_full_board_meals === false ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="utensils" />&nbsp; Full board Meal(s) Included</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.inc_entrance_ticket === false ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="ticket-alt" />&nbsp; Entrance Ticket(s) Included</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.inc_transportation === false ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="bus" />&nbsp; Transportation Included</p>
                        </div>
                      </Col>
                    )
                  }
                  {
                    this.state.voucherTourInfoModalContent.taggable.inc_shopping_stop === false ? (
                      null
                    ) : (
                      <Col className="gutter-row" md={12} lg={8}>
                        <div className="gutter-box" align="left">
                          <p><FontAwesomeIcon fixedWidth icon="shopping-bag" />&nbsp; Shopping Stop Included</p>
                        </div>
                      </Col>
                    )
                  }
                  <Col className="gutter-row" md={12} lg={8}>
                    <div className="gutter-box" align="left">
                      <p><FontAwesomeIcon fixedWidth icon="bolt" />&nbsp; Instant Confirmation</p>
                    </div>
                  </Col>  
                </Row>
              </div>
              <Divider /> */}
              <div className="quick-info">
                <p className="title">Highlight</p>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: this.state.voucherTourInfoModalContent.taggable.highlight.replace(/\n/g, '<br />') }} />
              </div>
              <Divider />
              <div className="quick-info">
                <p className="title">Description</p>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: this.state.voucherTourInfoModalContent.taggable.description && this.state.voucherTourInfoModalContent.taggable.description !== undefined ? this.state.voucherTourInfoModalContent.taggable.description.replace(/\n/g, '<br />') : this.state.voucherTourInfoModalContent.description.replace(/\n/g, '<br />') }} />
              </div>
              <Divider />
              <div className="quick-info">
                <p className="title">Terms & Conditions</p>
                <br />
                <div dangerouslySetInnerHTML={{ __html: this.state.voucherTourInfoModalContent.tnc.replace(/\n/g, '<br />') }} />
              </div>
              <Divider />
              <div className="quick-info">
                <p className="title">Itinerary</p>
                <br />
                {
                  this.state.voucherTourInfoModalContent.taggable.itineraries.map((itinerary) => {
                    return(
                      <React.Fragment>
                        <Card style={{ marginBottom: 10 }}>
                          <p>{itinerary.day_no}</p>
                          <p>{itinerary.title}</p>
                          <p>{itinerary.cities.map((value) => { return value.city }).join(' | ')}</p>
                          <p>{itinerary.activities.map((value) => { return value.activity }).join(' | ')}</p>
                        </Card>
                      </React.Fragment>
                    )
                  })
                }
                <p></p>
              </div>
            </React.Fragment>
            ) : (null)
          }
        </Modal>

      </div>
    );
  }
}
const VoucherShowPage = Form.create({ name: 'voucher' })(LeftPanel);
export default withRouter(VoucherShowPage);