import React, { Component } from 'react';
import { Form, Icon, Row, Col, Rate, Card, List, Avatar, Collapse, Input, Select, Button, Divider, Checkbox, DatePicker, InputNumber, Skeleton, notification, Popover, Calendar, Radio, Modal, Empty, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactMarkdown from 'react-markdown';
import './index.css';
import master from '../../../../../images/payment/master.png';
import instant from '../../../../../images/payment/instant.png';
import fpx from '../../../../../images/payment/fpx.png';
import warning from '../../../../../images/warning.png';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import API from '../../../../api';
import PaymentMethod from '../payment';
import ContactInfor from '../contactInfor';

const { Panel } = Collapse;
const { Option } = Select;
const { Group } = Radio;
const dateFormat = 'YYYY-MM-DD';

function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

function onChangeDate(date, dateString) {
  console.log(date, dateString);
}

function onChangeNumber(value) {
  console.log('changed', value);
}

function onPanelChange(value, mode) {
  console.log(value, mode);
}

class LeftPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      // Modal state
      ticketTncModal: false,
      ticketTncModalKey: '',
      bookingErrorModal: false,
      bookingErrorMessage: '',
      type: '',
      payment_type: '',
      payment_method: null,
      // detail: {},
      bookingDetails: [],
      ticketDetails: [],
      // taggable_type: '',
      check_out_disabled: false,
      banklist: [],
      fpxb2b_banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      b2b_offline_banklist: '',
      banklist_select: [],
      // credits: 0,
      method_visible: false,
      // visitDate: '',
      openDatePicker: false,
      validationErrors: [],
      agree: '',
      showToday: false,
      sale_rep: '',
      bank_type: null,
      redirect_url: null,
      dobw_banklist: [],
    }
  }

  componentDidMount() {
    this.banklistFetch();
  }

  // Conditional function
  isGt = () => {
    return this.props.dataList.taggable_type == 'GT'
  };

  isAct = () => {
    return this.props.dataList.taggable_type == 'ACT'
  };

  isFixedDate = () => {
    return this.props.dataList.travel_date_type == 'Fixed date'
  };

  isVisitDateCompulsory = () => {
    return this.props.dataList.is_visit_date_compulsory == true
  };

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  };

  hideDatePicker = (index) => {
    this.state.ticketDetails[index]['openDatePicker'] = false
    this.setState({
      openDatePicker: this.state.ticketDetails
    });
  };

  datePickerStatus = status => {
    if (!status) {
      this.hideDatePicker();
    }
  };

  toggleDatePicker = () => {
    this.setState(prevState => ({
      openDatePicker: !prevState.openDatePicker
    }));
  };

  onOpenModal = (modal, key) => {
    this.setState({
      ticketTncModalKey: key,
      ticketTncModal: true
    });
  };

  onCloseModal = (modal) => {
    if (modal === 'bookingErrorModal') {
      this.props.history.go(-1);
    } else {
      this.setState({
        ticketTncModal: false,
      });
    }
  };

  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          // credits: response.data.credits
        })
        that.props.getCredits(response.data.credits)
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handlePaymentChange(event) {
    console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null
      })
    }
    else /** if (event.target.value === 'credit_transaction') **/ {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }

    this.setState({
      payment_type: event.target.value
    })
    this.props.handlePaymentType(event.target.value);
  }

  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  handleBookingDataChange(index, type, value, e = '') {
    if (type == "sale_rep") {
      this.setState({
        sale_rep: value
      });
    }
    else if (type == "customerName") {
      this.state.bookingDetails[index]["name"] = value;
      this.state.bookingDetails[index][type] = value;
    }
    else {
      this.state.bookingDetails[index][type] = value;
    }
    this.setState({
      bookingDetails: this.state.bookingDetails
    })
    // console.log(this.state.bookingDetails);
  }

  handleBookingPackageDataChange(ticket_index, type, value, e = '') {
    this.state.ticketDetails[ticket_index][type] = value;
    this.setState({
      ticketDetails: this.state.ticketDetails
    })
    console.log(this.state.ticketDetails)
  }

  handleBookingQuestionDataChange(ticket_index, question_index, type, value, e = '') {
    this.state.ticketDetails[ticket_index].questionList[question_index][type] = value;
    this.setState({
      ticketDetails: this.state.ticketDetails
    })
    console.log(this.state.ticketDetails)
  }

  hide = () => {
    this.setState({
      visible: false
    });
  }

  handleVisibleChange = visible => {
    this.setState({ visible });
  }

  // handleCancel = () => {
  //   this.props.history.go(-1);
  // };

  typeRadio(event) {
    // console.log(event.target.value);
    this.setState({
      type: event.target.value
    })
  }

  handleTicketQuantity = (index, type, value, e = '') => {
    if (this.isGt()) {
        if (this.isFixedDate()) {
           if (this.props.visitDate !== '') {
            this.state.ticketDetails[index][type] = value;
            this.setState({
              ticketDetails: this.state.ticketDetails
            })
            this.props.handleTicketQuantity(index, type, value, e = '');
          } else {
            this.toggleDatePicker();
          }
        } else {
          this.state.ticketDetails[index][type] = value;
          this.props.handleTicketQuantity(index, type, value, e = '');
        }
        if (this.state.ticketDetails[index].questionList.length > 0) {
          this.state.ticketDetails[index].answer_required = value > 0 ? true : false;
        }
        this.setState({
          ticketDetails: this.state.ticketDetails
        }, () => {
          this.state.ticketDetails[index].questionList.map((question, question_index) => {
            this.props.form.validateFields(['answer'+index+'_'+question_index], { force: true });
          });
        })
    } else if (this.isAct()) {
      if (this.props.visitDate !== '') {
        this.state.ticketDetails[index][type] = value;
        this.setState({
          ticketDetails: this.state.ticketDetails
        })
        this.props.handleTicketQuantity(index, type, value, e = '');
      } else {
        this.toggleDatePicker();
      }
    }
  }

  handleVisitDate = (index, type, value) => {
    // if (type == "GT") {
    //   for (var i = 0; i < this.state.ticketDetails.length; i++) {
    //     this.state.ticketDetails[i]["visitDate"] = moment(value).format(dateFormat);
    //     this.state.ticketDetails[i]["visible"] = this.props.dataList.tickets[i].blockout_dates.includes(moment(value).format(dateFormat)) ? false : true // Check if selected visit date is equal to blockout dates, then disabled input
    //   }
    //   // this.setState({
    //   //   ticketDetails: this.state.ticketDetails
    //   // })
    // } else if (type == "ACT") {
    //   for (var i = 0; i < this.state.ticketDetails.length; i++) {
    //     this.state.ticketDetails[i]["visitDate"] = moment(value).format(dateFormat);
    //     this.state.ticketDetails[i]["visible"] = this.props.dataList.tickets[i].blockout_dates.includes(moment(value).format(dateFormat)) ? false : true // Check if selected visit date is equal to blockout dates, then disabled input
    //   }
    //   // this.setState({
    //   //   visitDate: value
    //   // })
    // }
    for (var i = 0; i < this.state.ticketDetails.length; i++) {
      this.state.ticketDetails[i]["visitDate"] = moment(value).format(dateFormat);
      this.state.ticketDetails[i]["visible"] = this.props.dataList.tickets[i].blockout_dates.includes(moment(value).format(dateFormat)) ? false : true // Check if selected visit date is equal to blockout dates, then disabled input
    }
    this.props.handleVisitDate(value);
    this.props.getDataTickets(this.state.ticketDetails);
  }

  openNotificationWithIcon = (type, error_name) => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
    }
    if (type === "error") {
      if (error_name === "credits_error") {
        notification[type]({
          message: 'Error!',
          description:
            'Insufficient credits! please purchase more credits.',
        });
      }
      if (error_name === "fpx_min_error") {
        notification[type]({
          message: 'Error!',
          description:
            'Transaction Amount is Lower than the Minimum Limit RM2.00 for B2B1.',
        });
      }

      if (error_name === "fpx_max_error") {
        notification[type]({
          message: 'Error!',
          description:
            'Maximum Transaction Limit Exceeded RM1,000 000 for B2B1.',
        });
      }
      if (error_name === "min_booking") {
        notification[type]({
          message: 'Error!',
          description:
            '*Please enter at least ' + this.props.dataList.min_booking + ' pax',
        });
      }
      if (error_name === "max_booking") {
        notification[type]({
          message: 'Error!',
          description:
            '*Please enter not more than ' + this.props.dataList.max_booking + ' pax',
        });
      }
    }
  };

  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  }

  checkDaysOfWeek = dayInString => {
    if (dayInString == "Mon") {
      return 1
    } else if (dayInString == "Tue") {
      return 2
    } else if (dayInString == "Wed") {
      return 3
    } else if (dayInString == "Thu") {
      return 4
    } else if (dayInString == "Fri") {
      return 5
    } else if (dayInString == "Sat") {
      return 6
    } else if (dayInString == "Sun") {
      return 7
    } else {
      return 0
    }
  };

  submitForm = event => {
    event.preventDefault();
    this.banklistFetch();

    const { bookingDetails, ticketDetails, /*taggable_type,*/ payment_type, payment_method, validationErrors, agree, sale_rep, redirect_url, bank_type } = this.state;
    
    let outputJson = {
      bookingDetails: bookingDetails,
      taggable_type: this.props.dataList.taggable_type,
      visitDate: this.props.visitDate,
      ticketDetails: ticketDetails.filter((t) => t.visible === true),
      payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url },
      price: this.props.totalPrice,
      sale_rep: sale_rep,
      agree: agree
    };
    console.log("This is the outputJson", outputJson);
    // console.log("This is the location", this.props.location);

    let bookingValidate = bookingDetails[0]
    let ticketValidate = bookingDetails[0].ticketDetails
    // console.log("ticketValidate", ticketValidate);
    // console.log("bookingValidate", bookingValidate);
    let total_quantity = 0;
    ticketValidate.map((item) => { return total_quantity += item.quantity })
    console.log("total_quantity",total_quantity)

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (payment_type === "" || (payment_type !== 'credit_transaction' ? payment_method === null : '') || agree === "" || !err === false || (total_quantity < this.props.dataList.min_booking || total_quantity > this.props.dataList.max_booking || this.props.totalPrice < 2 || this.props.totalPrice > 1000000)) {

          if (!err) {
            console.log('Received values of form: ', values);
          }
          if (total_quantity < this.props.dataList.min_booking) {
            validationErrors.quantity = '*Please enter at least ' + this.props.dataList.min_booking + ' pax';
            this.setState({ validationErrors: validationErrors })
            this.openNotificationWithIcon('error','min_booking');
          }
          else if (total_quantity > this.props.dataList.max_booking) {
            validationErrors.quantity = '*Please enter not more than ' + this.props.dataList.max_booking + ' pax';
            this.setState({ validationErrors: validationErrors })
            this.openNotificationWithIcon('error','max_booking');
          }
          else {
            validationErrors.quantity = '';
            this.setState({ validationErrors: validationErrors })
          }

          if (payment_type === "") {
            this.openNotificationWithIcon('warning','payment_method_error');
          }
          if (payment_method === null) {
              validationErrors.payment_method = "*Please select a payment method";
              this.setState({ validationErrors: validationErrors })
          } else {
              validationErrors.payment_method = null;
              this.setState({ validationErrors: validationErrors })
          }

          if (payment_type === "fpx_b2b" && this.props.totalPrice < 2) {
            this.openNotificationWithIcon('error','fpx_min_error');
          }
          if (payment_type === "fpx_b2b" && this.props.totalPrice > 1000000) {
            this.openNotificationWithIcon('error','fpx_max_error');
          }

          if (agree === "") {
            validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
            this.setState({ validationErrors: validationErrors })
          } else {
            validationErrors.agree = "";
            this.setState({ validationErrors: validationErrors })
          }

        console.log("This is the outputJson", outputJson);
      }
      else {
        this.setState({
          check_out_disabled: true,
        })

        let url = './api/v1' + this.props.location.pathname + '/create_booking/';
        let that = this;

        API.post(url, outputJson)
          .then(function (response) {
            console.log(response, '------------res');
            console.log(response.data.redirect_to, '------------redirect');
            if (response.data.redirect_to) {
              window.location = response.data.redirect_to
            } else if (response.data.credits_error) {
              that.openNotificationWithIcon('error','credits_error');
              that.setState({ payment_type: '' })
            }
            else {
              that.setState({
                check_out_disabled: false,
                bookingErrorModal: true,
                bookingErrorMessage: response.data.error
              })
            }
            that.setState({
              loading: false
            })
          })
          .catch(function (error) {
            console.log(error);
            that.setState({
              visible: true,
            })
          })
          .then(function () {
            // always executed
          });
      }
    });
  }

  componentDidUpdate(nextProps) {
    // if (this.props.dataList != this.state.detail) {
      if (nextProps.dataList !== this.props.dataList) {
        var payment_type = this.state.payment_type;
        var bookingDetails = [];
        var validationErrors = [];
        if (this.props.dataList.hasOwnProperty('tickets') && this.props.dataList.tickets.length > 0) {
          
          var ticketDetails = [];
          this.props.dataList.tickets.map((ticket) => {
            if (this.isGt()) {
              var questions = [];
              console.log(ticket.questions, "===ticket.questions")
              ticket.questions != null && ticket.questions.map((question) => {
                questions.push(question)
              })
              ticketDetails.push({ 
                id: ticket.id,
                name: ticket.name, 
                dta: ticket.dta,
                price: ticket.price, 
                quantity: 0, 
                visitDate: '',
                visible: true, 
                questionList: questions, 
                blockout_dates: ticket.blockout_dates, 
                description: ticket.description,
                terms_and_conditions: ticket.terms_and_conditions,
                answer_required: false
                // is_visit_date_compulsory: ticket.is_visit_date_compulsory
              })
            } else if (this.isAct()) {
              ticketDetails.push({ 
                id: ticket.id, 
                name: ticket.name, 
                dta: ticket.dta, 
                price: ticket.price, 
                quantity: 0, 
                visitDate: '', 
                visible: true, 
                blockout_dates: ticket.blockout_dates, 
                description: ticket.description, 
                terms_and_conditions: ticket.terms_and_conditions 
              })
            }
          })

        bookingDetails.push({ 
          email: '', 
          name: '', 
          customerName: '', 
          remark: '', 
          mobile: '', 
          ticketDetails: ticketDetails, 
          agree: '' 
        })
        validationErrors.push({ 
          email: '', 
          name: '', 
          customerName: '', 
          mobile: '', 
          ticketDetails: ticketDetails, 
          agree: '' 
        })
      }
      this.setState({
        // detail: this.props.dataList,
        bookingDetails: bookingDetails,
        ticketDetails: ticketDetails,
        // taggable_type: this.props.dataList.taggable_type,
        validationErrors: validationErrors,
        // max_quantity: this.props.dataList.max_booking
        showToday: this.props.dataList.advance_booking_days > 0 ? false : true,
      })
      this.props.getDataTickets(ticketDetails);
    }

    let updateJson = {
      bookingDetails: this.state.bookingDetails,
      taggable_type: this.props.dataList.taggable_type,
      visitDate: this.props.visitDate,
      ticketDetails: this.state.ticketDetails.filter((t) => t.visible === true),
      payment: { gateway: this.state.payment_type, method: this.state.payment_method },
      price: this.props.totalPrice,
      agree: this.state.agree
    };
    // console.log("This is the updateJson", updateJson);
  }

  render() {
    const {ticketDetails, validationErrors, type, banklist_select, method_visible, offline_banklist, payment_type, payment_method, ticketTncModal, bookingErrorModal, bookingErrorMessage, ticketTncModalKey, b2b_offline_banklist, bank_type } = this.state;
    const data = this.props.dataList
    const loading = this.props.loading;
    const { getFieldDecorator } = this.props.form;
    // console.log(loading, '----loading')
    // console.log(data, '----data')
    /********************************* formItemLayout ******************************/
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    return (
      <div className="LeftPanel">
        <div className="hotel_details">
          {/**************************** header title ****************************/}
          <div className="header_title">
            <h1>{data.title}</h1>
            <p className="location"><FontAwesomeIcon icon='map-marker-alt' /> {data.country}</p>
          </div>

          {/**************************** Description ****************************/}
          {
            loading ? (
              <Skeleton active />
            ) : (
                <Card className="things">
                  <div className="quick-info">
                  <p className="title">Things to know</p>
                    <Row gutter={2}>
                      {data.cancellation !== '' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="history" />&nbsp; {data.cancellation}</p></div>
                        </Col> : ''
                      }
                      {data.e_ticketing !== '' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="ticket-alt" />&nbsp; {data.e_ticketing}</p></div>
                        </Col> : ''
                      }
                      {data.travel_date_type !== '' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="calendar-alt" />&nbsp; {data.travel_date_type}</p></div>
                        </Col> : ''
                      }
                      {data.duration !== '' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="clock" />&nbsp; {data.duration} hours duration tour</p></div>
                        </Col> : ''
                      }
                      {data.ticket_type !== '' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="calendar-check" />&nbsp; {data.ticket_type}</p></div>
                        </Col> : ''
                      }
                      {data.languages !== '' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="globe" />&nbsp; {data.languages}</p></div>
                        </Col> : ''
                      }
                      {data.transfer !== '' && data.transfer !== 'No transfer' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="shuttle-van" />&nbsp; {data.transfer}</p></div>
                        </Col> : ''
                      }
                      {data.meeting_point !== '' && data.meeting_point !== 'No specific meeting point' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="map-marker-alt" />&nbsp; {data.meeting_point}</p></div>
                        </Col> : ''
                      }
                      {data.confirmation_time !== '' ?
                        <Col className="gutter-row" md={12} lg={8}>
                          <div className="gutter-box" align="left"><p><FontAwesomeIcon fixedWidth icon="check-circle" />&nbsp; {data.confirmation_time}</p></div>
                        </Col> : ''
                      }
                    </Row>
                    {
                      data.cancellation !== '' || data.e_ticketing !== '' || data.travel_date_type !== '' || data.duration !== '' || data.ticket_type !== '' || data.languages !== '' || data.transfer !== '' || data.meeting_point !== '' || data.confirmation_time !== '' ? <Divider /> : ''
                    }
                  </div>

                  <div className="description">
                    <p className="title">Description</p>
                    <p>
                      <ReactMarkdown source={data.description != undefined ? data.description : ''} />
                    </p>
                  </div>

                  <div className="operation">
                    <Divider />
                    <p className="title">Operation Days</p>
                    <p>
                      <ReactMarkdown source={data.operation_days != undefined ? data.operation_days.replace(/\ \*\*/g, "\n\n\ \*\*").replace(/\ - /g, "\n - ").replace(/\ \*/g, "\n\n") : ''} />
                    </p>
                  </div>

                  <div className="destination">
                    <Divider />
                    <p className="title">Destination</p>
                    <p>{data.country}</p>
                  </div>

                  {
                    data.terms_and_conditions != undefined && data.terms_and_conditions != '' ?
                    <Collapse defaultActiveKey={['1']} expandIconPosition='right'>
                      <Panel header="Terms and Conditions" key="1" >
                        <div>
                          <ReactMarkdown source={data.terms_and_conditions != undefined ? data.terms_and_conditions.replace(/\ \*\*/g, "\n\n\ \*\*").replace(/ - /g, "\n - ") : ''} />
                        </div>
                      </Panel>
                    </Collapse> : ''
                  }
                </Card>

              )
          }

          {/**************************** Booking ****************************/}
          <div className="header_title">
            <h2>Your Booking</h2>
          </div>

          {
            loading ? (
              <Skeleton active />
            ) : (
                <Form {...formItemLayout} onSubmit={this.submitForm} className="form_sty_select" >
                  <Card title={[
                    <div className="guest_content">
                      <p>Booking Selection</p>
                    </div>
                  ]} className="guest">

                    <div className="form_guest">
                    {/********************** Visit Date **********************/}
                    {
                      this.isAct() || (this.isGt() && this.isFixedDate() || this.isVisitDateCompulsory()) ? 
                      (
                        <div className="form_control">
                          <Form.Item label="Visit Date">
                            {getFieldDecorator('visit_date', {
                              rules: [{ required: true, message: '*Please select the visit date', }],
                            })(                            
                              this.isAct() ?
                              (
                                <DatePicker showToday={this.state.showToday} allowClear={false} open={this.state.openDatePicker} onOpenChange={this.toggleDatePicker} onChange={(date, dateString) => this.handleVisitDate(0, 'ACT', dateString)} disabledDate={current => {
                                  return ( (current && current < moment().add(data.advance_booking_days, "day")) || (current && !current.isBetween(data.date_start, data.date_end)) || (current && !data.operation_days.split(',').map((day) => { return day.trim() }).some((od)=>{ return current.isoWeekday() == this.checkDaysOfWeek(od) })) );
                                }} />
                              ) :
                              this.isGt() && this.isFixedDate() || this.isVisitDateCompulsory() ? 
                              (
                                <DatePicker allowClear={false} open={this.state.openDatePicker} onOpenChange={this.toggleDatePicker} onChange={(date, dateString) => this.handleVisitDate(0, 'GT', dateString)} disabledDate={current => {
                                  return ( (current && current < moment().add(1, "day")) );
                                }} />
                              ) : (null)
                            )}
                          </Form.Item>
                        </div>
                      ) : ''
                    }

                      <div className="form_control">
                        <div className="tickets-wrapper">
                        {
                          ticketDetails && ticketDetails.map((ticket, ticket_index) => {
                            return (
                              <div className="form_control">
                                <div className="package_1">
                                  <p className="type">{ticket.name} &nbsp;
                                      {
                                        ((ticket.description != undefined && ticket.description != '') || (ticket.terms_and_conditions !== '' && ticket.terms_and_conditions !== undefined)) &&
                                        <Tooltip title="Details">
                                          <span className="popup_icon" onClick={() => this.onOpenModal('ticketTncModal', ticket_index)}>
                                            <a><FontAwesomeIcon icon='question-circle' /></a>
                                          </span>
                                        </Tooltip>
                                      }
                                      {
                                        ticket.blockout_dates.length > 0 && 
                                        <span className="popup_icon">
                                          <Popover 
                                            content={
                                              <Calendar 
                                                disabledDate={current =>{
                                                    return (this.isAct() ? (current && !current.isBetween(data.date_start, data.date_end)) : ('')) || (current && ticket.blockout_dates.some((bd)=>{ return current.isSame(bd, 'day') }));
                                                  }
                                                } 
                                                fullscreen={false} 
                                                onPanelChange={onPanelChange} />
                                              
                                            } 
                                            title="Blockout Dates"
                                            style={{ marginTop: 10 }}
                                            >
                                            <FontAwesomeIcon icon='calendar-alt' />
                                          </Popover>
                                        </span>
                                      }
                                  </p>
                                  {
                                    this.isGt() ?
                                    (
                                      <p><small>{ticket.variation}</small></p>
                                    ) :
                                    this.isAct() ?
                                    (
                                      ''
                                    ) :
                                    (
                                      ''
                                    )
                                }
                                </div>
                                <div className="package_2">
                                {
                                  this.isGt() ?
                                  (
                                    <InputNumber min={0} max={20} className={validationErrors.quantity ? 'error_border' : ''} disabled={ticketDetails[ticket_index] && !ticketDetails[ticket_index].visible} defaultValue={ticket.quantity} value={data.travel_date_type !== "Fixed date" ? ticket.quantity : this.props.visitDate !== '' ? ticket.quantity : 0} onChange={(e) => this.handleTicketQuantity(ticket_index, 'quantity', e)} /> 
                                  ) :
                                  this.isAct() ?
                                  (
                                    <InputNumber min={0} max={20} className={validationErrors.quantity ? 'error_border' : ''} disabled={ticketDetails[ticket_index] && !ticketDetails[ticket_index].visible} defaultValue={ticket.quantity} value={this.props.visitDate !== '' ? ticket.quantity : 0} onChange={(e) => this.handleTicketQuantity(ticket_index, 'quantity', e)} />
                                  ) :
                                  (
                                    ''
                                  )
                                }
                                {/* <p className="error">{validationErrors.quantity}</p> */}
                                {/*<InputNumber min={0} max={this.props.visitDate !== '' ? 10 : 0} defaultValue={ticket.quantity} onChange={(e) => this.handleTicketQuantity(ticket_index, 'quantity', e)} />*/}
                                <span>x RM {this.priceFormat(ticket.price)}</span>
                                <div className="clear"></div>
                                </div>

                                {/*
                                  this.isGt() && ticket.is_visit_date_compulsory === true ?
                                  (
                                    <div className="form_control">
                                    <label className="content_icon">Visit Date</label>
                                    <DatePicker allowClear={false} onChange={(date, dateString) => this.handleVisitDate(ticket_index, 'GT', dateString)} disabledDate={current => {
                                      return current && current < moment().add(ticket.advance_booking_days, "day");
                                    }} />
                                    <div className="clear"></div>
                                    <p className="error">{validationErrors.visitDate}</p>
                                  </div>
                                  ) :
                                  (
                                    ''
                                  )
                                */}

                                {
                                  ticket.questionList && ticket.questionList.map((question, question_index) => {
                                    return (
                                      <div className="form_control pandding_right">
                                        <label>{question.question}</label>
                                        <Form.Item>
                                          {getFieldDecorator('answer'+ticket_index+'_'+question_index, {
                                            rules: [{ required: ticket.answer_required, message: '*Please answer the question' }],
                                          })(
                                              question.type === "DATE" ?
                                              (
                                                <DatePicker onChange={(date, dateString) => this.handleBookingQuestionDataChange(ticket_index, question_index, 'answer', moment(date).format(dateFormat), dateString)} />
                                              ) : 
                                              question.type === "OPTION" ?
                                              (
                                                <Select
                                                  showSearch
                                                  placeholder="Select your option"
                                                  onChange={(value) => this.handleBookingQuestionDataChange(ticket_index, question_index, 'answer', value)}
                                                  onFocus={onFocus}
                                                  onBlur={onBlur}
                                                  onSearch={onSearch}

                                                >
                                                  {
                                                    question.options && question.options.map((option, index) => {
                                                      return (
                                                        <Option value={option}>{option}</Option>
                                                      )
                                                    })
                                                  }
                                                </Select>
                                              ) :
                                              (
                                                <Input defaultValue="" onChange={(event) => this.handleBookingQuestionDataChange(ticket_index, question_index, 'answer', event.target.value)} />
                                              )
                                          )}
                                        </Form.Item>

                                        <div className="clear"></div>
                                      </div>
                                    )
                                  })
                                }
                                <div className="clear"></div>
                              </div>
                            )
                          })
                        }

                        <div className="clear"></div>
                      </div>
                      <p className="error">{validationErrors.quantity}</p>
                      </div>

                      <div className="form_control">
                        <Form.Item label="Sales person">
                          {getFieldDecorator('sale_rep', {
                            rules: [{ required: true, pattern: new RegExp("^[A-Za-z]{1}[A-Za-z0-9\\s\\-\\.]{2,}$"), message: '*Please fill in the sales person', }],
                          })(                            
                            <Input onChange={(event) => this.handleBookingDataChange(0, 'sale_rep', event.target.value)} placeholder="Sales person" />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </Card>

                  {/************************************************* Contact Info Component ******************************************/}
                  <ContactInfor loading={loading} getFieldDecorator={getFieldDecorator} contacts={this.state.bookingDetails} pageName={"ActivityShowPage"} />

                  {/**************************** Payment Method ****************************/}
                  <div className="header_title">
                    <h2>Your Payment Method</h2>
                  </div>

                  <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={true} credit_disabled={this.props.credit_disabled} fpx_disabled={false} service_fee={this.props.service_fee} final_price={this.props.final_price} payment_type={payment_type} payment_method={payment_method} credits={this.props.credits} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select} offline_banklist={offline_banklist} b2b_offline_banklist={b2b_offline_banklist} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)} fpxb2b_disabled={!data.fpxb2b_available}
                    onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)} allow_fpxb2c_overlimit={true} dobw_disabled={!data.dobw_available} fpxb2c_disabled={!data.fpxb2c_available} bank_type={bank_type}
                  /> 

                  <div className="after_select">
                    <Button type="primary" htmlType="submit" disabled={this.state.check_out_disabled}>CHECK OUT</Button>
                  </div>

                </Form>
              )
          }
        </div>
        <Modal
            visible={ticketTncModal}
            title={<React.Fragment><FontAwesomeIcon fixedWidth icon="question-circle" />&nbsp; Details</React.Fragment>}
            closable={false}
            footer={[
              <Button type="primary" key="close" onClick={() => this.onCloseModal('ticketTncModal')}>
                Ok
              </Button>,
            ]}
          >
            { 
              ticketTncModalKey !== '' && ticketDetails[ticketTncModalKey] && ticketDetails[ticketTncModalKey].description &&
              (
                <div>
                  <h3>Description</h3>
                  <ReactMarkdown disallowedTypes={['heading']} source={ticketDetails[ticketTncModalKey].description != undefined ? ticketDetails[ticketTncModalKey].description : ''} />
                </div>
              )
            }
            { 
              ticketTncModalKey !== '' && ticketDetails[ticketTncModalKey] && ticketDetails[ticketTncModalKey].terms_and_conditions &&
              (
                <div>
                  <h3>Terms & Conditions</h3>
                  <ReactMarkdown disallowedTypes={['heading']} source={ticketDetails[ticketTncModalKey].terms_and_conditions != undefined ? ticketDetails[ticketTncModalKey].terms_and_conditions.replace(/• /g, " - ") : ''} />
                </div>
              )
            }
          </Modal>
        <Modal
          visible={bookingErrorModal}
          title={null}
          footer={null}
          closable={false}
          destroyOnClose={() => this.onCloseModal('bookingErrorModal')}
        >
          <Empty
            image={warning}
            imageStyle={{
              height: 100,
            }}
            description={
              <p className="popup_text">{bookingErrorMessage}</p>
            }
            className="popup_footer"
          >
            <Button type="primary" onClick={() => this.onCloseModal('bookingErrorModal')}>Close</Button>
          </Empty>
        </Modal>
      </div>
    );
  }
}

const ActivityShowPage = Form.create({ name: 'activity' })(LeftPanel);
export default withRouter(ActivityShowPage);
