import React, { Component } from 'react';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel';
import MenuBooking from '../menu';
import './index.css'
import API from '../../../../api';

class DueInvoice extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: { username: '', role: '', credits: 0 },
      due_invoices: [],
      invoice_info: [],
      service_fee: 0,
      total: 0,
      payment_type: '',
    }

    this.handlePaymentType = this.handlePaymentType.bind(this);
  }

  componentDidMount() {
    this.userFetch();
    this.invoicesFetch();
  }

  userFetch() {
    let url = './api/v1/users/details';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        that.setState({
          user: { role: response.data.role, username: response.data.username, credits: response.data.credits },
          currentTab: that.state.prevTab,
          isLoading: false,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  }

  invoicesFetch() {
    let url = './api/v1/invoices';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        that.setState({
          due_invoices: response.data
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      }); 
  }

  handlePaymentType(value) {
    this.setState({
      payment_type: value
    })
  }

  handlePurchaseInfo = (data) => {
    let total = 0;
    let service_fee = 0;
    let invoice_data = data !== undefined ? data : [];

    if (invoice_data.length > 0) { 
      invoice_data.map((invoice) => {
        total += invoice.settlement_amount;
        service_fee += invoice.cc_rate * invoice.settlement_amount;
      })
    }

    this.setState({
      total: total,
      service_fee: service_fee,
      invoice_info: invoice_data,
    })
  }

  render() {
    return (
      <div id="DueInvoice">
        <MenuBooking selected="due_invoice" />
        <div className="content">
          <div className="warppage">
            <LeftPanel due_invoices={this.state.due_invoices} credits={this.state.user.credits} handlePurchaseInfo={this.handlePurchaseInfo} handlePaymentType={this.handlePaymentType} invoiceTotal={this.state.total} invoiceServiceFee={this.state.service_fee} />
            <RightPanel getInvoiceInfo={this.state.invoice_info} invoiceTotal={this.state.total} invoiceServiceFee={this.state.service_fee} payment_type={this.state.payment_type} />
          </div>
        </div>
      </div>

    );
  }
}

export default DueInvoice;