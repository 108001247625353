import React, { Component } from 'react';
import { Form, Card, Row, Col, Slider, Divider, Button, notification } from 'antd';
import instant from '../../../../../images/payment/instant.png';
import PaymentMethod from '../../show/payment';
import { withRouter } from 'react-router-dom';
import API from '../../../../api';

const gridStyle = {
  width: '100%',
  // textAlign: 'center',
};

class LeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      selected_ids: [],
      banklist: [],
      payment_type: '',
      payment_method: null,
      check_out_disabled: false,
      banklist: [],
      pbb_banklist: [],
      fpxb2b_banklist: [],
      dobw_banklist: [],
      offline_banklist: '',
      b2b_offline_banklist: '',
      banklist_select: [],
      method_visible: false,
      validationErrors: [],
      agree: '',
      invoice_details: [],
      bank_type: null,
      redirect_url: null,
    }
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  componentDidMount() {
    this.banklistFetch();
  }

  handlePurchaseInfo = (value) => {
    this.props.handlePurchaseInfo(value)
  }

  onClickInvoice(event) {
    // If user clicked on selected invoice, remove it from state
    if (event.currentTarget.className.includes("addBorderActive")) {
      this.state.selected = this.state.selected.filter(function(selected) { return selected !== event.currentTarget.dataset.invoice_no })
      this.state.selected_ids = this.state.selected_ids.filter(function(selected_ids) { return selected_ids !== event.currentTarget.dataset.id })
      let found_invoice = this.props.due_invoices.find(x => x.invoice_no === event.currentTarget.dataset.invoice_no)
      this.state.invoice_details = this.state.invoice_details.filter(function(invoice_details) { return invoice_details !== found_invoice })
    }
    else {
      this.state.selected = [...this.state.selected, event.currentTarget.dataset.invoice_no]
      this.state.selected_ids = [...this.state.selected_ids, event.currentTarget.dataset.id]
      let found_invoice = this.props.due_invoices.find(x => x.invoice_no === event.currentTarget.dataset.invoice_no)
      this.state.invoice_details = [...this.state.invoice_details, found_invoice]
    }

    this.setState({
      invoice_details: this.state.invoice_details,
      selected: this.state.selected,
      selected_ids: this.state.selected_ids,
    }, () => { this.handlePurchaseInfo(this.state.invoice_details) })
    
    console.log('event', event.currentTarget.dataset.id)
  }

  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          fpxb2b_banklist: response.data.fpxb2b_banklist,
          b2b_offline_banklist: response.data.b2b_offline_banklist,
          dobw_banklist: response.data.dobw_banklist,
          // credits: response.data.credits
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handlePaymentChange(event) {
    console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2c") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "fpx_b2b") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.fpxb2b_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === "dobw") {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.dobw_banklist,
        payment_method: null,
      })
    }
    else /** if (event.target.value === 'credit_transaction') **/ {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }

    this.setState({
      payment_type: event.target.value
    })
    this.props.handlePaymentType(event.target.value);
  }

  handlePaymentMethodChange(value, option) {
    let payment_method = value;
    let bank_type = ""
    let redirect_url = ""

    if (this.state.payment_type === "dobw"){
      var found_bank = this.state.banklist_select.find((val) => val[1] === payment_method);
      redirect_url = found_bank[2]
      const splitString = payment_method.split(" - ");
      payment_method = splitString[0];
      bank_type = splitString[1];
    }

    this.setState({
      payment_method: payment_method,
      bank_type: bank_type,
      redirect_url: redirect_url
    })
    // console.log("card method",value)
  };

  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
  };

  openNotificationWithIcon = (type, error_name) => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
    }
  };

  submitForm = event => {
    event.preventDefault();

    const { selected_ids, selected, payment_type, payment_method, agree, validationErrors, bank_type, redirect_url } = this.state;

    let outputJson = {
      invoice_ids: selected_ids,
      payment: { gateway: payment_type, method: payment_method, bank_type: bank_type, redirect_url: redirect_url },
      agree: agree
    };
    console.log("This is the outputJson", outputJson);

    this.props.form.validateFieldsAndScroll((err, values) => {
      if(payment_type === "" || agree === "" || !err === false) {
        if (!err) {
          console.log('Received values of form: ', values);
        }
        if (payment_type === "") {
            this.openNotificationWithIcon('warning','payment_method_error');
        }
        if (payment_method === null) {
            validationErrors.payment_method = "*Please select a payment method";
            this.setState({ validationErrors: validationErrors })
        } else {
            validationErrors.payment_method = null;
            this.setState({ validationErrors: validationErrors })
        }
        if (agree === "") {
            validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
            this.setState({ validationErrors: validationErrors, agree: false })
        } else {
            validationErrors.agree = "";
            this.setState({ validationErrors: validationErrors })
        }
      }
      else {
        this.setState({
          check_out_disabled: true,
        })

        let url = './api/v1/invoices/create_payment/';
        let that = this;

        API.post(url, outputJson)
          .then(function (response) {
            console.log(response, '------------res');
            console.log(response.data.redirect_to, '------------redirect');
            if (response.data.redirect_to) {
              window.location = response.data.redirect_to
            // } else if (response.data.credits_error) {
            //   that.openNotificationWithIcon('error','credits_error');
            //   that.setState({ payment_type: '' })
            }
            else {
              that.setState({
                check_out_disabled: false
                // bookingErrorModal: true,
                // bookingErrorMessage: response.data.error
              })
            }
            that.setState({
              loading: false
            })
          })
          .catch(function (error) {
            console.log(error);
            that.setState({
              visible: true,
            })
          })
          .then(function () {
            // always executed
          });
      }
    });
    
  }

  render() {
    const { inputValue, selected, credits, payment_type, payment_method, method_visible, validationErrors, banklist_select, offline_banklist, fixed_package, custom_package } = this.state;
    
    return (
      <div className="LeftPanel">
        {/************************************************ Form ************************************************/}
        <Form onSubmit={this.submitForm} className="form_sty_select" >
          <div className="credit_purchase">
            <div className="header_title">
              <h2>Select Invoices</h2>
            </div>
            <Card className="things instant_sty instant_sty2">
            {
              this.props.due_invoices.map((due_invoice, due_invoice_index) => {
                if (due_invoice.payable == true){
                  return(
                    <Card.Grid key={due_invoice_index} style={gridStyle} onClick={this.onClickInvoice.bind(this)} data-invoice_no={due_invoice.invoice_no} data-id={due_invoice.id} className={selected.includes(due_invoice.invoice_no) && selected.length !== 0 ? 'addBorderActive' : null}>
                      <Row>
                        <Col span={6}><span className="packagesCredit" >{due_invoice.category} : {due_invoice.invoice_no}</span></Col>
                        <Col span={6}><span className="packagesCredit">Product : {due_invoice.product}</span></Col>
                        <Col span={4}><span className="creditmoney">RM {this.priceFormat(due_invoice.settlement_amount)}</span></Col>
                        <Col span={6}><span className="creationDate">{due_invoice.creation_date}</span></Col>

                      </Row>
                    </Card.Grid>
                  )
                }
                else {
                  return(
                    <Card.Grid key={due_invoice_index} style={gridStyle} className="addBorderInactive">
                      <Row>
                        <Col span={6}><span className="packagesCredit" >{due_invoice.category} : {due_invoice.invoice_no}</span></Col>
                        <Col span={6}><span className="packagesCredit">Product : {due_invoice.product}</span></Col>
                        <Col span={4}><span className="creditmoney">RM {this.priceFormat(due_invoice.settlement_amount)}</span></Col>
                        <Col span={6}><span className="creationDate">{due_invoice.creation_date}</span></Col>
                      </Row>
                    </Card.Grid>
                  )
                }
              })
            }
            </Card>
          </div>

          {/************************************************ Payment Method ************************************************/}
          <div className="header_title">
            <h2>Your Payment Method</h2>
          </div>

          <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={true} credit_disabled={this.props.credit_disabled} fpx_disabled={false} invoice_payment={true}
            payment_type={payment_type} payment_method={payment_method} credits={this.props.credits} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select}
            offline_banklist={offline_banklist} handlePaymentMethodChange={(value, option) => this.handlePaymentMethodChange(value, option)}
            onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)} pageName="DueInvoiceShowPage" allow_fpxb2c_overlimit={false} final_price={this.props.invoiceTotal} service_fee={this.props.invoiceServiceFee} dobw_disabled={this.props.due_invoices.length > 0 && !this.props.due_invoices[0].dobw_available} bank_type={this.state.bank_type}
          /> 

          <div className="after_select">
            <Button type="primary" htmlType="submit" disabled={this.state.selected.length == 0 || this.state.check_out_disabled }>CHECK OUT</Button>
          </div>

        </Form>
      </div>
    );
  }
}

const DueInvoiceShowPage = Form.create({ name: 'due_invoice' })(LeftPanel);
export default withRouter(DueInvoiceShowPage);