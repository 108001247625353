import axios from 'axios';
import { pdfjs } from 'react-pdf'; 

// PDF Worker
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

axios.defaults.xsrfCookieName = "CSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  response => response,
  error => {
      if (error.response.status === 401) {
        window.location = './users/sign_in' + window.location.hash
      }
      else{
        return Promise.reject(error);
      }
  }
);

export default axios;