import React, { Component } from 'react';
import { Collapse, Icon, Menu, Dropdown, Card, Button, Skeleton } from 'antd';
import $ from 'jquery'
import './index.css'
import moment from 'moment';

const { Panel } = Collapse;
const dateFormat = 'DD MMM YYYY';

class RightPanel extends Component {

  constructor(props){
    super(props);
    this.state = {
      
    }
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const pax_count = this.props.pax_count
    const compulsory_charges = this.props.compulsory_charges
    // const dta = this.props.collectDta ? this.props.dta : 0;
    const dta = this.props.collectDta ? 0 : this.props.dta;
    const dta_adult = this.props.dta_adult
    const dta_single_supp = this.props.dta_single_supp
    const dta_voucher_adult = this.props.dta_voucher_adult
    const dta_voucher_single_supp = this.props.dta_voucher_single_supp
    const dta_child = this.props.dta_child
    const dta_voucher_child = this.props.dta_voucher_child
    const total = this.props.total
    const deposit = this.props.deposit
    const single_supplement = this.props.single_supplement
    const voucher_paid = this.props.voucher_paid

    const departure_date = this.props.departure_date
    const cut_off_date = this.props.cut_off_date
    const price_category = this.props.price_category
    const prices = this.props.prices
    const voucher_prices = this.props.voucher_prices
    const foc_prices = this.props.foc_prices
    const dataList = this.props.dataList
    const loading = this.props.loading
    const service_fee = this.props.service_fee
    const payment_type = this.props.payment_type
    const final_price = this.props.final_price
    const single_supp = this.props.single_supp
    const total_single_supp = this.props.total_single_supp

    if (dataList !== undefined) {
      var charges = []
      dataList && dataList.compulsory_charges.map((charge, i) => {
        if (charge.type === "Pax"){
          charges.push(
            <div key={i}>
              <span>{charge.title} x <strong>{pax_count}</strong></span>
              <span>+ RM {this.priceFormat(charge.price * pax_count)}</span>
            </div>
          );
        }
        else {
          charges.push(
            <div key={i}>
              <span>{charge.title}</span>
              <span>+ RM {this.priceFormat(+charge.price)}</span>
            </div>
          );
        }
      })
    }
    else {
      var charges = []
    }

    if (prices && prices.length > 0){
      var adult_count = 0;
      var child_count = 0;
      var single_supp_count = 0;
      prices.map((price) => {
        if (price.name === "adult") {
          adult_count += price.quantity
        }
        else if (price.name === "single_supplement") {
          single_supp_count += price.quantity
        }
        else {
          child_count += price.quantity
        }
      })
    }
    else {
      var adult_count = 0;
      var child_count = 0;
      var single_supp_count = 0;
    }

    if (voucher_prices && voucher_prices.length > 0){
      var adult_voucher_count = 0;
      var child_voucher_count = 0;
      var single_supp_voucher_count = 0;
      voucher_prices.map((voucher) => {
        if (voucher.name === "adult") {
          adult_voucher_count += voucher.quantity
        }
        else if (voucher.name === "single_supplement") {
          single_supp_voucher_count += voucher.quantity
        }
        else {
          child_voucher_count += voucher.quantity
        }
      })
    }
    else {
      var adult_voucher_count = 0;
      var child_voucher_count = 0;
      var single_supp_voucher_count = 0;
    }

    if (payment_type == "public_bank" || payment_type == "public_bank_2") {
      var final_total = total + service_fee
      var final_deposit = deposit + service_fee
    }
    else {
      var final_total = total
      var final_deposit = deposit
    }
   
    return (
      <div className="RightPanel res_panel_warp">
        <Card title={[
          <div key={1} className="title">
            <h2>Summary</h2>
          </div>]}>
          <div className="dates">
            <p className="date_title">Tour</p>
            <p className="date_select">{dataList.title}</p>
          </div>
          <div className="dates">
            <p className="date_title">Category</p>
            <p className="date_select">{price_category}</p>
          </div>
          <div className="dates">
            <p className="date_title">Travelling Period</p>
            <p className="date_select">{departure_date !== '' ? departure_date : ''} - {departure_date !== '' ? moment(departure_date).startOf('day').add(dataList.duration - 1, 'days').format("YYYY-MM-DD") : ''}</p>
          </div>
          <div className="select_list">
            <p className="note_label">
            <strong>Note:</strong> { loading ? '' : !moment().isAfter(moment(cut_off_date).subtract(2, 'days').format("YYYY-MM-DD")) ? 'Only deposit required for this booking' : 'Full payment will be collected upon confirmation' }
            </p>

            <p className="date_title">Fare Breakdown</p>
            { 
              prices && prices.map((price, i) => { 
                return(
                  price['quantity'] > 0 ?
                    <div key={i} className="list_warp">
                      {
                        price['quantity'] > 1 ?
                        <React.Fragment>
                          <Collapse bordered={false}>
                            <Panel className="subtraction_collapse" showArrow={true} header={
                              <div className="list_warp">
                                <span>
                                  <span>
                                    {price['name'] === "child_with_bed" ? "Child with extra bed" : price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} x  
                                    {
                                      foc_prices && foc_prices.length === undefined && price['name'] === foc_prices.name ?
                                        <strong>&nbsp;{price['quantity'] + 1}</strong>
                                      : <strong>&nbsp;{price['quantity']}</strong>
                                    }
                                  </span>
                                </span> 
                                {
                                  foc_prices && foc_prices.length === undefined && price['name'] === foc_prices.name ?
                                    <span>RM {this.priceFormat((price['price'] * price['quantity']) + foc_prices.price)}</span>
                                  : <span>RM {this.priceFormat(price['price'] * price['quantity'])}</span>
                                }  
                              </div>
                            }>
                              <div>
                                <span>Price per pax</span>
                                <span>RM {this.priceFormat(price['price'])}</span>
                              </div>
                            </Panel>
                          </Collapse>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          {
                            foc_prices && foc_prices.length === undefined && price['name'] === foc_prices.name ?
                              <Collapse bordered={false}>
                                <Panel className="subtraction_collapse" showArrow={true} header={
                                  <div className="list_warp">
                                    <span>
                                      <span>
                                        {price['name'] === "child_with_bed" ? "Child with extra bed" : price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} x  
                                        <strong>&nbsp;{price['quantity'] + 1}</strong>
                                      </span>
                                    </span> 
                                    <span>RM {this.priceFormat((price['price'] * price['quantity']) + foc_prices.price)}</span>  
                                  </div>
                                }>
                                  <div>
                                    <span>Price per pax</span>
                                    <span>RM {this.priceFormat(price['price'])}</span>
                                  </div>
                                </Panel>
                              </Collapse>
                            : 
                              <div>
                                <span>
                                  <span>{price['name'] === "child_with_bed" ? "Child with extra bed" : price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} x <strong>{price['quantity']}</strong></span>
                                </span>                        
                                <span>RM {this.priceFormat(price['price'] * price['quantity'])}</span>
                              </div>
                          } 
                        </React.Fragment>
                      }
                    </div>
                  : 
                    <div key={i} className="list_warp">
                      {
                        foc_prices && foc_prices.length === undefined && price['name'] === foc_prices.name ?
                          <div>
                            <span>
                              <span>{price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} x <strong>1</strong></span>
                            </span>                        
                            <span>RM {this.priceFormat(price['price'])}</span>
                          </div>
                        : ''       
                      }
                    </div>
                )
              })
            }
            { 
              foc_prices && foc_prices.length === undefined ?
                <div className="list_warp">
                  {
                    <Collapse bordered={false}>
                      <Panel className="subtraction_collapse" showArrow={true} header={<div className="list_warp"><span>Free of Charge x <strong>1</strong></span><span>- RM {this.priceFormat(foc_prices.price)}</span></div>}>
                          <span>
                            <span>{foc_prices.name.charAt(0).toUpperCase() + foc_prices.name.substr(1).toLowerCase().replace(/_/g, " ")} x <strong>1</strong></span>
                          </span>                        
                          <span>- RM {this.priceFormat(foc_prices.price)}</span>
                      </Panel>
                    </Collapse>
                  }
                </div>
              : ''
            }
            { 
              voucher_prices && voucher_prices.length > 0 ?
                voucher_prices.map((price, i) => {
                  return(
                    price['quantity'] > 0 ?
                      <div key={i} className="list_warp">
                        {
                          <React.Fragment>
                            {
                              price['quantity'] > 1 ?
                                <Collapse bordered={false}>
                                  <Panel className="subtraction_collapse" showArrow={true} header={
                                    <div className="list_warp">
                                      <span>
                                        <span>
                                          {price['name'] === "child_with_bed" ? "Child with extra bed" : price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} (Voucher) x  
                                          {
                                            foc_prices && foc_prices.length === undefined && price['name'] === foc_prices.name ?
                                              <strong>&nbsp;{price['quantity'] + 1}</strong>
                                            : <strong>&nbsp;{price['quantity']}</strong>
                                          }
                                        </span>
                                      </span> 
                                      {
                                        foc_prices && foc_prices.length === undefined && price['name'] === foc_prices.name ?
                                          <span>RM {this.priceFormat((price['price'] * price['quantity']) + foc_prices.price)}</span>
                                        : <span>RM {this.priceFormat(price['price'] * price['quantity'])}</span>
                                      }  
                                    </div>
                                  }>
                                    <div>
                                      <span>Price per pax</span>
                                      <span>RM {this.priceFormat(price['price'])}</span>
                                    </div>
                                  </Panel>
                                </Collapse>
                              : 
                                <div>
                                  <span>
                                    <span>{price['name'] === "child_with_bed" ? "Child with extra bed" : price['name'].charAt(0).toUpperCase() + price['name'].substr(1).toLowerCase().replace(/_/g, " ")} (Voucher) x <strong>{price['quantity']}</strong></span>
                                  </span>                        
                                  <span>RM {this.priceFormat(price['price'] * price['quantity'])}</span>
                                </div>
                            } 
                          </React.Fragment>
                        }
                      </div>
                    : ''
                  )
                })
              : ''
            }
            {/*{
              single_supplement > 0 ?
              <div className="list_warp">
                <span>Single Supplement</span>
                <span>RM {this.priceFormat(single_supplement)}</span>
              </div>
              : ''
            }*/}
            {
              compulsory_charges > 0 ?
              <div className="list_warp">
                <Collapse bordered={false}>
                  <Panel className="subtraction_collapse" showArrow={true} header={<div className="list_warp"><span>Compulsory Charges</span><span>+ RM {this.priceFormat(compulsory_charges)}</span></div>}>
                      { charges }
                  </Panel>
                </Collapse>
              </div> : ''
            }
            {
              dta > 0 ?
              <div className="list_warp">
                <Collapse bordered={false}>
                  <Panel className="subtraction_collapse" showArrow={true} header={<div className="list_warp"><span>DTA</span><span>- RM {this.priceFormat(dta)}</span></div>}>
                      {
                        this.priceFormat(dta_adult) > 0  ?
                        <div>
                          <span>DTA Adult x <strong>{adult_count}</strong></span>
                          <span>- RM {this.priceFormat(dta_adult)}</span>
                        </div> : ''
                      }
                      {
                        this.priceFormat(dta_voucher_adult) > 0  ?
                        <div>
                          <span>DTA Adult (Voucher) x <strong>{adult_voucher_count}</strong></span>
                          <span>- RM {this.priceFormat(dta_voucher_adult)}</span>
                        </div> : ''
                      }
                      {
                        this.priceFormat(dta_single_supp) > 0  ?
                        <div>
                          <span>DTA Single Supplement x <strong>{single_supp_count}</strong></span>
                          <span>- RM {this.priceFormat(dta_single_supp)}</span>
                        </div> : ''
                      }
                      {
                        this.priceFormat(dta_voucher_single_supp) > 0  ?
                        <div>
                          <span>DTA Single Supplement (Voucher) x <strong>{single_supp_voucher_count}</strong></span>
                          <span>- RM {this.priceFormat(dta_voucher_single_supp)}</span>
                        </div> : ''
                      }
                      {
                        this.priceFormat(dta_child) > 0  ?
                        <div>
                          <span>DTA Child x <strong>{child_count}</strong></span>
                          <span>- RM {this.priceFormat(dta_child)}</span>
                        </div> : ''
                      }
                      {
                        this.priceFormat(dta_voucher_child) > 0  ?
                        <div>
                          <span>DTA Child (Voucher) x <strong>{child_voucher_count}</strong></span>
                          <span>- RM {this.priceFormat(dta_voucher_child)}</span>
                        </div> : ''
                      }
                  </Panel>
                </Collapse>
              </div> : ''
            }

            {
              voucher_paid > 0 ?
              <div className="list_warp">
                <span>Voucher Paid</span>
                <span>- RM {this.priceFormat(voucher_paid)}</span>
              </div>
              : ''
            }

            {
              (payment_type == "public_bank" || payment_type == "public_bank_2") ?
                <div className="list_warp">
                  <span>Service Fees</span>
                  <span>RM {service_fee > 0 ? this.priceFormat(service_fee) : this.priceFormat(service_fee)}</span>
                </div>
              : ''
            }
          </div>
          <div className="select_list">
            <div className="list_warp">
              <div className="total">
                <span>Grand Total</span><span className="total_summary">RM {this.priceFormat(final_total)}</span>
              </div>
            </div>
          </div>
          
          <div className="total">
            {
              payment_type == 'pay_later' ?
                <React.Fragment>
                  <p className="pay_later">Please note that once you have opted for the Book Now, Pay Later option, your booking will be secured up to 72 hours from the time of booking.</p>
                  <p className="deposit_summary">Deposit needed before {moment().add(3, 'days').format('DD MMM YYYY')}: <strong>RM {this.priceFormat(final_deposit)}</strong></p>
                  <p className="balance_note_label">Final payment of <strong>RM {this.priceFormat(total - final_deposit)}</strong> is due before <strong>{cut_off_date !== '' ? moment(cut_off_date).format(dateFormat) : ''}</strong></p>
                </React.Fragment>
              : !moment().isAfter(moment(cut_off_date).subtract(2, 'days').format("YYYY-MM-DD")) ? 
                <React.Fragment>
                  <p className="deposit_summary">Deposit due now: <strong>RM {this.priceFormat(final_deposit)}</strong></p>
                  <p className="balance_note_label">Final payment of <strong>RM {this.priceFormat(total - final_deposit)}</strong> is due by <strong>{cut_off_date !== '' ? moment(cut_off_date).format(dateFormat) : ''}</strong></p>
                </React.Fragment>
              : ''
            }
          </div>
        </Card>
      </div>
    );
  }
}

export default RightPanel;
