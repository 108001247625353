import React, { Component } from 'react';
import { Form, Input, Button, Icon, DatePicker, InputNumber, AutoComplete, Select } from 'antd';
import moment from 'moment';
import Fuse from 'fuse.js';
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs';
import API from '../../../api';
import countryList from 'react-select-country-list';
import ReactMarkdown from 'react-markdown/with-html';

const { Option } = Select;
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';

function onChange(value) {
  console.log('changed', value);
}

function renderOption(item) {
  return (
    // item.city_id ? 
    //   <Option key={item.city_id} value={item.city_name + ', ' + item.country_name + ', ' + item.city_id + ', ' + item.country_code}>
    //     {item.city_name + ', ' + item.country_name}
    //   </Option>
    // : <Option key={item.hotel_id} value={item.hotel_name}>
    //     {item.hotel_name}
    //   </Option>
    <Option key={item.city_id} value={item.city_name + ', ' + item.country_name + ', ' + item.city_id + ', ' + item.country_code}>
      {item.city_name + ', ' + item.country_name}
    </Option>
  );
}

class Hotel extends Component {

    constructor(props) {
      super(props);
      const hotel_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

      this.state = {
        showItems: 100,
        hotelData: hotel_params['room_datas'] != undefined ? hotel_params['room_datas'] : [{ adult: 2, child: '', ages: [{ age: '', visible: false }, { age: '', visible: false }, { age: '', visible: false }, { age: '', visible: false }] }],
        from_location: [],
        place: hotel_params['search_criteria'] != undefined ? hotel_params['search_criteria']['place'] : '',
        nationality: hotel_params['search_criteria'] != undefined ? hotel_params['search_criteria']['country'] : 'MY',
        city_id: hotel_params['search_criteria'] != undefined ? hotel_params['search_criteria']['city_id'] : '',
        country_code: hotel_params['search_criteria'] != undefined ? hotel_params['search_criteria']['country_code'] : '',
        check_in_date: hotel_params['search_criteria'] != undefined ? moment(hotel_params['search_criteria']['check_in_date']) : moment(),
        check_out_date: hotel_params['search_criteria'] != undefined ? moment(hotel_params['search_criteria']['check_out_date']) : '',
        dateDisable: moment().startOf('day').add(1, 'days'),
        defDate: '',
        destinations: [],
        validationErrors: [],
        fuse: null,
        hotel_fuse: null,
      };
      this.submitForm = this.submitForm.bind(this);
      moment.updateLocale(moment.locale(), { invalidDate: '' });
    }

    componentDidMount() {
      this.countryFetch();
      // this.hotelFetch();
    }

    countryFetch() {
      let url = homepage_new.tbo_cities_url;
      // let url = './api/v1/hotels/cities';
      var that = this;
      // console.log(params);

      API.get(url)
        .then(function (response) {
          // console.log(response.data.cities, '------------res country');
          that.setState({
            fuse: new Fuse(response.data, {
              shouldSort: true,
              threshold: 0.3,
              location: 0,
              distance: 10,
              maxPatternLength: 32,
              minMatchCharLength: 1,
              keys: [
                {
                  name: "city_id",
                  weight: 0.4
                },
                {
                  name: "city_name",
                  weight: 0.3
                },
                {
                  name: "country_code",
                  weight: 0.2
                },
                {
                  name: "country_name",
                  weight: 0.1
                }
              ]
            })
          })
        })
        .catch(function (error) {
            console.log(error);
        })
        .then(function () {
            // always executed
        });
    }

    // hotelFetch() {
    //   let url = './api/v1/hotels/hotels';
    //   var that = this;
    //   // console.log(params);

    //   API.get(url)
    //     .then(function (response) {
    //       // console.log(response.data.cities, '------------res country');
    //       that.setState({
    //         hotel_fuse: new Fuse(response.data.hotels, {
    //           shouldSort: true,
    //           threshold: 0.3,
    //           location: 0,
    //           distance: 10,
    //           maxPatternLength: 32,
    //           minMatchCharLength: 1,
    //           keys: [
    //             {
    //               name: "hotel_name",
    //               weight: 0.4
    //             },
    //             {
    //               name: "hotel_id",
    //               weight: 0.3
    //             },
    //           ]
    //         })
    //       })
    //     })
    //     .catch(function (error) {
    //         console.log(error);
    //     })
    //     .then(function () {
    //         // always executed
    //     });
    // }

    /****************************** add Form *******************************/
    addRoom() {
      this.setState({
        hotelData: [...this.state.hotelData, { adult: 2, child: '', ages: [{ age: '', visible: false }, { age: '', visible: false }, { age: '', visible: false }, { age: '', visible: false }] }],
        room_count: this.state.room_count + 1
      }, () => { this.handleRoomCount(); })
    }

    handleRoomDataChange(index, type, e) {
      if (type == "child"){
        let ages = this.state.hotelData[index].ages
        for (var k = 0; k < ages.length; k++) {
          if (k < e) {
            ages[k]['visible'] = true;
          }
          else {
            ages[k]['visible'] = false;
          }
        }
      }

      this.state.hotelData[index][type] = e;
      this.setState({
        hotelData: this.state.hotelData
      })
    }

    handleChildAge(index, k, value) {
      this.state.hotelData[index]["ages"][k]["age"] = value;
      this.setState({
        hotelData: this.state.hotelData
      })
    }

    /****************************** remove add Form *******************************/
    removeRoom(index) {
      this.state.hotelData.splice(index, 1);
      this.setState({
        hotelData: this.state.hotelData,
        room_count: this.state.room_count - 1
      }, () => { this.handleRoomCount(); })
    }

    handleRoomCount = () => {
      if (this.state.room_count >= 6) {
        this.setState({
            add_room_disabled: true
        })
      }
      else {
        this.setState({
            add_room_disabled: false
        })
      }
    }

    /****************************** submitForm *******************************/
    submitForm(event) {
      const { place, nationality, city_id, country_code, check_in_date, check_out_date, hotelData, validationErrors } = this.state;
      // event.preventDefault();

      let outputJson = {
        search_criteria: { 
          place: place,
          city_id: city_id,
          country_code: country_code,
          check_in_date: moment(check_in_date).format(dateFormat),
          check_out_date: moment(check_out_date).format(dateFormat),
          sort_by: '',
          country: nationality,
          rooms: hotelData.length
        },
        room_datas: hotelData
      };

      console.log("This is the outputJson", outputJson);
      console.log(qs.stringify(outputJson), '----qs')
      
      this.props.form.validateFieldsAndScroll((err, values) => {
        if (place === "" || check_in_date === "" || check_out_date === "" || !err === false) {
          if (!err) {
            console.log('Received values of form: ', values);
          }
          if (place === "") {
            validationErrors.location = "* Please select location.";
            this.setState({ validationErrors: validationErrors })
          } 
          else {
            validationErrors.location = "";
            this.setState({ validationErrors: validationErrors })
          }
          if (check_in_date === "") {
            validationErrors.check_in_out = "* Please select check in date.";
            this.setState({ validationErrors: validationErrors })
          } 
          else {
            validationErrors.check_in_out = "";
            this.setState({ validationErrors: validationErrors })
          }
          if (check_out_date === "") {
            validationErrors.check_in_out = "* Please select check out date.";
            this.setState({ validationErrors: validationErrors })
          } 
          else {
            validationErrors.check_in_out = "";
            this.setState({ validationErrors: validationErrors })
          }
        }
        else {
          this.props.history.push({
            pathname: '/hotels',
            // search: "?" + formurlencoded(outputJson, { skipIndex: true }),
            search: "?" + qs.stringify(outputJson)
          });
        }
      });
    }

    handleShowMore() {
      this.setState({
        showItems: this.state.showItems >= this.state.items.length ? this.state.showItems : this.state.showItems + 10
      })
    }

    /****************************** handle Search autocomplate *******************************/
    handleSearch = value => {
      // var destination_array = [this.state.fuse.search(value).slice(0, 20), this.state.hotel_fuse.search(value).slice(0, 20)]

      this.setState({
        destinations: this.state.fuse.search(value).slice(0, 20)
        // destinations: destination_array.flat()
      })
    };

    handleLocationSelect(e) {
      // console.log(e, 'location')
      var res = e.split(", ");
      var country_name = res[1] ? ', ' + res[1] : ''
      this.setState({ place: res[0] + country_name, city_id: res[2], country_code: res[3] })
    }

    handleNationalitySelect = e => {
      // console.log(e, 'nationality')
      this.setState({ nationality: e })
    }

    handleDateChange(type, e) {
      this.setState({ 
        check_in_date: moment(e[0]).format(dateFormat),
        check_out_date: moment(e[1]).format(dateFormat),
        dateDisable: moment(e[0], dateFormat).add(1, 'days'),
        defDate: moment(e[0], dateFormat) 
      })
    }

    render() {
      const hotel_datas = this.state.hotelData.slice(0, this.state.hotelData.length);
      const { getFieldDecorator } = this.props.form;
      // console.log(hotel_datas, '---------hotel_datas')

      return (
        <div className="Hotel" >
          <form onSubmit={this.submitForm} className="form_warp">
            <div className="form_1">
              <div className="form_layout">
                <label>Where to Stay ?</label>
                <AutoComplete
                  style={{ width: 200 }}
                  dataSource={this.state.destinations.map(renderOption)}
                  onSearch={this.handleSearch}
                  placeholder="Search locations, hotels.."
                  value={this.state.place}
                  onChange={(e) => this.handleLocationSelect(e)}
                  disabled={(this.state.fuse === null) ? true : false}
                  // disabled={(this.state.hotel_fuse === null) ? true : false}
                />
                <p className="error">{this.state.validationErrors.location}</p>
              </div>
              <div className="form_layout">
                <label>Check In - Out</label>
                <RangePicker
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                  }}
                  defaultValue={[this.state.check_in_date, this.state.check_out_date]}
                  disabledDate={current => {
                      return current < moment().startOf('day').add(1, "days");
                  }}
                  format={dateFormat}
                  onChange={(e) => this.handleDateChange('check_in_out', e)}
                />
                <p className="error">{this.state.validationErrors.check_in_out}</p>
              </div>
              {/*<div className="form_layout">
                <label>Check-In</label>
                <DatePicker
                    defaultValue={this.state.check_in_date}
                    format={dateFormat}
                    disabledDate={current => {
                        return current < moment().startOf('day').add(1, "days");
                    }}
                    onChange={(e) => this.handleDateChange('check_in', e)}
                />
              </div>
              <div className="form_layout">
                <label>Check-Out</label>
                <DatePicker
                    defaultValue={this.state.check_out_date}
                    format={dateFormat}
                    disabledDate={current => {
                        return current < this.state.dateDisable;
                    }}
                    onChange={(e) => this.handleDateChange('check_out', e)}
                />
              </div> */}
              <div className="form_layout">
                <label>Nationality</label>
                <Select showSearch placeholder="Select Nationality"
                  onChange={this.handleNationalitySelect}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={onSearch}
                  defaultValue={this.state.nationality}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    countryList().getData().map((ctry) => {
                      return (
                        <Option key={ctry.label} value={ctry.value}>{ctry.label}</Option>
                      )
                    })
                  }
                </Select>
              </div>
            </div>
            { hotel_datas.map((data, index) => {
              return (
                <div className="form_2" key={index}>
                  <div className="room_layout">
                      <br/>Room {index+1}
                  </div>
                  <div className="form_layout2">
                      <label>Adult</label>
                      <InputNumber min={2} max={6} defaultValue={data.adult} onChange={(e) => this.handleRoomDataChange(index, 'adult', e)} />
                  </div>
                  <div className="form_layout2">
                      <label>Child</label>
                      <InputNumber placeholder="Child" min={0} max={4} defaultValue={data.child} onChange={(e) => this.handleRoomDataChange(index, 'child', e)} />
                  </div>
                  {/*<div className="form_layout2">
                      <label>Child Without Bed <small>(1  -  18)</small></label>
                      <InputNumber min={0} max={2} defaultValue={data.child_no_bed} onChange={(e) => this.handleRoomDataChange(index, 'child_no_bed', e)} />
                  </div>*/}

                  {
                    Object.keys(data.ages).map((data_age, k) => 
                      data.ages[data_age].visible === true ?
                        <div key={k} className="form_layout3">
                          <label>Child Age </label>
                          <InputNumber placeholder="Age" min={1} max={18} defaultValue={data.ages[data_age].age} onChange={(e) => this.handleChildAge(index, k, e)} />
                        </div>
                      : null
                    )
                  }
                  
                  {
                    index > 0 ? (
                      <div className="close_btn">
                        <Button type="primary" shape="circle" onClick={(e) => this.removeRoom(e)} icon="close"></Button>
                      </div>
                    ) : (null)
                  }
                  <div className="clear"></div>
                </div>
              )
            })}
            <div className="add_btn">
              <Button type="primary" icon="search" size="default" className="search_btn" disabled={this.state.add_room_disabled}
                  onClick={(e) => this.addRoom(e)}>
                  Add Another Room
              </Button>
            </div>

            <div className="btn_submit">
              <Button type="primary" onClick={this.submitForm} icon="search" size="default" className="search_btn">
                      Search 
              </Button>
            </div>
          </form>

        </div>
      );
    }
}

const HotelSearchPage = Form.create({ name: 'hotel_search' })(Hotel);
export default withRouter(HotelSearchPage);
