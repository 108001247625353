import React, { Component } from 'react';
import { Card, Icon, Radio, Collapse, Slider, InputNumber, Checkbox, Rate } from 'antd';
import './index.css';
import withRouter from "react-router-dom/es/withRouter";

const { Meta } = Card;
const { Panel } = Collapse;

function callback(key) {
    console.log(key);
}

class LeftPanel extends Component {
   constructor(props) {
      super(props);
      this.state = {
         value: 1,
         inputValue: 1,
         cutValue: 100,
         addValue: 5000,
         onBeforeValue: '0',
         onAfterValue: '0'
      }
   }

   onChangeValue = value => {
      this.setState({
         onBeforeValue: value,
      });
   };

   onAfterChange = value => {
      console.log('onAfterChange: ', value);
      this.setState({
         onAfterValue: value
      })
   }

   onChange = e => {
      console.log('radio checked', e.target.value);
      this.setState({
         value: e.target.value,
      });
   };

   sortByOnChange = e => {
      console.log("radio checked", e.target.value);
      this.props.sortByCallback(e.target.value);
   };

  rangeDuration(start, end) {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
  }

   render() {
      const { inputValue, addValue, cutValue, onAfterValue, onBeforeValue } = this.state;
      const addNum = onAfterValue[1];
      const beforeNum = onBeforeValue[0];
      const marks = this.rangeDuration(0, this.props.duration);

      return (
         <div id="LeftPanel">
            <div className="sort_result">
               <Card
                  actions={[
                     <Radio.Group onChange={this.sortByOnChange} value={this.props.sortBy}>
                        <Radio value='lowest_price'>Lowest Price</Radio>
                        <Radio value='highest_price'>Highest Price</Radio>
                        {/* <Radio value={3}>Review Score</Radio>
                                <Radio value={4}>Popularity</Radio> */}
                     </Radio.Group>
                  ]}
               >
                  <Meta
                     title="Sort Results"
                     description="Sort your search results by"
                  />
               </Card>
            </div>
            <div className="filter_result">
                <Card
                    actions={[
                        <Collapse
                            defaultActiveKey={['1']}
                            onChange={callback}
                            expandIconPosition="right"
                        >
                            <Panel header="Duration (Day)" key="1">
                              <Slider marks={marks} min={0} max={this.props.duration} value={this.props.filterByDuration} onChange={this.props.handleDurationChange} onAfterChange={this.props.handleDurationChange} />
                            </Panel>
                            <Panel header="Category" key="2">
                              <div className="checkbox_warp">
                                <Checkbox.Group options={this.props.categories} value={this.props.filterByCategory} onChange={this.props.handleCategoryChange} />
                              </div>
                            </Panel>
                        </Collapse>
                    ]}
                >
                    <Meta
                        title={[
                            <div className="title">
                                <p>Filter Results</p>
                                {/* <p><small>Reset Filter</small></p> */}
                            </div>
                        ]}
                        description="Showing results based on categories"
                        extra={<a href="#">More</a>}
                    />
                </Card>
            </div>
         </div>
      );
   }
}

export default withRouter(LeftPanel);
