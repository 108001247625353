import React, { Component } from 'react';
import { Collapse, Card, Button, Skeleton, Icon } from 'antd';
import './index.css'

const { Panel } = Collapse;

const listData = [
  { title: 'RM23.00/Day X 5day', description: 'RM200' },
  { title: 'Device x 1', description: 'Free' },
  { title: 'Shiping', description: '-' },
  { title: 'Tax', description: '-' }
]

class RightPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading: false
    }
  }

  priceFormat(value) {
    return value === undefined ? 0.00 : parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const loading = this.props.loading;

    if (this.props.data != null) {
      var total = this.props.payment_amount
    }
    else {
      var total = 0
    }

    if (this.props.payment_type == "public_bank" || this.props.payment_type == "public_bank_2") {
      var final_total = total + this.props.service_fee
    }
    else {
      var final_total = total
    }

    return (
      <div className="RightPanel res_panel_warp">
        {
          loading ? (
            <Skeleton active />
          ) : (
              <Card title={[
                <div className="title" key={0}>
                  <h2>Summary</h2>
                </div>]}>
                <div className="dates">
                  <p className="date_title">Package</p>
                  <p className="date_select">{this.props.data.code}</p>
                </div>
                {
                  (this.props.payment_type == "public_bank" || this.props.payment_type == "public_bank_2") ?
                    <div className="select_list">
                      <div className="list_warp">
                        
                          <span>Service Fees</span><span>{this.props.data.currency} {this.props.service_fee > 0 ? this.priceFormat(this.props.service_fee) : this.priceFormat(this.props.service_fee)}</span>
                        
                      </div>
                    </div>
                  : ''
                }

                <div className="select_list">
                  <div className="list_warp">
                    <div className="total">
                      <span>Total</span><span className="total_summary">{this.props.data.currency} {final_total > 0 ? this.priceFormat(final_total) : this.priceFormat(final_total)}</span>
                    </div>
                  </div>
                </div>

              </Card>
            )}
      </div>
    );
  }
}

export default RightPanel;
