import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, Statistic, Spin } from 'antd';

const StatisticCards = props => {
  const rowStyleFormats = { gutter: [16, 16] }
  const colStyleFormats = {
    lg: { span: 6 },
    md: { span: 12 },
    sm: { span: 12 },
    xs: { span: 24 },
  }

  const { isLoading, totalPax, totalAmount, totalOutstandingAmount, amountUnit } = props

  return (
    <div >
      <Row {...rowStyleFormats}>
        <Col {...colStyleFormats}>
          <Card>
            {
              isLoading ? 
                <Spin />
              : 
                <Statistic
                  title='Total Pax'
                  value={totalPax}
                  precision={0}
                />
            }
          </Card>
        </Col>
        <Col {...colStyleFormats}>
          <Card>
            {
              isLoading ? 
                <Spin />
              : 
                <Statistic
                  title='Total Amount'
                  value={totalAmount}
                  precision={2}
                  prefix= {amountUnit}
                />
            }
          </Card>
        </Col>
        <Col {...colStyleFormats}>
          <Card>
            {
              isLoading ? 
                <Spin />
              : 
                <Statistic
                  title='Total Outstanding Amount'
                  value={totalOutstandingAmount}
                  precision={2}
                  prefix={amountUnit}
                />
            }
          </Card>
        </Col>
      </Row>
    </div>
  );
};

StatisticCards.propTypes = {
  totalPax: PropTypes.number,
  totalAmount: PropTypes.number,
  totalOutstandingAmount: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  amountUnit: PropTypes.string,
};

StatisticCards.defaultProps = {
  totalPax: 0,
  totalAmount: 0.0,
  totalOutstandingAmount: 0.0,
  isLoading: true,
  amountUnit: 'RM'
}

export default StatisticCards;
