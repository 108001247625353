import React, { Component } from 'react';
import { Collapse, Card, Button, Skeleton, Icon } from 'antd';
import './index.css'

const { Panel } = Collapse;

const listData = [
  { title: 'RM23.00/Day X 5day', description: 'RM200' },
  { title: 'Device x 1', description: 'Free' },
  { title: 'Shiping', description: '-' },
  { title: 'Tax', description: '-' }
]

class RightPanel extends Component {

  componentDidMount() {

  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const data = this.props.dataList;
    const total_day = this.props.totalDay;
    const quantity = this.props.totalQty;
    const depart_date = this.props.departDate;
    const return_date = this.props.returnDate;
    const pickup_location = this.props.pickupLocation;
    const service_fee = this.props.service_fee;
    const loading = this.props.loading;
    // console.log(loading, '-------loading sum')
    // console.log(data, '--------data-wifi-right')

    var price_day = data.price * total_day;
    var price_qty = price_day * quantity;
    var dta_price = this.props.collectDta ? 0 : ((data.dta * total_day) * quantity); 
    var final_price =  price_qty - dta_price;
    

    if (this.props.payment_type == "public_bank" || this.props.payment_type == "public_bank_2") {
      var final_total = final_price + service_fee
    }
    else {
      var final_total = final_price
    }

    return (
      <div className="RightPanel res_panel_warp">
        {
          loading ? (
            <Skeleton active />
          ) : (
          <Card title={[
            <div className="title">
              <h2>Summary</h2>
            </div>]}>
            <div className="dates">
              <p className="date_title">Wifi Package(s)</p>
              <p className="date_select">{data.name} Wifi</p>
            </div>
            <div className="dates">
              <p className="date_title">Pick Up/Return Location</p>
              <p className="date_select">{pickup_location !== undefined ? pickup_location : '-'}</p>
            </div>
            <div className="dates">
              <p className="date_title">Pick Up/Return Date</p>
              <p className="date_select">{depart_date} - {return_date}</p>
            </div>
            <div className="select_list">
              <p className="note_label"><strong>Note:</strong> Full payment required for this booking.</p>
              <p className="date_title">Fare Breakdown</p>
              <div className="list_warp">
                <span>{data.category}</span>
                <span>{data.data_rules}</span>
              </div>
              <div className="list_warp">
                <span>RM {this.priceFormat(data.price)} / Day x {total_day} Day(s)</span>
                <span>RM {price_day > 0 ? this.priceFormat(price_day) : this.priceFormat(0)}</span>
              </div>
              <div className="list_warp">
                <span>Device Quantity</span>
                <span>x {quantity > 0 ? quantity : 0}</span>
              </div>
              {
                dta_price > 0 ?
                  <div className="list_warp">
                    <div className="subtraction_collapse">  
                      <Collapse bordered={false} expandIconPosition="left" expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />} >
                        <Panel header={
                          <div className="list_warp fare_info">
                            <span>Subtraction</span>
                            <span>- RM {this.priceFormat(dta_price)}</span>
                          </div>
                        }>
                        {
                          dta_price > 0 ?
                          <div>
                            <span>DTA <strong>RM {dta_price > 0 ? this.priceFormat(+data.dta) : this.priceFormat(0)}</strong> x <strong>{total_day > 0 ? total_day : 0}</strong> Day(s) x <strong>{quantity > 0 ? quantity : 0}</strong> Device(s)</span>
                            {/* <span>RM {dta_price > 0 ? dta_price : 0}</span> */}
                          </div> : ''
                        }
                        </Panel>
                      </Collapse>
                    </div>
                  </div> 
                : ''
              }
              {
                (this.props.payment_type == "public_bank" || this.props.payment_type == "public_bank_2") ?
                  <div className="list_warp">
                    <span>Service Fees</span>
                    <span>RM {service_fee > 0 ? this.priceFormat(service_fee) : this.priceFormat(service_fee)}</span>
                  </div>
                : ''
              }
            </div>



            <div className="select_list">
              <div className="list_warp">
                <div className="total">
                  <span>Grand Total</span><span className="total_summary">RM {final_total > 0 ? this.priceFormat(final_total) : this.priceFormat(final_total)}</span>
                </div>
              </div>
            </div>

          </Card>
        )}
      </div>
    );
  }
}

export default RightPanel;
