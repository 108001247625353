import React, { Component } from "react";
import { Card, Icon, Radio, Collapse, Slider, InputNumber, Checkbox, Switch, Row, Col} from "antd";
import "./index.css";
import withRouter from "react-router-dom/es/withRouter";
import gd from '../../../../../images/gd.png';
import fs from '../../../../../images/fs.png';
import gd_domestic from '../../../../../images/gd_domestic.png';
import gd_signature from '../../../../../images/gd_signature.png';
import cruise from '../../../../../images/cruise.png';
import fly_cruise from '../../../../../images/fly_cruise.png';
import premium from '../../../../../images/premium.png';
const { Meta } = Card;
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

function onChange(value) {
  console.log("onChange: ", value);
}

function onAfterChange(value) {
  console.log("onAfterChange: ", value);
}

function onChangeBox(checkedValues) {
  console.log("checked = ", checkedValues);
}

const facilities = [
  "Wifi",
  "Baggage",
  "Meal",
  "Power / USB port",
  "In-flight entertainment"
];
const airline = ["Airasia", " Max Airline"];

class LeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      inputValue: 1,
      cutValue: 100,
      addValue: 5000,
      onBeforeValue: "0",
      onAfterValue: "0"
    };
  }

  onChangeValue = value => {
    this.setState({
      onBeforeValue: value
    });
  };

  onAfterChange = value => {
    console.log("onAfterChange: ", value);
    this.setState({
      onAfterValue: value
    });
  };

  onAfterChange = value => {
      this.setState({
          addValue: value
      });
  };

  goBack() {
    this.props.history.go(-1);
  }

  onChange = e => {
      console.log('radio checked', e.target.value);
      this.setState({
          value: e.target.value,
      });
  };

  sortByOnChange = e => {
    console.log("radio checked", e.target.value);
    this.props.sortByCallback(e.target.value);
  };

  render() {
    const data = this.props.detail

    const {
      inputValue,
      addValue,
      cutValue,
      onAfterValue,
      onBeforeValue,
      dataJson
    } = this.state;
    const addNum = onAfterValue[1];
    const beforeNum = onBeforeValue[0];
    const { match, location, history } = this.props;
    console.log(onBeforeValue, '-------onBeforeValue')

    const categories = [];
    const departure_locations = [];
    data && data.map((x,i) => {
      categories.push({name: x.category, icon: x.category === "FS" ? fs : x.category === "GD Standard" ? gd : x.category === "GD Premium" ? premium : x.category == "GD Domestic" ? gd_domestic : x.category == "GD Signature" ? gd_signature : x.category === "GD FLY CRUISE" ? fly_cruise : x.category.includes("CRUISE") ? cruise : x.category.includes("GD In Depth") ? gd  : ""});
      departure_locations.push(x.departure_locations)
    })
    
    const filtered_locations = [...new Set(departure_locations.flat())];
    
    function listOf(a, param){
        return a.filter(function(item, pos, array){
            return array.map(function(mapItem){ return mapItem[param]; }).filter(Boolean).indexOf(item[param]) === pos;
        }).sort((a, b) => a[param].toString().localeCompare(b[param]))
    }

    return (
      <div id="LeftPanel">
        <div className="sort_result">
          <Card
            actions={[
                    <Radio.Group onChange={this.sortByOnChange} value={this.props.sortBy}>
                        <Radio value='lowest_price'>Lowest Price</Radio>
                        <Radio value='highest_price'>Highest Price</Radio>
                        {/* <Radio value={3}>Review Score</Radio>
                        <Radio value={4}>Popularity</Radio> */}
                    </Radio.Group>
                ]}
            >
            <Meta
              title="Sort Results"
              description="Sort your search results by"
            />
          </Card>
        </div>
        
        {/*
        <div className="filter_result">
          <Card
            actions={[
              <Collapse
                defaultActiveKey={["1"]}
                onChange={callback}
                expandIconPosition="right"
              >
                <Panel header="Category" key="1">
                  <div className="checkbox_warp">
                   <Checkbox.Group
                      style={{ width: '100%' }}
                      // options={listOf(categories, 'name').map((category) => { return category.name })}
                      // checked={true}
                      // defaultValue={filteredCategories}
                      value={this.props.filterByCategory}
                      onChange={this.props.handleCategoryChange}
                    >
                    {
                      listOf(categories, 'name').map((category,i) => {
                        return(
                          <Row
                            style={{marginTop: 10}}
                          >
                            <Col>
                              <Checkbox
                                value={`${category.name}`}>
                                  {
                                    homepage_new.suka === 'false' ?
                                    <img
                                      style={{width:'8%',height:'100%'}}
                                      alt="logo"
                                      src={category.icon}
                                      className="img_sty"
                                    /> : ''
                                  }
                                  &nbsp; {category.name}
                              </Checkbox>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </Checkbox.Group>
                  </div>
                </Panel>

                <Panel header="EX-END" key="2">
                  <div className="checkbox_warp">
                    <Checkbox.Group
                      style={{ width: '100%' }}
                      // options={listOf(categories, 'name').map((category) => { return category.name })}
                      // checked={true}
                      // defaultValue={filteredCategories}
                      value={this.props.filterByDeptLocation}
                      onChange={this.props.handleDeptLocationChange}
                    >
                      {
                        filtered_locations.map((location,i) => {
                          return(
                            <Row
                              style={{marginTop: 10}}
                            >
                              <Col>
                                <Checkbox
                                  value={`${location}`}>
                                  {location}
                                </Checkbox>
                              </Col>
                            </Row>
                          )
                        })
                      }
                    </Checkbox.Group>
                  </div>
                </Panel>
              </Collapse>
            ]}
          >
            <Meta
              title={[
                <div className="title">
                  <p>Filter Results</p>
                </div>
              ]}
              description="Showing results based on"
              extra={<a href="#">More</a>}
            />
          </Card>
        </div>
        */}
      </div>
    );
  }
}

export default withRouter(LeftPanel);
