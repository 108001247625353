import React, { Component } from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';

class Explore extends Component {

  render() {

    const tab = this.props.tabs[0];
    // console.log(tab, '---------cover-e')

    const settings = {
      className: "slider variable-width",
      infinite: true,
      arrows: false,
      dots: false,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 770,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 625,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
          }
        },
       

      ]
    };

    return (
      <div id="Explore">
        <div className="content_title">
          <h1>{this.props.title}</h1>
          {/* <p>In publishing and graphic design, Lorem ipsum</p> */}
        </div>
        <div className="slider">
          <Slider {...settings}>
            {
              tab.cards.map((card) => {
                return (
                  <div className="card_warp">
                    <div className="img_warp"> 
                    {
                      card.link ? 
                      (<a href={`${card.link}`} target="_blank"><img alt={card.cover} src={card.cover} /></a>) 
                      : 
                      (<img alt={card.cover} src={card.cover} />)
                    }
                    </div>
                    {/* <div className="content_warp">
                      <p>{tab.title}</p>
                      <p>{tab.description}</p>
                    </div> */}
                  </div>
                )
              })
            }
          </Slider>
        </div>
        <div className="clear"></div>

      </div>
    );
  }
}

export default Explore;
