import React, { Component } from 'react';
import { Table, Divider, Tag, Typography, Modal, Dropdown, Button, Menu, Form, Input, Radio, Select, DatePicker, Upload, Icon, Checkbox, message} from 'antd';
import MenuBooking from '../menu';
import { Link, withRouter} from 'react-router-dom';
import API from '../../../../api'
import './index.css';
import moment from 'moment';
import jsonToFormData from 'json-form-data';
import qs from 'qs';

const { Paragraph } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

function onChange(value) {
  console.log(`selected ${value}`);
}

const expandedRowRender = (record) => {
  const columns1 = [
    { title: 'Code', dataIndex: 'code', key: 'code' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Mobile', dataIndex: 'mobile', key: 'mobile' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Price', dataIndex: 'total_price', key: 'total_price' },
    { title: 'Redeemed At', dataIndex: 'redeemed_at', key: 'redeemed_at' },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      render: remark => (
        <Paragraph ellipsis={{ rows: 3, expandable: true }}>
          {remark}
        </Paragraph>
      ),
    },
  ]
  return <div>
    <Table columns={columns1} dataSource={record.bookings} pagination={false} />
  </div>;

}

class VoucherBooking extends Component {
  constructor(props) {
    super(props);
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    console.log(params)

    this.state = {
      details: [],
      loading: false,
      moadlVisible: false,
      visible: false,
      modalData: [],
      confirmInsuranceModalVisible: false,
      viewOrderModalVisible: false,
      confirmDirty: false,
      autoCompleteResult: [],
      selectValueNational: 1,
      toggle: false,
      noteClick: true,
      categories: '',
      designations: '',
      fileList: [],
      formDataState: null,
      isEditMode: false,
      book_date_from: '',
      book_date_to: '',
      order_no: '',
      mobile_no: '',
      redeem_type: '',
      sort_type: '',
      sorting_types: [],
    }
  }

  toSnakeCase(str) {
    return str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('_');
  }

  showModal(record) {
    console.log('record', record)
    this.setState({
      visible: true,
      modalData: this.state.details.filter((element) => {
        return element.reference_id == record
      })
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.apiFetch();
    this.sortTypeFetch();
  }

  apiFetch(params = '') {
    let url = './api/v1/vouchers/bookings';
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          details: response.data,
          loading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  sortTypeFetch() {
    let url = './api/v1/vouchers/booking_sort';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          sorting_types: response.data,
          loading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  processSolutions(data) {
    const { book_date_from, book_date_to, order_no, mobile_no, redeem_type, sort_type } = this.state
    
    if (data && data.length) {
      if (book_date_from !== "" && book_date_to !== "") {
        data = data.filter(i => i.book_date >= book_date_from && i.book_date <= book_date_to);
      } else if (book_date_from === "" && book_date_to !== "") {
        data = data.filter(i => i.book_date <= book_date_to);
      } else if (book_date_from !== "" && book_date_to === "") {
        data = data.filter(i => i.book_date >= book_date_from);
      }

      if (order_no !== "") {
        data = data.filter(i => order_no === i.reference_id);
      }

      if (mobile_no !== "") {
        data = data.filter(i => mobile_no === i.guest_mobile);
      }

      if (redeem_type == "full_partial") {
        data = data.filter(i => i.quantity > i.not_redeem_quantity);
      }

      if (sort_type == 'order_no_asc') {
        return data.sort((a,b) => (a.reference_id > b.reference_id) ? 1 : ((b.reference_id > a.reference_id) ? -1 : 0));
      } else if (sort_type == 'order_no_desc') {
        return data.sort((a,b) => (b.reference_id > a.reference_id) ? 1 : ((a.reference_id > b.reference_id) ? -1 : 0));
      } else if (sort_type == 'voucher_code_asc') {
        return data.sort((a,b) => (a.voucher_code > b.voucher_code) ? 1 : ((b.voucher_code > a.voucher_code) ? -1 : 0));
      } else if (sort_type == 'voucher_code_desc') {
        return data.sort((a,b) => (b.voucher_code > a.voucher_code) ? 1 : ((a.voucher_code > b.voucher_code) ? -1 : 0));
      } else if (sort_type == 'book_date_asc') {
        return data.sort(function (a, b) {
          var dateA = new Date(a.book_date), dateB = new Date(b.book_date)
          return dateA - dateB
        });
      } else if (sort_type == 'book_date_desc') {
        return data.sort(function (a, b) {
          var dateA = new Date(a.book_date), dateB = new Date(b.book_date)
          return dateB - dateA
        });
      }
    }
    return data
  }

  /************************************* isEditMode ***************************************/
  isEditMode = () => {
    this.setState(prevState => ({
      isEditMode: !prevState.isEditMode
    }))
  };

  handleBookingGroupDataChange = (type, value) => {
    this.state.modalData[0][type] = value;
    console.log('this is selected data', this.state.modalData);
    this.setState({
      modalData: this.state.modalData,
    })
  };

  handleBookingDataChange = (index, type, value) => {
    console.log(index, 'index')
    this.state.modalData[0].bookings[index][type] = value
    console.log('this is selected data', this.state.selectedData)
    this.setState({
      modalData: this.state.modalData,
    })
  };

  handleSearchChange = (type, value) => {
    this.setState({
      [type]: value
    })
  };

  updateBooking = e => {
    console.log("Clicked!")
    e.preventDefault();
    const form = this.props.form;
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log('check !err', !err)
      console.log('check error values', values)
      if (!err) {
      // console.log('Received values of form: ', values);
      let outputJson = {
        voucher_booking_group:{
          voucher_bookings_attributes: this.state.modalData[0].bookings
        }
      };
      console.log("This is the outputJson", outputJson);
      var formData = jsonToFormData(outputJson);

      let url = './api/v1/vouchers/' + this.state.modalData[0].id + '/update_bookings';
      var that = this

      API.patch(url, formData)
        .then(function (response) {
          console.log(response, '------------res');
          that.setState({
            isLoading: false,
          })
          if (response.data.message == 'success'){
            that.setState({
              isEditMode: false
            })
            that.apiFetch();
            message.success('Updated');
          }else{
            message.error(response.data.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          message.error('Error while updating...');
        })
        .then(function () {
          // always executed
        });
      }
    });
  };

  sendConfirmation = e => {
    e.preventDefault();

    let url = './api/v1/vouchers/' + this.state.modalData[0].id + '/confirmation';
    var that = this

    API.get(url)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          isLoading: false,
        })
        if (response.data.message == 'success'){
          that.apiFetch();
          message.success('Confirmation email sent!');
        }else{
          message.error(response.data.error);
        }
      })
      .catch(function (error) {
        console.log(error);
        message.error('Error while sending confirmation email...');
      })
      .then(function () {
        // always executed
      });
  };

  render() {
    const { details, modalData, sorting_types } = this.state;
    const { getFieldDecorator } = this.props.form;
    const data = this.processSolutions(details)
    const voucherData = modalData[0];
    // console.log('modalData-------', modalData)
    // console.log('data-------', data)
    console.log('voucherData-------', voucherData)

    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    const columns = [
      {
        title: 'Order No.',
        width: 150,
        dataIndex: 'reference_id',
        key: 'reference_id',
        render: (record) => (
          <Button type="link" onClick={() => this.showModal(record)}>{record}</Button>
        ),
      },
      {
        title: 'Ref No.',
        width: 150,
        dataIndex: 'ref_no',
        key: 'ref_no',
      },
      {
        title: 'Voucher Code',
        width: 150,
        dataIndex: 'voucher_code',
        key: 'voucher_code',
      },
      {
        title: 'Voucher Name',
        width: 200,
        dataIndex: 'voucher_name',
        key: 'voucher_name',
      },  
      {
        title: 'Qty',
        width: 80,
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Not Redeemed',
        width: 150,
        dataIndex: 'not_redeem_quantity',
        key: 'not_redeem_quantity',
      },
      {
        title: 'Customer Name',
        width: 150,
        dataIndex: 'guest_name',
        key: 'guest_name',
      },
      {
        title: 'Mobile No.',
        width: 150,
        dataIndex: 'guest_mobile',
        key: 'guest_mobile',
      },
      {
        title: 'Booking Date',
        width: 150,
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'Status',
        key: 'payment_status',
        dataIndex: 'payment_status',
        width: 100,
        render: (payment_status) => (
          <span>
            <Tag color={payment_status == 'Paid' ? 'green' : payment_status == 'Failed' ? 'volcano' : payment_status == 'Pending' ? 'geekblue' : ''} key={0}>
              {payment_status}
            </Tag>
          </span>
        ),
      },
      // {
      //   title: 'Action',
      //   width: 100,
      //   key: 'action',
      //   dataIndex: 'id',
      //   render: (record) => (
      //     <Button type="link" onClick={() => this.showModal(record)}>View</Button>
      //   ),
      // },
    ];
    return (
      <div id="VoucherContainer">
        <MenuBooking selected="voucher_group" />
        <div className="content">
          <p className="title">Voucher Order List</p>
          <Form {...formItemLayout} onSubmit={this.submitSearch}>
            <React.Fragment>
              <div className="input_warp">
                <div className="formItemWarp15">
                  <Form.Item label="Book Date From">
                    <DatePicker
                      className="voucher_datepicker"
                      format={dateFormat}
                      placeholder={'From'}
                      defaultValue={this.state.book_date_from != '' ? moment(this.state.book_date_from) : ''}
                      defaultPickerValue={moment()}
                      onChange={(date, dateString) => this.handleSearchChange('book_date_from', dateString)}
                    />
                  </Form.Item>
                </div>
                <div className="formItemWarp15">
                  <Form.Item label="Book Date To">
                    <DatePicker
                      className="voucher_datepicker"
                      format={dateFormat}
                      placeholder={'To'}
                      defaultValue={this.state.book_date_to != '' ? moment(this.state.book_date_to) : ''}
                      defaultPickerValue={moment()}
                      onChange={(date, dateString) => this.handleSearchChange('book_date_to', dateString)}
                    />
                  </Form.Item>
                </div>
                <div className="formItemWarp15">
                  <Form.Item label="Select Sort Type">
                    <Select placeholder="Select Sort Type" optionFilterProp="children"
                      onChange={(value) => this.handleSearchChange('sort_type', value)}
                      defaultValue={this.state.sort_type}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        sorting_types && sorting_types.map((items, i) => {
                          return (
                            <Option key={i} value={this.toSnakeCase(items)}>{items}</Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </div> 
                <div className="formItemWarp15">
                  <Form.Item label="Order No.">
                    <Input onChange={(e) => this.handleSearchChange('order_no', e.target.value)} defaultValue={this.state.order_no} />
                  </Form.Item>
                </div>
                <div className="formItemWarp15">
                  <Form.Item label="Mobile No.">
                    <Input onChange={(e) => this.handleSearchChange('mobile_no', e.target.value)} defaultValue={this.state.mobile_no} />
                  </Form.Item>
                </div>
                <div className="formItemWarp15">
                  <Form.Item label="Redeem">
                    <Select placeholder="Select Sort Type" optionFilterProp="children"
                      onChange={(value) => this.handleSearchChange('redeem_type', value)}
                      defaultValue={this.state.redeem_type}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="full_partial">Full/Partial</Option>
                      <Option value="no_all">No/All</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="btn_submit">
                {/*<Button type="danger" onClick={this.submitSearch}>Search</Button>*/}
              </div>
            </React.Fragment>
          </Form>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            // expandedRowRender={expandedRowRender}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        </div>

        <Modal
          // title="Order Information"
          visible={this.state.visible}
          // onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="bookingModal_sty"
          // okText="DOWNLOAD INVOICE"
          cancelText="RESEND CONFIRMATION"
          // cancelButtonProps={{ style: { display: 'none' } }}
          footer={
            modalData.length === 0 ? null :
              <span>
                { 
                  !this.state.isEditMode ? 
                    <Button type="primary" onClick={this.sendConfirmation}>DOWNLOAD CONFIRMATION</Button> : ''
                }
              </span>
          }
        >
          {
            modalData.length === 0 ? null :
              <div className="table_order">
                <h2>Order Information</h2>
                <div className="responsive_sroll">
                  <table className="booking_modal_table">
                    <tbody>
                      <tr>
                        <th>Order No.</th>
                        <td>{voucherData.reference_id}</td>
                        <th>Voucher Name</th>
                        <td><a href={voucherData.voucher_url}>{voucherData.voucher}</a></td>
                      </tr>
                      <tr>
                        <th>Date</th>
                        <td>{voucherData.created_at}</td>
                        <th>Qty</th>
                        <td>{voucherData.quantity}</td>
                      </tr>
                      <tr>
                        <th>Order Amount</th>
                        <td>{voucherData.total_price}</td>
                        <th>Status</th>
                        <td>
                          <Tag 
                            color={
                              voucherData.payment_status == 'Paid' ? 'green' : 
                              voucherData.payment_status == 'Partial Paid' ? 'blue' : 
                              voucherData.payment_status == 'Awaiting' ? 'geekblue' : 
                              voucherData.payment_status == 'Pending' ? 'orange' :
                              voucherData.payment_status == 'Refunded' || voucherData.payment_status == 'Cancelled' ? 'red' : 
                              ''
                            } 
                            key={0}>
                            {voucherData.payment_status}
                          </Tag>
                        </td>
                      </tr>
                      <tr>
                        <th>Surcharge</th>
                        <td>{voucherData.total_service_fee}</td>
                        <th>Remark</th>
                        <td>{voucherData.remark}</td>
                      </tr>
                      <tr>
                        <th colSpan="1">Entitlement(s)</th>
                        <td colSpan="3">
                          {
                            voucherData.entitles.map((ent, index) => {
                              return(
                                <div key={index}>
                                  <a href={ent.url}>{ent.title}</a>
                                </div>
                              )
                            })
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <td>{voucherData.guest_name}</td>
                        <th>Email</th>
                        <td>{voucherData.guest_email}</td>
                      </tr>
                      <tr>
                        <th>Mobile No.</th>
                        <td>{voucherData.guest_mobile}</td>
                        <th>Agent Remark</th>
                        <td>{voucherData.guest_remark}</td>
                      </tr>
                    </tbody>
                  </table>
                  <br/>
                  <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    { 
                      voucherData ? (
                        <React.Fragment>
                          <div className="responsive_sroll">
                            <table className="booking_modal_table">
                              <thead>
                                <tr>
                                  <th width="5%">#</th>
                                  <th>Voucher Ref.</th>
                                  <th>Redeemed For</th>
                                  <th>Date</th>
                                  <th>Amount</th>
                                  <th>Pax Name</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                voucherData.bookings.map((booking, index) => 
                                  <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{booking.code}</td>
                                    <td>{booking.tour_code}</td>
                                    <td>{booking.redeemed_at}</td>
                                    <td>{booking.tour_amount}</td>
                                    <td>{booking.pax_name}</td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </table>
                        </div>
                        </React.Fragment>
                      ) : (null)
                    }
                  </Form>
                </div>
              </div>
          }
        </Modal>
      </div>
    );
  }
}

const VoucherGroupBooking = Form.create({ name: 'Voucher' })(VoucherBooking);
export default withRouter(VoucherGroupBooking);