import React, { Component } from 'react';
import Search from '../../search/index';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import { withRouter } from 'react-router-dom'
import API from '../../../../api'
import { Spin } from 'antd';
import moment from 'moment';
import Footer from '../../home/footer/index';

class ListingFlight extends Component {

  constructor(props) {
    super(props);
    this.state = {
      detail: { solutions: [], airlines: [] },
      params: this.props.location.search,
      isLoading: true,
      sortBy: '',
      filterByDirectlyFly: false,
      filterByAirlinesList: [],
      gateways: ['Travelport', 'Malindo', 'Firefly'],
      response_times: 0
    }
    this.myRef = React.createRef();
    this.sortByCallback = this.sortByCallback.bind(this);
    this.filterCallback = this.filterCallback.bind(this);
    this.onAirlineChange = this.onAirlineChange.bind(this);
  }

  componentDidMount() {
    this.myRef.current.scrollIntoView({
      behavior: "smooth",
    })
    this.apiFetch();
    console.log(this.myRef, '---------ref')
  }

  componentDidUpdate() {
    if (this.props.location.search != this.state.params) {
      this.setState({
        params: this.props.location.search,
        isLoading: true,
      })
      console.log(this.props.location.search)
      this.apiFetch();
      this.myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      })
    }
  }

  sortByCallback(value) {
    console.log(value, 'SortBy')
    this.setState({
      sortBy: value
    })
  }

  filterCallback(type, value) {
    this.setState({
      filterByDirectlyFly: value
    })
  }

  onAirlineChange(filterByAirlinesList) {
    this.setState({
      filterByAirlinesList: filterByAirlinesList
    });
  }

  processSolutions(data) {
    const { sortBy, filterByDirectlyFly, filterByAirlinesList } = this.state
    console.log(data, '------this is data');
    if (data && data.length) {

      if (filterByDirectlyFly) {
        data = data.filter((obj) => {
          return obj.directly_fly === "true"
        })
      }

      if (filterByAirlinesList.length > 0) {
        data = data.filter(i => filterByAirlinesList.includes(i.airline));
      }

      if (sortBy == 'lowest_price') {
        return data.sort((a, b) => a.total_price - b.total_price)
      } else if (sortBy == 'shortest_duration') {
        return data.sort((a, b) => moment.duration(a.journeys[0].travel_time_in_second).subtract(moment.duration(b.journeys[0].travel_time_in_second)).seconds())
      } else if (sortBy == 'earliest_departure') {
        return data.sort((a, b) => moment(a.journeys[0].departure_date + " " + a.journeys[0].departure_time).isBefore(b.journeys[0].departure_date + " " + b.journeys[0].departure_time) ? -1 : 1)
      } else if (sortBy == 'earliest_arrival') {
        return data.sort((a, b) => moment(a.journeys[0].arrival_date + " " + a.journeys[0].arrival_time).isBefore(b.journeys[0].arrival_date + " " + b.journeys[0].arrival_time) ? -1 : 1)
      }
    }
    return data
  }

  apiFetch() {
    // const { params } = this.state;
    const params = this.props.location.search;
    let url = './api/v1' + this.props.location.pathname;
    var that = this;
    console.log(params);
    that.setState({
      detail: { solutions: [], airlines: [] },
      response_times: 0,
      isLoading: true
    })

    that.state.gateways.forEach((gateway) => {
      API.get(url + params + '&gateway='+gateway)
        .then(function (response) {
          let new_solutions = that.state.detail.solutions.concat(response.data.solutions)
          let new_airlines = that.state.detail.airlines.concat(response.data.airlines)

          that.setState({
            detail: { solutions: new_solutions, airlines: new_airlines},
            response_times: that.state.response_times + 1,
            isLoading: new_solutions.length > 0 || (that.state.response_times + 1 === that.state.gateways.length) ? false : true
          })

        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    })

  }



  render() {
    const { isLoading, detail, sortBy, filterByDirectlyFly} = this.state;
    const params = this.props.location.search;
    //console.log(detail, "---------detail----------");
    const data = this.processSolutions(detail.solutions)
    //console.log(data, "---------data----------");


    return (
      <div >
        <Search isLoading={isLoading} tagline={homepage_new.tagline} covers={homepage_new.search_covers} />
        <Spin size="large" spinning={isLoading}>
          <div id="ListingFlight" ref={this.myRef}>
            <div className="warp">
              <LeftPanel detail={detail} filterCallback={this.filterCallback} sortByCallback={this.sortByCallback}
                sortBy={sortBy} filterByDirectlyFly={filterByDirectlyFly} airlineFilter={this.onAirlineChange}  />
              <RightPanel detail={data} dataLenght={data.length} filterByDirectlyFly={filterByDirectlyFly} loading={isLoading}/>
              <div className="clear"></div>
            </div>
          </div>
        </Spin>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ListingFlight);
