import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Input, Select, AutoComplete } from 'antd';
import Api from '../../../api'
import { debounce } from './debounce';

class NewSubagentFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
      confirmDirty: false,
      country: '',
      countries: [],
      states: [],
      statesList: [],
      cities: [],
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.handleSubmit(e)
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.postCreateSubagent(values)
      }
    });
  };

  postCreateSubagent = (values) => {
    this.setState({ confirmLoading: true })
    Api
      .post('/api/v1/subagents', { user: values })
      .then(response => {
        this.props.openNotification(true, 'Success', 'Subagent was successfully created!')
        this.props.appendNewUser({...response.data.data, is_new: true})
        this.setState({
          visible: false,
        })
        this.props.form.resetFields()
      })
      .catch(error => {
        if (error.response.status == 422) {
          this.props.openNotification(false, 'Error' + ': ' + error.response.statusText, error.response.data.errors)
        } else {
          this.props.openNotification(false, 'Error' + ': ' + error.response.statusText, error.message)
        }
      })
      .finally(() => {
        this.setState({
          confirmLoading: false
        })
      })
  }

  fetchStates = () => {
    Api.get('/api/v1/states')
    .then((response) => {
      this.setState({
        statesList: response.data,
        states: response.data,
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  searchCity = (country, term) => {
    Api.get('/api/v1/cities', { params: {country: country, term: term} })
    .then((response) => {
      this.setState({
        cities: response.data.data.map(city => city.attributes.name)
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['password_confirmation'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  validatePhonesFormat = (rule, value, callback) => {
    if (value) {
      let result = value.every(phone => /^(?:\+[1-9]\d{0,2})?([0-9\-]{8,14})$/.test(phone))
      if (!result) {
        callback('Invalid phone number. Must be in format +[country_code][number] (e.g., +601234567890)')
      }
    }
    callback();
  }

  handleCountryChange = value => {
    this.setState({
      countries: this.props.countries.filter(country => (new RegExp(value, 'i').test(country)))
    })
  }
  
  handleCountrySelect = value => {
    const states = value === 'Malaysia' ? this.state.statesList : []

    this.setState({
      states: states,
      country: value,
    })
  }

  handleStateChange = value => {
    this.setState({
      states: this.state.statesList.filter(state => (new RegExp(value, 'i').test(state)))
    })
  }

  handleCityChange = debounce(value => {
    this.searchCity(this.state.country, value)
  }, 500)

  componentDidMount() {
    this.fetchStates();
  }

  render() {
    const { visible, confirmLoading, countries, states, cities } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { Option } = Select;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div id="new-subagent">
        <Button
          type="primary"
          onClick={this.showModal}
          style={{ marginBottom: 15 }}
          size='large'
        >
          Add New Subagent
        </Button>
        <Modal
          centered
          title="New Subagent Form"
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label="Username">
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: 'Please input username!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Password" hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    validator: this.validateToNextPassword,
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label="Confirm Password" hasFeedback>
              {getFieldDecorator('password_confirmation', {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  {
                    validator: this.compareToFirstPassword,
                  },
                ],
              })(<Input.Password onBlur={this.handleConfirmBlur} />)}
            </Form.Item>
            <Form.Item label="Full Name">
              {getFieldDecorator('full_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please input full name!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Other Name">
              {getFieldDecorator('other_name', {
                rules: [],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Alias Name">
              {getFieldDecorator('alias_name', {
                rules: [],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Address">
              {getFieldDecorator('address', {
                rules: [
                  {
                    required: true,
                    message: 'Please input address!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="City">
              {getFieldDecorator('city', {
                rules: [
                  {
                    required: true,
                    message: 'Please input city!',
                  },
                ],
              })(
                <AutoComplete dataSource={cities} onChange={value => this.handleCityChange(value)}>
                  <Input />
                </AutoComplete>
              )}
            </Form.Item>
            <Form.Item label="State">
              {getFieldDecorator('state', {
                rules: [
                  {
                    required: true,
                    message: 'Please input state!',
                  },
                ],
              })(
                <AutoComplete dataSource={states} onChange={value => this.handleStateChange(value)} >
                  <Input />
                </AutoComplete>
              )}
            </Form.Item>
            <Form.Item label="Postal">
              {getFieldDecorator('postal', {
                rules: [
                  {
                    required: true,
                    message: 'Please input postal!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Country">
              {getFieldDecorator('country', {
                rules: [
                  {
                    required: true,
                    message: 'Please input country!',
                  },
                ],
              })(
                <AutoComplete
                  dataSource={countries}
                  onChange={value => this.handleCountryChange(value)}
                  onSelect={value => this.handleCountrySelect(value)}
                >
                  <Input />
                </AutoComplete>
              )}
            </Form.Item>
            <Form.Item label="Phones">
              {getFieldDecorator('phones', {
                type: 'array',
                rules: [
                  {
                    required: true,
                    message: 'Please input phones!',
                  },
                  {
                    validator: this.validatePhonesFormat,
                  }
                ],
              })(<Select mode="tags" />)}
            </Form.Item>
            <Form.Item label="Emails">
              {getFieldDecorator('emails', {
                type: 'array',
                rules: [
                  {
                    required: true,
                    message: 'Please input emails!',
                  },
                ],
              })(<Select mode="tags" />)}
            </Form.Item>
            <Form.Item label="Active">
              {getFieldDecorator('is_active', {
                initialValue: "true",
              })(
                <Select placeholder="Please select active status">
                  <Option value="true">Yes</Option>
                  <Option value="false">No</Option>
                </Select>,
              )}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}


NewSubagentFormModal.propTypes = {
  appendNewUser: PropTypes.func.isRequired,
  openNotification: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
};

const WrappedSubagentForm = Form.create({ name: 'new_subagent' })(NewSubagentFormModal)
export default WrappedSubagentForm;
