import React, { Component } from 'react';
import './index.css';
import InfiniteScroll from 'react-infinite-scroller';
import ReactMarkdown from 'react-markdown/with-html';
import { List, Button, Icon, Rate, Skeleton, Empty, Typography, Tag, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter, Link } from 'react-router-dom';
import qs from 'qs';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const { Paragraph } = Typography;

class RightPanel extends Component {
  constructor(props) {
    super(props);
    const voucher = qs.parse(this.props.params, { ignoreQueryPrefix: true })
    this.state = {
      isVisible: false,
      modalData: { title: '', content: '' },
      agent_id: voucher['agent'] != undefined ? voucher['agent'] : '',
    }
    this.handleClick = this.handleClick.bind(this);
    // this.showModal = this.showModal.bind(this);
    // this.state = {
    //     dataDetail: this.props.detail,
    // }
  }

  showModal(value) {
    console.log(this);
    console.log(value, 'value')
    const modalData = this.props.detail.find(element => {
      return element.id === value
    })
    // console.log(modalData, 'modalData')
    this.setState({
      copied: false,
      isVisible: true,
      modalData: { title: modalData.title, terms_and_conditions: modalData.terms_and_conditions, description: modalData.description }
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
        isVisible: false,
    });
  };

  handleClick(e, data) {
    var btn = e.target
    btn.classList.toggle("active");

    var content = btn.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  }

  onCopy = () => {
    this.setState({
      copied: true
    });
  };

  render() {
    const { isVisible } = this.state;
    const dataDetail = this.props.detail;
    const lengthDetail = this.props.dataLength;

    return (
      <div id="RightPanel">
          {
            lengthDetail === 0 ? (
              <div className="empty">
                {
                  this.props.loading ? (
                    <Skeleton active avatar paragraph={{ rows: 4 }} />
                  ) : (
                    <Empty description="Data not Found" />    
                  )
                }
              </div>
            ) : (
              <div>
                <List
                  itemLayout="vertical"
                  size="large"
                  dataSource={dataDetail}
                  renderItem={(item, index) => (
                    <List.Item
                      key={item.id}
                      extra={[
                        <div className="right_price">
                          <p className="tax_tag"><small>From</small></p>
                          <p className="price_tag">RM {item.price} </p>
                          <div className="select_btn">
                            <Link to={{
                                pathname: "/vouchers/" + encodeURIComponent(item.id),
                                search: this.props.location.search
                            }} >
                                <Button type="primary" ghost>SELECT</Button>
                            </Link>
                          </div>
                          <div className="clear"></div>
                        </div>
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <img
                              className="img_sty"
                              alt="logo"
                              src={item.image_url !== '' ? item.image_url : homepage_new.default_image}
                          />}
                        title={<p className="title_panel">
                            <Paragraph ellipsis={{ rows: 2, }}
                                className="ellipsis_style ellipsis_style_view">{item.title}
                            </Paragraph>
                            <span className="view_sty">
                              <Icon type="question-circle" className="view_icon" onClick={(e) => this.showModal(item.id)} />
                            </span>
                            <div className="clear"></div>
                          </p>
                        }
                        description={[
                          <div key={item.id} className="content_rate">
                            <div className="date">
                              <p>Valid Period : {item.duration}</p>
                            </div>
                            <div className="locate">
                              <span className="locate_warp">
                                <Tag color="blue">{item.code}</Tag>
                              </span>
                              <span className="locate_warp">
                                <Tag color="blue">{item.category}</Tag>
                              </span>
                              <CopyToClipboard
                                onCopy={this.onCopy}
                                options={{message: 'Link Copied!'}}
                                text={item.referral_link}
                              >
                                <Tag color="green">Copy Voucher Referral Link</Tag>
                              </CopyToClipboard>
                            </div>
                          </div>
                        ]}
                      />
                    </List.Item>
                  )}
                />
              </div>
            )
          }
          <Modal
            title={this.state.modalData.title}
            visible={isVisible}
            footer={null}
            onCancel={this.handleCancel}
            className="modal_voucher_listing"
          >
            {
              this.state.modalData.description !== '' ?
                <React.Fragment>
                  <p className="departure_style">Highlight</p>
                  <p className="departure"><ReactMarkdown source={this.state.modalData.description} escapeHtml={false} /></p>
                </React.Fragment>
              : ''
           }
           {
              this.state.modalData.terms_and_conditions !== '' ?
                <React.Fragment>
                  <p className="departure_style">Terms & Conditions</p>
                  <p className="departure"><ReactMarkdown source={this.state.modalData.terms_and_conditions} escapeHtml={false} /></p>
                </React.Fragment>
              : ''
           }
          </Modal>
      </div>
    );
  }
}

export default withRouter(RightPanel);
