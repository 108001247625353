import React, { Component } from 'react';
import { Table, Tooltip } from 'antd';
import './index.css'

class PriceDetails extends Component {
  render() {
    /**************************************************** renderContent ****************************************************/
    const renderContent = (value, row, index, ) => {
      const obj = {
        children: value,
        props: {},
      };
      if (index === 6) {
        obj.props.colSpan = 0;
      }
      return obj;
    };

    const renderDates = (value, row, index) => {
      return <div className="date_scroll">
        {
          row.date.map((item, i) => {
            return (
              <div key={i}><span className="date_sty" key={i}>{item}</span><br/></div>
            )
          })
        }
      </div>
    };

    const renderData = (value, row, index) => {
      var qty = Object.keys(row).find(key => row[key] === value);

      const obj = {
        children: this.props.dtaTooltip ? <Tooltip title={
                      row.normal == value ? 'DTA: ' + row.dta_normal : 
                      row.normal1 == value ? 'DTA: ' + row.dta_normal1 :
                      row.normal2 == value ? 'DTA: ' + row.dta_normal2 :
                      row.normal3 == value ? 'DTA: ' + row.dta_normal3 :
                      row.normal4 == value ? 'DTA: ' + row.dta_normal4 :
                      row.normal5 == value ? 'DTA: ' + row.dta_normal5 :
                      row.normal6 == value ? 'DTA: ' + row.dta_normal6 :
                      row.normal7 == value ? 'DTA: ' + row.dta_normal7 :
                      row.normal8 == value ? 'DTA: ' + row.dta_normal8 :
                      row.normal9 == value ? 'DTA: ' + row.dta_normal9 : 
                      row.specialoffer == value ? 'DTA: ' + row.dta_specialoffer : 
                      row.specialdeal == value ? 'DTA: ' + row.dta_specialdeal : 
                      row.superpromo == value ? 'DTA: ' + row.dta_superpromo : 
                      row.promo == value ? 'DTA: ' + row.dta_promo : 
                      row.early_bird == value ? 'DTA: ' + row.dta_early_bird : 
                      'DTA: ' + row["dta_"+qty]
                    }>
                    <span>{value}</span>
                  </Tooltip> : <span>{value}</span>,
        props: { className: 'rolspan04' },
      };
      return obj
    };

    /**************************************************** columns with Merged  ****************************************************/
    let columns = [
      {
        title: this.props.title ? this.props.title : '',
        colSpan: 1,
        dataIndex: 'name',
        width: 270,
        render: (value, row, index) => {
          const obj = {
            children: value,
            props: {},
          };
          obj.props.rowSpan = row.rowSpan;
          // if (index === 6) {
          //   obj.props.colSpan = 0;
          // }
          return obj
        },
      },
    ];

    this.props.columns.map((c, i) => {
      if (c.title === "Departure Dates") {
        c["render"] = renderDates;
      }
      else {
        c["render"] = renderData;
      }
    })

    columns.push(this.props.columns)
    columns = columns.flat()

    /**************************************************** data ****************************************************/
    const data = [
      {
        key: '1',
        rowSpan: 1,
        name: 'Adult',
        date: ['12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019',],
        promo: 'RM 3002',
        offer: 'RM 1988',
        normal: 'RM 1988',
        early_bird: 'RM 1988',
      },
      {
        key: '2',
        rowSpan: 3,
        name: 'Child twin',
        date: ['12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019'],
        offer: 'RM 1988',
        normal: 'RM 1988',
        promo: 'RM 3902',
        early_bird: 'RM 1988',
      },
      {
        key: '3',
        rowSpan: 0,
        name: 'Child with extra bed',
        date: ['12/5/2019', '12/5/2019', '12/5/2019', '12/5/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019',],
        promo: 'RM 3902',
        offer: 'RM 1988',
        normal: 'RM 1988',
        early_bird: 'RM 1988',
      },
      {
        key: '4',
        rowSpan: 0,
        name: 'Child with no bed',
        date: ['12/6/2019', '12/6/2019', '12/6/2019', '12/6/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019', '12/9/2019',],
        promo: 'RM 1002',
        offer: 'RM 1988',
        normal: 'RM 1988',
        early_bird: 'RM 1988',
      },
    ];


    return (
      <div>
        {/*************************************** with rowSpan table  *****************************************/}
        <div className="price_table_warp">
          <Table columns={columns} dataSource={this.props.data} bordered className="PriceDetails" pagination={false} scroll={{ x: 700 }} />
        </div>

  
      </div>
    );
  }
}

export default PriceDetails;
