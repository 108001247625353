import React, { Component } from 'react';
import { Card, Button, List, Spin, Skeleton } from 'antd';
import './index.css'

class RightPanel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // $(document).ready(function () {
    //   $(window).scroll(function () {
    //     if ($(this).scrollTop() > 1000) {
    //       $("#change_text").text("Passenger Info")
    //     }else {
    //       $("#change_text").text("CHECK OUT")
    //     }
    //   });
    // });
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const data = this.props.selectedData;
    const loading = this.props.loading
    console.log(data, '--------data-right--------')
    
    return (
      <div className="RightPanel res_panel_warp">
        {
          loading ? (
            <Skeleton active />
          ) : (

              <Card title={[<h2>Summary</h2>]}>
                <div className="dates">
                  <p className="date_title">Dates</p>
                  <p className="date_select">{data?.dates}</p>
                </div>

                <div className="dates">
                  <p className="date_title">Flight</p>
                  <p className="date_select">{data?.airports}</p>
                </div>

                <div className="dates">
                  <p className="date_title">Fare breakdown</p>
                  {/* <p className="date_select">-</p> */}
                </div>

                <div className="select_list">
                  <List
                    bordered={false}
                    dataSource={data?.pricings}
                    renderItem={item => (
                      <List.Item>
                        <div className="list_warp">
                          <span>{item.code === 'ADT' ? 'Adult' : item.code === 'CNN' ? 'Child' : item.code === 'INF' ? 'Infant' : null} x {item.count}</span>
                          <span>RM {this.priceFormat(item.base)}</span>
                          <p><span>Tax</span><span>RM {this.priceFormat(item.taxes)}</span></p>
                          
                        </div>
                      </List.Item>

                    )}
                  />
                  { this.props.brbCount >= 1 ? (
                    <p className="brb">
                      <span>BRB x {this.props.brbCount}</span>
                      <span>RM {this.priceFormat(26*this.props.brbCount)}</span>
                    </p>
                    ) : (null) }

                </div>

                <div className="total">
                  <span>Total</span><span>RM {this.priceFormat(data.total_price + (26 *this.props.brbCount))}</span>
                </div>

              </Card>
            )
        }
      </div>
    );
  }
}

export default RightPanel;
