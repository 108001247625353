import React, { Component } from 'react';
import { Tabs, Button } from 'antd';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css'


const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}


class Inspire extends Component {

  render() {

    const tabs = this.props.tabs;
    // console.log(this.props, '-----props')
    // console.log(tabs, '-----tab props');

    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      slidesToShow: 3,
      centerPadding: "250px",
      // slidesToScroll: 1,
      speed: 500,
      responsive: [
        {
          breakpoint: 1599,
          settings: {
            arrows: true,
            slidesToScroll: 5,
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 1350,
          settings: {
            arrows: true,
            slidesToScroll: 3,
            centerPadding: "150px",
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 1050,
          settings: {
            arrows: true,
            slidesToScroll: 3,
            centerPadding: "100px",
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            slidesToScroll: 3,
            centerPadding: "80px",
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 720,
          settings: {
            arrows: true,
            infinite: true,
            slidesToScroll: 3,
            centerPadding: "50px",
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 625,
          settings: {
            arrows: true,
            slidesToShow: 1,
            centerPadding: "0px",
            slidesToScroll: 1,
            swipeToSlide: true,
          }
        },
      ]
    };

    return (
      <div id="Inspire">
        <div className="content_title">
          {/* <p>Don’t know play what?</p> */}
          <h1>{this.props.title}</h1>
        </div>

        {
          tabs.length > 1 ? (
            <div className="tab_warp">
              <Tabs defaultActiveKey="0" onChange={callback}>
                {
                  tabs.map((tab, i) => {
                    return (
                      <TabPane tab={tab.title} key={i}>
                        <div className="slider">
                          <Slider {...settings} slidesToShow={parseInt(tab.slides_to_show)} infinite={tab.cards.length > 2}>
                            {
                              // tab.covers.map((cover) => {
                              //   return (
                              //     <img src={cover.img} alt="cover" />
                              //   )
                              // })
                              tab.cards.map((card) => {
                                return(
                                  card.link ? 
                                  (<a href={`${card.link}`} target="_blank"><img src={card.cover} alt="cover" /></a>)
                                  :
                                  (<img src={card.cover} alt="cover" />)
                                )
                              })
                            }
                          </Slider>
                        </div>
                      </TabPane>
                    )
                  })
                }
              </Tabs>
            </div>
          ) : (
            <div className="slider">
              <Slider {...settings}>
                {
                  tabs[0].cards.map((card) => {
                    return(
                      <a href={`${card.link}`} target="_blank"><img src={card.cover} alt="cover" /></a>
                    )
                  })
                }
              </Slider>
            </div>
          )
        }

      </div>
    );
  }
}

export default Inspire;
