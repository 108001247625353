import React, { Component } from 'react';
import { Form, Input, Button, Icon, AutoComplete, Select } from 'antd';
import moment from 'moment';
import Fuse from 'fuse.js';
import { withRouter, Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import qs from 'qs';
import API from '../../../api';

const { Option, OptGroup } = AutoComplete;

function onBlur() {
    console.log('blur');
}

class Voucher extends Component {
  constructor(props) {
    super(props);
    const voucher = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.state = {
      showItems: 100,
      keyword: voucher['keyword'] != undefined ? voucher['keyword'] : '',
      agent_id: voucher['agent_id'] != undefined ? voucher['agent_id'] : '',
      agents: [],
      vouchers: [],
    };
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    // this.agentListFetch();
    this.voucherFetch();
  }

  agentListFetch = () => {
    API.get('./api/v1/vouchers/agent_list')
      .then((response) => {
        console.log(response, '------------res');
        this.setState({
          agents: response.data
        })
      })
      .catch((error) => {
        console.log(error);
      })
  };

  voucherFetch() {
    let url = './api/v1/vouchers/title_list';
    var that = this;
    // console.log(params);

    API.get(url)
        .then(function (response) {
          // console.log(response, '------------res country');
          that.setState({
            vouchers: response.data
          })
        })
        .catch(function (error) {
            console.log(error);
        })
        .then(function () {
            // always executed
        });
  }

  /****************************** submitForm *******************************/
  submitForm(event) {
    const { keyword, agent_id } = this.state;
    event.preventDefault();

    let outputJson = {
      keyword: keyword,
      agent_id: agent_id,
    };

    console.log("This is the outputJson", outputJson);

    let url = './api/v1/vouchers';
    var that = this;
    that.props.history.push({
      pathname: '/vouchers',
      search: "?" + qs.stringify(outputJson),
      // search: "?" + formurlencoded(outputJson, { skipIndex: true }),
    });
  }

  handleShowMore() {
    this.setState({
      showItems:
        this.state.showItems >= this.state.items.length ? this.state.showItems : this.state.showItems + 10
    })
  }

  handleDestinationChange = value => {
    this.setState({ 
      keyword: value
    });
  };

  handleAgentChange = value => {
    this.setState({ 
      agent_id: value
    });
  };

  render() {
    console.log(this.state.vouchers, "========this.state.vouchers")
    if (this.state.vouchers == undefined || this.state.vouchers === '') {
      var vouchers = [];
    }
    else {
      var vouchers = this.state.vouchers
      var dataVouchers = [];
      vouchers.map((voucher, index) => 
        dataVouchers.push({"name": voucher})
      )
    }

    var dataSource = [
      { "title": "Title", "children": dataVouchers }
    ]

    const options = dataSource
      .map(item => (
        <OptGroup key={item.title} label={<strong>{item.title}</strong>}>
            {item.children && item.children.map(opt => (
              <Option key={opt.name} value={opt.name}>
                {opt.name}
              </Option>
            ))}
        </OptGroup>
      ))

    let agentListGroupByState = []
    this.state.agents.map((agent) => {
      agentListGroupByState.push(
        {
          id: agent.id,
          title: agent.state,
          children: agent.agents
        }
      )
    })

    const renderTitle = (title) => {
      return (
        <span>
          {title}
        </span>
      );
    }

    const agent_options = agentListGroupByState
      .map(group => (
        <OptGroup key={group.id} label={renderTitle(group.title)}>
          {group.children.map(opt => (
            <Option key={opt.id} value={`${opt.id}`} label={opt.name} state={opt.state}>
              {opt.name}
            </Option>
          ))}
        </OptGroup>
    ))

    return (
      <div className="Voucher" >
        <form onSubmit={this.submitForm} className="form_warp">
          <div className="form_layout">
            <label>Keywords</label>
            <div className="certain-category-search-wrapper">
              <AutoComplete
                className="certain-category-search"
                dropdownClassName="certain-category-search-dropdown"
                allowClear={true}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 300 }}
                style={{ width: '100%' }}
                dataSource={options}
                placeholder="Search voucher..."
                optionLabelProp="value"
                filterOption={true}
                optionFilterProp={"children"}
                // onChange={this.handleKeywordChange}
                onChange={this.handleDestinationChange}
                defaultValue={this.state.keyword}
              >
                <Input onKeyPress={this.handleKeyPress} suffix={<Icon type="search" className="certain-category-icon" />} />
              </AutoComplete>
            </div>
          </div>

          <div className="form_layout">
            <label>Agent</label>
            <div className="certain-category-search-wrapper">
              <AutoComplete
                className="certain-category-search"
                dropdownClassName="certain-category-search-dropdown"
                allowClear={true}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 300 }}
                style={{ width: '100%' }}
                dataSource={agent_options}
                placeholder="Search agent..."
                optionLabelProp="value"
                filterOption={true}
                optionFilterProp={"children"}
                // onChange={this.handleKeywordChange}
                onChange={this.handleAgentChange}
                defaultValue={this.state.agent_id}
              >
                <Input onKeyPress={this.handleKeyPress} suffix={<Icon type="search" className="certain-category-icon" />} />
              </AutoComplete>
            </div>
          </div>
            
          <div className="btn_submit">
            <Button type="primary" onClick={this.submitForm} icon="search" size="default" className="search_btn" id="btn_submit_wifi">
                Search
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(Voucher);
