import React, { Component } from 'react';
import { Collapse, Card, Button, Skeleton, Icon, Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.css'

class RightPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  render() {
    const { loading } = this.state;
    if (this.props.payment_type == "public_bank" || this.props.payment_type == "public_bank_2") {
      var round_service_fee = this.props.bookingServiceFee.toFixed(2)
      var final_total = this.props.bookingTotal + parseFloat(round_service_fee)
    }
    else {
      var final_total = this.props.bookingTotal
    }
    
    return (
      <div className="RightPanel res_panel_warp">
        {
          loading ? (
            <Skeleton active />
          ) : (
              <Card title={[
                <div className="title" key={0}>
                  <h2>Summary</h2>
                </div>]}>
                {
                  this.props.getBookingInfo.length > 0 ?
                    this.props.getBookingInfo.map((due_booking, index) => {
                      return(
                        <div className="dates" key={index}>
                          <p className="date_title">{due_booking.category} : {due_booking.ref_no}</p>
                          <div className="date_select">
                            <Row>
                            <Col span={12}><p>{due_booking.currency} {this.priceFormat(due_booking.booked_from == "travelb2bsg" ? due_booking.currency_amount : due_booking.amount)}</p></Col>
                            <Col span={12}><span className="remove_icon" onClick={(event) => this.props.onClickRemoveData(due_booking.id)}><FontAwesomeIcon fixedWidth icon="times-circle" /></span></Col>
                            </Row>
                          </div>
                        </div>
                      )
                    })
                  : (null)
                }

                {
                  (this.props.payment_type == "public_bank" || this.props.payment_type == "public_bank_2") ?
                    <div className="dates">
                      <p className="date_title">Service Fee</p>
                      <div className="date_select">
                      <p className="date_select">{this.props.currency} {this.priceFormat(this.props.bookingServiceFee)}</p>
                      </div>
                    </div> 
                  : (null)
                }
                
                <div className="select_list">
                  <div className="list_warp">
                    <div className="total">
                      <span>Grand Total</span><span className="total_summary">{this.props.currency} {this.priceFormat(final_total)}</span>
                    </div>
                  </div>
                </div>  
              </Card>
            )}
      </div>
    );
  }
}

export default RightPanel;
