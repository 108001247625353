import React, { Component } from 'react';
import { Table, Tag, Typography, Modal, Button, Form, Input, Select, message } from 'antd';
import MenuBooking from '../menu';
import { Link, withRouter} from 'react-router-dom';
import API from '../../../../api'
import qs from 'qs';
import moment from 'moment';
import './index.css';
import jsonToFormData from 'json-form-data';

const { Paragraph } = Typography;

function onChange(value) {
  console.log(`selected ${value}`);
}

const expandedRowRender = (record) => {
  const columns1 = [
    { title: 'Package', dataIndex: 'package_name', key: 'package_name', className: 'hide', },
    { title: 'Quantity', dataIndex: 'total_quantity', key: 'total_quantity' },
    { title: 'Price', dataIndex: 'total_price', key: 'total_price' },
    { title: 'Category', dataIndex: 'category', key: 'category' },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      render: remark => (
        <Paragraph ellipsis={{ rows: 3, expandable: true }}>
          {remark}
        </Paragraph>
      ),
    },
  ]

  return <div>
    <Table columns={columns1} dataSource={record.activity_bookings} pagination={false} />
  </div>;

}

class ActivityBooking extends Component {
  state = {
    data: [],
    loading: false,
    visible: false,
    categories: '',
    designations: '',
    isEditMode: false,
    modalData: [],
    activity_booking_group: {
      activity_bookings_attributes: [{}]
    }
  };

  showModal(record) {
    console.log('record', record)
    this.setState({
      visible: true,
      modalData: this.state.data.filter((element) => {
        return element.order_no == record
      })
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleBookingDataChange(type, value) {
    this.state.activity_booking_group['activity_bookings_attributes'][0][type] = value;

    this.setState({
      activity_booking_group: this.state.activity_booking_group
    })
    console.log(this.state.activity_booking_group);
  };

  /************************************* isEditMode ***************************************/
  isEditMode = () => {
    this.setState(prevState => ({
      isEditMode: !prevState.isEditMode
    }))
  };

  updateBooking = e => {
    console.log("Clicked!")
    e.preventDefault();
    const form = this.props.form;
    this.props.form.validateFieldsAndScroll((err, values) => {
      console.log('check !err', !err)
      console.log('check error values', values)
      if (!err) {
        // console.log('Received values of form: ', values);
        this.state.activity_booking_group['activity_bookings_attributes'][0]['id'] = this.state.modalData[0].activity_bookings[0].id;

        let outputJson = {
          activity_booking_group: this.state.activity_booking_group
        }

        console.log("This is the outputJson", outputJson);
        var formData = jsonToFormData(outputJson);

        let url = './api/v1/activities/' + this.state.modalData[0].id + '/update_bookings?taggable_type=' + this.state.modalData[0].taggable_type;
        var that = this

        API.patch(url, formData)
          .then(function (response) {
            console.log(response, '------------res');
            that.setState({
              isLoading: false,
            })
            if (response.data.message == 'success'){
              that.setState({
                isEditMode: false
              })
              that.apiFetch();
              message.success('Updated');
            }else{
              message.error(response.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
            message.error('Error while updating...');
          })
          .then(function () {
            // always executed
          });
      }
    });
  };

  proceedPayment = e => {
    console.log("proceedPayment Clicked!")
    e.preventDefault();

    let outputJson = {
      taggable_type: this.state.modalData[0].taggable_type
    }
    console.log("This is the outputJson", outputJson);

    this.props.history.push({
        pathname: '/bookings/activities/'+ this.state.modalData[0].id + '/payment',
        search: "?" + qs.stringify(outputJson)
    });
  };

  apiFetch(params = '') {
    let url = './api/v1/activities/bookings';
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          data: response.data,
          isLoading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }

  componentDidMount() {
    this.apiFetch();
  }

  render() {
    const { data, modalData } = this.state;
    const { getFieldDecorator } = this.props.form;
    const activity = modalData[0];
    console.log('data-ac----', data)
    console.log('modalData-ac----', modalData)

    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    const columns = [
      {
        title: 'Booking Date',
        width: 100,
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'Order No.',
        width: 130,
        dataIndex: 'order_no',
        key: 'order_no',
      },
      {
        title: 'Ref No.',
        width: 130,
        dataIndex: 'ref_no',
        key: 'ref_no',
      },
      {
        title: 'Name',
        width: 150,
        dataIndex: 'agent_name',
        key: 'agent_name',
      },
      {
        title: 'Package',
        dataIndex: 'package_name',
        key: 'package_name',
        width: 240,
      },
      // {
      //   title: 'Price',
      //   width: 150,
      //   dataIndex: 'total_price',
      //   key: 'price',
      // },  
      // {
      //   title: 'Quantity',
      //   width: 100,
      //   dataIndex: 'total_quantity',
      //   key: 'quantity',
      // },
      {
        title: 'Status',
        key: 'payment_status',
        dataIndex: 'payment_status',
        width: 100,
        render: (payment_status) => (
          <span>
            <Tag 
              color={
                payment_status == 'Paid' ? 'green' : 
                payment_status == 'Partial Paid' ? 'blue' : 
                payment_status == 'Pending' ? 'orange' :
                payment_status == 'Refunded' || payment_status == 'Cancelled' ? '' : 
                payment_status == "Failed" ? 'red' :
                ''
              } 
              key={0}>
              {payment_status}
            </Tag>
          </span>
        ),
      },
      {
        title: 'Action',
        width: 100,
        key: 'action',
        dataIndex: 'order_no',
        render: (record) => (
          <Button type="link" onClick={() => this.showModal(record)} >View</Button>
        ),
      },
    ];


    return (
      <div id="ActivityContainer">
        <MenuBooking selected="activity" />
        <div className="content">
          <p className="title">Activity Bookings</p>
          <Table
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            // expandedRowRender={expandedRowRender}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        </div>
        <Modal
          // title="Order Information"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="bookingModal_sty"
          okText="DOWNLOAD INVOICE"
          cancelText="RESEND CONFIRMATION"
          footer={
            modalData.length === 0 ? null :
              <span>
                { 
                  this.state.isEditMode && activity.editable ? 
                    <Button type="primary" onClick={() => { this.isEditMode() }} >CANCEL</Button> : '' 
                }
                { 
                  this.state.isEditMode && activity.editable ? 
                    <Button type="primary" onClick={this.updateBooking} >SAVE</Button> : '' 
                }
                { 
                  !this.state.isEditMode && activity.editable ? 
                    <Button type="primary" onClick={() => { this.isEditMode() }} >EDIT</Button> : '' 
                }
                {
                  !this.state.isEditMode && activity.payable ? 
                    <Button type="primary" onClick={this.proceedPayment}>PAY NOW</Button> : ''
                }
                {
                  !this.state.isEditMode && activity.invoices !== undefined && activity.invoices.length > 0 ? 
                    activity.invoices.length > 1 ?
                      <Dropdown overlay={
                        <Menu>
                        {
                          activity.invoices.map((invoice, index) => {
                            return(
                              <Menu.Item key={index}>
                                <a href={invoice.url} target="_blank">Invoice {index + 1}</a>
                              </Menu.Item>
                            )
                          })
                        }
                        </Menu>
                      }>
                        <Button type="primary">
                          DOWNLOAD INVOICES <Icon type="down" />
                        </Button>
                      </Dropdown> : 
                      <Button type="primary">
                        <a href={activity.invoices[0].url} target="_blank">DOWNLOAD INVOICE</a>
                      </Button> 
                  : ''
                }
                {
                  !this.state.isEditMode && activity.receipts !== undefined && activity.receipts.length > 0 ? 
                    activity.receipts.length > 1 ?
                      <Dropdown overlay={
                        <Menu>
                        {
                          activity.receipts.map((receipt, index) => {
                            return(
                              <Menu.Item key={index}>
                                <a href={receipt.url} target="_blank">Receipt {index + 1}</a>
                              </Menu.Item>
                            )
                          })
                        }
                        </Menu>
                      }>
                        <Button type="primary">
                          DOWNLOAD RECEIPTS <Icon type="down" />
                        </Button>
                      </Dropdown> : 
                      <Button type="primary">
                        <a href={activity.receipts[0].url} target="_blank">DOWNLOAD RECEIPT</a>
                      </Button> 
                  : ''
                }
                {
                  !this.state.isEditMode && activity.credit_notes !== undefined && activity.credit_notes.length > 0 ? 
                    activity.credit_notes.length > 1 ?
                      <Dropdown overlay={
                        <Menu>
                        {
                          activity.credit_notes.map((credit_note, index) => {
                            return(
                              <Menu.Item key={index}>
                                <a href={credit_note.url} target="_blank">Credit Note {index + 1}</a>
                              </Menu.Item>
                            )
                          })
                        }
                        </Menu>
                      }>
                        <Button type="primary">
                          DOWNLOAD CREDIT NOTES <Icon type="down" />
                        </Button>
                      </Dropdown> : 
                      <Button type="primary">
                        <a href={activity.credit_notes[0].url} target="_blank">DOWNLOAD CREDIT NOTES</a>
                      </Button> 
                  : ''
                }
              </span>
          }
        >
          {
            modalData.length === 0 ? null :
              <div className="table_order">
                <h2>Order Information</h2>
                <div className="responsive_sroll">
                  <table className="booking_modal_table">
                    <tr>
                      <th>Order No</th>
                      <td>{activity.order_no}</td>
                      <th>Package</th>
                      <td>{activity.package_name}</td>
                    </tr>
                    <tr>
                      <th>Country</th>
                      <td>{activity.country}</td>
                      <th>Quantity</th>
                      <td>{activity.total_quantity}</td>
                    </tr>
                    <tr>
                      <th>Visit Date</th>
                      <td>{activity.visit_date}</td>
                      <th>Price</th>
                      <td>{activity.total_price}</td>
                    </tr>
                    <tr>
                      <th>Surcharge</th>
                      <td>{activity.total_service_fee}</td>
                      <th>Status</th>
                      <td>
                        <Tag 
                        color={
                          activity.payment_status == 'Paid' ? 'green' : 
                          activity.payment_status == 'Partial Paid' ? 'blue' : 
                          activity.payment_status == 'Awaiting' ? 'geekblue' : 
                          activity.payment_status == 'Pending' ? 'orange' :
                          activity.payment_status == 'Refunded' || activity.payment_status == 'Cancelled' ? '' : 
                          activity.payment_status == "Failed" ? 'red' :
                          ''
                        } 
                        key={0}>
                          {activity.payment_status}
                        </Tag>
                      </td>
                    </tr>
                  </table>
                  <br/>
                  <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                    <div>
                      <p className="title">Contact Details</p>
                      <p className="line"></p>
                      <div className="input_warp">
                        {/*************************** Name ***************************/}
                        <div className="formItemWarp50">
                          <Form.Item label="Guest Name">
                            {getFieldDecorator('name', {
                              initialValue: activity.name,
                              rules: [{ required: true, message: '*Please fill in the name', }],
                            })(
                              <Input onChange={(e) => this.handleBookingDataChange('name', e.target.value)} placeholder="Guest Name" disabled={!this.state.isEditMode} />
                            )}
                          </Form.Item>
                        </div>
                      
                        {/*************************** Mobile ***************************/}
                        <div className="formItemWarp50">
                          <Form.Item label="Guest Mobile">
                            {getFieldDecorator('mobile', {
                              initialValue: activity.mobile,
                              rules: [{ required: true, message: '*Please fill in the phone number', }],
                            })(
                              <Input onChange={(e) => this.handleBookingDataChange('mobile', e.target.value)} placeholder="Guest Mobile" disabled={!this.state.isEditMode} />
                            )}
                          </Form.Item>
                        </div>

                      {/*************************** Email ***************************/}
                        <div className="formItemWarp50">
                          <Form.Item label="Guest E-mail">
                            {getFieldDecorator('email', {
                              initialValue: activity.email,
                              rules: [{ required: true, message: '*Please fill in the email', }],
                            })(
                              <Input placeholder="Guest E-mail" onChange={(e) => this.handleBookingDataChange('email', e.target.value)} disabled={!this.state.isEditMode} />
                            )}
                          </Form.Item>
                        </div>

                        {/*************************** Remark ***************************/}
                        <div className="formItemWarp50">
                          <Form.Item label="Remark">
                            {getFieldDecorator('remark', {
                              initialValue: activity.remark,
                            })(
                              <Input placeholder="Remark" onChange={(e) => this.handleBookingDataChange('remark', e.target.value)} disabled={!this.state.isEditMode} />
                            )}
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
          }
        </Modal>
      </div >
    );
  }
}

const ActivityBookingForm = Form.create({ name: 'Activity' })(ActivityBooking);
export default withRouter(ActivityBookingForm);