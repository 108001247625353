import React, { Component } from 'react';
import { Card, Button, List, Spin, Skeleton } from 'antd';
import './index.css'
import moment from 'moment'

const passengerCategoryMapping = { 'ADT': 'Adult', 'CNN': 'Child', 'INF': 'Infant' }

class RightPanel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  // showTitle = (journeys) => {
  //   if (journeys.length === 2) {
  //     return `Round Trip Flight: ${journeys[0].departure_airport.name} - ${journeys[0].arrival_airport.name}`
  //   } else if (journeys.length > 2) {
  //     return `Multi City Flight: ${journeys.map((journey) => ( `${journey.departure_airport.name} - ${journey.arrival_airport.name}` )).join(', ')}`
  //   } else {
  //     return `One Way Flight: ${journeys[0].departure_airport.name} - ${journeys[0].arrival_airport.name}`
  //   }
  // };

  // showDate = (journeys) => {
  //   return `${journeys[0].departure_date} - ${journeys[journeys.length - 1].departure_date}` 
  // };

  render() {
    const data = this.props.dataList;
    const dataSource = this.props.dataList.journeys
    const final_price = this.props.final_price
    const service_fee = this.props.service_fee
    const payment_type = this.props.payment_type

    if (payment_type == "public_bank" || payment_type == "public_bank_2") {
      var final_total = final_price + service_fee
    }
    else {
      var final_total = final_price
    }

    // console.log("check =======> ", this.props.addonData)

    return (
      <div className="RightPanel res_panel_warp">
        <Card title={[<h2>Summary</h2>]}>
          {
            this.props.data.flight !== undefined ? 
            (
              <React.Fragment>
              {
                this.props.data.flight.ttl !== undefined ?
                (
                  <div className="dates">
                    <p className="date_title">Ticketing Time Limit (TTL)</p>
                    <p className="date_select">{moment(this.props.data.flight.ttl).format("YYYY-MM-DD HH:mm")}</p>
                  </div>
                )
                :
                (
                  null
                )
              }
              <div className="dates">
                <p className="date_title">Dates</p>
                <p className="date_select">{this.props.data.flight.dates}</p>
              </div>

              <div className="dates">
                <p className="date_title">Flight</p>
                <p className="date_select">{this.props.data.flight.title}</p>
              </div>

              <div className="dates">
                <p className="date_title">PNR</p>
                <p className="pnr_select">GDS PNR : {this.props.data.flight.booking_id}</p>
                <p className="date_select">Airlines PNR : {this.props.data.flight.supplier_code}</p>
              </div>

              <div className="dates">
                <p className="date_title">Fare breakdown</p>
                {/* <p className="date_select">-</p> */}
              </div>

              <div className="select_list">
                <List
                  bordered={false}
                  dataSource={this.props.data.flight.pricings}
                  renderItem={item => (
                    <List.Item>
                      <div className="list_warp">
                        <span>{passengerCategoryMapping[item.code]} x {item.count}</span>
                        <span>RM {this.priceFormat(item.base)}</span>
                        <div>
                          <span>Tax</span>
                          <span>RM {this.priceFormat(item.taxes)}</span>
                        </div>
                        <hr />
                        <div>
                          <span>Add Ons</span>
                        </div>
                        {
                          this.props.data.flight.journeys.map((journey, journey_index) => {
                            return (
                              <React.Fragment>
                                <div>
                                  <span><b>{journey.departure_airport.iata}-{journey.arrival_airport.iata}</b></span>
                                </div>
                                {
                                  this.props.data.booking_group !== undefined ?
                                  (
                                    <React.Fragment>
                                      {
                                        this.props.data.booking_group.booking_addons.find(x => x.journey === `${journey.departure_airport.iata}-${journey.arrival_airport.iata}`).passengers.map((passenger, passenger_index) => {
                                            return (
                                              <div>
                                                <span style={{ color: 'blue' }}>Passenger {passenger_index + 1}</span>
                                                {
                                                  passenger.segments.map((segment, segment_index) => {
                                                    return (
                                                      <div style={{ marginLeft: '5px' }}>
                                                        <span style={{ color: 'orange' }}>{segment.segment_name}</span>
                                                        {
                                                          segment.addons.map((addon, addon_index) => {
                                                            const addonKind = addon.kind.includes('meal') ? "Meal" : addon.kind.includes('baggage') ? "Baggage" : addon.kind.includes('seat') ? "Seat" : addon.kind

                                                            return (
                                                              <div style={{ marginLeft: '10px' }}>
                                                                <span><b>{addonKind}:</b> {addon.name.length > 10 ? addon.name.substring(0, 10 - 3) + "..." : addon.name}</span>
                                                                <span>RM {parseFloat(addon.amount || 0).toFixed(2)}</span>
                                                              </div>
                                                            )
                                                          })
                                                        }
                                                      </div>
                                                    )
                                                  })
                                                }
                                              </div>
                                            )
                                        })
                                      }
                                    </React.Fragment>
                                  )
                                  :
                                  (
                                   <React.Fragment>
                                      {
                                        this.props.addonData.meals !== undefined ?
                                        (
                                          <React.Fragment>
                                          {
                                            this.props.addonData.meals.filter(x => x.board_off_point === `${journey.departure_airport.iata}-${journey.arrival_airport.iata}`).length > 0 ?
                                            (
                                              <React.Fragment>
                                              {
                                                this.props.addonData.meals.filter(x => x.board_off_point === `${journey.departure_airport.iata}-${journey.arrival_airport.iata}`).map((meal) => {
                                                  return (
                                                    <div>
                                                      <span>Meal: {meal.name.length > 10 ? meal.name.substring(0, 10 - 3) + "..." : meal.name}</span>
                                                      <span>RM {parseFloat(meal.amount || 0).toFixed(2)}</span>
                                                    </div>
                                                  )
                                                })
                                              }
                                              </React.Fragment>
                                            )
                                            :
                                            (
                                              null
                                            )
                                          }
                                          </React.Fragment>
                                        )
                                        :
                                        (
                                          null
                                        )
                                      }
                                      {
                                        this.props.addonData.baggages !== undefined ?
                                        (
                                          <React.Fragment>
                                          {
                                            this.props.addonData.baggages.filter(x => x.board_off_point === `${journey.departure_airport.iata}-${journey.arrival_airport.iata}`).length > 0 ?
                                            (
                                              <React.Fragment>
                                              {
                                                this.props.addonData.baggages.filter(x => x.board_off_point === `${journey.departure_airport.iata}-${journey.arrival_airport.iata}`).map((baggage) => {
                                                  return (
                                                    <div>
                                                      <span>Baggage: {baggage.name.length > 15 ? baggage.name.substring(0, 15 - 3) + "..." : baggage.name}</span>
                                                      <span>RM {parseFloat(baggage.amount || 0).toFixed(2)}</span>
                                                    </div>
                                                  )
                                                })
                                              }
                                              </React.Fragment>
                                            )
                                            :
                                            (
                                              null
                                            )
                                          }
                                          </React.Fragment>
                                        )
                                        :
                                        (
                                          null
                                        )
                                      }
                                      {
                                        this.props.addonData.seats !== undefined ?
                                        (
                                          <React.Fragment>
                                          {
                                            this.props.addonData.seats.filter(x => x.board_off_point === `${journey.departure_airport.iata}-${journey.arrival_airport.iata}`).length > 0 ?
                                            (
                                              <React.Fragment>
                                              {
                                                this.props.addonData.seats.filter(x => x.board_off_point === `${journey.departure_airport.iata}-${journey.arrival_airport.iata}`).map((seat) => {
                                                  return (
                                                    <div>
                                                      <span>Seat: {seat.code}</span>
                                                      <span>RM {parseFloat(seat.amount || 0).toFixed(2)}</span>
                                                    </div>
                                                  )
                                                })
                                              }
                                              </React.Fragment>
                                            )
                                            :
                                            (
                                              null
                                            )
                                          }
                                          </React.Fragment>
                                        )
                                        :
                                        (
                                          null
                                        )
                                      }
                                   </React.Fragment>
                                  )
                                }
                              </React.Fragment>
                            )
                          })
                        }
                      </div>
                    </List.Item>
                  )}
                />

                {
                  (payment_type == "public_bank" || payment_type == "public_bank_2") ?
                    <div className="list_warp">
                      <span>Service Fees</span>
                      <span>RM {service_fee > 0 ? this.priceFormat(service_fee) : this.priceFormat(service_fee)}</span>
                    </div>
                  : ''
                }
              </div>

              <div className="total">
                <span>Total</span><span>RM {this.priceFormat(final_total)}</span>
              </div>
              </React.Fragment>
            )
            :
            (
              <Skeleton active />
            )
          }
        </Card>
      </div>
    );
  }
}

export default RightPanel;
