import React, { Component } from 'react';
import { Form, Input, Button, Icon, AutoComplete, Select } from 'antd';
import moment from 'moment';
import Fuse from 'fuse.js';
import { withRouter, Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import qs from 'qs';
import API from '../../../api';

const { Option, OptGroup } = AutoComplete;

function onBlur() {
    console.log('blur');
}



class Wifi extends Component {
    constructor(props) {
        super(props);
        const wifi_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        this.state = {
            showItems: 100,
            keyword: wifi_params['keyword'] != undefined ? wifi_params['keyword'] : '',
            destinations: '',
        };
        this.submitForm = this.submitForm.bind(this);

    }


    componentDidMount() {
        this.countryFetch();
    }

    countryFetch() {
        let url = './api/v1/wifis/country_list';
        var that = this;
        // console.log(params);

        API.get(url)
            .then(function (response) {
                // console.log(response, '------------res country');
                that.setState({
                    destinations: response.data
                })
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    /****************************** submitForm *******************************/
    submitForm(event) {
        const { keyword } = this.state;
        event.preventDefault();

        let outputJson = {
            keyword: keyword
        };

        console.log("This is the outputJson", outputJson);

        let url = './api/v1/wifis';
        var that = this;
        that.props.history.push({
            pathname: '/wifis',
            search: "?" + qs.stringify(outputJson),
            // search: "?" + formurlencoded(outputJson, { skipIndex: true }),
        });
    }

    handleShowMore() {
        this.setState({
            showItems:
                this.state.showItems >= this.state.items.length ?
                    this.state.showItems : this.state.showItems + 10
        })
    }

    handleDestinationChange = value => {
        this.setState({ 
            keyword: value
        });
    };

    render() {
        if (this.state.destinations.countries == undefined || this.state.destinations.countries === '') {
          var countries = [];
        }
        else {
          var countries = this.state.destinations.countries
          var dataCountries = [];
          countries.map((country,index) => 
            dataCountries.push({"name": country})
          )
        }

        var dataSource = [
          { "title": "Countries", "children": dataCountries }
        ]

        const options = dataSource
          .map(item => (
            <OptGroup key={item.title} label={<strong>{item.title}</strong>}>
                {item.children && item.children.map(opt => (
                  <Option key={opt.name} value={opt.name}>
                    {opt.name}
                  </Option>
                ))}
            </OptGroup>
          ))

        return (
            <div className="Wifi" >
                <form onSubmit={this.submitForm} className="form_warp">
                        <div className="form_layout">
                            <label>Keywords</label>
                            <div className="certain-category-search-wrapper">
                              <AutoComplete
                                className="certain-category-search"
                                dropdownClassName="certain-category-search-dropdown"
                                allowClear={true}
                                dropdownMatchSelectWidth={false}
                                dropdownStyle={{ width: 300 }}
                                style={{ width: '100%' }}
                                dataSource={options}
                                placeholder="Search package, country..."
                                optionLabelProp="value"
                                filterOption={true}
                                optionFilterProp={"children"}
                                // onChange={this.handleKeywordChange}
                                onChange={this.handleDestinationChange}
                                defaultValue={this.state.keyword}
                              >
                                <Input onKeyPress={this.handleKeyPress} suffix={<Icon type="search" className="certain-category-icon" />} />
                              </AutoComplete>
                            </div>
                        </div>

                    
                    <div className="btn_submit">
                        <Button type="primary" onClick={this.submitForm} icon="search" size="default" className="search_btn" id="btn_submit_wifi">
                            Search
                        </Button>
                    </div>

                </form>


            </div>
        );
    }
}

export default withRouter(Wifi);
