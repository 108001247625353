import React, { Component } from 'react';
import { Table, Divider, Tag, Typography, Modal, Dropdown, Button, Menu, Form, Input, Radio, Select, DatePicker, Upload, Icon, Checkbox, message} from 'antd';
import MenuBooking from '../menu';
import { Link, withRouter } from 'react-router-dom';
import API from '../../../../api'
import './index.css';
import moment from 'moment';
import jsonToFormData from 'json-form-data';
import qs from 'qs';

const { Paragraph } = Typography;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const expandedRowRender = (record) => {
  const columns1 = [
    { title: 'Code', dataIndex: 'code', key: 'code' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Mobile', dataIndex: 'mobile', key: 'mobile' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Price', dataIndex: 'total_price', key: 'total_price' },
    { title: 'Redeemed At', dataIndex: 'redeemed_at', key: 'redeemed_at' },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      render: remark => (
        <Paragraph ellipsis={{ rows: 3, expandable: true }}>
          {remark}
        </Paragraph>
      ),
    },
  ]
  return <div>
    <Table columns={columns1} dataSource={record.bookings} pagination={false} />
  </div>;

}

class VoucherListing extends Component {
  constructor(props) {
    super(props);
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    console.log(params)

    this.state = {
      details: [],
      loading: false,
      moadlVisible: false,
      visible: false,
      modalData: [],
      sorting_types: [],
      book_date_from: '',
      book_date_to: '',
      order_no: '',
      code: '',
      voucher_status: '',
      sort_type: '',
    }
  }

  toSnakeCase(str) {
    return str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('_');
  }

  showModal(record) {
    this.setState({
      visible: true,
      modalData: this.state.details.filter((element) => {
        return element.id == record
      })
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };


  componentDidMount() {
    this.apiFetch();
    this.sortTypeFetch();
  }

  apiFetch(params = '') {
    let url = './api/v1/vouchers/listings';
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          details: response.data,
          isLoading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }

  sortTypeFetch() {
    let url = './api/v1/vouchers/listing_sort';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          sorting_types: response.data,
          loading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  handleSearchChange = (type, value) => {
    this.setState({
      [type]: value
    })
  };

  processSolutions(data) {
    const { book_date_from, book_date_to, order_no, code, voucher_status, sort_type } = this.state
    
    if (data && data.length) {
      if (book_date_from !== "" && book_date_to !== "") {
        data = data.filter(i => i.book_date >= book_date_from && i.book_date <= book_date_to);
      } else if (book_date_from === "" && book_date_to !== "") {
        data = data.filter(i => i.book_date <= book_date_to);
      } else if (book_date_from !== "" && book_date_to === "") {
        data = data.filter(i => i.book_date >= book_date_from);
      }

      if (order_no !== "") {
        data = data.filter(i => order_no === i.grouping_id);
      }

      if (code !== "") {
        data = data.filter(i => code === i.code);
      }

      if (voucher_status !== "") {
        data = data.filter(i => voucher_status === i.voucher_status);
      }

      if (sort_type == 'order_no_asc') {
        return data.sort((a,b) => (a.grouping_id > b.grouping_id) ? 1 : ((b.grouping_id > a.grouping_id) ? -1 : 0));
      } else if (sort_type == 'order_no_desc') {
        return data.sort((a,b) => (b.grouping_id > a.grouping_id) ? 1 : ((a.grouping_id > b.grouping_id) ? -1 : 0));
      } else if (sort_type == 'voucher_ref_asc') {
        return data.sort((a,b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0));
      } else if (sort_type == 'voucher_ref_desc') {
        return data.sort((a,b) => (b.code > a.code) ? 1 : ((a.code > b.code) ? -1 : 0));
      } else if (sort_type == 'book_date_asc') {
        return data.sort(function (a, b) {
          var dateA = new Date(a.book_date), dateB = new Date(b.book_date)
          return dateA - dateB
        });
      } else if (sort_type == 'book_date_desc') {
        return data.sort(function (a, b) {
          var dateA = new Date(a.book_date), dateB = new Date(b.book_date)
          return dateB - dateA
        });
      } else if (sort_type == 'voucher_status_asc') {
        return data.sort((a,b) => (a.voucher_status > b.voucher_status) ? 1 : ((b.voucher_status > a.voucher_status) ? -1 : 0));
      } else if (sort_type == 'voucher_status_desc') {
        return data.sort((a,b) => (b.voucher_status > a.voucher_status) ? 1 : ((a.voucher_status > b.voucher_status) ? -1 : 0));
      } else if (sort_type == 'tour_code_asc') {
        return data.sort(function(a, b) {
            return (a.tour_code === null) - (b.tour_code === null) || +(a > b) || -(a < b);
        });
      } else if (sort_type == 'tour_code_desc') {
        return data.sort(function(a, b) {
            return (b.tour_code === null) - (a.tour_code === null) || +(b > a) || -(b < a);
        });  
      }
    }
    return data
  }

  render() {
    const { details, modalData, sorting_types } = this.state;
    const voucherData = modalData[0];
    const data = this.processSolutions(details)
    // console.log('modalData-------', modalData)
    // console.log('data-------', data)
    // console.log('voucherData-------', voucherData)

    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    const columns = [
      {
        title: 'Order No.',
        width: 130,
        dataIndex: 'grouping_id',
        key: 'grouping_id',
      },
      // {
      //   title: 'Voucher Ref.',
      //   dataIndex: 'code',
      //   key: 'code',
      //   width: 120,
      //   render: (record) => (
      //     <Button type="link" onClick={() => this.showModal(record)}>{record}</Button>
      //   ),
      // },
      {
        title: 'Booking Date',
        width: 120,
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'Customer Name',
        width: 150,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Voucher Value',
        width: 130,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: 'Redeemed For',
        width: 150,
        dataIndex: 'tour_code',
        key: 'tour_code',
      },
      {
        title: 'Pax Name',
        width: 150,
        dataIndex: 'pax_name',
        key: 'pax_name',
      },
      {
        title: 'Amount',
        width: 130,
        dataIndex: 'tour_amount',
        key: 'tour_amount',
      },
      {
        title: 'Tour Order No.',
        width: 200,
        dataIndex: 'tour_order_no',
        key: 'tour_order_no',
      },
      {
        title: 'Redeem Date',
        width: 200,
        dataIndex: 'redeemed_at',
        key: 'redeemed_at',
      },
      {
        title: 'Status',
        key: 'voucher_status',
        dataIndex: 'voucher_status',
        width: 80,
        render: (voucher_status) => (
          <span>
            <Tag color={voucher_status == 'Paid' ? 'green' : voucher_status == 'Failed' ? 'red' : voucher_status == 'Pending' ? 'geekblue' : voucher_status == 'Expired' ? 'orange' : voucher_status == 'Redeemed' ? 'purple' : '' } key={0}>
              {voucher_status}
            </Tag>
          </span>
        ),
      },
      {
        title: 'Action',
        width: 80,
        key: 'action',
        dataIndex: 'id',
        render: (record) => (
          <Button type="link" onClick={() => this.showModal(record)}>View</Button>
        ),
      },
    ];
    return (
      <div id="VoucherContainer">
        <MenuBooking selected="voucher" />
        <div className="content">
          <p className="title">Voucher Listing</p>
          <Form {...formItemLayout} onSubmit={this.submitSearch}>
            <React.Fragment>
              <div className="input_warp">
                {/*************************** Book Date ***************************/}
                <div className="formItemWarp15">
                  <Form.Item label="Book Date From">
                    <DatePicker
                      format={dateFormat}
                      className="voucher_datepicker"
                      placeholder={'From'}
                      defaultValue={this.state.book_date_from != '' ? moment(this.state.book_date_from) : ''}
                      defaultPickerValue={moment()}
                      onChange={(date, dateString) => this.handleSearchChange('book_date_from', dateString)}
                    />
                  </Form.Item>
                </div>
                <div className="formItemWarp15">
                  <Form.Item label="Book Date To">
                    <DatePicker
                      format={dateFormat}
                      className="voucher_datepicker"
                      placeholder={'To'}
                      defaultValue={this.state.book_date_to != '' ? moment(this.state.book_date_to) : ''}
                      defaultPickerValue={moment()}
                      onChange={(date, dateString) => this.handleSearchChange('book_date_to', dateString)}
                    />
                  </Form.Item>
                </div>
                <div className="formItemWarp15">
                  <Form.Item label="Order No.">
                    <Input onChange={(e) => this.handleSearchChange('order_no', e.target.value)} defaultValue={this.state.order_no} />
                  </Form.Item>
                </div>
                {/*<div className="formItemWarp15">
                  <Form.Item label="Voucher Ref.">
                    <Input onChange={(e) => this.handleSearchChange('code', e.target.value)} defaultValue={this.state.code} />
                  </Form.Item>
                </div>*/}
                <div className="formItemWarp15">
                  <Form.Item label="Voucher Status">
                    <Select
                      showSearch
                      placeholder="Select voucher status"
                      // optionFilterProp="children"
                      onChange={(value) => this.handleSearchChange('voucher_status', value)}
                      // onFocus={onFocus}
                      // onBlur={onBlur}
                      // onSearch={onSearch}
                      defaultValue={this.state.voucher_status}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      <Option value="Paid">Paid</Option>
                      <Option value="Failed">Failed</Option>
                      <Option value="Redeemed">Redeemed</Option>
                      <Option value="Expired">Expired</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="formItemWarp15">
                  <Form.Item label="Sort Type">
                    <Select placeholder="Select Sort Type" optionFilterProp="children"
                      onChange={(value) => this.handleSearchChange('sort_type', value)}
                      defaultValue={this.state.sort_type}
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {
                        sorting_types && sorting_types.map((items, i) => {
                          return (
                            <Option key={i} value={this.toSnakeCase(items)}>{items}</Option>
                          )
                        })
                      }
                    </Select>
                  </Form.Item>
                </div>   
              </div>
              <div className="btn_submit">
                {/*<Button type="danger" onClick={this.submitSearch}>Search</Button>*/}
              </div>
            </React.Fragment>
          </Form>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            // expandedRowRender={expandedRowRender}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        </div>

        <Modal
          // title="Order Information"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="bookingModal_sty"
          //okText="DOWNLOAD INVOICE"
          //cancelText="RESEND CONFIRMATION"
          // cancelButtonProps={{ style: { display: 'none' } }}
          footer={null}
        >
          {
            modalData.length === 0 ? null :
              <div className="table_order">
                <h2>Order Information</h2>
                <div className="responsive_sroll">
                  <table className="booking_modal_table">
                    <tbody>
                      <tr>
                        {/*<th>Voucher Ref.</th>
                        <td>{voucherData.code}</td>*/}
                        <th colSpan="1">Voucher Name</th>
                        <td colSpan="3"><a href={voucherData.voucher_url}>{voucherData.voucher}</a></td>
                      </tr>
                      <tr>
                        <th>Agent</th>
                        <td>{voucherData.agent}</td>
                        <th>Name</th>
                        <td>{voucherData.name}</td>
                      </tr>
                      <tr>
                        <th>Mobile No.</th>
                        <td>{voucherData.mobile}</td>
                        <th>Email</th>
                        <td>{voucherData.email}</td>
                      </tr>
                      <tr>
                        <th>Redeemed At</th>
                        <td>{voucherData.redeemed_at}</td>
                        <th>Redeemed Tour</th>
                        <td>{voucherData.tour_code}</td>
                      </tr>
                      <tr>
                        <th>Price</th>
                        <td>{voucherData.price}</td>
                        <th>Status</th>
                        <td>
                          <Tag 
                            color={
                              voucherData.payment_status == 'Paid' ? 'green' :  
                              voucherData.payment_status == 'Pending' ? 'orange' :
                              voucherData.payment_status == 'Refunded' || voucherData.payment_status == 'Cancelled' ? 'red' : 
                              ''
                            } 
                            key={0}>
                            {voucherData.payment_status}
                          </Tag>
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="1">Entitlement(s)</th>
                        <td colSpan="3">
                          {
                            voucherData.entitles.map((ent, index) => {
                              return(
                                <div key={index}>
                                  <a href={ent.url}>{ent.title}</a>
                                </div>
                              )
                            })
                          }
                        </td>
                      </tr>
                      <tr>
                        <th colSpan="1">Remark</th>
                        <td colSpan="3">{voucherData.remark}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          }
        </Modal>
      </div>
    );
  }
}

export default VoucherListing;