import React, { Component } from 'react';
import { Table, Tag, Typography, Modal, Button, } from 'antd';
import MenuBooking from '../menu';
import { Link } from 'react-router-dom';
import API from '../../../../api'
import './index.css';

const { Paragraph } = Typography;



const expandedRowRender = (record) => {
  const columns1 = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Mobile', dataIndex: 'mobile', key: 'mobile' },
    { title: 'Email', dataIndex: 'email', key: 'email' },
    { title: 'Depart Date', dataIndex: 'depart_date', key: 'depart_date' },
    { title: 'Return Date', dataIndex: 'return_date', key: 'return_date' },
    { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
    {
      title: 'Remark',
      dataIndex: 'remark',
      key: 'remark',
      width: 200,
      render: remark => (
        <Paragraph ellipsis={{ rows: 3, expandable: true }}>
          {remark}
        </Paragraph>
      ),
    },
  ]
  return <div>
    <Table columns={columns1} dataSource={record.bookings} pagination={false} />
  </div>;

}

class WifiBooking extends Component {
  state = {
    data: [],
    loading: false,
    visible: false,
    modalData: []
  };

  showModal(record) {
    this.setState({
      visible: true,
      modalData: this.state.data.filter((element) => {
        return element.id == record
      })
    });
  };

  handleOk = e => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };


  componentDidMount() {
    this.apiFetch();
  }

  apiFetch(params = '') {
    let url = './api/v1/wifis/bookings';
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          data: response.data,
          isLoading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }

  render() {
    const { data, modalData } = this.state;
    const wifiData = modalData[0];
    // console.log('modalData-------', modalData)
    // console.log('data-------', data)
    // console.log('wifiData-------', wifiData)



    const columns = [
      {
        title: 'Booking Date',
        width: 150,
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'Order No.',
        width: 150,
        dataIndex: 'order_no',
        key: 'order_no',
      },
      {
        title: 'Ref No.',
        width: 150,
        dataIndex: 'ref_no',
        key: 'ref_no',
      },
      {
        title: 'Name',
        width: 150,
        dataIndex: 'guest_name',
        key: 'guest_name',
      },
      {
        title: 'Package',
        dataIndex: 'code',
        key: 'code',
        width: 150,
      },
      // {
      //   title: 'Price',
      //   width: 150,
      //   dataIndex: 'total_price',
      //   key: 'total_price',
      // },  
      // {
      //   title: 'Quantity',
      //   width: 100,
      //   dataIndex: 'total_quantity',
      //   key: 'total_quantity',
      // },
      {
        title: 'Status',
        key: 'payment_status',
        dataIndex: 'payment_status',
        width: 100,
        render: (payment_status) => (
          <span>
            <Tag 
              color={
                payment_status == 'Paid' ? 'green' : 
                payment_status == 'Partial Paid' ? 'blue' : 
                payment_status == 'Pending' ? 'orange' :
                payment_status == 'Refunded' || payment_status == 'Cancelled' ? '' : 
                payment_status == "Failed" ? 'red' :
                ''
              } 
              key={0}>
              {payment_status}
            </Tag>
          </span>
        ),
      },
      {
        title: 'Action',
        width: 100,
        key: 'action',
        dataIndex: 'id',
        render: (record) => (
          <Button type="link" onClick={() => this.showModal(record)}>View</Button>
        ),
      },
    ];
    return (
      <div id="WifiContainer">
        <MenuBooking selected="wifi" />
        <div className="content">
          <p className="title">Wifi Bookings</p>
          <Table
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            // expandedRowRender={expandedRowRender}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        </div>

        <Modal
          // title="Order Information"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="bookingModal_sty"
          okText="DOWNLOAD INVOICE"
          cancelText="RESEND CONFIRMATION"
          // cancelButtonProps={{ style: { display: 'none' } }}
          footer={null}
        >
          {
            modalData.length === 0 ? null :
              <div className="table_order">
                <h2>Order Information</h2>
                <div className="responsive_sroll">
                  <table className="booking_modal_table">
                    <tr>
                      <th>Package</th>
                      <td>{wifiData.package_name}</td>
                      <th>Pick Up Point</th>
                      <td>{wifiData.pickup_point}</td>
                    </tr>
                    <tr>
                      <th>Price</th>
                      <td>{wifiData.total_price}</td>
                      <th>Surcharge</th>
                      <td>{wifiData.total_service_fee}</td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td>{wifiData.bookings[0].name}</td>
                      <th>Mobile</th>
                      <td>{wifiData.bookings[0].mobile}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{wifiData.bookings[0].email}</td>
                      <th>Depart Date</th>
                      <td>{wifiData.bookings[0].depart_date}</td>
                    </tr>
                    <tr>
                      <th>Return Date</th>
                      <td>{wifiData.bookings[0].return_date}</td>
                      <th>Quantity</th>
                      <td>{wifiData.bookings[0].quantity}</td>
                    </tr>
                    <tr>
                      <th>Remark</th>
                      <td>
                        <Paragraph ellipsis={{ rows: 1, expandable: true }}>
                          {wifiData.bookings[0].remark}
                        </Paragraph>
                      </td>
                      <th></th>
                      <td></td>
                    </tr>
                  </table>
                </div>
                <div className="btn_modal_booking">
                  <span>
                    {/* <Button href={wifiData.invoice_url} target="_blank">RESEND CONFIRMATION</Button> */}
                    {
                      wifiData.payable ? 
                        <Button type="primary"><Link to={{pathname: "/bookings/wifis/"+wifiData.id+"/payment"}}>PAY NOW</Link></Button> : '' 
                    }
                    {
                      wifiData.invoices !== undefined && wifiData.invoices.length > 0 ? 
                        wifiData.invoices.length > 1 ?
                          <Dropdown overlay={
                            <Menu>
                            {
                              wifiData.invoices.map((invoice, index) => {
                                return(
                                  <Menu.Item key={index}>
                                    <a href={invoice.url} target="_blank">Invoice {index + 1}</a>
                                  </Menu.Item>
                                )
                              })
                            }
                            </Menu>
                          }>
                            <Button type="primary">
                              DOWNLOAD INVOICES <Icon type="down" />
                            </Button>
                          </Dropdown> : 
                          <Button type="primary">
                            <a href={wifiData.invoices[0].url} target="_blank">DOWNLOAD INVOICE</a>
                          </Button> 
                      : ''
                    }
                    {
                      wifiData.receipts !== undefined && wifiData.receipts.length > 0 ? 
                        wifiData.receipts.length > 1 ?
                          <Dropdown overlay={
                            <Menu>
                            {
                              wifiData.receipts.map((receipt, index) => {
                                return(
                                  <Menu.Item key={index}>
                                    <a href={receipt.url} target="_blank">Receipt {index + 1}</a>
                                  </Menu.Item>
                                )
                              })
                            }
                            </Menu>
                          }>
                            <Button type="primary">
                              DOWNLOAD RECEIPTS <Icon type="down" />
                            </Button>
                          </Dropdown> : 
                          <Button type="primary">
                            <a href={wifiData.receipts[0].url} target="_blank">DOWNLOAD RECEIPT</a>
                          </Button> 
                      : ''
                    }
                    {
                      wifiData.credit_notes !== undefined && wifiData.credit_notes.length > 0 ? 
                        wifiData.credit_notes.length > 1 ?
                          <Dropdown overlay={
                            <Menu>
                            {
                              wifiData.credit_notes.map((credit_note, index) => {
                                return(
                                  <Menu.Item key={index}>
                                    <a href={credit_note.url} target="_blank">Credit Note {index + 1}</a>
                                  </Menu.Item>
                                )
                              })
                            }
                            </Menu>
                          }>
                            <Button type="primary">
                              DOWNLOAD CREDIT NOTES <Icon type="down" />
                            </Button>
                          </Dropdown> : 
                          <Button type="primary">
                            <a href={wifiData.credit_notes[0].url} target="_blank">DOWNLOAD CREDIT NOTES</a>
                          </Button> 
                      : ''
                    }
                  </span>
                </div>

              </div>
          }
        </Modal>


      </div>
    );
  }
}

export default WifiBooking;