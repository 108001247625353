import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input } from 'antd';
import './reset_password_form_modal.css';

class ResetPasswordFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
    }; 
  }

  showModal = () => {
    this.props.form.resetFields()
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.handleSubmit(e)
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          confirmLoading: true,
        })

        this.props.apiPatchSubagent(this.props.record.key, values)
        .then((success) => {
          if (success) {
            this.props.form.resetFields()
            this.setState({
              visible: false,
            })
          }
          this.setState({
            confirmLoading: false,
          })
        })
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['password_confirmation'], { force: true });
    }
    callback();
  };

  render() {
    const { visible, confirmLoading } = this.state;
    const { record } = this.props
    const { getFieldDecorator } = this.props.form;
    const title = `Reset Password for ${record.username}`
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <div id="reset-password">
        <a type="primary" onClick={this.showModal}>
          Reset Password
        </a>
        <Modal
          title={title}
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
        >
          <div id="reset-password-modal-body">
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Form.Item label="Password" hasFeedback>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password />)}
              </Form.Item>
              <Form.Item label="Confirm Password" hasFeedback>
                {getFieldDecorator('password_confirmation', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}

ResetPasswordFormModal.propTypes = {
  record: PropTypes.any.isRequired,
  apiPatchSubagent: PropTypes.func.isRequired,
  openNotification: PropTypes.func.isRequired,
};

const WrappedResetPasswordForm = Form.create({ name: 'subagent_reset_password' })(ResetPasswordFormModal)
export default WrappedResetPasswordForm;
