import React, { Component } from 'react';
import { Form, Input, Button, Icon, AutoComplete, Select, DatePicker, Modal } from 'antd';
import moment from 'moment';
import Fuse from 'fuse.js';
import { withRouter, Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import qs from 'qs';
import API from '../../../api';

const { Option, OptGroup } = AutoComplete;
const { MonthPicker } = DatePicker;
const monthFormat = 'YYYY/MM';
const dateFormat = 'YYYY/MM/DD';

class Cruise extends Component {

  constructor(props) {
    super(props);
    const series_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.state = {
      keyword: series_params['keyword'] != undefined ? series_params['keyword'] : '',
      month: series_params['month'] != undefined ? series_params['month'] : '',
      depart_date: series_params['depart_date'] != undefined ? series_params['depart_date'] : '',
      destinations: '',
      itineraries: '',
      maintainanceModal: false
    }
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
      this.countryFetch();
      this.itineraryFetch();
      // Change Moment.js "Invalid Date" message
      moment.updateLocale(moment.locale(), { invalidDate: '' });
  }

  countryFetch() {
      let url = './api/v1/series/cruise_country_list';
      var that = this;
      // console.log(params);

      API.get(url)
          .then(function (response) {
              // console.log(response, '------------res country');
              that.setState({
                  destinations: response.data
              })
          })
          .catch(function (error) {
              console.log(error);
          })
          .then(function () {
              // always executed
          });
  }

  itineraryFetch() {
      let url = './api/v1/series/cruise_itinerary_list';
      var that = this;
      // console.log(params);

      API.get(url)
          .then(function (response) {
              // console.log(response, '------------res country');
              that.setState({
                  itineraries: response.data
              })
          })
          .catch(function (error) {
              console.log(error);
          })
          .then(function () {
              // always executed
          });
  }

  /****************************** submitForm *******************************/
  submitForm(event) {
      // event.preventDefault();
      // if(homepage_new.suka === 'true' && moment().isBetween('2023-05-03', '2023-05-08') && moment().isBetween(moment('18:00:00', 'HH:mm:ss'), moment('5:59:59', 'HH:mm:ss'))){
        // this.setState({
          // maintainanceModal: true,
        // });
      // }else{
        let outputJson = {
          keyword: this.state.keyword,
          month: this.state.month,
          depart_date: this.state.depart_date,
          type: "cruise"
        };

        console.log("This is the outputJson", outputJson);
        console.log(qs.stringify(outputJson))
        console.log(this.props)

        this.props.history.push({
            pathname: '/series',
            search: "?" + qs.stringify(outputJson)
        });      
      // }

  }

  /****************************** monthOnChange *******************************/
  monthOnChange(dateString) {
    this.setState({ 
      month: moment(dateString).format(monthFormat)
    });
  }

  /****************************** dateOnChange *******************************/
  dateOnChange(dateString) {
    this.setState({ 
      depart_date: moment(dateString).format(dateFormat)
    });
  }

  handleFormChange(type, value){
    this.setState({
      [type]: value
    })
  }

  // handleKeywordChange = value => {
  //   if (value == undefined) {
  //     this.setState({ 
  //       keyword: ''
  //     });
  //   } else {
  //     this.setState({ 
  //       keyword: value
  //     });
  //   }
  //   console.log("selected", value)
  // };

  handleButtonPress = () => {
    this.submitForm();
  };

  handleKeyPress = event => {
    if (event.key == 'Enter') {
      this.submitForm();
    }
  };

  onCloseModal = (modal) => {
    this.setState({
      maintainanceModal: false
    });
  };

  render() {
    if (this.state.destinations.countries == undefined || this.state.destinations.countries === '') {
      var countries = [];
    }
    else {
      var countries = this.state.destinations.countries
      var dataCountries = [];
      countries.map((country,index) => 
        dataCountries.push({"name": country})
      )
    }

    if (this.state.itineraries.codes == undefined || this.state.itineraries.codes === '') {
      var codes = [];
    }
    else {
      var codes = this.state.itineraries.codes
      var dataCodes = [];
      codes.map((code) => 
        dataCodes.push({"name": code[1]})
      )
    }

    var dataSource = [
      { "title": "Destinations", "children": dataCountries },
      { "title": "Itineraries", "children": dataCodes }
    ]

    const options = dataSource
      .map(item => (
        <OptGroup key={item.title} label={<strong>{item.title}</strong>}>
            {item.children && item.children.map(opt => (
              <Option key={opt.name} value={opt.name}>
                {opt.name}
              </Option>
            ))}
        </OptGroup>
      ))

    return (
      <div className="Cruise" >
        <form onSubmit={this.submitForm} className="form_warp">
          <div className="form_layout">
            <label>{homepage_new.suka === 'false' ? "Keyword" : "Destinasi"}</label>
            <div className="certain-category-search-wrapper">
              <AutoComplete
                className="certain-category-search"
                dropdownClassName="certain-category-search-dropdown"
                allowClear={true}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: 300 }}
                style={{ width: '100%' }}
                dataSource={options}
                placeholder={homepage_new.suka === 'false' ? "Search caption, destination, tour..."  : "Carian destinasi"}
                optionLabelProp="value"
                filterOption={true}
                optionFilterProp={"children"}
                // onChange={this.handleKeywordChange}
                onChange={(value) => this.handleFormChange('keyword', value)}
                defaultValue={this.state.keyword}
              >
                <Input onKeyPress={this.handleKeyPress} suffix={<Icon type="search" className="certain-category-icon" />} />
              </AutoComplete>
            </div>
          </div>
          {
            // homepage_new.suka === 'true' ? (
            //   <div className="form_layout">
            //     <label>Travelling Date</label>
            //     <DatePicker 
            //       placeholder="YYYY/MM/DD"
            //       defaultValue={this.state.depart_date !== '' ? moment(this.state.depart_date, dateFormat) : ''} 
            //       disabledDate={current => { return moment().add(-1, 'day') >= current || moment().add(24, 'month') <= current }}
            //       onChange={(date, dateString) => this.dateOnChange(dateString)}
            //     />
            //   </div>
            // ) : (
              <div className="form_layout">
                <label>{homepage_new.suka === 'false' ? "Travelling Month" : "Tarikh"}</label>
                <MonthPicker
                  placeholder="YY/MM"
                  onChange={(date, dateString) => this.monthOnChange(dateString)}
                  defaultValue={this.state.month !== '' ? moment(this.state.month, monthFormat) : ''}
                  disabledDate={current => { return moment().add(-1, 'month') >= current || moment().add(24, 'month') <= current }}
                />
              </div>
            // )
          }
          <div className="btn_submit">
            <Button type="primary" onClick={this.handleButtonPress} icon="search" size="default" className="search_btn">
                {homepage_new.suka === 'false' ? "Search"  : "Carian"}
            </Button>
          </div>
        </form>
        <Modal
          title="Important Notice"
          style={{ top: 100 }}
          width={450}
          visible={this.state.maintainanceModal}
          closable={false}
          footer={[
            <Button key="no" onClick={() => this.onCloseModal('maintainanceModal')}>
              Ok
            </Button>
          ]}
        >
          <h3><strong>Cruise bookings under maintenance from 6 pm - 6 am. Apologise for the inconvenience.</strong></h3>
        </Modal>

      </div>
    );
  }
}

export default withRouter(Cruise);
