import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom'
import { Form, Input, Select, Button, AutoComplete, Radio, Icon, DatePicker, Menu, Dropdown, Avatar } from 'antd';
import Buttons from './button'
import moment from 'moment';
import countryList from 'react-select-country-list'
import qs from 'qs'
import API from '../../../api';

const { Option, OptGroup } = AutoComplete;
const dateFormat = 'YYYY/MM/DD';

class Activity extends Component {
    constructor(props) {
        super(props);
        const activity_params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        this.state = {
            keyword: activity_params['keyword'] != undefined ? activity_params['keyword'] : '',
            category: 'attraction',
            countries: ''
            // country: '',
            // from_date: '',
            // to_date: ''
        };
        this.submitForm = this.submitForm.bind(this);
    }

    componentDidMount() {
        this.countryFetch();
    }

    /****************************** Travellers HandleMenuClick *******************************/
    handleMenuClick = e => { if (e.key === '4') { this.setState({ visible: false }); } };

    /****************************** Dropdown Function *******************************/
    handleVisibleChange = flag => { this.setState({ visible: flag }); };

    handleFormChange(type, value){
      this.setState({
        [type]: value
      })
    }

    countryFetch() {
        let url = './api/v1/activities/country_list' + '?category=' + this.state.category;
        var that = this;
        // console.log(params);

        API.get(url)
            .then(function (response) {
                // console.log(response, '------------res country');
                that.setState({
                    countries: response.data
                })
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    submitForm(event) {
        event.preventDefault();

        let outputJson = {
          keyword: this.state.keyword,
          category: this.state.category
            // country: this.state.country,
            // from_date: this.state.from_date,
            // to_date: this.state.to_date
        };

        console.log("This is the outputJson", outputJson);
        console.log(qs.stringify(outputJson))
        console.log(this.props)

        this.props.history.push({
            pathname: '/activities',
            search: "?" + qs.stringify(outputJson)
        });      
    }

    render() {
        /****************************** Form Layout Function *******************************/
        //const formItemLayout = formLayout === 'vertical' ? { labelCol: { span: 24 }, wrapperCol: { span: 24 }, } : null;
        // const buttonItemLayout = formLayout === 'vertical' ? { wrapperCol: { span: 24, offset: 24 }, } : null;
        if (this.state.countries.countries == undefined || this.state.countries.countries === '') {
          var countries = [];
        }
        else {
          var countries = this.state.countries.countries
          var dataCountries = [];
          countries.map((country,index) => 
            dataCountries.push({"name": country})
          )
        }

        var dataSource = [
          { "title": "Countries", "children": dataCountries }
        ]

        const options = dataSource
          .map(item => (
            <OptGroup key={item.title} label={<strong>{item.title}</strong>}>
                {item.children && item.children.map(opt => (
                  <Option key={opt.name} value={opt.name}>
                    {opt.name}
                  </Option>
                ))}
            </OptGroup>
          ))

        return (
            <div class="Activity" >
            
                <form onSubmit={this.submitForm} className="form_warp">
                    
                        <div className="form_layout">
                            <label>Keyword</label>
                            <AutoComplete
                              className="certain-category-search"
                              dropdownClassName="certain-category-search-dropdown"
                              allowClear={true}
                              dropdownMatchSelectWidth={false}
                              dropdownStyle={{ width: 300 }}
                              style={{ width: '100%' }}
                              dataSource={options}
                              placeholder="Search country, title..."
                              optionLabelProp="value"
                              filterOption={true}
                              optionFilterProp={"children"}
                              onChange={(value) => this.handleFormChange('keyword', value)}
                              value={this.state.keyword}
                            >
                              <Input onKeyPress={this.handleKeyPress} suffix={<Icon type="search" className="certain-category-icon" />} />
                            </AutoComplete>
                        </div>
                        {/*<div className="form_layout">
                            <label>Country</label>
                            <Select showSearch placeholder="Select"
                              // onChange={onChangeSelect}
                              defaultValue={this.state.country}
                              onChange={(value) => this.handleFormChange('country', value)}
                            >
                              {
                                countryList().getData().map((country) => {
                                  return (
                                    <Option key={country.value} value={country.label}>{country.label}</Option>
                                  )
                                })
                              }
                            </Select>
                        </div>*/}
                        {/*<div className="form_layout">
                            <label>From</label>
                            <DatePicker
                                defaultValue={this.state.from_date != '' ? moment(this.state.from_date) : '' }
                                format={dateFormat}
                                disabledDate={current => {
                                    return current && current < moment().add(2, "day");
                                }}
                                onChange={(date, dateString) => this.handleFormChange('from_date', dateString)}
                            />
                        </div>
                        <div className="form_layout">
                            <label>To</label>
                            <DatePicker
                                defaultValue={this.state.to_date != '' ? moment(this.state.to_date) : '' }
                                format={dateFormat}
                                disabledDate={current => {
                                    return current && current < moment().add(2, "day");
                                }}
                                onChange={(date, dateString) => this.handleFormChange('to_date', dateString)}
                            />
                        </div>*/}
                        <div className="btn_submit">
                            <Link to="/activities">
                                <Button type="primary" onClick={this.submitForm} icon="search" size="default" className="search_btn">
                                    Search 
                                </Button>
                            </Link>
                        </div>
                    

                </form>

            </div>
        );
    }
}

export default withRouter(Activity);
