import React, { Component } from 'react';
import NavigationBar from './navigation_bar';
import { withRouter } from 'react-router-dom';
import { Layout, notification } from 'antd';
import API from '../../../api'
import './index.css';
import SubagentTable from './subagent_table';
import NewSubagentFormModal from './new_subagent_form_modal';
import ErrorIcon from './error_icon'
import SuccessIcon from './success_icon'

const { Content, Header } = Layout;

class SubagentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      subagents: [],
      isLoading: true,
      countries: [],
    }
  }

  componentDidMount() {
    this.fetchUserDetail();
    this.fetchSubagents();
    this.fetchCountries();
  }

  fetchUserDetail = () => {
    let that = this
    API.get('./api/v1/users/details')
      .then(function (response) {
        that.setState({
          user: response.data,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
  }

  fetchSubagents = () => {
    let that = this
    API
      .get('./api/v1/subagents')
      .then(function (response) {
        that.setState({
          subagents: response.data.data,
        })
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        that.setState({
          isLoading: false
        })
      }); 
  }

  fetchCountries = () => {
    let that = this
    API.get('./api/v1/countries')
    .then(function (response) {
      that.setState({
        countries: response.data.data.map(country => country.attributes.name),
      })
    })
    .catch(function (error) {
      console.log(error);
    })
  }

  appendNewUser = (user) => {
    let newSubagents = [user, ...this.state.subagents]
    this.setState({
      subagents: newSubagents,
    })
  }

  openNotification = (success, title, message) => {
    notification.open({
      message: title,
      description: message,
      icon: success ? <SuccessIcon /> : <ErrorIcon />,
    });
  };
  
  render() {
    const { isLoading, subagents, user, countries } = this.state

    return (
      <div id="subagents" className={homepage_new.suka === "false" ? "" : "suka"}>
        <NavigationBar user={this.state.user} />
        <div className="content">
          <div>
            <p className="title">Subagents</p>
            { user.is_master_agent &&
              <NewSubagentFormModal
                appendNewUser={this.appendNewUser}
                openNotification={this.openNotification}
                countries={countries}
              />
            }
            <SubagentTable
              isLoading={isLoading}
              subagents={subagents}
              isMasterAgent={user.is_master_agent}
              openNotification={this.openNotification}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SubagentListing);
