import React, { Component } from 'react';
import { Table, Tag, Typography, Modal, Button, } from 'antd';
import MenuBooking from '../menu';
import { Link } from 'react-router-dom';
import API from '../../../../api'
import './index.css';

const { Paragraph } = Typography;

const expandedRowRender = (record) => {
  const columns1 = [
    { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
    { title: 'Age', dataIndex: 'age', key: 'age' },
    { title: 'Guest Type', dataIndex: 'guest_type', key: 'guest_type' },
  ]
  return <div>
    <Table columns={columns1} dataSource={record.guests} pagination={false} />
  </div>;

}

class HotelBooking extends Component {
  state = {
    data: [],
    loading: false,
    visible: false,
    modalData: []
  };

  showModal(record) {
    console.log('record', record)
    this.setState({
      visible: true,
      modalData: this.state.data.filter((element) => {
        return element.id == record
      })
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.apiFetch();
  }

  apiFetch(params = '') {
    let url = './api/v1/hotels/bookings';
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          data: response.data,
          isLoading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }

  render() {
    const { data, modalData } = this.state;
    const hotel = modalData[0];
    console.log('modalData-----', modalData)
    console.log('data-----', data)

    const columns = [
      {
        title: 'Booking Date',
        width: 100,
        dataIndex: 'created_at',
        key: 'created_at',
      },
      {
        title: 'Order No.',
        width: 100,
        dataIndex: 'confirmation_no',
        key: 'confirmation_no',
      },
      {
        title: 'Ref No.',
        width: 120,
        dataIndex: 'ref_no',
        key: 'ref_no',
      },
      {
        title: 'Name',
        width: 150,
        dataIndex: 'guest_name',
        key: 'guest_name',
      },
      {
        title: 'Hotel Name',
        dataIndex: 'hotel_name',
        key: 'code',
        width: 250,
      },
      // {
      //   title: 'Price',
      //   width: 100,
      //   dataIndex: 'total_price',
      //   key: 'total_price',
      // },
      // {
      //   title: 'Guests',
      //   width: 100,
      //   dataIndex: 'total_quantity',
      //   key: 'total_quantity',
      // },
      {
        title: 'Status',
        key: 'payment_status',
        dataIndex: 'payment_status',
        width: 80,
        render: (payment_status) => (
          <span>
            <Tag color={payment_status == 'Paid' ? 'green' : payment_status == 'Failed' ? 'volcano' : payment_status == 'Pending' ? 'geekblue' : ''} key={0}>
              {payment_status}
            </Tag>
          </span>
        ),
      },
      {
        title: 'Action',
        width: 80,
        key: 'action',
        dataIndex: 'id',
        render: (record) => (
          <Button type="link" onClick={() => this.showModal(record)} >View</Button>
        ),
      },
    ];

    return (
      <div id="HotelContainer">
        <MenuBooking selected="hotel" />
        <div className="content">
          <p className="title">Hotel Bookings</p>
          <Table
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            // expandedRowRender={expandedRowRender}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        </div>
        <Modal
          // title="Order Information"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          className="bookingModal_sty"
          okText="DOWNLOAD INVOICE"
          cancelText="RESEND CONFIRMATION"
          footer={null}
        >
          {
            modalData.length === 0 ? null :
              <div className="table_order">
                <h2>Order Information</h2>
                <div className="responsive_sroll">
                <table className="booking_modal_table">
                  <tr>
                    <th>Confirmation No.</th>
                    <td>{hotel.confirmation_no}</td>
                    <th>Amount Paid</th>
                    <td>{hotel.total_paid_amount}</td>
                  </tr>
                  <tr>
                    <th>Hotel Name</th>
                    <td colspan="3">{hotel.hotel_name}</td>
                  </tr>
                  <tr> 
                    <th>Hotel Address</th>
                    <td colspan="3">{hotel.hotel_address}</td>
                  </tr>
                  <tr>
                    <th>Check-in Date</th>
                    <td>{hotel.check_in}</td>
                    <th>Check-out Date</th>
                    <td>{hotel.check_out}</td>
                  </tr>
                  <tr>
                    <th>Room Quantity</th>
                    <td>{hotel.room_qty}</td>
                    <th>Room Type</th>
                    <td>{hotel.room}</td>
                  </tr>
                  <tr>
                    <th>Price</th>
                    <td>{hotel.total_price}</td>
                    <th>Surcharge</th>
                    <td>{hotel.total_service_fee}</td>
                  </tr>
                  <tr>
                    <th>Special Request</th>
                    <td colspan="3">{hotel.remark}</td>
                  </tr>
                </table>
                { 
                  hotel.guests.map((guest, index) => 
                    <table className="booking_modal_table">
                      <tr>
                        <th colspan={4}>Guest #{index+1}</th>
                      </tr>
                      <tr>
                        <th>First Name</th>
                        <td>{guest.first_name}</td>
                        <th>Last Name</th>
                        <td>{guest.last_name}</td>
                      </tr>
                      <tr>
                        <th>Age</th>
                        <td>{guest.age}</td>
                        <th>Guest Type</th>
                        <td>{guest.guest_type}</td>
                      </tr>
                    </table>
                  )
                }
                </div>
                <div className="btn_modal_booking">
                  <span>
                    { 
                      hotel.payable != undefined && hotel.payable ? 
                        <Button type="primary"><Link to={{pathname: "/bookings/hotels/"+hotel.id+"/payment"}}>PAY NOW</Link></Button> : '' 
                    }
                    {
                      hotel.invoices !== undefined && hotel.invoices.length > 0 ? 
                        hotel.invoices.length > 1 ?
                          <Dropdown overlay={
                            <Menu>
                            {
                              hotel.invoices.map((invoice, index) => {
                                return(
                                  <Menu.Item key={index}>
                                    <a href={invoice.url} target="_blank">Invoice {index + 1}</a>
                                  </Menu.Item>
                                )
                              })
                            }
                            </Menu>
                          }>
                            <Button type="primary">
                              DOWNLOAD INVOICES <Icon type="down" />
                            </Button>
                          </Dropdown> : 
                          <Button type="primary">
                            <a href={hotel.invoices[0].url} target="_blank">DOWNLOAD INVOICE</a>
                          </Button> 
                      : ''
                    }
                    {
                      hotel.receipts !== undefined && hotel.receipts.length > 0 ? 
                        hotel.receipts.length > 1 ?
                          <Dropdown overlay={
                            <Menu>
                            {
                              hotel.receipts.map((receipt, index) => {
                                return(
                                  <Menu.Item key={index}>
                                    <a href={receipt.url} target="_blank">Receipt {index + 1}</a>
                                  </Menu.Item>
                                )
                              })
                            }
                            </Menu>
                          }>
                            <Button type="primary">
                              DOWNLOAD RECEIPTS <Icon type="down" />
                            </Button>
                          </Dropdown> : 
                          <Button type="primary">
                            <a href={hotel.receipts[0].url} target="_blank">DOWNLOAD RECEIPT</a>
                          </Button> 
                      : ''
                    }
                    {
                      hotel.credit_notes !== undefined && hotel.credit_notes.length > 0 ? 
                        hotel.credit_notes.length > 1 ?
                          <Dropdown overlay={
                            <Menu>
                            {
                              hotel.credit_notes.map((credit_note, index) => {
                                return(
                                  <Menu.Item key={index}>
                                    <a href={credit_note.url} target="_blank">Credit Note {index + 1}</a>
                                  </Menu.Item>
                                )
                              })
                            }
                            </Menu>
                          }>
                            <Button type="primary">
                              DOWNLOAD CREDIT NOTES <Icon type="down" />
                            </Button>
                          </Dropdown> : 
                          <Button type="primary">
                            <a href={hotel.credit_notes[0].url} target="_blank">DOWNLOAD CREDIT NOTES</a>
                          </Button> 
                      : ''
                    }
                  </span>
                </div>
              </div>
          }
        </Modal>
      </div>
    );
  }
}

export default HotelBooking;