import React, { Component } from 'react';
import { Card, Divider, Select, Input, DatePicker, Checkbox, Button, List, Modal, Empty, Spin, Skeleton, notification, Form, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment';
import './index.css';
import flight from '../../../../../images/flight.png';
import brb from '../../../../../images/brb.png';
import land from '../../../../../images/land.png';
import warning from '../../../../../images/warning.png';
import { Link, withRouter } from 'react-router-dom';
import countryList from 'react-select-country-list'
import qs from 'qs'
import API from '../../../../api';
import PaymentMethod from '../payment';
import ReactMarkdown from 'react-markdown/with-html';

const { TabPane } = Tabs;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';

const locaData = [
  { kgEmpty: "-" }
]
const passengerCategoryMapping = { 'ADT': 'Adult', 'CNN': 'Child', 'INF': 'Infant' }
const passengerAgeMapping = { 'ADT': 100, 'CNN': 8, 'INF': 2 }

function nl2br (str, is_xhtml) {
   var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
   return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
} 

class LeftPanel extends Component {

  constructor(props) {
    super(props);
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    this.state = {
      payment_type: '',
      payment_method: null,
      detail: [],
      // passengers: [{designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: '', brb: 'No' ,remark: ''}]
      // passengers: [{designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: 'ADT', brb: 'No' ,remark: ''}, {designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: 'ADT', brb: 'No' ,remark: ''}, {designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: 'CNN', brb: 'No' ,remark: ''}, {designation: '', first_name: '', last_name: '', country: '', mobile: '', passport_number: '', passport_issue_date: '', passport_expiry_date: '', date_of_birth: '', category: 'INF', brb: 'No' ,remark: ''}]
      passengers: [],
      validationErrors: [],
      visible: false,
      confirmationVisible: false,
      disabledConfirmationButtion: false,
      check_out_disabled: false,
      loading: true,
      banklist: [],
      pbb_banklist: [],
      offline_banklist: '',
      banklist_select: [],
      method_visible: false,
      credits: 0,
      credit_disabled: false,
      agree: "",
      errorResponse: [],
      errorResponseVisible: false,
      fareRuleModalVisible: false,
      book_success: false,
      book_ttl: '',
      cabin_class: params['cabin_class'] != undefined ? params['cabin_class'] : 'Economy',
      sale_rep: ''
    }
    this.submitForm = this.submitForm.bind(this);
    this.formValidate = this.formValidate.bind(this);
    this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
    // console.log(countryList().getData(), 'countryList')
  }

  /***************************************************** componentDidMount *******************************************************/
  componentDidMount() {
    this.banklistFetch();
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  }

  /***************************************************** componentDidUpdate *******************************************************/
  componentDidUpdate() {
    if (this.props.dataList != this.state.detail) {
      var passengers = [];
      var validationErrors = [];
      // console.log(this.props.selectedData, 'this.props.selectedData');
      if (this.props.selectedData && this.props.selectedData.hasOwnProperty('pricings') && this.props.selectedData.pricings.length > 0) {
        this.props.selectedData.pricings.forEach((pricing) => {
          for (var i = 0; i < pricing.count; i++) {
            passengers.push({ designation: '', first_name: '', last_name: '', country: 'MY', mobile: '',  email:'', passport_number: '', passport_issue_date: '', passport_expiry_date: '', passport_issue_place: 'MY', date_of_birth: '', category: pricing.code, brb: 'No', remark: '' })
            validationErrors.push({ agree: '', payment_type: '', payment_method: null })
          }
        })
      }
      // console.log(passengers, 'passengers');
      this.setState({
        detail: this.props.dataList,
        passengers: passengers,
        validationErrors: validationErrors,
        loading: false
      })
    }
  }

  /***************************************************** banklistFetch function *******************************************************/
  banklistFetch() {
    let url = './api/v1/payments/payment_method';
    var that = this;
    // console.log(params);

    API.get(url)
      .then(function (response) {
        // console.log(response, '------------res banklist--------------------');
        that.setState({
          banklist: response.data.banklist,
          pbb_banklist: response.data.pbb_banklist,
          offline_banklist: response.data.offline_banklist,
          credits: response.data.credits
        })

        if (response.data.credits >= (that.state.detail.total_price + 26 * that.props.brbCount)) {
          that.setState({
            credit_disabled: false
          })
        }
        else {
          that.setState({
            credit_disabled: true
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  /***************************************************** handlePaymentChange function *******************************************************/
  handlePaymentChange(event) {
    // console.log(event.target.value);
    if (event.target.value === 'public_bank') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'public_bank_2') {
      this.setState({
        method_visible: true,
        banklist_select: this.state.pbb_banklist,
        payment_method: null
      })
    }
    else if (event.target.value === 'fpx_b2c') {
      this.banklistFetch();
      this.setState({
        method_visible: true,
        banklist_select: this.state.banklist,
        payment_method: null
      })
    }
    else {
      this.setState({
        banklist_select: [],
        method_visible: false,
        payment_method: null
      })
    }
    this.setState({
      payment_type: event.target.value
    })
  }

  /***************************************************** handlePaymentMethodChange function *******************************************************/
  handlePaymentMethodChange(value) {
    this.setState({
      payment_method: value
    })
  }

  // onChangeLuggageCheckbox(event) {
  //   console.log(event.target.value);
  //   this.setState({
  //     type: event.target.value
  //   })
  // }

  /***************************************************** handleCancelWrong function *******************************************************/
  handleCancelWrong = () => {
    this.setState({
      visible: false,
      disabledConfirmationButtion: false,
    });
    this.props.history.go(-1);
  };

  /***************************************************** handleCancel function *******************************************************/
  handleCancel = () => {
    this.setState({
      visible: false,
      visibleNodata: false,
      errorResponseVisible: false
    });
    this.props.history.go(-1);
  };

  /***************************************************** handleCloseConfirmation function *******************************************************/
  handleCloseConfirmation = () => {
    this.setState({
      confirmationVisible: false,
    });
  };

  /***************************************************** handleOpenConfirmation function *******************************************************/
  handleOpenConfirmation = () => {
    this.setState({
      confirmationVisible: true,
    });
  };

  /***************************************************** handlePassengerDataChange function *******************************************************/
  handlePassengerDataChange = (index, type, value) => {
    // console.log(index, 'index')
    this.state.passengers[index][type] = value;
    if (type == "sale_rep") {
      this.setState({
        sale_rep: value
      });
    }
    else if (type == "brb") {
      const brbCount = this.state.passengers.filter(passenger => passenger["brb"] == "Yes").length
      this.props.handleBrbCount(brbCount);
      // console.log(brbCount, 'brbCount')
      if (this.state.credits >= (this.state.detail.total_price + 26 * brbCount)) {
        this.setState({
          credit_disabled: false
        })
      }
      else {
        this.setState({
          credit_disabled: true
        })
      }
    }
    this.setState({
      passengers: this.state.passengers
    })
  }

  handleTabCallback = (key) => {
    this.props.handleTabCallback(key)
  }

  /***************************************************** openNotificationWithIcon function *******************************************************/
  openNotificationWithIcon = (type, error_name) => {
    if (type === "warning") {
      if (error_name === "payment_method_error") {
        notification[type]({
          message: 'Warning!',
          description:
            'Please select payment method before you continue.',
        });
      }
    }
    if (type === "error") {
      if (error_name === "credits_error") {
        notification[type]({
          message: 'Error!',
          description:
            'Insufficient credits! please purchase more credits.',
        });
      }
    }
  };

  /***************************************************** onChangePaymentCheckBox function *******************************************************/
  onChangePaymentCheckBox(e) {
    let isChecked = e.target.checked === true ? "agree" : "";
    // console.log(`checked = ${isChecked}`);
    this.setState({
      agree: isChecked
    })
    // console.log(`checked = ${e.target.checked}`);
  }

  /***************************************************** formValidate function *******************************************************/
  formValidate = e => {
    e.preventDefault();
    const { payment_type, validationErrors, agree, payment_method } = this.state;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (/* payment_type === "" || agree === "" || /* (payment_type !== "credit_transaction" ? payment_method === null : "") || */ !err === false) {
        if (!err) {
          console.log('Received values of form: ', values);
        }
        // if (payment_type === "") {
        //   this.openNotificationWithIcon('warning','payment_method_error');
        // }
        // if (payment_method === null) {
        //   validationErrors.payment_method = "*Please select your payment method";
        //   this.setState({ validationErrors: validationErrors })
        // } else {
        //   validationErrors.payment_method = null;
        //   this.setState({ validationErrors: validationErrors })
        // }
        // if (agree === "") {
        //   validationErrors.agree = "*Please agreed the Terms of Use and Privacy Statement";
        //   this.setState({ validationErrors: validationErrors })
        // } else {
        //   validationErrors.agree = "";
        //   this.setState({ validationErrors: validationErrors })
        // }
      }
      else {
        this.setState({
          confirmationVisible: true,
        })
      }
    });
  }

  /***************************************************** submitForm function *******************************************************/
  submitForm(event) {
    event.preventDefault();
    this.banklistFetch();

    this.setState({
      disabledConfirmationButtion: true,
      check_out_disabled: true,
    })
    const { passengers, payment_type, payment_method, agree, sale_rep } = this.state;
    const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let outputJson = {
      type: params['type'] != undefined ? params['type'] : '',
      trips: params['trips'] != undefined ? params['trips'] : [{ from: '', to: '', depart: '', return: '' }],
      adults: params['adults'] != undefined ? Number(params['adults']) : 1,
      children: params['children'] != undefined ? Number(params['children']) : 0,
      infants: params['infants'] != undefined ? Number(params['infants']) : 0, 
      cabin_class: params['cabin_class'] != undefined ? params['cabin_class'] : '',
      flight_bookings: passengers,
      code: this.props.selectedData.code,
      sale_rep: sale_rep,
      code: this.props.selectedData.code,
      source: params['source'],
      payment: { gateway: "pay_later", method: payment_method },
      agree: agree
    };
    // console.log("This is the outputJson", outputJson);

    let url = './api/v1' + this.props.location.pathname + '/create_booking/';
    let that = this;

    API.post(url, outputJson)
      .then(function (response) {
        if (response.data.redirect_to) {
          window.location = response.data.redirect_to;
          // that.setState({
          //   book_success: true,
          //   book_ttl: response.data.ttl
          // })
          // window.setTimeout(function(){
          //   window.location = response.data.redirect_to;
          // }, 5000);
        } else if (response.data.credits_error) {
          that.openNotificationWithIcon('error','credits_error');
          that.setState({ payment_type: '', confirmationVisible: false, disabledConfirmationButtion: false, check_out_disabled: false })
        } else {
          that.setState({
            check_out_disabled: false,
            errorResponseVisible: true,
            errorResponse: response.data.error
          })
        }
        that.setState({
          loading: false,
        })
      })
      .catch(function (error) {
        console.log(error);

        that.setState({
          visible: true,
        })
      })
      .then(function () {
        // always executed
      });
  }

  renderFlightDetail(data, dataSource){
    return (
      <div>
        <div className="header_title">
          <p style={{ marginVertical: 20 }}><a onClick={() => { this.setState({ fareRuleModalVisible: true }) }}>Show Fare Rules</a></p>
        </div>
        <List
          bordered={false}
          dataSource={dataSource}
          renderItem={item =>
            <div>
              <Card title={[
                <div className="title_content">
                  <img src={flight} /><span>{item.departure_airport.city} ({item.departure_airport.iata})</span>
                  <FontAwesomeIcon icon='long-arrow-alt-right' />
                  <span>{item.arrival_airport.city} ({item.arrival_airport.iata})</span>
                  {/* <span className="total_travel_time">{item.travel_time}</span> */}
                </div>
              ]} bordered={false} >

                {/* <p>Friday, 23 Jun 2019</p> */}

                <div className="airline_content">
                  <div className="img_warp"><img src={item.airline_image} /></div>
                  <div className="content">
                    <div className="content_title">
                      <span><b className="title_air">{item.airline_name}</b><span className="number">{item.segments[0].carrier} {item.segments[0].flight_number}</span></span>
                      <span className="kg"><FontAwesomeIcon size='lg' icon='suitcase' /> {item.segments[0].baggage_max_weight === "" ? locaData[0].kgEmpty : item.segments[0].baggage_max_weight}</span>
                    </div>
                    <p className="class_type">{item.segments[0].cabin_class}</p>
                    <div className="time_warp">
                      <div className="from">
                        <div className="time">
                          <p className="time_sty">{item.departure_time}</p><p><small>{item.departure_airport.city} ({item.departure_airport.iata})</small></p>
                        </div>
                        <div className="icon"><FontAwesomeIcon size='lg' icon='long-arrow-alt-right' /></div>
                        <div className="clear"></div>
                      </div>
                      <div className="to">
                        <p className="time_sty">{item.arrival_time}</p><p><small>{item.arrival_airport.city} ({item.arrival_airport.iata})</small></p>
                      </div>
                      <div className="stop_time">
                        <p className="time_sty">{item.travel_time}</p><p><small>{item.stop} stop</small></p>
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                  <div className="clear"></div>

                  {
                    item.segments.map((segment) => {
                      return (
                        <div className="air_time">

                          <p className="type">{segment.airline_name} - {segment.carrier} {segment.flight_number} <span className="flight_time">{segment.flight_time}</span></p>
                          <div className="depart">
                            <img src={flight} /><span className="space"><b>{segment.departure_time}</b></span>
                            <span className="space">{segment.departure_date}</span>
                            <span>
                              <b>{segment.departure_airport.city} ({segment.departure_airport.iata})</b>
                            </span>
                          </div>
                          <div className="depart">
                            <img src={land} /><span className="space"><b>{segment.arrival_time}</b></span>
                            <span className="space">{segment.arrival_date}</span>
                            <span>
                              <b>{segment.arrival_airport.city} ({segment.arrival_airport.iata})</b>
                            </span>
                          </div>

                          {segment.transit_time === "" ? (
                            null
                          ) : (
                              <div className="plane">
                                <Divider>TRANSFER DURATION {segment.transit_time}</Divider>
                              </div>
                            )}
                        </div>
                      )
                    })
                  }
                </div>
              </Card>

            </div>
          }
        />
      </div>
    )
  }

  render() {
    /******************************************* state ****************************************/
    //const data = this.props.dataList.journeys[0].departure_time;
    const { visible, banklist_select, payment_type, payment_method, method_visible, offline_banklist, credit_disabled, credits, validationErrors, terms_coditions, termsError, errorResponse, book_success, book_ttl } = this.state;
    const { getFieldDecorator } = this.props.form;
    // const dataSource = this.state.detail.journeys;
    // const data = this.state.detail;
    const selectedData = this.props.selectedData;

    const dataList = this.props.dataList
    const passengers = this.state.passengers;

    // const count = Object.keys(data).length;
    // console.log(this.state.payment_type, '------------payment_type');
    // console.log("check passengers ===> ", passengers)

    const passengerDepatureDate = this.state.detail.journeys && this.state.detail.journeys[0].departure_date;

    /************************************** formItemLayout ****************************************/
    const formItemLayout = {
      labelCol: { xl: { span: 24 }, xl: { span: 24 }, },
      wrapperCol: { xl: { span: 24 }, xl: { span: 24 }, },
    };

    // let getLength = function (obj) {
    //   var i = 0, key;
    //   for (key in obj) {
    //     if (obj.hasOwnProperty(key)) {
    //       i++;
    //     }
    //   }
    //   return i;
    // };

    // const count_journeys_key = getLength(dataSource);
    // console.log(count_journeys_key, '------------countKey');

    return (
      <div className="LeftPanel">
        <div className="flight_details">
          {
            dataList && dataList.solutions && dataList.solutions.length > 0 &&
            (
              <div className="header_title">
                <h1>{selectedData.title}</h1>
                <p className="subtitle">{selectedData.dates} | {selectedData.passenger} </p>
              </div>
            )
            
          }
          {
            dataList && dataList.solutions && dataList.solutions.length == 1 ?
              this.renderFlightDetail(selectedData, selectedData.journeys) :
              <Tabs activeKey={`${this.props.selectedKey}`} onChange={this.handleTabCallback}>
                {
                  dataList && dataList.solutions && dataList.solutions.length > 1 && dataList.solutions.map((data, i) => {
                    return (
                      <TabPane 
                        tab={[
                            <div className="tab_select_style" key={i}>
                              <p className="date_tab">{data.code}</p>
                              <p className="tab_price">RM {this.priceFormat(data.total_price)}</p>
                            </div>
                          ]} 
                        key={i}
                        >
                        {this.renderFlightDetail(data, data.journeys)}
                      </TabPane>
                  )})
                }

              </Tabs>
          }


          {/********************************** Passenger Info **********************************/}
          <div className="header_title">
            <h2>Passenger Info</h2>
          </div>

          {/***************************************************** Form *****************************************************/}
          <Form {...formItemLayout} onSubmit={this.submitForm} className="form_sty_select" >
            <Card className="passenger" loading={this.state.loading}>
              <div className="form_control">
                <Form.Item label="Sales person">
                  {getFieldDecorator('sale_rep', {
                    rules: [{ required: true, pattern: new RegExp("^[A-Za-z]{1}[A-Za-z0-9\\s\\-\\.]{2,}$"), message: '*Please fill in the sales person', }],
                  })(                            
                    <Input onChange={(event) => this.handlePassengerDataChange(0, 'sale_rep', event.target.value)} placeholder="Sales person" />
                  )}
                </Form.Item>
                <br/>
              </div>

              {passengers.map((passenger, index) => {
                {/*console.log(passenger.brb, 'brb');*/}
                return (
                  <div>
                    <div className="passenger_content">
                      <p>Passenger {index + 1}: {passengerCategoryMapping[passenger.category]}</p>
                    </div>

                    <div className="form_passenger">
                      <div className="input_warp">
                        {/************************************** Designation **************************************/}
                        <div className="form_control pandding_right" >
                          <Form.Item label="Designation" >
                            {getFieldDecorator('designation' + index, {
                              initialValue: passenger.designation,
                              rules: [{ required: true, message: '*Please select the designation', }],
                            })(
                              <Select placeholder="Select" onChange={(value) => this.handlePassengerDataChange(index, 'designation', value)}>
                                {
                                  ['Miss', 'Mr', 'Ms', 'Mstr'].map((designation) => {
                                    return (
                                      <Option key={designation + index} value={designation}>{designation}</Option>
                                    )
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </div>

                        {/************************************** Nationality **************************************/}
                        <div className="form_control pandding_left">
                          <Form.Item label="Nationality" >
                            {getFieldDecorator('country' + index, {
                              initialValue: passenger.country,
                              rules: [{ required: true, message: '*Please select the Nationality', }],
                            })(
                              <Select showSearch placeholder="Select" onChange={(value) => this.handlePassengerDataChange(index, 'country', value)}>
                                {
                                  countryList().getData().map((country) => {
                                    return (
                                      <Option key={country.value + index} value={country.value}>{country.label}</Option>
                                    )
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input_warp">
                        {/************************************** Name **************************************/}
                        <div className="form_control pandding_right">
                          <Form.Item label="First & Middle Name(As per passport)" >
                            {getFieldDecorator('first_name' + index, {
                              initialValue: passenger.first_name,
                              rules: [{ required: true, message: '*Please fill in the name', }],
                            })(
                              <Input placeholder="Name" onChange={(event) => this.handlePassengerDataChange(index, 'first_name', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>

                        {/************************************** last_name **************************************/}
                        <div className="form_control pandding_left">
                          <Form.Item label="Last/Family Name(As per passport)" >
                            {getFieldDecorator('last_name' + index, {
                              initialValue: passenger.last_name,
                              rules: [{ required: true, message: '*Please fill in the surname', }],
                            })(
                              <Input placeholder="Surname" onChange={(event) => this.handlePassengerDataChange(index, 'last_name', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input_warp">
                        {/************************************** Passport number **************************************/}
                        <div className="form_control pandding_right">
                          <Form.Item label="Passport number" >
                            {getFieldDecorator('passport_number' + index, {
                              initialValue: passenger.passport_number,
                              rules: [{ required: true, message: '*Please fill in the passport number', }],
                            })(
                              <Input placeholder="Passport number" onChange={(event) => this.handlePassengerDataChange(index, 'passport_number', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>
                        

                        {/************************************** Passport issue date **************************************/}
                        {/*<div className="form_control pandding_left">
                          <Form.Item label="Passport issue date" >
                            {getFieldDecorator('issue_date' + index, {
                              initialValue: passenger.passport_issue_date,
                              rules: [{ required: true, message: '*Please select the issue date', }],
                            })(
                              <DatePicker
                                format={dateFormat}
                                onChange={(date, dateString) => this.handlePassengerDataChange(index, 'passport_issue_date', dateString)}
                                disabledDate={current => {
                                  return (current && current > moment())
                                }}
                              />
                            )}
                          </Form.Item>
                        </div>*/}

                        {/************************************** Passport expiry date **************************************/}
                        <div className="form_control pandding_left">
                          <Form.Item label="Passport expiry date" >
                            {getFieldDecorator('expiry_date' + index, {
                              initialValue: passenger.passport_expiry_date,
                              rules: [{ required: true, message: '*Please select the expiry date', }],
                            })(
                              <DatePicker
                                format={dateFormat}
                                onChange={(date, dateString) => this.handlePassengerDataChange(index, 'passport_expiry_date', dateString)}
                                disabledDate={current => {
                                  return (passengerDepatureDate && current < moment(passengerDepatureDate))
                                }}
                              />
                            )}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input_warp">
                        {/************************************** Date of birth **************************************/}
                        <div className="form_control pandding_right">
                          <Form.Item label="Date of birth" >
                            {getFieldDecorator('birth_date' + index, {
                              initialValue: passenger.date_of_birth,
                              rules: [{ required: true, message: '*Please select the date of birth', }],
                            })(
                              <DatePicker
                                format={dateFormat}
                                onChange={(date, dateString) => this.handlePassengerDataChange(index, 'date_of_birth', dateString)}
                                disabledDate={current => {
                                  return current < moment().add(-passengerAgeMapping[passenger.category], 'year').startOf('day') || current > moment().toDate()
                                }}
                              />
                            )}
                          </Form.Item>
                        </div>

                        {/************************************** Mobile **************************************/}
                        <div className="form_control pandding_left">
                          <Form.Item label="Mobile" >
                            {getFieldDecorator('mobile' + index, {
                              initialValue: passenger.mobile,
                              rules: [{ required: true, message: '*Please fill in the mobile number', }],
                            })(
                              <Input placeholder="Mobile number" onChange={(event) => this.handlePassengerDataChange(index, 'mobile', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>
                      </div>

                      <div className="input_warp">
                        {/************************************** Email **************************************/}
                        <div className="form_control pandding_right">
                          <Form.Item label="Email" >
                            {getFieldDecorator('email' + index, {
                              initialValue: passenger.email,
                              rules: [{ required: true, message: '*Please fill in the email address', }],
                            })(
                              <Input placeholder="Email" onChange={(event) => this.handlePassengerDataChange(index, 'email', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>

                        {/************************************** Remark **************************************/}
                        <div className="form_control pandding_left">
                          <Form.Item label="Remark" >
                            {getFieldDecorator('remark' + index)(
                              <Input placeholder="Additional..." onChange={(event) => this.handlePassengerDataChange(index, 'remark', event.target.value)} />
                            )}
                          </Form.Item>
                        </div>
                      </div>


                      {/************************************** Lost Luggage Protection **************************************/}
                      <div className="luggage_protection">
                        <Card title="Lost Luggage Protection"
                          extra={
                            <div className="icon_style">
                              <p><small>*Services provided by</small></p>
                              {/* <FontAwesomeIcon icon='suitcase' /> */}
                              <img src={brb} alt="brb" />
                            </div>
                          }
                        >
                          <div className="content">
                            <p>
                              For just MYR26 per person,this service provides an
                              additional search for your missing baggage.</p>
                            <p className="content_tick">
                              <FontAwesomeIcon icon='check' className="check" />If your luggage does not arrive at the destination in 4 days (96 hours),
                              you'll be paid USD1,000
                              </p>
                            <p className="content_tick">
                              <FontAwesomeIcon icon='check' className="check" />Receive constant email/sms updates during location and transportation to the
                              destination airport.
                             </p>

                            <div className="check_box"
                              onChange={(event) => this.handlePassengerDataChange(index, 'brb', event.target.value)}
                            >
                              <div className="box_type">
                                <input type="radio" id={"select_yes" + index} name={"select" + index} value="Yes" />
                                <label htmlFor={"select_yes" + index} >
                                  Yes, I want the service.
                                 </label>
                              </div>

                              <div className="box_type">
                                <input type="radio" id={"select_no" + index} name={"select" + index} value="No" defaultChecked={true} />
                                <label htmlFor={"select_no" + index} >
                                  No, Thank you.
                                 </label>
                              </div>
                              <div className="clear"></div>
                            </div>
                            <p>
                              <small>The service applies to all checked luggage;does not exclude any possible refunds
                                from the airline or insurance company <span className="terms">(Terms & Conditions)</span></small>
                            </p>
                          </div>
                        </Card>
                      </div>
                      <div className="clear"></div>
                    </div>
                  </div>
                )
              })
              }
            </Card>

            {/************************************************ Payment Method ************************************************/}
            {/* <div className="header_title">
              <h2>Your Payment Method</h2>
            </div>

            <PaymentMethod handlePaymentChange={this.handlePaymentChange.bind(this)} no_credit_payment={true} credit_disabled={credit_disabled} fpx_disabled={false}
              credits={credits} payment_type={payment_type} payment_method={payment_method} method_visible={method_visible} validationErrors={validationErrors} banklist_select={banklist_select}
              offline_banklist={offline_banklist} handlePaymentMethodChange={(value) => this.handlePaymentMethodChange(value)}
              onChangePaymentCheckBox={(e) => this.onChangePaymentCheckBox(e)}
            />
            */}
            <div className="after_select">
              <Button type="primary" disabled={this.state.check_out_disabled} onClick={this.formValidate}>BOOK NOW</Button>
            </div> 

          </Form>
        </div>

      {/************************************************ modal book_success ************************************************/}
        <Modal
          visible={this.state.book_success}
          title={null}
          footer={null}
          closable={null}
          destroyOnClose={true}
        >
          <Empty
            image={warning}
            imageStyle={{ height: 100, }}
            description={<p className="popup_text">Your ticketing deadline is by <strong>{book_ttl}</strong></p>}
            className="popup_footer"
          >
            
          </Empty>
        </Modal>


        {/************************************************ modal errorResponseVisible ************************************************/}
        <Modal
          visible={this.state.errorResponseVisible}
          title={null}
          footer={null}
          closable={null}
          destroyOnClose={true}
        >
          <Empty
            image={warning}
            imageStyle={{ height: 100, }}
            description={<p className="popup_text">{errorResponse}</p>}
            className="popup_footer"
          >
            <Button type="primary" onClick={this.handleCancel}>Close</Button>
          </Empty>
        </Modal>

        {/************************************************ modal no data popup ************************************************/}
        <Modal
          visible={this.props.jsonLength}
          title={null}
          footer={null}
          closable={this.handleCancel}
          destroyOnClose={this.handleCancel}
        >
          <Empty
            image={warning}
            imageStyle={{ height: 100, }}
            description={<p className="popup_text">The seats are no longer available. Sorry for inconvenient.</p>}
            className="popup_footer"
          >
            <Button type="primary" onClick={this.handleCancel}>Close</Button>
          </Empty>
        </Modal>

        {/************************************************ modal submit problems ************************************************/}
        <Modal
          visible={this.state.visible}
          title={null}
          footer={null}
          closable={null}
          destroyOnClose={true}
        >
          <Empty
            image={warning}
            imageStyle={{ height: 100, }}
            description={<p className="popup_text">There are some problems with your submit.</p>}
            className="popup_footer"
          >
            <Button type="primary" onClick={this.handleCancelWrong}>Close</Button>
          </Empty>
        </Modal>

        {/************************************************ modal confirmation submit ************************************************/}
        <Modal
          visible={this.state.confirmationVisible}
          title="Important Message"
          onOk={this.submitForm}
          onCancel={this.handleCloseConfirmation}
          okButtonProps={{ disabled: this.state.disabledConfirmationButtion }}
          cancelButtonProps={{ disabled: this.state.disabledConfirmationButtion }}
        >
          <h3>Attention:</h3>
          <p>Thank you for making booking with us.</p>
          <p>Please be noted that no ticket will issued until full payment received.</p>
          <strong>TOTAL PRICE: RM {this.priceFormat(selectedData.total_price + (26 * this.props.brbCount))}</strong>
          <p>Additional handling fees may apply.</p>
          <p>After booking submit, kindly contact our ticketing team for further assistance.</p>
          <p>Confirm to submit this booking transaction?</p>
        </Modal>

        {/************************************************ modal confirmation submit ************************************************/}
        <Modal
          visible={this.state.fareRuleModalVisible}
          title="Fare Rule Details"
          closable={() => { this.setState({ fareRuleModalVisible: false }) }}
          destroyOnClose={() => { this.setState({ fareRuleModalVisible: false }) }}
          onCancel={() => { this.setState({ fareRuleModalVisible: false }) }}
          footer={null}
        >
          <p>{selectedData.fare_rule_details !== undefined && selectedData.fare_rule_details != null ? <ReactMarkdown source={nl2br(selectedData.fare_rule_details[0])} escapeHtml={false} /> : ""}</p>
          {/*<p style={{whiteSpace: 'pre-line'}}>{data.fare_rule_details !== undefined && data.fare_rule_details != null ? data.fare_rule_details[0] : ""}</p>*/}
        </Modal>

      </div >
    );
  }
}

const FlightShowPage = Form.create({ name: 'flght' })(LeftPanel);
export default withRouter(FlightShowPage);
