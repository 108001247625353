import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import MenuBooking from "../../booking/menu";
import "./index.css";
import { Button } from 'antd';
import Filter from './filter';
import StatisticCards from './statisticCards';
import axios from "../../../../api";
import moment from 'moment';


class ListingStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      displayStatistic: true,
      statisticData: {},
      currencyUnit: 'RM',
      default_filter: {
        booking_date_start: moment().startOf('month').format('YYYY-MM-DD'),
        booking_date_end: moment().endOf('month').format('YYYY-MM-DD'),
      }
    }
  }

  fetchStatisticData = (filters = {}) => {
    this.setState({ isLoading: true })

    axios.get('/api/v1/series/booking_statistics', {
      params: {
        ...filters
      }
    })
    .then((response) => {
      this.setState({
        statisticData: {
          totalPax: parseInt(response.data.data.attributes.total_pax),
          totalAmount: parseFloat(response.data.data.attributes.total_amount),
          totalOutstandingAmount: parseFloat(response.data.data.attributes.total_outstanding_amount),
        },
        displayStatistic: true
      })
      
    })
    .catch((error) => {
      console.error(error)
      this.setState({
        displayStatistic: false
      })
    })
    .finally(()=> {
      this.setState({
        isLoading: false
      })
    })
  }

  componentDidMount() {
    this.fetchStatisticData(this.state.default_filter);
  }

  render() {
    const { isLoading, currencyUnit, displayStatistic } = this.state

    return (
      <div id="listing-statistics">
        <MenuBooking selected="listing_statistics" />
        <div className="content">
          <p className="title">Booking Statistics</p>
          <Filter fetchStatisticData={this.fetchStatisticData} appType={homepage_new.app_type} />
          {
            displayStatistic && <StatisticCards isLoading={isLoading} {...this.state.statisticData} amountUnit={currencyUnit} />
          }
        </div>
      </div>
    )
  }
}

export default withRouter(ListingStatistic)
