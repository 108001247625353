import React, { Component } from "react";
import { Card, Icon, Radio, Collapse, Slider, InputNumber, Checkbox, Switch } from "antd";
import "./index.css";
import withRouter from "react-router-dom/es/withRouter";
const { Meta } = Card;
const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

function onChange(value) {
  console.log("onChange: ", value);
}

function onAfterChange(value) {
  console.log("onAfterChange: ", value);
}


const facilities = [
  "Wifi",
  "Baggage",
  "Meal",
  "Power / USB port",
  "In-flight entertainment"
];
const airline = ["Airasia", " Max Airline"];

class LeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 1,
      inputValue: 1,
      cutValue: 100,
      addValue: 5000,
      onBeforeValue: "0",
      onAfterValue: "0",


    };
  }

  onChangeValue = value => {
    this.setState({
      onBeforeValue: value
    });
  };

  onAfterChange = value => {
    console.log("onAfterChange: ", value);
    this.setState({
      onAfterValue: value
    });
  };

  // onAfterChange = value => {
  //     this.setState({
  //         addValue: value
  //     });
  // };

  goBack() {
    this.props.history.go(-1);
  }

  sortByOnChange = e => {
    console.log("radio checked", e.target.value);
    this.props.sortByCallback(e.target.value);
  };

  filterByOnChange = e => {
    console.log("switch checked", e);
    this.props.filterCallback('directlyFly', e);
  };


  onChangeBox(checkedValues) {
    console.log("checked = ", checkedValues);
  }

  // onGroupChange = (checkedList) => {
  //   this.setState({
  //     checkedList,
  //   });
  // }

  render() {
    const {
      inputValue,
      addValue,
      cutValue,
      onAfterValue,
      onBeforeValue,
    } = this.state;
    const addNum = onAfterValue[1];
    const beforeNum = onBeforeValue[0];
    // const { match, location, history, detail } = this.props;

    const dataAirlines = this.props.detail.airlines || [];

    return (
      <div id="LeftPanel">
        <div className="sort_result">
          <Card
            actions={[
              <Radio.Group onChange={this.sortByOnChange} value={this.props.sortBy}>
                <Radio value="lowest_price">Lowest Price</Radio>
                <Radio value="shortest_duration">Duration Shortest</Radio>
                <Radio value="earliest_departure">Departure Earliest</Radio>
                <Radio value="earliest_arrival">Arrival Earliest</Radio>
              </Radio.Group>
            ]}
          >
            <Meta
              title="Sort Results"
              description="Sort your search results by"
            />
          </Card>
        </div>

        <div className="filter_result">
          <Card
            actions={[
              <Collapse
                defaultActiveKey={["1"]}
                onChange={callback}
                expandIconPosition="right"
              >

                <Panel header="Direct" key="1">
                  <div className="slider_value">
                    <Switch onChange={this.filterByOnChange} checked={this.props.filterByDirectlyFly} /> Direct Flight Only
                  </div>
                </Panel>

                <Panel header="Airline" key="2">
                  <div className="checkbox_warp">
                    <Checkbox.Group
                      onChange={this.props.airlineFilter}
                    >
                      {
                        dataAirlines.map((item, i) => {
                          return (
                            <Checkbox key={i} value={item.code}>{item.name}</Checkbox>
                          )
                        })
                      }
                    </Checkbox.Group>

                  </div>
                </Panel>


                {/* 
                <Panel header="Price Range Per Flight" key="1">
                  <div className="slider_value">
                    <p>RM {onBeforeValue === '0' ? cutValue : beforeNum}</p>
                    <p>-</p>
                    <p>RM {onAfterValue === '0' ? addValue : addNum}</p>
                    <div className="clear"></div>
                  </div>

                  <Slider
                    range
                    step={10}
                    min={100}
                    max={10000}
                    defaultValue={[this.state.cutValue, this.state.addValue]}
                    onChange={this.onChangeValue}
                    onAfterChange={this.onAfterChange}
                  />
                </Panel>
                <Panel header="Facilities" key="2">
                  <div className="checkbox_warp">
                    <Checkbox.Group
                      options={facilities}
                      defaultValue={['Apple']}
                      onChange={onChangeBox}
                    />

                  </div>
                </Panel> */}

              </Collapse>
            ]}
          >
            <Meta
              title={[
                <div className="title" key={0}>
                  <p>Filter Results</p>
                  {/* <p>
                    <small>Reset Filter</small>
                  </p> */}
                </div>
              ]}
              description="Showing results based on categories"
              extra={<a href="#">More</a>}
            />

          </Card>
        </div>

      </div>
    );
  }
}

export default withRouter(LeftPanel);
