import React, { Component } from 'react';
import Search from '../search/index';
import Inspire from './inspire/index';
import Places from './places/index';
import More from './more/index';
import Explore from './explore/index';
import Footer from './footer/index';
import './index.css'

// const homepage = {
//   tagline: 'Your Confidence, Our Commitment!',
//   search_covers: [
//     {
//       img: require('../../../../images/banner/20.jpg')
//     }
//   ],
//   sliders: [
//     {
//       type: "tab_sliders",
//       title: 'Let us inspire you',
//       tabs: [
//         {
//           title: "TOP ACTIVITY",
//           covers: [
//             { img: require('../../../../images/top_activity/01.png') },
//             { img: require('../../../../images/top_activity/02.png') },
//             { img: require('../../../../images/top_activity/03.png') },
//             { img: require('../../../../images/top_activity/04.png') },
//             { img: require('../../../../images/top_activity/05.png') },
//             { img: require('../../../../images/top_activity/01.png') },
//             { img: require('../../../../images/top_activity/02.png') },
//             { img: require('../../../../images/top_activity/03.png') },
//             { img: require('../../../../images/top_activity/04.png') },
//             { img: require('../../../../images/top_activity/05.png') }
//           ]
//         },
//         {
//           title: "TOP WIFI",
//           covers: [
//             { img: require('../../../../images/roamin/01.png') },
//             { img: require('../../../../images/roamin/02.png') },
//             { img: require('../../../../images/roamin/03.png') },
//             { img: require('../../../../images/roamin/04.png') },
//             { img: require('../../../../images/roamin/01.png') },
//             { img: require('../../../../images/roamin/02.png') },
//             { img: require('../../../../images/roamin/03.png') },
//             { img: require('../../../../images/roamin/04.png') },
//           ]
//         }
//       ]
//     },

//     {
//       type: "tab_cards",
//       title: "Hot Destinations",
//       banner: require('../../../../images/small_banner/2.png'),
//       tabs: [
//         {
//           title: "",
//           cards: [
//             {
//               cover: require('../../../../images/top_place/asia/01.png'),
//               item1: {
//                 icon: require('../../../../images/hotel_logo/amari-watergate-bkk-logo.png'),
//                 title: 'Amari Watergate Bangkok',
//                 subtitle: "Location: Bangkok",
//                 hightlight: "Price From RM 456",
//               },
//               item2: {
//                 icon: require('../../../../images/hotel_logo/avani_bangkok.png'),
//                 title: 'AVANI Atrium Bangkok',
//                 subtitle: "Location: Bangkok",
//                 hightlight: "Price From RM 219",
//               }
//             },
//             {
//               cover: require('../../../../images/top_place/asia/05.png'),
//               item1: {
//                 icon: require('../../../../images/hotel_logo/marriott_hotel.png'),
//                 title: 'Marriott Sydney Harbour at Circular Quay',
//                 subtitle: "Location: Sydney",
//                 hightlight: "Price From RM 1333",
//               },
//               item2: {
//                 icon: require('../../../../images/hotel_logo/radisson.jpg'),
//                 title: 'Radisson Blu Plaza Hotel Sydney',
//                 subtitle: "Location: Sydney",
//                 hightlight: "Price From RM 1330",
//               }
//             },
//             {
//               cover: require('../../../../images/top_place/asia/03.png'),
//               item1: {
//                 icon: require('../../../../images/hotel_logo/The_Venetian_logo.png'),
//                 title: 'Venetian Macao Resort',
//                 subtitle: "Location: Macau",
//                 hightlight: "Price From RM 764",
//               },
//               item2: {
//                 icon: require('../../../../images/hotel_logo/Jw Marriott Macao.png'),
//                 title: 'JW Marriott Macau',
//                 subtitle: "Location: Macau",
//                 hightlight: "Price From RM 695",
//               }
//             },
//             {
//               cover: require('../../../../images/top_place/asia/04.png'),
//               item1: {
//                 icon: require('../../../../images/hotel_logo/W-hotel.jpg'),
//                 title: 'W Taipei',
//                 subtitle: "Location: Taipei",
//                 hightlight: "Price From RM 1493",
//               },
//               item2: {
//                 icon: require('../../../../images/hotel_logo/westgate_taipei_logo.jpg'),
//                 title: 'Westgate',
//                 subtitle: "Location: Taipei",
//                 hightlight: "Price From 569",
//               }
//             }
//           ]
//         }
//       ]
//     },
    
//     {
//       type: "slider",
//       title: "Airlines Deals",
//       covers: [
//         { img: require('../../../../images/explore_img/01.png') },
//         { img: require('../../../../images/explore_img/02.png') },
//         { img: require('../../../../images/explore_img/03.png') },
//         { img: require('../../../../images/explore_img/04.png') },
//         { img: require('../../../../images/explore_img/05.png') },
//         { img: require('../../../../images/explore_img/06.png') },
//         { img: require('../../../../images/explore_img/07.png') },
//         { img: require('../../../../images/explore_img/08.png') },
//       ]
//     }
//   ]
// }

class Homepage extends Component {

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    
    return (
      <div>
        <Search tagline={homepage_new.tagline} covers={homepage_new.search_covers} />
        {  
          <div id="Home" className={homepage_new.suka === 'false' ? ""  : "suka-margin"}>
          {
            homepage_new.sliders.map((slider) => {
              return (
                <div>
                  {
                    slider.type === "tab_sliders" ? (
                      <Inspire title={slider.title} tabs={slider.tabs} />
                    ) : slider.type === "banners" ? (
                      <Explore title={slider.title} tabs={slider.tabs} />
                    ) : slider.type === "tab_cards" ? (
                      <Places title={slider.title} tabs={slider.tabs} />
                    ) : slider.type === "sliders" ? (
                      <More title={slider.title} tabs={slider.tabs} />
                    ) : (null)
                  }
                </div>
              )
            })
          }
        </div>
        }
        <div className="footer-breakline" style={{ marginBottom: '4em' }} ></div>
        <Footer />
      </div>
    );
  }
}

export default Homepage;
