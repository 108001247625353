import React, { Component } from 'react';
import Search from '../../search/index';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import API from '../../../../api'
import { withRouter } from 'react-router-dom'
import { Spin } from 'antd';
import Footer from '../../home/footer/index';

class ListingActivity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            detail: [],
            globaltix: [],
            activity: [],
            isLoading: true,
            sortBy: 'lowest_price',
            params: this.props.location.search
        }
        this.myRef = React.createRef();

        this.sortByCallback = this.sortByCallback.bind(this);
    }

    componentDidMount() {
        this.myRef.current.scrollIntoView({
            behavior: "smooth",
        })
        this.apiFetch();
    }

    componentDidUpdate() {
        if (this.props.location.search != this.state.params) {
            this.setState({
                params: this.props.location.search,
                isLoading: true
            })
            console.log(this.props.location.search)
            this.apiFetch();
            this.myRef.current.scrollIntoView({
                behavior: "smooth",
            })
        }
    }

    sortByCallback(value){
      console.log(value, 'SortBy')
      this.setState({
        sortBy: value
      })
    }

    processDetail(data){
      const { sortBy } = this.state

      if(data && data.length){

        if(sortBy == 'lowest_price') {
          return data.sort((a, b) => a.price - b.price)
        } else if(sortBy == 'highest_price') {
          return data.sort((a, b) => b.price - a.price)
        }
      }
      return data
    }

    apiFetch() {
        const params = this.props.location.search;
        let url = './api/v1' + this.props.location.pathname;
        var that = this;
        console.log(url + params, "query url + params");

        API.get(url + params)
        .then(function (response) {
            console.log(response,'------------res');

            // let globaltix = [];
            // let activity = [];
          //     response.data.activities.forEach((myData) => {

          //         myData.globaltix && myData.globaltix.map((gt) => {
          //           globaltix.push(gt)
          //         })

          //         myData.activity && myData.activity.map((act) => {
          //           activity.push(act)
          //         })

          //     })

          // const combined = [...globaltix, ...activity]; // two arrays combined

            that.setState({
                // globaltix: globaltix,
                // activity: activity,
                detail : response.data.activities,
                isLoading: false,
            })
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    render() {
        const {isLoading, detail, sortBy } = this.state;
        let params = this.props.location.search;

        const data = this.processDetail(detail);
        console.log("Detail -->", data);
        return (
            <div>
                <Search tagline={homepage_new.tagline} covers={homepage_new.search_covers} />
                <Spin size="large" spinning={isLoading}>
                    <div id="ListingActivity" ref={this.myRef}>
                        <div className="warp" >
                            <LeftPanel sortByCallback={this.sortByCallback} sortBy={sortBy} />
                            <RightPanel detail={data} dataLength={data.length} loading={isLoading} defaultImage={homepage_new.default_image} />
                            <div className="clear"></div>
                        </div>
                    </div>
                </Spin>
                <Footer />
            </div>
        );
    }
}

export default withRouter(ListingActivity);
