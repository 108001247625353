import React, { Component } from 'react';
import { Button, Tabs, Card, Icon, Avatar, Rate, Typography, Popconfirm, message } from 'antd';
import './index.css'

const { TabPane } = Tabs;
const { Paragraph } = Typography;

class Places extends Component {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const tab = this.props.tabs[0];
    //console.log(this.props.tab, '-----tab')
    return (
      <div id="Places">

        {/*<div className="place_banner">
          {/*<img src={this.props.banner} alt="banner" />
           <div className="title">
                <span>Best </span>
                <span>Travel Deal</span>
                <div className="percent">
                <span>50% off</span>
               </div>
                Button type="primary">Primary</Button>
              </div>
        </div> */}

        <div className="content_title">
            <h1>{this.props.title}</h1>
         {/* 
          <p className="border_width"></p>

          <div>
             <p>
              In publishing and graphic design, Lorem ipsum is a placeholder text
              commonly used to demonstrate the visual form of a document without
              relying on meaningful content.
              Replacing the actual content with placeholder text allows designers
              to design the form of the content before the content itself has been produced.
             </p> 
          </div> 

        <div className="clear"></div> */}
        </div>
        
        <div >
          <div className="tab_content">
            {
              tab.cards.map((card) => {
                return (
                  <div className="card_style">
                    <Card
                      style={{ width: 300 }}
                      cover={<img src={card.cover} alt="example" />}
                      actions={
                        card.link ? 
                        [(
                          <div className="action_style">
                            <a href={`${card.link}`} target="_blank">{is_logged_in ? '' : [ homepage_new.suka === 'false' ? 'View More' : 'Info Lanjut']} &nbsp; <Icon type="right" className="icon_action" /></a>
                          </div>
                          )] 
                        : 
                        ([])}
                    >
                      <div className="card_meta">
                        {
                          card.item1.icon === "" ? (null) :
                          (
                            <div className="card_logo"><img src={card.item1.icon} alt="logo" /></div>
                          )
                        }
                        <div className="card_content">
                          <Paragraph ellipsis={{ rows: 1 }} className="ellipsis_style">{card.item1.title}</Paragraph>
                          {
                            card.item1.subtitle == "" ? (null) : (<p>{card.item1.subtitle}</p>)
                          }
                          {
                            card.item1.hightlight == "" ? (null) : (<p ><span className="price">{card.item1.hightlight}</span></p>)
                          }
                        </div>
                      </div>
                      {
                        card.item2.title === "" ? (null) :
                        (
                          <div className="card_meta">
                            {
                              card.item2.icon === "" ? (null) :
                              (
                                <div className="card_logo"><img src={card.item1.icon} alt="logo" /></div>
                              )
                            }
                            <div className="card_content">
                              <Paragraph ellipsis={{ rows: 1 }} className="ellipsis_style">{card.item2.title}</Paragraph>
                              {
                                card.item1.subtitle == "" ? (null) : (<p>{card.item2.subtitle}</p>)
                              }
                              {
                                card.item1.hightlight == "" ? (null) : (<p ><span className="price">{card.item2.hightlight}</span></p>)
                              }
                            </div>
                          </div>
                        )
                      }
                      <div className="clear"></div>
                    </Card>
                  </div>
                )
              })
            }


            <div className="clear"></div>


            {/* <Tabs defaultActiveKey="1">
              <TabPane tab={<span><img src={hk} alt="example" />Asia</span>} key="1">
                {
                  dataAsia.map((item, i) => {
                    return (
                      <div className="card_style">
                        <Card
                          style={{ width: 300 }}
                          cover={<img src={tab.img} alt="example" />}
                          actions={[
                            <div></div>,
                            <div className="action_style">View More</div>,
                            <Icon type="right" className="icon_action" />
                          ]}
                        >
                          <div className="card_meta">
                            <div><img src={hk} alt="ap" /></div>
                            <div>
                              <p>{tab.title}</p>
                              <p>{tab.title}</p>
                              <p >Price From : <span className="price">{tab.price}</span></p>
                            </div>
                          </div>
                          <div className="card_meta">
                            <div><img src={hk} alt="ap" /></div>
                            <div>
                              <p>{tab.title_2}</p>
                              <p>{tab.description}</p>
                              <p >Price From : <span className="price">{tab.price2}</span></p>
                            </div>
                          </div>
                          <div className="clear"></div>
                        </Card>
                      </div>
                    )
                  })
                }
                <div className="clear"></div>
              </TabPane>
              <TabPane tab={<span><img src={hk} alt="example" />Europe</span>} key="2">

                {
                  dataEurope.map((item, i) => {
                    return (
                      <div className="card_style">
                        <Card
                          style={{ width: 300 }}
                          cover={<img src={tab.img} alt="example" />}
                          actions={[
                            <div></div>,
                            <div className="action_style">View More</div>,
                            <Icon type="right" className="icon_action" />
                          ]}
                        >
                          <div className="card_meta">
                            <div><img src={hk} alt="ap" /></div>
                            <div>
                              <p>{tab.title}</p>
                              <p>{tab.description}</p>
                              <p >Price From : <span className="price">{tab.price}</span></p>
                            </div>
                          </div>
                          <div className="card_meta">
                            <div><img src={hk} alt="ap" /></div>
                            <div>
                              <p>{tab.title}</p>
                              <p>{tab.description}</p>
                              <p >Price From : <span className="price">{tab.price2}</span></p>
                            </div>
                          </div>
                          <div className="clear"></div>
                        </Card>
                      </div>
                    )
                  })
                }
                <div className="clear"></div>
              </TabPane>

            </Tabs> */}
          </div>
        </div>

        <div className="clear"></div>

      </div>
    );
  }
}

export default Places;
