import React, { Component } from "react";
import { Icon } from 'antd';
import "./index.css";
import { withRouter, Link } from 'react-router-dom';

class Footer extends Component {

  render() {

    return (
      <div className="Footer">
        <div className="warp">
          <div className="company">
            <div className="logo">
              <img src={homepage_new.app_logo} alt={homepage_new.app_name} />
              <span>{homepage_new.suka === 'false' ? homepage_new.app_name : ''}</span>
            </div>
            <div className="content">
              <p>
                {
                  homepage_new.suka === 'false' ? "Today, ICE Holidays Sdn. Bhd. is a leading Business-to-Business (B2B) Travel Wholesaler in Malaysia. Our 'B2B Travel Wholesale' business model is unique as we offer our partners ready-made products and packages, with advertisements and promotions support."
                  : "Suka Travel is a 100% pure Business to Business (B2B) Wholesale Agent specializing in Muslim Friendly Travel in Malaysia. This B2B platform will support Agents to get information, make booking/ reservations and payments effortlessly."
                }
            </p>
            </div>
          </div>

          {
            is_logged_in &&
              <div className="list_btn">
              <ul style={{display: "flex"}}>
                <li>
                  <Link to="/" className="footer_hover">
                    <div className="icon_warp">
                      <Icon type="home" theme="filled" />
                    </div>
                    <p>Home</p>
                  </Link>
                </li>
                <li>
                  <a href={homepage_new.pdpa} target="_blank" className="footer_hover">
                    <div className="icon_warp">
                      <Icon type="file-ppt" theme="filled" />
                    </div>
                    <p>PDPA</p>
                  </a>
                </li>
                {/* <li>
                  <a href={pdpa} target="_blank" className="footer_hover">
                    <div className="icon_warp">
                      <Icon type="safety-certificate" theme="filled" />
                    </div>
                    <p>Terms & Conditions</p>
                  </a>
                </li> */}
                <li>
                  <a href={homepage_new.privacy} target="_blank" className="footer_hover">
                    <div className="icon_warp">
                      <Icon type="lock" theme="filled" />
                    </div>
                    <p>Privacy Policy</p>
                  </a>
                </li>
                <li>
                  <a href={homepage_new.manual} target="_blank" className="footer_hover" disabled={homepage_new.suka === 'true'}>
                    <div className="icon_warp">
                      <Icon type="read" theme="filled" />
                    </div>
                    <p>User Guide</p>
                  </a>
                </li>
                <li>
                  <a href={homepage_new.series_terms} target="_blank" className="footer_hover">
                    <div className="icon_warp">
                      <Icon type="file" theme="filled" />
                    </div>
                    <p>T&C</p>
                  </a>
                </li>
                <div className="clear"></div>
              </ul>
            </div>
          }
          
        </div>
        <div className="copyright">
          <p>
          {
            homepage_new.suka === 'false' ? "Copyright © 2022 Travelb2b. Operated by Ice Holidays Sdn Bhd. All rights reserved."
            : "Copyright 2022 Suka Travel & Tours. Operated by Suka Travel & Tours. All rights reserved."
          }
          
          </p>
        </div>

      </div>
    );
  }
}

export default withRouter(Footer);
