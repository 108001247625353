import React, { Component } from 'react';
import Search from '../../search/index';
import LeftPanel from './leftPanel';
import RightPanel from './rightPanel'
import API from '../../../../api'
import { withRouter } from 'react-router-dom'
import { Spin } from 'antd';
import defaultImage from '../../../../../images/default-image.png';
import Footer from '../../home/footer/index';
import moment from 'moment';
import qs from 'qs'

class ListingLandTour extends Component {

  constructor(props) {
    super(props);
    this.state = {
      detail: [],
      isLoading: true,
      duration: 0,
      categories: [],
      sortBy: 'lowest_price',
      filterByDuration: 0,
      // filterByDuration: [0, 0],
      filterByCategory: [],
      params: this.props.location.search
    }
    this.myRef = React.createRef();
    this.sortByCallback = this.sortByCallback.bind(this);
  }

  componentDidMount() {
    this.apiFetch();
    this.myRef.current.scrollIntoView({
       behavior: "smooth",
    })
  }

  componentDidUpdate() {
    if (this.props.location.search != this.state.params) {
      this.setState({
          params: this.props.location.search,
      })
      console.log(this.props.location.search)
      this.apiFetch();
    }

    this.myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  }

  sortByCallback(value){
    console.log(value, 'SortBy')
    this.setState({
      sortBy: value
    })
  }

  rangeDuration(start, end) {
    return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
  }

  handleDurationChange = (value) => {
    // for range
    // if (value.length > 1) {
    //   var range = this.rangeDuration(value[0], Math.max(...value));
    // }
    // else {
    //   var range = this.rangeDuration(0, value[0]);
    // }
    this.setState({
      filterByDuration: value
    });
  };

  handleCategoryChange = (value) => {
    this.setState({
      filterByCategory: value
    });
  };

  processDetail(data){
    const { sortBy, filterByDuration, filterByCategory } = this.state

    if(data && data.length){
      // for range
      // if (!filterByDuration.every(x => x == 0)) {
      if (filterByDuration > 0) {
        data = data.filter(i => filterByDuration == i.duration);
      }

      if (filterByCategory.length > 0) {
        data = data.filter(i => filterByCategory.includes(i.category));
      }

      if(sortBy == 'lowest_price') {
        return data.sort((a, b) => a.price - b.price)
      } else if(sortBy == 'highest_price') {
        return data.sort((a, b) => b.price - a.price)
      }
    }
    return data
  }

  apiFetch() {
    const params = this.props.location.search;        
    let url = './api/v1' + this.props.location.pathname;
    var that = this;
    
    API.get(url + params)
      .then(function (response) {
        that.setState({
          detail: response.data.land_tours,
          duration: response.data.max_duration,
          categories: response.data.categories,
          isLoading: false,
        })
      })
      .catch(function (error) {
          console.log(error);
      })
      .then(function () {
          // always executed
      });
    }

  render() {
    const { sortBy, isLoading, detail, departure_date, duration, filterByDuration, categories, filterByCategory } = this.state;
    const data = this.processDetail(detail);

    return (
      <div>
        <Search tagline={homepage_new.tagline} covers={homepage_new.search_covers} />
        <Spin size="large" spinning={isLoading}>
          <div id="ListingLandTour" ref={this.myRef}>
            <div className="warp" >
              <LeftPanel sortByCallback={this.sortByCallback} sortBy={sortBy} duration={duration} filterByDuration={filterByDuration} handleDurationChange={this.handleDurationChange} categories={categories} filterByCategory={filterByCategory}  handleCategoryChange={this.handleCategoryChange} />
              <RightPanel loading={isLoading} dataLength={data.length} defaultImage={defaultImage} detail={data} />
              <div className="clear"></div>
            </div>
          </div>
        </Spin>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ListingLandTour);
