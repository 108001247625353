import React, { Component } from 'react';
import { Icon, Collapse, Input, Card, Button, Skeleton } from 'antd';
import './index.css'
import qs from 'qs'

const { Panel } = Collapse;

class RightPanel extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //
    }
  }

  priceFormat(value) {
    return value === undefined ? 0 : value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX.XX format
    // return value === undefined ? 0 : value.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') // this is for RM X,XXX format
  } 

  componentDidMount() {
  }
  
  render() {
    const price = this.props.dataList.price
    const totalQty = this.props.totalQty
    const total_price = price * totalQty
    const payment_type = this.props.payment_type
    const service_fee = this.props.service_fee;
    const final_price = this.props.final_price;

    if (payment_type == "public_bank" || payment_type == "public_bank_2") {
      var final_total = total_price + service_fee
    }
    else {
      var final_total = total_price
    }

    return (
      <div className="RightPanel res_panel_warp">
      {
          this.props.loading ? (
            <Skeleton active />
          ) : (
        <Card title={[
          <div className="title" key={0}>
            <h2>Summary</h2>
          </div>]}>
          <div className="dates">
            <p className="date_title">Voucher</p>
            <p className="date_select">{totalQty} x {this.props.dataList.title}</p>
          </div>

          <div className="select_list">
            <p className="note_label"><strong>Note:</strong> Full payment required for this booking.</p>
            {
              (payment_type == "public_bank" || payment_type == "public_bank_2") ?
                <div className="list_warp">
                  <span>Service Fees</span>
                  <span>RM {service_fee > 0 ? this.priceFormat(service_fee) : this.priceFormat(service_fee)}</span>
                </div>
              : ''
            }
            <div className="list_warp">
              <div className="total">
                <span>Grand Total</span>
                {
                  this.props.dataList.balance === undefined || this.props.dataList.balance === 0 ? (
                    <span className="sold_out">SOLD OUT</span>
                  ) : (
                    <span className="total_summary">RM {this.priceFormat(final_total)}</span>
                  )
                }
              </div>
            </div>
          </div>

        </Card>
        )
      }
      </div>
    );
  }
}

export default RightPanel;