import React, { Component } from 'react';
import './index.css';
import InfiniteScroll from 'react-infinite-scroller';
import ReactMarkdown from 'react-markdown/with-html';
import { List, Button, Icon, Rate, Skeleton, Empty, Typography, Tag, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter, Link } from 'react-router-dom';
import qs from 'qs';

const { Paragraph } = Typography;
const localData = [
   { kgEmpty: "-" }
]

class RightPanel extends Component {
   constructor(props) {
      super(props);
      this.state = {
        limit: 10,
        loadingMore: false,
        hasMore: true,
        isVisible: false,
        modalData: { title: '', cn_title: '', description: '', cn_description: '', category: '', location: '', code: '', departdate: '', min_booking: '' }
      }
   }

   /******************************************** showModal ***************************************************/
   showModal = (id) => {
      const modalData = this.props.detail.find(elm => {
         return elm.id === id
      })
      this.setState({
         isVisible: true,
         modalData: {
            title: modalData.title, other_caption: modalData.cn_title, description: modalData.description, cn_description: modalData.cn_description, category: modalData.category, location: modalData.country, code: modalData.code, departdate: modalData.travel_date, min_booking: modalData.min_booking
         }
      });
   };

   /******************************************** handleCancel ********************************************************/
   handleCancel = e => {
      // console.log(e);
      this.setState({
         isVisible: false,
      });
   };

   loadMoreData = () => {
      this.setState({
         loadingMore: true,
      });

      if (this.state.limit >= this.props.dataLength) {
         this.setState({
            loadingMore: false,
            hasMore: false
         });
      } else {
         setTimeout(() => {
            this.setState({
               limit: this.state.limit + 5,
               loadingMore: false,
            });
         }, 1000);
      }
   }

   render() {
      const { isVisible, modalData, limit, loadingMore, hasMore } = this.state;
      const dataDetail = this.props.detail;
      const lengthDetail = this.props.dataLength; 

      return (
         <div id="RightPanel">
            {
               // if data lenght 0 display empty data layout
               lengthDetail === 0 ? (
                  <div className="empty">
                     {
                        this.props.loading ? (
                           <Skeleton active avatar paragraph={{ rows: 4 }} />
                        ) : (
                              <Empty description="No Ground Tour Found" />
                           )
                     }
                  </div>
               ) : (
                      <InfiniteScroll
                        initialLoad={false}
                        loadMore={this.loadMoreData}
                        hasMore={!this.state.loadingMore && this.state.hasMore}
                     >
                        <List
                           itemLayout="vertical"
                           size="large"
                           dataSource={dataDetail.slice(0, limit)}
                           renderItem={item => (
                              <List.Item
                                 key={item.id}
                                 extra={[
                                    <div key={item.id} className="right_price">
                                       <p className="from"><small>From</small></p>
                                       <p className="price_tag">RM {item.price}</p>
                                       <p className="tax_tag"><small>Per Pax</small></p>
                                       <div className="select_btn">
                                          <Link to={{
                                             pathname: "/land_tours/" + item.id,
                                             search: "?month=" + encodeURIComponent(qs.parse(this.props.location.search, { ignoreQueryPrefix: true })['month'])
                                          }}
                                          >
                                            <Button type="primary" ghost>SELECT</Button>
                                          </Link>
                                       </div>

                                       <div className="clear"></div>
                                    </div>
                                 ]}
                              >
                                 <List.Item.Meta
                                    avatar={
                                      <img
                                        className="img_sty"
                                        alt="logo"
                                        src={item.image_url !== '' ? item.image_url : this.props.defaultImage}
                                      />}
                                    title={
                                       <div className="title_panel">
                                          <Paragraph ellipsis={{ rows: 2, }}
                                             className="ellipsis_style ellipsis_style_view">{item.title}</Paragraph>
                                          <span className="view_sty">
                                             <Icon type="question-circle" className="view_icon"
                                                onClick={() => this.showModal(item.id)}
                                             />
                                          </span>
                                          <div className="clear"></div>
                                       </div>
                                    }
                                    description={[
                                       <div key={item.id} className="content_rate">
                                            <div className="locate">
                                              <span className="locate_warp">
                                                <FontAwesomeIcon size="lg" icon='map-marker-alt' className="location_icon" />{item.country}
                                              </span>
                                              <span className="locate_warp">
                                                <Tag color="blue">{item.code}</Tag>
                                              </span>
                                            </div>
                                            <div className="date">
                                                <p>Travelling Period : {item.travel_date}</p>
                                                <div className="depart_style">
                                                  <Tag color="orange"><FontAwesomeIcon size="lg" icon='bolt' className="instant_icon" />Instant Confirmation</Tag>
                                                  <span className="locate_warp">
                                                    <Tag color="orange"><b>{item.min_booking}</b> To Go</Tag>
                                                  </span>
                                                  <span className="locate_warp">
                                                    <Tag color="orange">{item.category}</Tag>
                                                  </span>
                                                  {
                                                    // item.depart_day === "" ? (null) : (<Tag color="volcano">{item.depart_day}</Tag>)
                                                  }
                                                </div>
                                            </div>
                                         </div>
                                    ]}
                                 />
                              </List.Item>
                           )}
                        >
                           {this.state.loadingMore && this.state.hasMore && (
                              <Skeleton avatar title={false} paragraph={{ rows: 4 }} loading={this.state.loadingMore} active></Skeleton>
                           )}
                        </List>
                    </InfiniteScroll>
                  )
            }
            {/************************ Modal list content diaplay **************************/}
            <Modal
               title={
                  <React.Fragment>
                     <span>{modalData.title}</span>
                     <br />
                     <small>{modalData.cn_title}</small>
                  </React.Fragment>
               }
               visible={isVisible}
               onCancel={this.handleCancel}
               footer={null}
               className="modal_land_tour_listing"
            >
               <p>
                  <span className="modal_locate_warp">
                    <FontAwesomeIcon icon='map-marker-alt' size='lg' className="location_icon" />{modalData.location}
                  </span>
                  <span className="modal_locate_warp">
                    <Tag color="blue">{modalData.code}</Tag>
                  </span>
                  <span className="modal_locate_warp">
                    <Tag color="orange"><b>{modalData.min_booking}</b> To Go</Tag>
                  </span>
                  <span className="modal_locate_warp">
                     <Tag color="orange">{modalData.category}</Tag>
                  </span>
               </p>
               <p className="departure_style">Travelling Period</p>
               <p className="departure"><Tag color="orange">{modalData.departdate}</Tag></p>
               {
                  modalData.description !== '' ?
                     <React.Fragment>
                        <p className="departure_style">Highlight</p>
                        <p className="departure"><ReactMarkdown source={modalData.description} escapeHtml={false} /></p>
                        <p className="departure"><ReactMarkdown source={modalData.cn_description} escapeHtml={false} /></p>
                     </React.Fragment>
                     : ''
               }
            </Modal>

         </div>
      );
   }
}

export default withRouter(RightPanel);
