import React, { Component } from 'react';
import { Table, Tag, Typography, Modal, Button, } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import MenuBooking from '../menu';
import API from '../../../../api'
import './index.css';
import moment from 'moment'

const { Paragraph } = Typography;

const expandedRowRender = (record) => {
  const columns1 = [
    { title: 'Flight Carrier', dataIndex: 'carrier', key: 'carrier' },
    { title: 'Cabin Class', dataIndex: 'cabin_class', key: 'cabin_class' },
    { title: 'Origin/Terminal ', dataIndex: 'origin_terminal', key: 'origin_terminal' },
    { title: 'Destination/Terminal', dataIndex: 'destination_terminal', key: 'destination_terminal' },
    { title: 'Departure', dataIndex: 'departure_time', key: 'departure_time' },
    { title: 'Arrival', dataIndex: 'arrival_time', key: 'arrival_time' },
    { title: 'Travel Time', dataIndex: 'travel_time', key: 'travel_time' },
    {
      title: 'Remark', dataIndex: 'sell_message', key: 'sell_message', width: 300,
      render: remark => (
        <Paragraph ellipsis={{ rows: 1, expandable: true }}>
          {remark}
        </Paragraph>
      ),
    }
  ]
  const columns2 = [
    { title: 'Designation', dataIndex: 'designation', key: 'designation' },
    { title: 'First Name', dataIndex: 'first_name', key: 'first_name' },
    { title: 'Last Name', dataIndex: 'last_name', key: 'last_name' },
    { title: 'Country', dataIndex: 'country', key: 'country' },
    { title: 'Mobile', dataIndex: 'mobile', key: 'mobile' },
    { title: 'Passport Number', dataIndex: 'passport_number', key: 'passport_number' },
    { title: 'Passport Issue Date', dataIndex: 'passport_issue_date', key: 'passport_issue_date' },
    { title: 'Passport Expirty Date', dataIndex: 'passport_expiry_date', key: 'passport_expiry_date' },
    { title: 'Passport Issue Place', dataIndex: 'passport_issue_place', key: 'passport_issue_place' },
    { title: 'Date of Birth', dataIndex: 'date_of_birth', key: 'date_of_birth' },
    { title: 'Category', dataIndex: 'category', key: 'category' },
    { title: 'Remark', dataIndex: 'remark', key: 'remark' },
    { title: 'BRB', dataIndex: 'brb', key: 'brb' }
  ]

  return <div>
    <Table columns={columns1} dataSource={record.flight_details} pagination={false} />
    <Table columns={columns2} dataSource={record.flight_bookings} pagination={false} />
  </div>;

}


class FlightBooking extends Component {
  state = {
    data: [],
    loading: false,
    visible: false,
    modalData: [],
    bookingAddonsModalVisible: false,
    bookingAddonsModalData: "",
    currentAddonsPassengerData: ""
  };

  showModal(record) {
    console.log('record', record)
    this.setState({
      visible: true,
      // modalData: record,
      modalData: this.state.data.filter((element) => {
        return element.code == record
      })
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  componentDidMount() {
    this.apiFetch();
  }

  apiFetch(params = '') {
    // const params = this.props.location.search;
    let url = './api/v1/flights/bookings';
    var that = this;
    console.log(params);

    API.get(url + params)
      .then(function (response) {
        console.log(response, '------------res');
        that.setState({
          data: response.data,
          isLoading: false
        })

      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });

  }

  render() {
    const { data, modalData } = this.state;
    const infor = modalData[0];
    console.log('modalData-------', modalData)
    console.log('data-------', data)
    console.log('infor-----', infor)


    const columns = [
      {
        title: 'Booking Date',
        dataIndex: 'created_at',
        key: 'created_at',
        width: 130,
      },
      {
        title: 'PNR',
        dataIndex: 'code',
        key: 'code',
        width: 150,
      },
      {
        title: 'Ref No.',
        width: 150,
        dataIndex: 'ref_no',
        key: 'ref_no',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 210,
      },
      // {
      //   title: 'Price',
      //   dataIndex: 'price',
      //   key: 'price',
      //   width: 150,
      //   align: 'center'
      // },
      // {
      //   title: 'Pax',
      //   dataIndex: 'pax',
      //   key: 'pax',
      //   width: 100,
      //   align: 'center'
      // },
      // {
      //   title: 'Segment',
      //   dataIndex: 'segment',
      //   key: 'segment',
      //   width: 100,
      //   align: 'center'
      // },
      {
        title: 'Origin Destination',
        dataIndex: 'orgin_destination',
        key: 'orgin_destination',
        width: 200,
      },
      {
        title: 'Ticket Number',
        dataIndex: 'ticket_no',
        key: 'ticket_no',
        width: 150,
      },
      {
        title: 'Status',
        key: 'code',
        dataIndex: 'code',
        width: 50,
        render: (code) => {
          const queriedData = this.state.data.find(x => x.code === code)

          return (
            <span>
              <Tag color={queriedData.payment_status == 'Paid' ? 'green' : queriedData.payment_status == 'Failed' ? 'volcano' : queriedData.payment_status == 'Pending' ? 'geekblue' : ''} key={0}>
                {queriedData.payment_status}
                <br />
                {
                  (queriedData.payment_status === 'Awaiting' || queriedData.payment_status === 'Pending' || queriedData.payment_status === 'Partial Paid') && queriedData.ttl !== null ?
                  (
                    <span><b>Till</b> {moment(queriedData.ttl).format("YYYY-MM-DD HH:mm")}</span>
                  )
                  :
                  (
                    null
                  )
                }
              </Tag>
              {/*
                (queriedData.payment_status === 'Awaiting' || queriedData.payment_status === 'Pending' || queriedData.payment_status === 'Partial Paid') && queriedData.ttl !== null ?
                (
                  <Tag key={0}>
                    {"Till: " + moment(queriedData.ttl).format("YYYY-MM-DD HH:mm")}
                  </Tag>
                )
                :
                (
                  null
                )
              */}
            </span>
          )
        }
      },
      {
        title: 'Action',
        width: 80,
        key: 'action',
        dataIndex: 'code',
        render: (record) => (
          <Button type="link" onClick={() => this.showModal(record)} >View</Button>
        ),
      },
    ];

    console.log('record', this.state.data)

    return (
      <div id="FlightContainer">
        <MenuBooking selected="flight" />
        <div className="content">
          <p className="title">Flight Bookings</p>
          <Table
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            // onChange={this.handleTableChange}
            // expandedRowRender={expandedRowRender}
            scroll={{ x: 'max-content' }}
            className="table_warp" />
        </div>
        <Modal
          // title="Order Information"
          visible={this.state.visible}
          onOk={this.handleOk}
          okText="DOWNLOAD INVOICE"
          onCancel={this.handleCancel}
          cancelText="RESEND CONFIRMATION"
          className="bookingModal_sty"
          destroyOnClose={true}
          footer={
            <span>
            {
              infor && infor.code !== undefined && !infor.payment_status.includes('Paid') && moment(infor.ttl).isAfter(moment()) ?
              <Link style={{ marginRight: 10 }} to={{ pathname: "/bookings/flights/" + infor.pay_code + "/addon", state: { data: infor } }}><Button type="primary">PAY NOW</Button></Link> : ''
            }
            {
              infor && infor.invoices !== undefined && infor.invoices.length > 0 ? 
                infor.invoices.length > 1 ?
                  <Dropdown overlay={
                    <Menu>
                    {
                      infor.invoices.map((invoice, index) => {
                        return(
                          <Menu.Item key={index}>
                            <a href={invoice.url} target="_blank">Invoice {index + 1}</a>
                          </Menu.Item>
                        )
                      })
                    }
                    </Menu>
                  }>
                    <Button type="primary">
                      DOWNLOAD INVOICES <Icon type="down" />
                    </Button>
                  </Dropdown> : 
                  <Button type="primary">
                    <a href={infor.invoices[0].url} target="_blank">DOWNLOAD INVOICE</a>
                  </Button> 
              : ''
            }
            {
              infor && infor.receipts !== undefined && infor.receipts.length > 0 ? 
                infor.receipts.length > 1 ?
                  <Dropdown overlay={
                    <Menu>
                    {
                      infor.receipts.map((receipt, index) => {
                        return(
                          <Menu.Item key={index}>
                            <a href={receipt.url} target="_blank">Receipt {index + 1}</a>
                          </Menu.Item>
                        )
                      })
                    }
                    </Menu>
                  }>
                    <Button type="primary">
                      DOWNLOAD RECEIPTS <Icon type="down" />
                    </Button>
                  </Dropdown> : 
                  <Button type="primary">
                    <a href={infor.receipts[0].url} target="_blank">DOWNLOAD RECEIPT</a>
                  </Button> 
              : ''
            }
            {
              infor && infor.credit_notes !== undefined && infor.credit_notes.length > 0 ? 
                infor.credit_notes.length > 1 ?
                  <Dropdown overlay={
                    <Menu>
                    {
                      infor.credit_notes.map((credit_note, index) => {
                        return(
                          <Menu.Item key={index}>
                            <a href={credit_note.url} target="_blank">Credit Note {index + 1}</a>
                          </Menu.Item>
                        )
                      })
                    }
                    </Menu>
                  }>
                    <Button type="primary">
                      DOWNLOAD CREDIT NOTES <Icon type="down" />
                    </Button>
                  </Dropdown> : 
                  <Button type="primary">
                    <a href={infor.credit_notes[0].url} target="_blank">DOWNLOAD CREDIT NOTES</a>
                  </Button> 
              : ''
            }
            </span>
          }
        >
          {
            modalData.length === 0 ? null :
              <div className="table_order">
                <h2>Order Information</h2>
                <div className="responsive_sroll">
                  <div className="booking_modal_table_warp">
                    <h3>Personal Details</h3>
                    {
                      infor.flight_bookings.map((booking, booking_index) => {
                        return (
                          <React.Fragment>
                            <a onClick={(e) => { e.preventDefault() }}><p style={{ marginBottom: '10px', marginTop: infor.flight_bookings.length > 1 && booking_index === infor.flight_bookings.length - 1 ? '15px' : 0 }}>Passenger {booking_index + 1}</p></a>
                            <table className="booking_modal_table">
                              <tr>
                                <th>Designation</th>
                                <td>{booking.designation}</td>
                                <th>First Name</th>
                                <td>{booking.first_name}</td>
                              </tr>
                              <tr>
                                <th>Last Name</th>
                                <td>{booking.last_name}</td>
                                <th>Nationality</th>
                                <td>{booking.country}</td>
                              </tr>
                              <tr>
                                <th>Mobile</th>
                                <td>{booking.mobile}</td>
                                <th>Passport Number</th>
                                <td>{booking.passport_number}</td>
                              </tr>
                              <tr>
                                <th>Passport Issue Date</th>
                                <td>{booking.passport_issue_date}</td>
                                <th>Passport Expirty Date</th>
                                <td>{booking.passport_expiry_date}</td>
                              </tr>
                              <tr>
                                <th>Passport Issue Place</th>
                                <td>{booking.passport_issue_place}</td>
                                <th>Date of Birth</th>
                                <td>{booking.date_of_birth}</td>
                              </tr>
                              <tr>
                                <th>Category</th>
                                <td>{booking.category}</td>
                                <th>Remark</th>
                                <td>{booking.remark}</td>
                              </tr>
                              <tr>
                                <th>BRB</th>
                                <td>{booking.brb}</td>
                                <th>Ticket Number</th>
                                <td>{booking.ticket_no}</td>
                              </tr>
                              
                            </table>
                          </React.Fragment>
                        )
                      })
                    }
                  </div>

                  <div className="booking_modal_table_warp">
                    <h3>Flight Details</h3>
                    <table className="booking_modal_table">
                      <tr>
                        <th>Price</th>
                        <td>{infor.price}</td>
                        <th>Surcharge</th>
                        <td>{infor.total_service_fee}</td>
                      </tr>
                    </table>
                    {
                      infor.flight_details.map((item, index) => {
                        return (
                          <table className="booking_modal_table booking_modal_table_2">
                            <tr>
                              <th>Flight Carrier</th>
                              <td>{item.carrier}</td>
                              <th>Cabin Class</th>
                              <td>{item.cabin_class}</td>
                            </tr>
                            <tr>
                              <th>Origin/Terminal</th>
                              <td>{item.origin_terminal}</td>
                              <th>Destination/Terminal</th>
                              <td>{item.destination_terminal}</td>
                            </tr>
                            <tr>
                              <th>Departure</th>
                              <td>{item.departure_time}</td>
                              <th>Arrival</th>
                              <td>{item.arrival_time}</td>
                            </tr>
                            <tr>
                              <th>Travel Time</th>
                              <td>{item.travel_time}</td>
                              <th>Remark</th>
                              <td>
                                <Paragraph ellipsis={{ rows: 1, expandable: true }}>
                                  {item.sell_message}
                                </Paragraph>
                              </td>
                            </tr>
                            <tr>
                              <th>Add-Ons</th>
                              <td colSpan={3}>
                                {
                                  infor.booking_addons !== undefined ?
                                  (
                                    <React.Fragment>
                                      {
                                        infor.booking_addons.find(x => x.journey.includes(item.journey)).passengers.map((passenger, passenger_index) => {
                                          return (
                                            <React.Fragment>
                                              <a onClick={(e) => { e.preventDefault() /* this.setState({ bookingAddonsModalVisible: true, bookingAddonsModalData: passenger, currentAddonsPassengerData: passenger_index }) */ }}><p style={{ marginBottom: '5px' }}>Passenger {passenger_index + 1}</p></a>
                                              {
                                                passenger.segments !== undefined ?
                                                (
                                                  passenger.segments.map((segment, segment_index) => {
                                                    return (
                                                      <React.Fragment>
                                                        <p><b>{segment.segment_name}</b></p>
                                                        <ul>
                                                          {
                                                            segment.addons.map((addon, addon_index) => {
                                                              const addonKind = addon.kind.includes('meal') ? "Meal" : addon.kind.includes('baggage') ? "Baggage" : addon.kind.includes('seat') ? "Seat" : addon.kind
                                                              return (
                                                                <p style={{ marginBottom: '2px' }}><b>{addonKind}:</b> {addon.name}</p>
                                                              )
                                                            })
                                                          }
                                                        </ul>
                                                      </React.Fragment>
                                                    )
                                                  })
                                                )
                                                :
                                                (
                                                  null
                                                )
                                              }
                                            </React.Fragment>
                                          )
                                        })
                                      }
                                    </React.Fragment>
                                  )
                                  :
                                  (
                                    null
                                  )
                                }
                              </td>
                            </tr>
                          </table>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
          }
        </Modal>
        <Modal
          title={`Passenger ${this.state.currentAddonsPassengerData + 1}: Add-On Details`}
          visible={this.state.bookingAddonsModalVisible}
          onCancel={() => { this.setState({ bookingAddonsModalVisible: false }) }}
          cancelText="Close"
          okText={null}
          className="bookingModal_sty"
          destroyOnClose={true}
          okButtonProps={{
            style: {
              display: "none",
            },
          }}
        >
          {
            this.state.bookingAddonsModalData.segments !== undefined ?
            (
              this.state.bookingAddonsModalData.segments.map((segment, segment_index) => {
                return (
                  <React.Fragment>
                    <p><b>{segment.segment_name}</b></p>
                    <ul>
                      {
                        segment.addons.map((addon, addon_index) => {
                          const addonKind = addon.kind.includes('meal') ? "Meal" : addon.kind.includes('baggage') ? "Baggage" : addon.kind.includes('seat') ? "Seat" : addon.kind
                          return (
                            <li><b>{addonKind}:</b> {addon.name}</li>
                          )
                        })
                      }
                    </ul>
                  </React.Fragment>
                )
              })
            )
            :
            (
              null
            )
          }
        </Modal>
      </div>
    );
  }
}

export default FlightBooking;